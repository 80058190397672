import React from "react";
import PropTypes from "prop-types";
import { GridItem, Users } from "../../../styling/styles/grid.style.js";
import { Box, Typography } from "@mui/material";
import { userPropMap } from "../Grids.js";
import nightModebg from "../../../assets/image/room/nightModeBg.webp";
import wifiIcon from "../../../assets/icons/room/wifiIcon.svg";
import muteIcon from "../../../assets/icons/room/muteIcon.svg";
import AudioDetector from "../audio-detector/AudioDetector.js";
import SmallAudioDetector from "../audio-detector/SmallAudioDetector.js";

const UserLocalGridCard = ({
  stream,
  gridClass,
  isAniamtion,
  userIndex,
  isWakedUser,
  mediaStream,
  smallAnimatorRef,
  username,
  role,
}) => {
  const {
    localVideoCont,
    viewer,
    userPersonalAction,
    roomData,
    isNightMode,
    micStatus,
    user,
    localStream,
    isAdmin,
    playerAbility,
    setAudioLevel,
    captureMediaStreams
  } = stream;
  const userPersonalaActionProp = userPropMap[userPersonalAction];
  const playerInfo = roomData?.players?.find(
    (player) => player.username === user.username,
  );
  const avatar = playerInfo?.avatar;

  let isMafiaPlayer = playerAbility?.side === "MAFIA";

  const audioStatusClass = isNightMode
    ? null
    : micStatus
      ? "audio-detector"
      : "mute-mic";

  const showMuteMic = audioStatusClass === "mute-mic";
  const showAudioDetector = audioStatusClass === "audio-detector";
  return (
    !viewer && (
      <GridItem
        shouldanimate={false}
        className={`${gridClass} item${userIndex} grid-item`}
      >
        <Users
          id={username + "-grid"}
          ref={localVideoCont}
          className={`grid-alignment ${isAniamtion ? "gird-animation" : ""}`}
          style={{
            border: `3px solid transparent`,
            transition: `border-color 0.2s ease`,
            borderRadius: "18px",
            borderColor: 
              userPersonalaActionProp
                ? userPersonalaActionProp.borderColor
                : "transparent",
            ...(isNightMode &&
              isMafiaPlayer && { border: "3px solid var(--darkRed)" }),
          }}
        >
          <video
            className="video"
            muted
            autoPlay
            playsInline
            ref={(video) => {
              if (video) {
                if (!video.srcObject && localStream) {
                  video.srcObject = localStream;
                } else if (!localStream && video.srcObject) {
                  video.srcObject = null;
                }
              }
            }}
          ></video>
          <Box className="avatar-box">
            {/*!micStatus this for when player only accpet open mic ,while waking up , we have to show avatar  */}
            {isNightMode && !isWakedUser && !isAdmin ? (
              <img
                className="night-mode-bg"
                src={nightModebg}
                alt="night"
              ></img>
            ) : (
              avatar && (
                <img
                  className={`avatar grid-screen-avatar`}
                  src={avatar}
                  alt="avatar"
                />
              )
            )}
          </Box>
          {userPersonalaActionProp && !isNightMode && (
            <Box
              key={userPersonalaActionProp.icon}
              className="user-actions-icons"
            >
              <img src={userPersonalaActionProp.icon} alt="like" />
            </Box>
          )}
          <Box className="user-status-wrapper">
            <Box className="user-name-box">
              <img
                src={wifiIcon}
                alt="online"
                style={{ width: "17px", height: "17px" }}
              ></img>
              <Typography
                color="var(--white)"
                fontSize={"14px"}
                fontWeight={"50"}
              >
                {username}
              </Typography>

              {showMuteMic ? (
                <img src={muteIcon} alt="mute" width="13px" height="13px" />
              ) : (
                showAudioDetector && (
                  <SmallAudioDetector
                    username={username}
                    smallAnimatorRef={smallAnimatorRef}
                  />
                )
              )}
            </Box>
          </Box>
          {showAudioDetector && (
            <AudioDetector
              smallAnimatorRef={smallAnimatorRef}
              mediaStream={mediaStream}
              username={username}
              setAudioLevel={setAudioLevel}
              captureMediaStreams={captureMediaStreams}
            />
          )}

          {role !== "GOD" && <Box className="grid-number-box">{userIndex}</Box>}
        </Users>
      </GridItem>
    )
  );
};
UserLocalGridCard.propTypes = {
  stream: PropTypes.any,
  gridClass: PropTypes.any.isRequired,
  isAniamtion: PropTypes.bool,
  userIndex: PropTypes.number,
  isWakedUser: PropTypes.bool.isRequired,
  mediaStream: PropTypes.any,
  smallAnimatorRef: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};
export default UserLocalGridCard;
