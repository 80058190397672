import React from "react";
import { Box, Button, Typography } from "@mui/material";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  createVideoRoomCoins,
  createAudioRoomCoins,
} from "../../../../constants/gameLobbyConstant.js";
import coinIcon from "../../../../assets/icons/coinIconSm.webp";

const CreateButton = ({
  handleActions,
  selectedGameInfo,
  roomType,
  infoBtn2Ref,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      ref={infoBtn2Ref}
      name="create-btn"
      onClick={() =>
        handleActions(
          !selectedGameInfo.isLocked && {
            actionType: "CREATE",
            coins:
              roomType === "VIDEO"
                ? createVideoRoomCoins
                : createAudioRoomCoins,
          },
        )
      }
      type="submit"
      color="primary"
      variant="contained"
      size="large"
      className="info-btn2"
    >
      <Box
        sx={{
          display: "flex",
          gap: "3px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!selectedGameInfo.isLocked && t("APP.LOBBY.INFO_TAB.CREATE_BTN")}

        {!selectedGameInfo.isLocked && (
          <Box className="coins-required">
            <img src={coinIcon} alt="coin" />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "var(--lemonYellow)",
              }}
            >
              {roomType === "VIDEO"
                ? createVideoRoomCoins
                : createAudioRoomCoins}
            </Typography>
          </Box>
        )}
      </Box>
    </Button>
  );
};
CreateButton.propTypes = {
  handleActions: propTypes.func.isRequired,
  selectedGameInfo: propTypes.any.isRequired,
  roomType: propTypes.string.isRequired,
  infoBtn2Ref: propTypes.object.isRequired,
};
export default CreateButton;
