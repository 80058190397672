import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  socket: null,
  connected: false,
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setSocketConnection: (state, { payload }) => {
      state.socket = payload;
    },
    connectSocket: (state) => {
      state.connected = true;
    },
    disconnectSocket: (state) => {
      state.connected = false;
    },
  },
  extraReducers: (builder) => {},
});
export const { setSocketConnection, connectSocket, disconnectSocket } =
  socketSlice.actions;
export default socketSlice.reducer;
