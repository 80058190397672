import React from "react";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
const CopyTooltip = ({ CopyIcon, open }) => {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      arrow
      // onClose={handleTooltipClose}
      open={open}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: "13px",
            bgcolor: "var(--darkGreen)",
            "& .MuiTooltip-arrow": {
              color: "var(--darkGreen)",
            },
          },
        },
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="Coppied"
    >
      {CopyIcon}
    </Tooltip>
  );
};
CopyTooltip.propTypes = {
  CopyIcon: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};
export default CopyTooltip;
