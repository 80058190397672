import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Player } from "./Player.js";
import PropTypes from "prop-types";
import useGameRoomTable from "../../../hooks/game-room/auto-mode/roomTable.hook.js";

let animateTimer;
export const RoomTable = ({ stream }) => {
  const roomTableProps = useGameRoomTable({ stream });
  const { seconds } = roomTableProps;

  const micStatus = true;

  const { screenArray } = stream;
  const dataLength = screenArray.length;

  const [animationIdx, setAnimationIdx] = useState(0);

  useEffect(() => {
    if (dataLength < 1 || animateTimer) return;
    animateTimer = setInterval(() => {
      setAnimationIdx((pre) => {
        pre++;
        if (pre >= dataLength - 1) {
          clearInterval(animateTimer);
        }
        return pre;
      });
    }, 400);
  }, [screenArray]);

  return (
    <>
      <Box className="room-table-parent-wrapper">
        {/* <Box className="content"> */}

        <Box className="players-wrapper">
          <Box className="top-players-wrapper">
            <Player
              idx={5}
              data={screenArray[5]}
              stream={stream}
              visibility={
                dataLength > 5 && animationIdx >= 5 ? "visible" : "hidden"
              }
            />

            <Player
              idx={4}
              data={screenArray[4]}
              stream={stream}
              visibility={
                dataLength > 4 && animationIdx >= 4 ? "visible" : "hidden"
              }
            />
          </Box>

          <Box className="middle-players-wrapper">
            <Box className="middle-left-wrapper">
              <Player
                idx={6}
                data={screenArray[6]}
                stream={stream}
                visibility={
                  dataLength > 6 && animationIdx >= 6 ? "visible" : "hidden"
                }
              />

              <Player
                idx={7}
                data={screenArray[7]}
                stream={stream}
                visibility={
                  dataLength > 7 && animationIdx >= 7 ? "visible" : "hidden"
                }
              />
              <Player
                idx={8}
                data={screenArray[8]}
                stream={stream}
                visibility={
                  dataLength > 8 && animationIdx >= 8 ? "visible" : "hidden"
                }
              />
            </Box>
            <Box className="middle-right-wrapper">
              <Player
                idx={3}
                data={screenArray[3]}
                stream={stream}
                visibility={
                  dataLength > 3 && animationIdx >= 3 ? "visible" : "hidden"
                }
              />
              <Player
                idx={2}
                data={screenArray[2]}
                stream={stream}
                visibility={
                  dataLength > 2 && animationIdx >= 2 ? "visible" : "hidden"
                }
              />

              <Player
                idx={1}
                data={screenArray[1]}
                stream={stream}
                visibility={
                  dataLength > 1 && animationIdx >= 1 ? "visible" : "hidden"
                }
              />
            </Box>

            {seconds > 0 && (
              <Box sx={{ zIndex: "10" }}>
                <Typography
                  sx={{
                    fontSize: "1.5vw",
                    color: "var(--stealBlue)",
                    marginBottom: "-0.55vw",
                  }}
                >
                  Game is starting in
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "3vw",
                    color: "var(--stealBlue)",
                  }}
                >
                  {seconds}
                </Typography>
              </Box>
            )}
          </Box>

          <Box className="bottom-players-wrapper">
            <Player
              idx={9}
              data={screenArray[9]}
              stream={stream}
              visibility={
                dataLength > 9 && animationIdx >= 9 ? "visible" : "hidden"
              }
            />
            <Player
              idx={0}
              micStatus={micStatus}
              data={screenArray[0]}
              stream={stream}
              visibility={
                dataLength > 0 && animationIdx >= 0 ? "visible" : "hidden"
              }
            />
          </Box>
        </Box>

        {/* </Box> */}
      </Box>
    </>
  );
};

RoomTable.propTypes = {
  stream: PropTypes.any.isRequired,
};
