import Cookies from 'js-cookie';
import axios from 'axios';
import store from "../store/store.js";
import { refreshTokenApi } from "../features/auth/authThunks.js";
import { logoutUser } from "../features/auth/authSlice.js";
import { setLanguage } from "../features/global/globalSlice.js";

const dispatch = store.dispatch;

const getToken = () => {
  return Cookies.get('accessToken'); 
};

const isTokenExpired = (token) => {
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      return exp < Date.now() / 1000;
    } catch {
      return true;
    }
};
  
const setupAuthInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      if (config.headers['Skip-Interceptor'] || config._retry) {
         return config;
      }

      let token = getToken();
      if (token === undefined || isTokenExpired(token)) {
        config._retry = true;
        try {
          await dispatch(refreshTokenApi({})).unwrap();
        } catch (error) {
          console.error('Refresh Token Failed:', error);
          dispatch(logoutUser());
          dispatch(setLanguage("")); 
        }
      } 
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default setupAuthInterceptor;