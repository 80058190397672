import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as AutoIcon } from "../../assets/icons/lobby/autoIcon.svg";
import { ReactComponent as ManualIcon } from "../../assets/icons/lobby/manualIcon.svg";
import { useTheme } from "@mui/material/styles/index.js";
import { useJoinRoom } from "./hooks.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PRIVATE, AUTO, MANUAL } from "../../constants/gameRoomConstants.js";
import PasswordInput from "../auth/common/PasswordInput.js";
import { useTranslation } from "react-i18next";
import { alertDialog } from "../../features/global/globalSlice.js";
import WaitingPopup from "./WaitingPopup.js";
import { rankedJoinRoomList } from "../../data/game-lobby/index.js";
import { useHandleInfoTab } from "../../hooks/game-lobby/waitlist.hook.js";
import CustomScenarioPopup from "./CustomScenarioPopup.js";
import {
  CreateButton,
  WatchButton,
  RejoinButton,
  JoinButton,
} from "./info-tab/buttons/index.js";
import RecommendBrowserModal from "./RecommendBrowserModal.js";
import { browserName } from "react-device-detect";
let timeoutId;
let actionType;
let coinsRequired;
const privacy = PRIVATE;
function InfoTab({
  selectedGameInfo,
  slectedTab,
  value,
  setRoomLists,
  roomLists,
  teachComponentsRef,
}) {
  const { user } = useSelector((state) => state.auth);
  const { createRoomList } = useSelector((state) => state.gameLobby);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const theme = useTheme();
  const { t } = useTranslation();
  let isCreateMode = slectedTab.gameMode === "create" && value === 0;
  const gameScenario = selectedGameInfo.scenario.replace(/\s/g, "_");
  const [openWaitingPopup, setOpenWaitingPopup] = useState(false);
  const [openCustomScenarioPopup, setOpenCustomScenarioPopup] = useState(false);
  const [godMode, setGodMode] = useState(MANUAL);
  const [roomType, setRoomType] = useState("VIDEO");
  const [password, setPassword] = useState("");
  const infoBtn2Ref = useRef(null);
  const [validation, setValidation] = useState([]);
  const [passwordRequired, setPasswordRequired] = useState(true);
  const [openRecomendModal, setOpenRecomendModal] = useState(false);
  const [preserveActionFunc, setPreserveActionFunc] = useState(() => {
    return () => {};
  });

  const { handleRoomOpperation, handleCheckPassword, errorCode } =
    useJoinRoom();
  const { addToWaitList, removeToWaitList } = useHandleInfoTab({
    setRoomLists,
    handleRoomOpperation,
  });
  const handleGodModeChange = (event, value) => {
    if (value === AUTO) {
      return;
    }
    if (value !== null && value !== godMode) {
      setGodMode(value);
    }
  };
  const handleRoomTypeChange = (event, value) => {
    if (value !== null && value !== roomType) {
      setRoomType(value);
    }
  };

  let { roomNumber, scenario, type, minPlayer, maxPlayer } = selectedGameInfo;

  useEffect(() => {
    selectedGameInfo?.playerIds?.some(
      (userObj) => userObj.userId === user.id,
    ) && setPasswordRequired(false);
  }, [selectedGameInfo]);

  useEffect(() => {
    if (id && !openWaitingPopup) {
      const roomAvailable = rankedJoinRoomList.some((room) => id === room._id);
      if (roomAvailable) {
        setOpenWaitingPopup(true);
        addToWaitList(selectedGameInfo);
      } else {
        dispatch(
          alertDialog({
            title: t("APP.LOBBY.INFO_TAB.ALERT_DIALOG_WRONG_ID"),
            icon: "error",
          }),
        );
      }
    }
  }, []);
  const handleTutorial = () => {
    let tutorialUrl;
    if (selectedGameInfo.articlePath) {
      tutorialUrl = selectedGameInfo.articlePath;
    } else {
      tutorialUrl = createRoomList.find((room) => {
        return selectedGameInfo.scenario === room.scenario;
      })?.articlePath;
    }
    window.open(`${tutorialUrl}`, "_blank");
  };

  function submitForm(e, customScenario, maxCustomPlayer) {
    e && e.preventDefault();
    customScenario && (scenario = customScenario);
    minPlayer = maxCustomPlayer ?? minPlayer;
    maxPlayer = maxCustomPlayer ?? maxPlayer;

    if (actionType === "TUTORIAL") {
      handleTutorial();
      return;
    }
    const nonFilledInputs = [];
    if (e) {
      let inputFields = e.target.querySelectorAll("input");
      const formData = {};

      inputFields.forEach((input) => {
        let value = input.value;
        if (!value) {
          nonFilledInputs.push(input.name);
        } else if (input.name === "password" && input.value.length < 4) {
          nonFilledInputs.push("shortPassword");
        }
        formData[input.name] = value;
      });
      setValidation(nonFilledInputs);
      // hide errors after 3 secs
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setValidation([]);
      }, 3000);
    }

    // length === 0 means no validation error
    if (nonFilledInputs.length === 0) {
      if (user.coins < coinsRequired) {
        dispatch(
          alertDialog({
            title: t("APP.LOBBY.INFO_TAB.ALERT_DIALOG_LESS_COINS"),
            icon: "warning",
          }),
        );
        return;
      }
      function handleAction() {
        let role = actionType === "WATCH" ? "VIEWER" : "PLAYER";
        if (
          actionType === "JOIN" &&
          slectedTab.gameMode === "join" &&
          value === 0
        ) {
          setOpenWaitingPopup(true);
          addToWaitList(selectedGameInfo);
          window.history.replaceState(
            "",
            "",
            window.location.pathname + `?tab=join&id=${selectedGameInfo._id}`,
          );
          return;
        }
        switch (actionType) {
          case "JOIN":
          case "WATCH":
          case "REJOIN":
            handleCheckPassword({
              roomId: roomNumber,
              password,
              passwordRequired,
              callback: () => {
                handleRoomOpperation({
                  operationType: "joinRoom",
                  roomId: roomNumber,
                  role,
                  password: password,
                  type: type,
                });
              },
            });
            break;
          case "CREATE":
            if (scenario === "CUSTOM") {
              setOpenCustomScenarioPopup(true);
              return;
            }
            handleRoomOpperation({
              operationType: "createRoom",
              privacy,
              mode: godMode,
              scenario,
              roomType,
              minPlayer,
              maxPlayer,
            });
            break;

          default:
            break;
        }
      }

      if (browserName === "Chrome") {
        handleAction();
      } else {
        setOpenRecomendModal(true);
        setPreserveActionFunc(() => {
          return () => {
            handleAction();
          };
        });
      }
    }
  }

  function handleActions(action) {
    actionType = action.actionType;
    coinsRequired = action.coins;
  }
  const termsConditionsText = t("APP.LOBBY.INFO_TAB.READ_TERMS").split("❔");

  const btnsProps = {
    handleActions,
    selectedGameInfo,
    roomType,
    slectedTab,
    infoBtn2Ref,
  };

  // condition to display button
  // for user reJoin or watch
  const isInGameCondition =
    selectedGameInfo.gameInProgress ||
    selectedGameInfo.playersCount === selectedGameInfo.maxPlayer;
  // for user rejoin
  const isRejoin = selectedGameInfo?.playerIds?.some((userObj) => {
    const isEndedWithShot =
      selectedGameInfo.state === "ENDED" && userObj.status === "SHOT";
    const isActive = !["KICKED", "SHOT"].includes(userObj.status);
    return userObj.userId === user.id && (isEndedWithShot || isActive);
  });

  return (
    <Box
      noValidate
      component="form"
      onSubmit={submitForm}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          infoBtn2Ref.current.click();
        }
      }}
      className="info-tab-parent-wrapper"
    >
      {openWaitingPopup && (
        <WaitingPopup
          openWaitingPopup={openWaitingPopup}
          setOpenWaitingPopup={setOpenWaitingPopup}
          selectedGameInfo={selectedGameInfo}
          removeToWaitList={removeToWaitList}
          roomLists={roomLists}
        />
      )}

      {openCustomScenarioPopup && (
        <CustomScenarioPopup
          openCustomScenarioPopup={openCustomScenarioPopup}
          setOpenCustomScenarioPopup={setOpenCustomScenarioPopup}
          submitForm={submitForm}
          selectedGameInfo={selectedGameInfo}
        />
      )}

      <Box sx={{ display: "flex", gap: "5px", marginBottom: "2px" }}>
        <Typography
          color={"var(--white)"}
          fontWeight={500}
          sx={{
            fontSize: "22px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "15px",
              lineHeight: "1.2",
            },
          }}
        >
          {t("APP.LOBBY.INFO_TAB.SCENARIO")}:
        </Typography>

        <Typography
          color={"var(--primary)"}
          fontWeight={600}
          sx={{
            fontSize: "22px",
            textTransform: "uppercase",
            [theme.breakpoints.down("sm")]: {
              fontSize: "15px",
              lineHeight: "1.2",
            },
          }}
        >
          {t(`APP.LOBBY.BODY.SCENARIO_CARDS.SCENARIOS.${gameScenario}.TITLE`)}
        </Typography>
      </Box>

      {!isCreateMode && selectedGameInfo.roomNumber && (
        <Box
          ref={(elem) =>
            (teachComponentsRef.current = {
              ...teachComponentsRef.current,
              roomNumber: elem,
            })
          }
          sx={{ display: "inline-flex", gap: "5px", margin: "7px 0 2px" }}
        >
          <Typography
            color={"var(--white)"}
            fontWeight={500}
            sx={{
              fontSize: "22px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
                // display: "none",
              },
              textTransform: "uppercase",
            }}
          >
            {t("APP.LOBBY.INFO_TAB.ROOM")}:
          </Typography>

          <Typography
            color={"var(--primary)"}
            fontWeight={600}
            sx={{
              fontSize: "22px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
              },
            }}
          >
            {selectedGameInfo.roomNumber}
          </Typography>
        </Box>
      )}
      <Typography
        color={"var(--white)"}
        fontWeight={500}
        sx={{
          fontSize: "22px",
          marginTop: "7px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            lineHeight: "1.2",
          },
        }}
      >
        {t("APP.LOBBY.INFO_TAB.DESCRIPTION")}:
      </Typography>
      <Typography
        color={"var(--primary)"}
        fontWeight={600}
        sx={{
          fontSize: "18px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
            lineHeight: "1.2",
            margin: "2px 0 6px",
          },
          margin: "2px 0 12px",
          lineHeight: "1.4",
        }}
      >
        {t(
          `APP.LOBBY.BODY.SCENARIO_CARDS.SCENARIOS.${gameScenario}.DESCRIPTION`,
        )}
      </Typography>

      {isCreateMode && (
        <Box
          className="toggle-buttons-wrapper"
          sx={{
            marginTop: "4.6296svh",

            [theme.breakpoints.down("sm")]: {
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginTop: "17px",
              marginBottom: "11px",
            },
          }}
        >
          <Box>
            <ToggleButtonGroup
              className="toggle-buttons-group"
              value={godMode}
              exclusive
              onChange={handleGodModeChange}
              aria-label="mode"
              size="large"
            >
              <ToggleButton
                title="Coming Soon"
                sx={{ cursor: "not-allowed !important" }}
                className="toggle-button"
                value={AUTO}
              >
                <Box className="toggle-button-icon-Wrap">
                  <AutoIcon
                    style={{ marginRight: "2px" }}
                    className={godMode === AUTO ? "" : "light"}
                  />{" "}
                </Box>{" "}
                {t("APP.LOBBY.INFO_TAB.AUTO_BTN")}
              </ToggleButton>
              <ToggleButton className="toggle-button" value={MANUAL}>
                <Box className="toggle-button-icon-Wrap">
                  <ManualIcon
                    style={{ marginRight: "2px" }}
                    className={godMode === MANUAL ? "" : "light"}
                  />{" "}
                </Box>{" "}
                {t("APP.LOBBY.INFO_TAB.MANUAL_BTN")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              marginTop: "25px",
              [theme.breakpoints.down("sm")]: { marginTop: "0px" },
            }}
          >
            <ToggleButtonGroup
              ref={(elem) =>
                (teachComponentsRef.current = {
                  ...teachComponentsRef.current,
                  roomType: elem,
                })
              }
              className="toggle-buttons-group"
              value={roomType}
              exclusive
              onChange={handleRoomTypeChange}
              aria-label="room-type"
              size="large"
            >
              <ToggleButton className="toggle-button" value="VIDEO">
                <Box className="toggle-button-icon-Wrap"></Box>
                {t("APP.LOBBY.INFO_TAB.VIDEO_BTN")}
              </ToggleButton>
              <ToggleButton className="toggle-button" value="AUDIO">
                <Box className="toggle-button-icon-Wrap"></Box>
                {t("APP.LOBBY.INFO_TAB.AUDIO_BTN")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      )}

      {!isCreateMode &&
        selectedGameInfo.privacy === "PRIVATE" &&
        !selectedGameInfo.playerIds.some(
          (userObj) => userObj.userId === user.id,
        ) && (
          <Box>
            <Box sx={{ height: "14px", width: "100%" }}></Box>

            <Box
              ref={(elem) =>
                (teachComponentsRef.current = {
                  ...teachComponentsRef.current,
                  password: elem,
                })
              }
              sx={{ display: "inline-block" }}
            >
              <PasswordInput
                setPassword={setPassword}
                validation={validation}
                errorCode={errorCode}
                screen="gameLobby"
                type="number"
                maxLength={4}
                minLength={4}
              />
            </Box>
          </Box>
        )}

      <Box
        sx={{
          ...(isCreateMode && {
            marginTop: "4.6296svh",
            [theme.breakpoints.down("sm")]: { marginTop: "5px" },
          }),
        }}
      >
        <Typography
          color={"var(--white)"}
          fontWeight={400}
          sx={{
            fontSize: "14px",
          }}
        >
          {termsConditionsText[0]}
          {
            <Link
              to="https://gorgine.com/terms-and-conditions-en/"
              target="_blank"
              style={{ color: "var(--primary)" }}
            >
              {t("APP.LOBBY.INFO_TAB.TERMS_LINK")}
            </Link>
          }
          {termsConditionsText[1]}
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "10px",
          [theme.breakpoints.down("sm")]: { marginTop: "5px" },
        }}
      >
        {scenario !== "CUSTOM" && (
          <Button
            type="submit"
            className="tutorial-btn info-btn2 "
            onClick={() => handleActions({ actionType: "TUTORIAL", coins: 0 })}
            color="darkGray"
            variant="contained"
            size="large"
          >
            {t("APP.LOBBY.INFO_TAB.TUTORIAL_BTN")}
          </Button>
        )}
        <Box
          display={"inline-block"}
          ref={(elem) =>
            (teachComponentsRef.current = {
              ...teachComponentsRef.current,
              infoBtnsRef: elem,
            })
          }
        >
          {isCreateMode ? (
            <CreateButton {...btnsProps} />
          ) : isInGameCondition ? (
            isRejoin ? (
              <RejoinButton {...btnsProps} />
            ) : (
              <WatchButton {...btnsProps} />
            )
          ) : (
            <JoinButton {...btnsProps} />
          )}
        </Box>
      </Box>
      <RecommendBrowserModal
        {...{ openRecomendModal, setOpenRecomendModal, preserveActionFunc }}
      />
    </Box>
  );
}
InfoTab.propTypes = {
  selectedGameInfo: PropTypes.any.isRequired,
  slectedTab: PropTypes.any.isRequired,
  value: PropTypes.number.isRequired,
  setRoomLists: PropTypes.func.isRequired,
  roomLists: PropTypes.any.isRequired,
  teachComponentsRef: PropTypes.object.isRequired,
};
export default InfoTab;
