import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copyIcon.svg";
import { ReactComponent as CancelCircularIcon } from "../../../assets/icons/cancelCircularIcon.svg";
import CopyTooltip from "../CoppyTooltip.js";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
let timeOutId;
const InvitePopup = ({ invitePopup, setinvitePopup, stream }) => {
  const { roomId, roomData } = stream;
  const { t } = useTranslation();
  const [toolTipOpen, setToolTipOpen] = useState(-1);
  const handleCopy = (text, toolTipIndex) => {
    setToolTipOpen(toolTipIndex);
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setToolTipOpen(-1);
    }, 1500);
    navigator.clipboard.writeText(text);
  };
  return (
    <Box
      className={`invite-popup-parent-wrapper ${
        invitePopup ? "" : "hide-invite-popup"
      }`}
    >
      <Box className="invite-popup-title">
        <Typography
          className="invite-popup-headings"
          color={"var(--white)"}
          fontWeight={400}
          fontSize={20}
        >
          {t("APP.GAME_ROOM.FOOTER.MIDDLE.INVITE_POPUP.INVITE_FRIEND")}
        </Typography>

        <CancelCircularIcon
          onClick={() => {
            setinvitePopup(false);
          }}
          className="cancel-icon"
        />
      </Box>

      <Typography
        className="invite-popup-headings"
        sx={{ mt: "20px" }}
        color={"var(--gray)"}
        fontWeight={400}
        fontSize={13}
      >
        {t("APP.GAME_ROOM.FOOTER.MIDDLE.INVITE_POPUP.SHARE_LINK")}
      </Typography>

      <Box className="room-link-box">
        <Typography
          color={"var(--white)"}
          fontWeight={700}
          sx={{ fontSize: { xs: 9, md: 13 } }}
        >
          {window.location.href}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CopyTooltip
            open={toolTipOpen === 1}
            CopyIcon={
              <CopyIcon
                onClick={() => {
                  handleCopy(window.location.href, 1);
                }}
              />
            }
          />
        </Box>
      </Box>

      <Box sx={{ mt: "20px" }} className="credententials-wrap">
        <Box>
          <Typography
            className="invite-popup-headings"
            color={"var(--gray)"}
            fontWeight={400}
            fontSize={13}
          >
            {t("APP.GAME_ROOM.FOOTER.MIDDLE.INVITE_POPUP.ROOM_ID")}
          </Typography>
          <Box className="credententials-box">
            <Typography color={"var(--white)"} fontWeight={700} fontSize={13}>
              {roomId}
            </Typography>
            <CopyTooltip
              open={toolTipOpen === 2}
              CopyIcon={
                <CopyIcon
                  onClick={() => {
                    handleCopy(roomId, 2);
                  }}
                />
              }
            />
          </Box>
        </Box>
        {roomData.privacy === "PRIVATE" && (
          <Box>
            <Typography
              className="invite-popup-headings"
              color={"var(--gray)"}
              fontWeight={400}
              fontSize={13}
            >
              {t("APP.GAME_ROOM.FOOTER.MIDDLE.INVITE_POPUP.ROOM_PASSWORD")}
            </Typography>
            <Box>
              <Box className="credententials-box">
                <Typography
                  color={"var(--white)"}
                  fontWeight={700}
                  fontSize={13}
                >
                  {roomData.password}
                </Typography>
                <CopyTooltip
                  open={toolTipOpen === 3}
                  CopyIcon={
                    <CopyIcon
                      onClick={() => {
                        handleCopy(roomData.password, 3);
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
InvitePopup.propTypes = {
  invitePopup: PropTypes.bool,
  setinvitePopup: PropTypes.func.isRequired,
  stream: PropTypes.object.isRequired,
};
export default InvitePopup;
