import { params } from "../../../hooks/game-room/handlegameRoom.js";
export const nightModeStarted = () => {
  // this socket dont listen for admin himself , its broadcasting
  const { socket, setIsNightMode, setAwakedPlayers } = params;
  socket.on("nightModeStarted", async (isNightMode) => {
    setIsNightMode(isNightMode);
    //  empty this while shifting game mode
    setAwakedPlayers([]);
  });
};
