import { Box, Typography } from "@mui/material";
import playerImg from "../../../assets/image/room/auto-mode/right-top.png";
import flagIcon from "../../../assets/icons/room/auto-mode/flagIcon.svg";
import React from "react";
import propTypes from "prop-types";
import micIcon from "../../../assets/icons/room/auto-mode/micIcon.svg";
import netGoneWifiIcon from "../../../assets/icons/room/auto-mode/netGoneWifiIcon.svg";
import { autoUserPropMap } from "./AutoMode.js";

const topRightRound = [5, 6, 7, 8, 9];
export const Player = ({ idx, micStatus, data, stream, visibility }) => {
  const { username, status, socketId, avatar, localUser } = data || {};
  // const username = data?.username;

  const name = username ? (localUser ? "Me" : username) : "Bilits";
  const isDisConnected = status === "DISCONNECTED";
  const isKicked = status === "KICKED";

  const { userPersonalAction, likeDislikeChallange } = stream;

  const userPersonalActionProp = autoUserPropMap[userPersonalAction];

  function getUserActionProp(socketId) {
    for (const userType in autoUserPropMap) {
      if (likeDislikeChallange[userType].includes(socketId)) {
        return autoUserPropMap[userType];
      }
    }
    return null;
  }

  let userActionProp = getUserActionProp(socketId);

  const actionIconSrc = localUser
    ? userPersonalActionProp?.icon
    : userActionProp?.icon;

  const additionalIconsSrc =
    isKicked || isDisConnected ? netGoneWifiIcon : micStatus ? micIcon : "";
  return (
    <>
      <Box
        sx={{
          border: micStatus && "2px solid var(--darkBlue)",
          opacity: (isDisConnected || isKicked) && "0.5",
        }}
        className={`auto-mode-player-parent ${visibility} ${topRightRound.includes(idx) ? "round-top-right " : "round-bottom-right"}`}
      >
        <Box className="player-box">
          <Box data-count={idx + 1} className="player-img-wrapper">
            <img
              style={{
                border: micStatus && "2px solid var(--darkBlue)",
                borderLeft: micStatus && "0",
              }}
              src={avatar ?? playerImg}
              alt="player-img"
              className="player-img"
            />

            {(isKicked || isDisConnected) && (
              <Typography sx={{ fontSize: "0.5vw" }} className="kicked-typo">
                {isKicked ? "Kicked" : isDisConnected ? "Disconnected" : ""}
              </Typography>
            )}
          </Box>

          <Box className="player-info">
            <Box className="player-name">
              <Typography fontSize="1vw">{name}</Typography>
              <img height={14} width={19} src={flagIcon} alt="flag-icon" />
              {(micStatus || isDisConnected || isKicked) && (
                <img
                  height={14}
                  width={19}
                  src={additionalIconsSrc}
                  alt="additional-icon"
                />
              )}
            </Box>

            <Typography
              sx={{
                fontSize: "0.7vw",
                color: "var(--stealBlue)",
              }}
            >
              Level: 0
            </Typography>
          </Box>
        </Box>

        {actionIconSrc && (
          <Box key={actionIconSrc} className="action-icons-box">
            <img src={actionIconSrc} alt="action-icons" />
          </Box>
        )}
      </Box>
    </>
  );
};

Player.propTypes = {
  idx: propTypes.number.isRequired,
  playerActions: propTypes.any,
  micStatus: propTypes.bool,
  data: propTypes.object,
  stream: propTypes.any,
  visibility: propTypes.string.isRequired,
};
