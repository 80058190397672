import React, { useState, useEffect } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as EllipseIcon } from "../../../../assets/icons/room/actions/ellipseIcon.svg";
import { ReactComponent as Kick } from "../../../../assets/icons/room/actions/minusIcon.svg";
import { ReactComponent as Video } from "../../../../assets/icons/room/actions/videoIcon.svg";
import { ReactComponent as CloseVideo } from "../../../../assets/icons/room/actions/closeVideo.svg";
import { ReactComponent as ShotIcon } from "../../../../assets/icons/room/actions/viewerIcon.svg";
import PropTypes from "prop-types";
import {
  handleAwakeSleep,
  kickOutUser,
  handleAdminMuteVideo,
} from "../../../../handlers/game-room/index.js";
import { useTranslation } from "react-i18next";
const MoreActions = ({ stream, actionBoxRef, iteration }) => {
  const { t } = useTranslation();
  const {
    currentSelectedPeer,
    adminVideoMutedUsers,
    awakedPlayers,
    isNightMode,
    isGameStart,
  } = stream;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (func) => {
    setAnchorEl(null);
    if (func) {
      func(currentSelectedPeer);
    }
  };

  useEffect(() => {
    if (open) {
      actionBoxRef.current[iteration - 1].style.display = "block";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const isVideoMuted = adminVideoMutedUsers.includes(currentSelectedPeer);
  const isAwaked = awakedPlayers.includes(currentSelectedPeer);
  return (
    <Box>
      <IconButton
        onClick={handleClick}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      >
        <EllipseIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
        }}
        PaperProps={{
          style: {
            backgroundColor: "var(--charcoalGray)",
            color: "var(--white)",
            width: "104px",
          },
        }}
      >
        {/* kick player */}
        <MenuItem
          sx={{
            "&:hover": {
              backgroundColor: "var(--darkGray)",
            },
            fontSize: "8px",
            fontWeight: "400",
          }}
          onClick={() => {
            handleClose(() => kickOutUser("KICKED"));
          }}
        >
          <Kick style={{ marginRight: "6px" }} />
          {t(
            "APP.GAME_ROOM.GRID_SCREEN_CARD.ACTIONS_MENU.MORE_ACTIONS.KICK_PLAYER_ITEM",
          )}
        </MenuItem>
        {/* shot player */}
        {isGameStart && (
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "var(--darkGray)",
              },
              fontSize: "8px",
              fontWeight: "400",
            }}
            onClick={() => {
              handleClose(() => kickOutUser("SHOT"));
            }}
          >
            <ShotIcon style={{ marginRight: "6px" }} />
            {t(
              "APP.GAME_ROOM.GRID_SCREEN_CARD.ACTIONS_MENU.MORE_ACTIONS.SHOT_PLAYER_ITEM",
            )}
          </MenuItem>
        )}

        {!isNightMode && (
          <Box>
            {/* video */}
            <MenuItem
              sx={{
                "&:hover": {
                  backgroundColor: "var(--darkGray)",
                },
                fontSize: "8px",
                fontWeight: "400",
              }}
              onClick={() => {
                handleClose(handleAdminMuteVideo);
              }}
            >
              {isVideoMuted ? (
                <CloseVideo style={{ marginRight: "6px" }} />
              ) : (
                <Video style={{ marginRight: "6px" }} />
              )}
              {isVideoMuted
                ? t(
                    "APP.GAME_ROOM.GRID_SCREEN_CARD.ACTIONS_MENU.MORE_ACTIONS.OPEN_CAMERA",
                  )
                : t(
                    "APP.GAME_ROOM.GRID_SCREEN_CARD.ACTIONS_MENU.MORE_ACTIONS.CLOSE_CAMERA",
                  )}
            </MenuItem>
          </Box>
        )}

        {/* awake & sleep*/}
        {isNightMode && (
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "var(--darkGray)",
              },
              fontSize: "8px",
              fontWeight: "400",
            }}
            onClick={() => {
              handleClose(handleAwakeSleep);
            }}
          >
            {isAwaked ? (
              <Kick style={{ marginRight: "6px" }} />
            ) : (
              <Kick style={{ marginRight: "6px" }} />
            )}
            {isAwaked
              ? t(
                  "APP.GAME_ROOM.GRID_SCREEN_CARD.ACTIONS_MENU.MORE_ACTIONS.PUT_SLEEP",
                )
              : t(
                  "APP.GAME_ROOM.GRID_SCREEN_CARD.ACTIONS_MENU.MORE_ACTIONS.AWAKE_PLAYER",
                )}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
MoreActions.propTypes = {
  stream: PropTypes.any.isRequired,
  actionBoxRef: PropTypes.any.isRequired,
  iteration: PropTypes.number.isRequired,
};
export default MoreActions;
