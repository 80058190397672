import { useEffect } from "react";
import { emitSocketEvent, socketEventListener } from "../../socket/socket.js";
import { useSelector } from "react-redux";
import { rankedJoinRoomList } from "../../data/game-lobby/index.js";

const useHandleInfoTab = ({ setRoomLists, handleRoomOpperation }) => {
  const { user } = useSelector((state) => state.auth);
  const { avatar, username, id: userId } = user;

  const addToWaitList = ({ scenario }) => {
    emitSocketEvent("addWaitList", [scenario, userId, avatar, username]);
  };

  const removeToWaitList = ({ scenario }) => {
    emitSocketEvent("removeWaitList", scenario, userId);
  };

  useEffect(() => {
    socketEventListener("auto-room-created", (roomNumber) => {
      handleRoomOpperation({
        operationType: "joinRoom",
        roomId: roomNumber,
        role: "PLAYER",
        type: "AUDIO",
        mode: "AUTO",
      });
    });
    emitSocketEvent("getWaitList");
    const removeEventListener = socketEventListener("waitList", (data) => {
      const playersCount = data["scenario:COMMANDO"]?.length || 0;
      rankedJoinRoomList[0].playersCount = playersCount;
      setRoomLists((pre) => {
        pre.public.join[0].playersCount = playersCount;
        const newData = { ...pre };
        return newData;
      });
    });

    return () => {
      if (removeEventListener) {
        removeEventListener();
      }
    };
  }, []);

  return { addToWaitList, removeToWaitList };
};

export { useHandleInfoTab };
