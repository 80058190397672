function compactEncode(input) {
  const charMap = {
    "+": "-",
    "/": "_",
    "=": "",
  };

  return input.replace(/[+/=]/g, (match) => charMap[match]);
}

function compactDecode(input) {
  const charMap = {
    "-": "+",
    _: "/",
  };

  // Add padding back if needed
  while (input.length % 4) {
    input += "=";
  }

  return input.replace(/[-_]/g, (match) => charMap[match]);
}

function urlSafeBtoa(str) {
  return btoa(str);
}

function urlSafeAtob(str) {
  return atob(str);
}

export function encodeParams(params) {
  const paramStr = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join("&");

  // URL-safe Base64 encoding with additional compact encoding
  const obfuscated = compactEncode(urlSafeBtoa(paramStr));

  return obfuscated;
}

export function decodeParams(encodedParams) {
  // Additional compact decoding followed by URL-safe Base64 de-obfuscation
  try {
    const decoded = urlSafeAtob(compactDecode(encodedParams));

    const paramPairs = decoded.split("&");
    const params = {};

    paramPairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    return params;
  } catch (error) {
    return { error };
  }
}
