import { useState, useRef, useEffect } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import bg from "../../assets/image/login-background.webp";
import { useTranslation } from "react-i18next";
import reload from "../../assets/icons/auth/reload.svg";
import {
  authenticateOtpApi,
  sendOtpApi,
} from "../../features/auth/authThunks.js";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setNavigateTo } from "../../features/global/globalSlice.js";
import { useHandleOtpChange } from "../../hooks/auth/HandleOtp.hook.js";
import TimeProgress from "../../components/auth/common/TimeProgress.js";
import { Logo } from "../../components/global/Logo.js";
import { LanguageBtn, SupportBtn } from "../../components/global/index.js";

const Otp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumber = "+" + searchParams.get("phone-number")?.trim();
  let type = searchParams.get("type");
  if (type === "forget-password") {
    type = "PASSWORD";
  } else if (type === "registration") {
    type = "VERIFICATION";
  }
  const [isCodeSent, setIsCodeSent] = useState(true);
  const [isDisplayResend, setIsDisplayResend] = useState(false);
  const [otp, setOtp] = useState({});
  const [otpError, setOtpError] = useState(null);
  let inputWrapperRefArr = [];
  const firstCharRef = useRef(null);
  const secondCharRef = useRef(null);
  const thirdCharRef = useRef(null);
  const fourthCharRef = useRef(null);
  const fifthCharRef = useRef(null);
  const sixthCharRef = useRef(null);

  const { handleOtpChange } = useHandleOtpChange({
    setOtp,
    inputWrapperRefArr,
    firstCharRef,
    secondCharRef,
    thirdCharRef,
    fourthCharRef,
    fifthCharRef,
    sixthCharRef,
  });

  useEffect(() => {
    if (isCodeSent) {
      setTimeout(() => {
        setIsCodeSent(false);
      }, 6000);
      setIsDisplayResend(false);
      setTimeout(() => {
        setIsDisplayResend(true);
      }, 60000);
    }
  }, [isCodeSent]);

  const clearOtpInput = () => {
    firstCharRef.current.value = "";
    firstCharRef.current.focus();
    firstCharRef.current.style.pointerEvents = "auto";
    secondCharRef.current.value = "";
    thirdCharRef.current.value = "";
    fourthCharRef.current.value = "";
    fifthCharRef.current.value = "";
    sixthCharRef.current.value = "";
    setOtp({});
  };
  useEffect(() => {
    if (otp?.sixthChar) {
      const values = Object.values(otp);
      const otpValue = values.join("");
      let data = {
        phoneNumber: phoneNumber,
        otp: otpValue,
        type,
      };
      (async () => {
        try {
          await dispatch(authenticateOtpApi({ payload: data })).unwrap();
          dispatch(
            setNavigateTo(
              type === "PASSWORD"
                ? `/new-password?phone-number=${phoneNumber}`
                : type === "VERIFICATION" && "/game-lobby?tab=join",
            ),
          );
        } catch (error) {
          setOtpError(error.code);
          clearOtpInput();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);
  const resendOtp = async () => {
    try {
      await dispatch(sendOtpApi({ payload: { phoneNumber } })).unwrap();
      setIsCodeSent(true);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${bg})`,
        }}
        className="otp-parent-wrap auth-pages-parent-wrapper"
      >
        {isCodeSent && (
          <Box className="code-sent-box">
            <Typography
              sx={{
                color: "var(--white)",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              {t("APP.AUTH.OTP.CODE_SEND")}
            </Typography>
          </Box>
        )}

        <Box className="auth-header">
          <Logo />
          <Box sx={{ display: "flex", gap: "22px" }}>
            <SupportBtn />
            <LanguageBtn />
          </Box>
        </Box>

        <Box className="form-wrap">
          <Box className="form-box">
            <Typography
              color={"var(--white)"}
              sx={{
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              {t("APP.AUTH.OTP.OTP_TITLE")}
            </Typography>
            <Typography
              color={"var(--mutedGray)"}
              sx={{
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {t("APP.AUTH.OTP.OTP_INSTRUCTIONS")}
            </Typography>
            <Box sx={{ height: "19px", width: "100%" }}></Box>
            <Box className="otp-inputs-wrap">
              <TextField
                autoFocus
                autoComplete="off"
                name="firstChar"
                className="otp-input"
                onKeyUp={handleOtpChange}
                ref={(ref) => {
                  inputWrapperRefArr.push({ firstChar: ref });
                }}
                inputRef={firstCharRef}
                inputProps={{
                  maxLength: 1,
                }}
                id="outlined-basic"
                variant="outlined"
              />
              <TextField
                name="secondChar"
                autoComplete="off"
                ref={(ref) => {
                  inputWrapperRefArr.push({ secondChar: ref });
                }}
                className="otp-input"
                inputRef={secondCharRef}
                sx={{
                  pointerEvents: "none",
                }}
                onKeyUp={handleOtpChange}
                inputProps={{
                  maxLength: 1,
                }}
                id="outlined-basic"
                variant="outlined"
              />
              <TextField
                name="thirdChar"
                autoComplete="off"
                className="otp-input"
                inputRef={thirdCharRef}
                ref={(ref) => {
                  inputWrapperRefArr.push({ thirdChar: ref });
                }}
                sx={{
                  pointerEvents: "none",
                }}
                onKeyUp={handleOtpChange}
                inputProps={{
                  maxLength: 1,
                }}
                id="outlined-basic"
                variant="outlined"
              />
              <TextField
                name="fourthChar"
                autoComplete="off"
                className="otp-input"
                inputRef={fourthCharRef}
                ref={(ref) => {
                  inputWrapperRefArr.push({ fourthChar: ref });
                }}
                sx={{
                  pointerEvents: "none",
                }}
                onKeyUp={handleOtpChange}
                inputProps={{
                  maxLength: 1,
                }}
                id="outlined-basic"
                variant="outlined"
              />
              <TextField
                name="fifthChar"
                autoComplete="off"
                className="otp-input"
                inputRef={fifthCharRef}
                ref={(ref) => {
                  inputWrapperRefArr.push({ fifthChar: ref });
                }}
                sx={{
                  pointerEvents: "none",
                }}
                onKeyUp={handleOtpChange}
                inputProps={{
                  maxLength: 1,
                }}
                id="outlined-basic"
                variant="outlined"
              />
              <TextField
                name="sixthChar"
                autoComplete="off"
                className="otp-input"
                inputRef={sixthCharRef}
                ref={(ref) => {
                  inputWrapperRefArr.push({ sixthChar: ref });
                }}
                sx={{
                  pointerEvents: "none",
                }}
                onKeyUp={handleOtpChange}
                inputProps={{
                  maxLength: 1,
                }}
                id="outlined-basic"
                variant="outlined"
              />
            </Box>

            <Typography
              color={"var(--Crimson)"}
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                marginTop: "8px",
                marginBottom: "20px",
                visibility: otpError ? "visible" : "hidden",
              }}
            >
              {t("APP.AUTH.OTP.OTP_ERROR_CODE")}
            </Typography>

            <Box className="otp-resend-box">
              {isDisplayResend ? (
                <Box>
                  <img className="otp-reload-img" src={reload} alt="reload" />
                </Box>
              ) : (
                <TimeProgress />
              )}

              <Typography
                color={`var(--white)`}
                sx={{
                  fontSize: "13px",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                  '@media (max-width: 450px)': {
                    whiteSpace: "normal",
                    display: "block"
                  },
                }}
              >
                {t("APP.AUTH.OTP.OTP_NOT_SEND")}
                {isDisplayResend && (
                  <>
                    <Button
                      onClick={resendOtp}
                      sx={{
                        textTransform: "none",
                        padding: "0",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                      type="text"
                      className="link-hover"
                    >
                      {t("APP.AUTH.OTP.OTP_RESEND_LINK")}
                    </Button>

                    {t("APP.AUTH.OTP.OTP_OR")}
                    {" "}
                    <a
                      href="https://t.me/gorgine_support"
                      style={{ color: "var(--primary)" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("APP.AUTH.OTP.CONTACT_SUPPORT")}
                    </a>
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Otp;
