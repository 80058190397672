import { handleApi } from "../../api/handleApi.js";

export const loginApi = handleApi("auth/login", "POST", {
  headers: {
    'Skip-Interceptor': 'true'
  },
  showGlobalLoader: true,
});

export const refreshTokenApi = handleApi("auth/refresh-token", "POST", {
  headers: {
    'Skip-Interceptor': 'true'
  }
});

export const checkUsernameApi = handleApi("auth/check-username", "GET", {
  headers: {
    'Skip-Interceptor': 'true'
  }
});

export const registerApi = handleApi("auth/register", "POST", {
  headers: {
    'Skip-Interceptor': 'true'
  },
  showGlobalLoader: true,
});

export const authenticateOtpApi = handleApi("auth/otp", "POST", {
  headers: {
    'Skip-Interceptor': 'true'
  },
  showGlobalLoader: true,
});

export const sendOtpApi = handleApi("auth/send-otp", "PATCH", {
  headers: {
    'Skip-Interceptor': 'true'
  },
  showGlobalLoader: true,
});

export const forgetPasswordApi = handleApi("auth/forgot-password", "POST", {
  headers: {
    'Skip-Interceptor': 'true'
  },
  showGlobalLoader: true,
});

export const resetPasswordApi = handleApi("auth/reset-password", "POST", {
  headers: {
    'Skip-Interceptor': 'true'
  },
  showGlobalLoader: true,
});

export const logoutApi = handleApi("auth/logout", "POST", {
  headers: {
    'Skip-Interceptor': 'true'
  },
  showGlobalLoader: true,
});
