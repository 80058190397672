import { useState, useEffect } from "react";
let timer;
const useGameRoomTable = ({ stream }) => {
  const [seconds, setSeconds] = useState(0);
  const { roomData } = stream;

  useEffect(() => {
    if (roomData?.startTime) {
      timer = setInterval(() => {
        const currentTime = new Date();
        const startTime = new Date(roomData?.startTime);
        const timeDifference = currentTime - startTime;
        const timeInSeconds = Math.round(timeDifference / 1000);

        setSeconds(15 - timeInSeconds);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [roomData?.startTime]);

  useEffect(() => {
    if (seconds < 0) {
      clearInterval(timer);
    }
  }, [seconds]);

  return { seconds };
};

export default useGameRoomTable;
