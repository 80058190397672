import { Device, detectDevice } from "mediasoup-client";
import { params } from "../../../hooks/game-room/handlegameRoom.js";
export async function handleCapabilities(capabilities) {
  const { mediasoupDevice } = params;
  const cap = { routerRtpCapabilities: capabilities };
  const detectedHandler = detectDevice();
  let handlerName;
  if (detectedHandler) {
    handlerName = detectedHandler;
  } else {
    handlerName = "Safari12";
  }

  try {
    mediasoupDevice.current = new Device({ handlerName: handlerName });
  } catch (err) {
    console.error(err);
  }
  await mediasoupDevice.current?.load(cap);
}
