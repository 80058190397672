import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import { setReRender, setLanguage } from "../../features/global/globalSlice.js";
import { useDispatch } from "react-redux";
import languageChangeIcon from "../../assets/icons/header/languageChangeIcon.svg";
import { useLocation } from "react-router-dom";
export const LanguageBtn = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = () => {
    const lang = i18n.language === "en" ? "fa" : "en";
    const currentURL = new URL(window.location.href);
    currentURL.pathname = lang + location.pathname;
    window.history.replaceState(null, "", currentURL.toString());
    dispatch(setLanguage(lang));
    // Reload the router
    dispatch(setReRender(true));
    setTimeout(() => {
      dispatch(setReRender(false));
    }, 0);
  };

  return (
    <>
      <Tooltip
        title={
          i18n.language === "en"
            ? t("APP.LANGUAGES.FARSI")
            : t("APP.LANGUAGES.ENGLISH")
        }
        arrow
        placement="bottom"
      >
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
          }}
          onClick={handleLanguageChange}
        >
          <img src={languageChangeIcon} alt="language-change" />
        </Box>
      </Tooltip>
    </>
  );
};
