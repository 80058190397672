import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CancelCircularIcon } from "../../assets/icons/cancelCircularIcon.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const CopiedPopup = ({ copiedPopup, setCopiedPopup }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  return (
    <Box
      className={`copied-popup-parent-wrapper ${
        copiedPopup ? "" : "hide-copied-popup"
      }`}
    >
      <Box className="copied-popup-title">
        <Typography
          className="copied-popup-headings"
          color={"var(--white)"}
          fontWeight={400}
          fontSize={20}
        >
          {t("APP.SUBSCRIPTION.COPIED_POPUP.INVITE_FRIEND")}
        </Typography>

        <CancelCircularIcon
          onClick={() => {
            setCopiedPopup(false);
          }}
          className="cancel-icon"
        />
      </Box>

      <Typography
        className="invite-popup-headings"
        sx={{ mt: "20px" }}
        color={"var(--gray)"}
        fontWeight={400}
        fontSize={13}
      >
        {t("APP.SUBSCRIPTION.COPIED_POPUP.REFERRAL_COPIED")}
      </Typography>

      <Box className="link-box">
        <Typography
          color={"var(--white)"}
          fontWeight={700}
          sx={{ fontSize: { xs: 9, md: 13 } }}
        >
          {window.location.origin +
            "/register?referralCode=" +
            user.referralCode}
        </Typography>
      </Box>
    </Box>
  );
};
CopiedPopup.propTypes = {
  copiedPopup: PropTypes.bool,
  setCopiedPopup: PropTypes.func.isRequired,
};
export default CopiedPopup;
