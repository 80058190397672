import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export function handleApi(apiurl, method, config = {}) {
  return createAsyncThunk(
    apiurl,
    async ({ payload = {}, params = {} }, thunkApi) => {
      try {
        const response = await axios.request({
          method: method,
          url: apiurl,
          data: payload,
          withCredentials: "include",
          headers: { ...config.headers },
          params: params,
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return thunkApi.rejectWithValue(error.response.data);
      }
    },
  );
}
