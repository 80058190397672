import { useState } from "react";
import { Box, Typography } from "@mui/material";
import bg from "../../assets/image/login-background.webp";
import warningIcon from "../../assets/icons/auth/warningIcon.svg";
import { Link } from "react-router-dom";
import { useHandleLoginHook } from "../../hooks/auth/HandleLogin.hook.js";
import { useTranslation } from "react-i18next";
import {
  PhoneInput,
  PasswordInput,
  SubmitBtn,
} from "../../components/auth/common/index.js";
import { Logo } from "../../components/global/Logo.js";
import { getPhoneNumber } from "../../handlers/auth/getPhoneNumberFromInput.js";
import { useDispatch } from "react-redux";
import { setNavigateTo } from "../../features/global/globalSlice.js";
import { LanguageBtn, SupportBtn } from "../../components/global/index.js";
const Login = () => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState([]);
  const [errorCode, setErrorCode] = useState(null);
  const { handleLogin } = useHandleLoginHook({
    password,
    setValidation,
    setErrorCode,
  });
  const { t } = useTranslation();

  const submitForm = (e) => {
    e.preventDefault();
  };

  const reDirectToForget = () => {
    dispatch(
      setNavigateTo({
        path: "/forgot-password",
        state: { phoneNumber: getPhoneNumber([], true) },
      }),
    );
  };
  return (
    <Box
      noValidate
      component="form"
      onSubmit={submitForm}
      sx={{
        backgroundImage: `url(${bg})`,
      }}
      className="login-parent-wrap auth-pages-parent-wrapper"
    >
      <Box className="auth-header">
        <Logo />
        <Box sx={{ display: "flex", gap: "22px" }}>
          <SupportBtn />
          <LanguageBtn />
        </Box>
      </Box>

      <Box className="form-wrap">
        <Box className="form-box">
          <Typography
            color={"var(--white)"}
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              lineHeight: "35px",
              marginBottom: "5px",
            }}
          >
            {t("APP.AUTH.LOGIN.LOGIN_ACCOUNT_TITLE")}
          </Typography>
          <Typography
            color={"var(--mutedGray)"}
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "28px",
            }}
          >
            {t("APP.AUTH.LOGIN.LOGIN_PHONE_NUMBER_INSTRUCTION")}
          </Typography>
          <Box sx={{ height: "30px", width: "100%" }}></Box>
          <PhoneInput validation={validation} screen={"login"} />
          <Box sx={{ height: "7px", width: "100%" }}></Box>

          <PasswordInput validation={validation} setPassword={setPassword} />

          {errorCode ? (
            <>
              <Box sx={{ height: "10px", width: "100%" }}></Box>
              <Box className="warning-alert">
                <img src={warningIcon} alt="warning" />
                {t(`APP.ERROR_CODES.${errorCode}`)}
              </Box>
              <Box sx={{ height: "18px", width: "100%" }}></Box>
            </>
          ) : (
            <Box sx={{ height: "18px", width: "100%" }}></Box>
          )}
          <Typography
            color={"var(--primary)"}
            className="link-hover"
            onClick={reDirectToForget}
            sx={{
              cursor: "pointer",
              textAlign: "right",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "18.75px",
            }}
          >
            {t("APP.AUTH.LOGIN.FORGOT_PASSWORD")}
          </Typography>
          <Box sx={{ height: "10px", width: "100%" }}></Box>
          <SubmitBtn handleSubmit={handleLogin} screen={"login"} />

          <Typography
            color={"var(--white)"}
            sx={{
              textAlign: "center",
              marginTop: "23px",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "18.75px",
            }}
          >
            {t("APP.AUTH.NOT_HAVE_ACCOUNT")}{" "}
            <Link className="link-hover" to="/register">
              {t("APP.AUTH.REGISTER_LINK_TEXT")}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
