export const useHandleOtpChange = ({
  setOtp,
  inputWrapperRefArr,
  firstCharRef,
  secondCharRef,
  thirdCharRef,
  fourthCharRef,
  fifthCharRef,
  sixthCharRef,
}) => {
  const handleOtpChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOtp((pre) => ({ ...pre, [name]: value }));
    if (value.length === 1) {
      let enableEventsIndex = -1;
      inputWrapperRefArr.forEach((ref, index) => {
        const keyName = Object.keys(ref)[0];
        ref[keyName].style.pointerEvents = "none";
        if (enableEventsIndex === index) {
          ref[keyName].style.pointerEvents = "auto";
        } else {
          ref[keyName].style.pointerEvents = "none";
        }
        if (keyName === name) {
          enableEventsIndex = index + 1;
        }
      });
      switch (name) {
        case "firstChar":
          secondCharRef.current.focus();
          break;
        case "secondChar":
          thirdCharRef.current.focus();
          break;
        case "thirdChar":
          fourthCharRef.current.focus();
          break;
        case "fourthChar":
          fifthCharRef.current.focus();
          break;
        case "fifthChar":
          sixthCharRef.current.focus();
          break;
        default:
          break;
      }
    } else if (value.length === 0) {
      inputWrapperRefArr.forEach((ref, index) => {
        const keyName = Object.keys(ref)[0];
        ref[keyName].style.pointerEvents = "none";
        if (keyName === name) {
          let keyIndex = 0;
          if (index > 0) {
            keyIndex = index - 1;
          }
          const preKey = Object.keys(inputWrapperRefArr[keyIndex])[0];
          inputWrapperRefArr[keyIndex][preKey].style.pointerEvents = "auto";
        }
      });
      switch (name) {
        case "secondChar":
          firstCharRef.current.focus();
          break;
        case "thirdChar":
          secondCharRef.current.focus();
          break;
        case "fourthChar":
          thirdCharRef.current.focus();
          break;
        case "fifthChar":
          fourthCharRef.current.focus();
          break;
        case "sixthChar":
          fifthCharRef.current.focus();
          break;
        default:
          break;
      }
    }
  };
  return { handleOtpChange };
};
