import React from "react";
import { Box, Typography } from "@mui/material";
import eyeIconMist from "../../../assets/icons/room/eyeIconMist.svg";
import userIconMist from "../../../assets/icons/room/userIconMist.svg";
import PropTypes from "prop-types";
import CaluclateStartTime from "../../game-lobby/CaluclateStartTime.js";
import { useTranslation } from "react-i18next";
const InfoPopup = ({ isShow, stream }) => {
  const { t } = useTranslation();
  const { roomData, roomId } = stream;
  const { scenario, startTime, viewers, players } = roomData;
  let gameScenario = scenario?.replace(/\s/g, "_");
  if (gameScenario?.slice(0, 6).toLowerCase() === "custom") {
    gameScenario = "CUSTOM";
  }
  return (
    <Box
      className={`game-info-popup-parent ${isShow ? "" : "hide-info-popup"}`}
    >
      <Box className="info-popup-flex">
        <Box>
          <Typography
            sx={{
              mb: "4px",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "normal",
            }}
            color="var(--mysticMist)"
          >
            {t(`APP.LOBBY.BODY.SCENARIO_CARDS.SCENARIOS.${gameScenario}.TITLE`)}
          </Typography>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "normal",
            }}
            color="var(--mysticMist)"
          >
            <CaluclateStartTime startTime={startTime} />
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              mb: "4px",
              fontWeight: "400",
              fontSize: "14px",
              background: "var(--semiTransparentRed)",
              borderRadius: "5px",
              padding: "1px 10px",
              whiteSpace: "nowrap",
              height: "21px",
              display: "flex",
              alignItems: "center",
            }}
            color="var(--mysticMist)"
          >
            {t("APP.GAME_ROOM.INFO_POPUP.ROOM")}: {roomId} -{" "}
            {t("APP.GAME_ROOM.INFO_POPUP.PASSWORD")}: {roomData.password}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "19px",
              justifyContent: "end",
              marginRight: "4px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "3px" }}
                  src={eyeIconMist}
                  alt="viewers"
                />
              </Box>
              <Typography
                sx={{ fontWeight: "400", fontSize: "14px" }}
                display="inline"
                color="var(--mysticMist)"
              >
                {viewers?.length}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "3px" }}
                  src={userIconMist}
                  alt="players"
                />
              </Box>
              <Typography
                sx={{ fontWeight: "400", fontSize: "14px" }}
                display="inline"
                color="var(--mysticMist)"
              >
                {
                  players?.filter((player) => player.status === "CONNECTED")
                    .length
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
InfoPopup.propTypes = {
  isShow: PropTypes.bool.isRequired,
  stream: PropTypes.object.isRequired,
};
export default InfoPopup;
