import { useTranslation } from "react-i18next";

export const useTeachData = () => {
  const { t } = useTranslation();

  return {
    coinsBalance: {
      text: t("APP.TEACH_USER_DATA.LOBBY.COIN_BALAINCE_TEXT"),
      placement: "bottom",
      key: "coinsBalance",
    },
    roomType: {
      text: t("APP.TEACH_USER_DATA.LOBBY.ROOM_TYPE_TEXT"),
      placement: "top",
      key: "roomType",
    },
    createRoom: {
      text: t("APP.TEACH_USER_DATA.LOBBY.CREATE_ROOM_TEXT"),
      placement: "top",
      key: "createRoom",
    },
    joinTab: {
      text: t("APP.TEACH_USER_DATA.LOBBY.JOIN_TAB_TEXT"),
      placement: "bottom",
      key: "joinTab",
    },
    watchTab: {
      text: t("APP.TEACH_USER_DATA.LOBBY.WATCH_TAB_TEXT"),
      placement: "bottom",
      key: "watchTab",
    },
    password: {
      text: t("APP.TEACH_USER_DATA.LOBBY.PASSWORD_TEXT"),
      placement: "left",
      key: "password",
    },
    joinRoom: {
      text: t("APP.TEACH_USER_DATA.LOBBY.JOIN_ROOM_TEXT"),
      placement: "top",
      key: "joinRoom",
    },
    watchRoom: {
      text: t("APP.TEACH_USER_DATA.LOBBY.WATCH_ROOM_TEXT"),
      placement: "top",
      key: "watchRoom",
    },
    playIcon: {
      text: t("APP.TEACH_USER_DATA.LOBBY.PLAY_ICON_TEXT"),
      placement: "right",
      key: "playIcon",
    },
    roomNumber: {
      text: t("APP.TEACH_USER_DATA.LOBBY.ROOM_NUMBER_TEXT"),
      placement: "left",
      key: "roomNumber",
    },
  };
};

export let trackTeachSteps = {
  coinsBalance: false,
  roomType: false,
  createRoom: false,
  done: false,
  joinRoom: false,
  watchRoom: false,
  playIcon: false,
  joinTab: false,
  watchTab: false,
  watchField: {
    roomNumber: false,
    password: false,
  },
  joinField: {
    roomNumber: false,
    password: false,
  },
};
