import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { cameraMicPermissionAlert } from "./handleStartStream.js";
export const handleMic = (state) => {
  const {
    currentMicState,
    socket,
    audioProducerId,
    audioProducer,
    setMicStatus,
    camerMicPermission,
    localStreamRef,
    produceTransport,
    setCaptureMediaStreams,
    user,
  } = params;
  if (!camerMicPermission) {
    cameraMicPermissionAlert();
    return;
  }
  const stateVar = state ? state : currentMicState.current;
  socket.emit(
    "handleProducer",
    {
      producerId: audioProducerId.current,
      state: stateVar,
    },
    async (response) => {
      if (stateVar) {
        await audioProducer.current?.pause();
        const isAudioPaused = audioProducer.current?.paused;
        if (isAudioPaused) {
          currentMicState.current = false;
          setMicStatus(false);
        } else {
          console.log(
            "trying to pause audio , but its not closed , it is a bug",
          );
        }
      } else {
        if (audioProducer.current) {
          await audioProducer.current?.resume();
          const isAudioResumed = !audioProducer.current?.paused;
          if (isAudioResumed) {
            currentMicState.current = true;
            setMicStatus(true);
          }
        } else {
          try {
            // Simulate the "track ended" error by stopping the audio track before producing
            const audioTrack = localStreamRef?.current?.getAudioTracks()[0];
            audioProducer.current = await produceTransport.current?.produce({
              track: audioTrack,
            });

            currentMicState.current = true;
            setMicStatus(true);
            setCaptureMediaStreams((prev) => ({
              ...prev,
              [user.username]: localStreamRef.current,
            }));
          } catch (error) {
            console.error("Error during audio production:", error);
            
            // Handle the "track ended" error
            if (error.message.includes("track ended")) {
              // Restart the local stream and re-produce the audio track
              try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const newAudioTrack = stream.getAudioTracks()[0];

                // Produce the new audio track
                audioProducer.current = await produceTransport.current?.produce({
                  track: newAudioTrack,
                });

                // Update the local stream reference and capture media streams
                localStreamRef.current = stream;
                currentMicState.current = true;
                setMicStatus(true);
                setCaptureMediaStreams((prev) => ({
                  ...prev,
                  [user.username]: localStreamRef.current,
                }));
              } catch (restartError) {
                console.error("Failed to restart the audio track:", restartError);
              }
            }
          }
        }
      }
    },
  );
};

export const handleMuteForAll = (isMute) => {
  const { socket, mutedAllStatus, roomId, setMicAllStatus } = params;
  if (!mutedAllStatus.current || isMute) {
    socket.emit("muteAll", roomId);
    mutedAllStatus.current = true;
    setMicAllStatus(false);
  } else {
    socket.emit("unmuteAll", roomId);
    mutedAllStatus.current = false;
    setMicAllStatus(true);
  }
};
