import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import bgImg1 from "../../assets/image/game-lobby/lobbyBackground.webp";
import Tabs from "@mui/material/Tabs/index.js";
import Tab from "@mui/material/Tab/index.js";
import Grid from "@mui/material/Grid/index.js";
import GamesCard from "../../components/game-lobby/GamesCard.js";
import { useTheme } from "@mui/material/styles/index.js";
import InfoTab from "../../components/game-lobby/InfoTab.js";
import {
  useGetRoomList,
  useGetCreateRoomList,
  useGameLobby,
} from "../../hooks/game-lobby/gameLobby.hooks.js";
import { rankedJoinRoomList } from "../../data/game-lobby/index.js";
import { Header } from "../../components/global/Header.js";
import { useLocation } from "react-router-dom";
import { DropDown } from "../../components/game-lobby/DropDown.js";
import { useTranslation } from "react-i18next";
import VotingComponent from "../../components/game-lobby/VotingComponent.js";
const isRankedOff = process.env.REACT_APP_RANKED_FEATURE_FLAG === "true";

let timoutId = {};
function GameLobby() {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabName = searchParams.get("tab");
  const valueParam = parseInt(searchParams.get("value"), 10);

  const [gameMode, setGameMode] = useState(
    isRankedOff ? "create" : tabName || "join",
  );
  const [value, setValue] = useState(valueParam || 0);
  const [rightTabValue, setRightTabValue] = useState(0);
  const [selectedGameInfo, setSlectedGameInfo] = useState("");
  const [gameId, setGameId] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);
  const [hideCardsScrollbar, setHideCardsScrollbar] = useState(true);
  const [hideInfoScrollbar, setHideInfoScrollbar] = useState(true);
  const { teachComponentsRef } = useGameLobby({ tabName, value });

  let cardGameMode = selectedGameInfo?.gameMode?.gameMode;
  let cardValue = selectedGameInfo?.gameMode?.value;
  let isDisplayInfo = cardGameMode === gameMode && value === cardValue;

  let { createRoomList } = useGetCreateRoomList();
  const [roomLists, setRoomLists] = useState({
    public: { join: rankedJoinRoomList, watch: [] },
    private: { join: [], watch: [] },
  });

  useGetRoomList(selectedItem, setRoomLists);

  const itemToRoomMap = {
    0: [t("APP.LOBBY.SELECTED_ROOM.ACTIVE")],
    1: [t("APP.LOBBY.SELECTED_ROOM.PROGRESS")],
    2: [t("APP.LOBBY.SELECTED_ROOM.OPEN")],
    3: [t("APP.LOBBY.SELECTED_ROOM.PRIVATE")],
    4: [t("APP.LOBBY.SELECTED_ROOM.PUBLIC")],
  };

  const roomTypes = {
    join: "public",
    create: "private",
  };
  const roomListkeys = {
    0: "join",
    1: "watch",
  };
  const roomSelected = itemToRoomMap[selectedItem];
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  function handleRightTabChange(event, newValue) {
    setRightTabValue(newValue);
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  let tabs = [];

  if (gameMode === "join") {
    tabs.push(
      <Tab label={t("APP.LOBBY.TABS.JOIN")} {...a11yProps(0)} key="join" />,
      <Tab label={t("APP.LOBBY.TABS.WATCH")} {...a11yProps(1)} key="watch" />,
    );
  } else if (gameMode === "create") {
    tabs.push(
      <Tab
        label={t("APP.LOBBY.TABS.CREATE")}
        {...a11yProps(0)}
        key="scenarios"
      />,
      <Tab
        ref={(elem) =>
          (teachComponentsRef.current = {
            ...teachComponentsRef.current,
            joinTab: elem,
          })
        }
        label={t("APP.LOBBY.TABS.JOIN")}
        {...a11yProps(1)}
        key="join"
      />,
      <Tab
        ref={(elem) =>
          (teachComponentsRef.current = {
            ...teachComponentsRef.current,
            watchTab: elem,
          })
        }
        label={t("APP.LOBBY.TABS.WATCH")}
        {...a11yProps(2)}
        key="watch"
      />,
    );
  } else if (gameMode === "assist") {
    tabs.push(
      <Tab
        label={t("APP.LOBBY.TABS.CREATE")}
        {...a11yProps(0)}
        key="scenarios"
      />,
    );
  }

  const isVoteDisabled = process.env.REACT_APP_DISABLE_VOTE_TAB === "true";

  if (gameMode === "create" && !isVoteDisabled) {
    tabs.push(
      <Tab
        label={t("APP.LOBBY.TABS.VOTE")}
        {...a11yProps(3)}
        key="vote"
        disabled={isVoteDisabled}
      />,
    );
  }

  const handleScroll = (scrollerName, setHideScrollbar) => {
    setHideScrollbar(false);
    clearTimeout(timoutId[scrollerName]);

    timoutId[scrollerName] = setTimeout(() => {
      setHideScrollbar(true);
    }, 1000);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bgImg1})`,
      }}
      className={`game-lobby-parent-wrap gameLobby-page`}
    >
      <Box className="game-container">
        <Box>
          <Header
            gameMode={gameMode}
            setGameMode={setGameMode}
            screen="gameLobby"
            setValue={setValue}
            teachComponentsRef={teachComponentsRef}
          />
        </Box>

        {/* Body Content */}
        <Box className="game-body">
          {/* Left Side Tabs */}
          <Box className="body-left">
            <Tabs
              className="body-tabs-wrapper"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "var(--primary)",
                },
              }}
              value={value}
              onChange={handleChange}
            >
              {tabs}
            </Tabs>

            {gameMode === "join" && (
              <Box sx={{ position: "absolute", right: "8px" }}>
                <DropDown
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              </Box>
            )}
            {value === 3 ? (
              <VotingComponent />
            ) : (
              <>
                <Box className="body-grid">
                  <Grid
                    className={`grid-wrapper ${hideCardsScrollbar ? "scrollbar-visible" : ""}`}
                    onScroll={() =>
                      handleScroll("cardsScroll", setHideCardsScrollbar)
                    }
                    container
                    columnSpacing={{ xs: 1, sm: 5.7 }}
                    // sx={{flexWrap: "wrap !important"}}
                    // columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <GamesCard
                      data={
                        gameMode === "join"
                          ? roomLists["public"][roomListkeys[value]]
                          : gameMode === "create"
                            ? value === 0
                              ? createRoomList
                              : roomLists["private"][roomListkeys[value - 1]]
                            : []
                      }
                      setSlectedGameInfo={setSlectedGameInfo}
                      selectedGameInfo={selectedGameInfo}
                      gameMode={{ gameMode, value }}
                      gameId={gameId}
                      setGameId={setGameId}
                      isDisplayInfo={isDisplayInfo}
                      selectedItem={selectedItem}
                      value={value}
                      setValue={setValue}
                      teachComponentsRef={teachComponentsRef}
                    />
                  </Grid>
                </Box>
                {roomLists[roomTypes[gameMode]][
                  roomListkeys[gameMode === "join" ? value : value - 1]
                ]?.length < 1 &&
                  (gameMode === "join" ||
                    (gameMode === "create" && value > 0)) && (
                    <Box className="body-no-info">
                      <Typography
                        sx={{
                          fontSize: "25px",
                          fontWeight: "700",
                          color: "var(--white)",
                          [theme.breakpoints.down("sm")]: {
                            fontSize: "20px",
                          },
                        }}
                      >
                        {t("APP.LOBBY.SELECTED_ROOM.NOT_AVAILABLE.NO")}{" "}
                        {roomSelected}{" "}
                        {t("APP.LOBBY.SELECTED_ROOM.NOT_AVAILABLE.ROOM")}
                      </Typography>
                      <Typography
                        onClick={() => {
                          setGameMode("create");
                          setValue(0);
                        }}
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "var(--darkBlue)",
                          cursor: "pointer",
                          [theme.breakpoints.down("sm")]: {
                            fontSize: "17px",
                          },
                        }}
                      >
                        {t("APP.LOBBY.SELECTED_ROOM.NOT_AVAILABLE.CREATE_LINK")}
                      </Typography>
                    </Box>
                  )}
              </>
            )}
          </Box>

          {/* Right side tab */}
          <Box className="body-right">
            <Tabs
              className="body-tabs-wrapper card-info-tab-wrap"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "var(--primary)",
                },
              }}
              value={rightTabValue}
              onChange={handleRightTabChange}
            >
              <Tab
                label={t("APP.LOBBY.TABS.INFO")}
                {...a11yProps(0)}
                key="INFO"
              />
            </Tabs>

            {isDisplayInfo ? (
              <Box
                onScroll={() =>
                  handleScroll("infoScroll", setHideInfoScrollbar)
                }
                className={`right-info-wrapper ${
                  hideInfoScrollbar ? "scrollbar-visible" : ""
                }`}
              >
                <InfoTab
                  slectedTab={{ gameMode, value }}
                  selectedGameInfo={selectedGameInfo}
                  value={value}
                  setRoomLists={setRoomLists}
                  roomLists={roomLists}
                  teachComponentsRef={teachComponentsRef}
                />
              </Box>
            ) : (
              <Box className="body-no-info">
                <Typography
                  color={"var(--white)"}
                  sx={{
                    fontSize: "25px",
                    fontWeight: "700",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "20px",
                    },
                  }}
                >
                  {t("APP.LOBBY.INFO_TAB.INFO_NOT_AVAILABLE")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GameLobby;
