import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import { refreshTokenApi } from '../features/auth/authThunks.js';
import Cookies from 'js-cookie';
import store from "../store/store.js";

const dispatch = store.dispatch;

const isTokenExpired = (token) => {
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      return exp < Date.now() / 1000;
    } catch {
      return true;
    }
  };

  async function dispatchRefreshToken() {
    try {
      await dispatch(refreshTokenApi({})).unwrap();
    } catch (error) {
        console.error("Token refresh failed", error);
    }
  }

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('accessToken'));
  const [isAuthenticating, setAuthenticating] = useState(false);

  const authenticate = async () => {
    const accessToken = Cookies.get('accessToken');
    const expiresIn = Cookies.get('expiresIn');

    if ((!accessToken || isTokenExpired(accessToken)) && expiresIn) {
      setAuthenticating(true);
      try {
        await dispatchRefreshToken();
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Authentication failed:', error);
        setIsAuthenticated(false);
      }
      setAuthenticating(false);
    } else if (!expiresIn) {
        setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    authenticate();
  }, [Cookies.get('accessToken')]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAuthenticating, authenticate }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
    children: PropTypes.node
  };
