import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import onlineSupportIcon from "../../assets/icons/header/onlineSupportIcon.svg";
import { Link } from "react-router-dom";
export const SupportBtn = () => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip
        title={t("APP.LOBBY.HEADER.TOOLTIP_SUPPORT")}
        arrow
        placement="bottom"
      >
        <Link
          to="https://t.me/gorgine_support"
          target="_blank"
          style={{ display: "flex", cursor: "pointer" }}
        >
          <img src={onlineSupportIcon} alt="support" />
        </Link>
      </Tooltip>
    </>
  );
};
