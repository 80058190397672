import { Box } from "@mui/material";
import React from "react";
import propTypes from "prop-types";

const SmallAudioDetector = ({ smallAnimatorRef, username }) => {
  return (
    <Box className="audio-detector">
      <Box className="small-detector">
        <div
          ref={(elem) =>
            (smallAnimatorRef.current = {
              ...smallAnimatorRef.current,
              [username]: elem,
            })
          }
          id="animator-wrapper"
          className="boxContainer"
        >
          <div className="box box1"></div>
          <div className="box box2"></div>
          <div className="box box3"></div>
        </div>
      </Box>
    </Box>
  );
};
SmallAudioDetector.propTypes = {
  smallAnimatorRef: propTypes.object.isRequired,
  username: propTypes.string.isRequired,
};
export default SmallAudioDetector;
