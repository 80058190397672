import { params } from "../../../hooks/game-room/handlegameRoom.js";
export const handleMuteVideo = (socketId) => {
  const {
    socket,
    peersRef,
    videoMutedUsers,
    setVideoMutedUsers,
    setScreenArray,
  } = params;
  const storageId = peersRef.current.find(
    (peer) => peer?.socketId === socketId,
  )?.storageId;
  const state = videoMutedUsers.includes(socketId) ? false : true;
  socket.emit(
    "handleConsumer",
    {
      storageId: storageId,
      state: state,
    },
    (response) => {
      if (state) {
        setVideoMutedUsers((prev) => [...prev, socketId]);
        setScreenArray((prev) =>
          prev.map((peer) =>
            peer.storageId === storageId ? { ...peer, closeVideo: true } : peer,
          ),
        );
      } else {
        const newArr = videoMutedUsers.filter((sockId) => sockId !== socketId);
        setVideoMutedUsers(newArr);
        setScreenArray((prev) =>
          prev.map((peer) =>
            peer.storageId === storageId
              ? { ...peer, closeVideo: false }
              : peer,
          ),
        );
      }
    },
  );
};

export const handleMute = (socketId) => {
  const { socket, audioMutedUsers, setAudioMutedUsers } = params;
  const storageId = document.getElementsByClassName(`${socketId}`)[0]?.id;
  const state = audioMutedUsers.includes(socketId) ? false : true;
  socket.emit(
    "handleConsumer",
    {
      storageId: storageId,
      state: state,
    },
    (response) => {
      if (state) {
        setAudioMutedUsers((prev) => [...prev, socketId]);
      } else {
        const newArr = audioMutedUsers.filter((sockId) => sockId !== socketId);
        setAudioMutedUsers(newArr);
      }
    },
  );
};
