import { params } from "../../../hooks/game-room/handlegameRoom.js";
export const producerResumedPaused = () => {
  const {
    socket,
    setMicHiddenElems,
    setAdminAudioMutedUsers,
    adminVideoMutedUsers,
    setAdminVideoMutedUsers,
  } = params;
  socket.on("producerPaused", ({ storageId, socketId, kind }) => {
    if (kind === "video") {
    } else {
      setMicHiddenElems((prev) => [...prev, socketId]);
      setAdminAudioMutedUsers((prev) => [...prev, socketId]);
    }
  });

  socket.on("producerResumed", ({ storageId, socketId, kind }) => {
    if (kind === "video") {
      const filteredUsers = adminVideoMutedUsers.filter(
        (sockId) => sockId !== socketId,
      );
      setAdminVideoMutedUsers(filteredUsers);
    } else {
      setMicHiddenElems((prev) => prev.filter((id) => id !== socketId));
      setAdminAudioMutedUsers((prev) => prev.filter((id) => id !== socketId));
    }
  });
};
