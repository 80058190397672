import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
export const useHandlePhoneInput = (screen, phoneInputRef) => {
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    // to prevent from effect on other mui inputs
    const phoneWrapper = document.querySelector(".phone-input-custom-wrapper");
    const inputBaseElement = phoneWrapper.querySelector(".MuiInputBase-root");
    Array.from(inputBaseElement.children).forEach((child, index) => {
      if (index === 1) {
        const verticalLine = document.createElement("div");
        verticalLine.classList.add("verticalLine");
        inputBaseElement.insertBefore(verticalLine, child);
      }
    });
    const phoneNumberInput = document.createElement("input");
    phoneNumberInput.classList.add("phone-input-field");
    const { phoneNumber } = location.state || {};
    phoneNumberInput.value = phoneNumber ? phoneNumber : "";

    phoneNumberInput.type = "tel";
    phoneNumberInput.placeholder =
      screen === "register"
        ? t("APP.AUTH.PHONE_NUMBER_INPUT.REGISTER_PHONE_NUMBER_PLACEHOLDER")
        : t("APP.AUTH.PHONE_NUMBER_INPUT.LOGIN_PHONE_NUMBER_PLACEHOLDER");

    let fieldset = phoneWrapper.querySelector("fieldset");
    phoneNumberInput.addEventListener("focus", function () {
      fieldset.style.borderWidth = "2px";
      fieldset.classList.add("primary-border-fieldset");
    });
    phoneNumberInput.addEventListener("blur", function () {
      fieldset.style.borderWidth = "1px";
      fieldset.classList.remove("primary-border-fieldset");
    });
    phoneNumberInput.addEventListener("input", function () {
      let inputValue = phoneNumberInput.value;
      // Remove non-numeric characters using a regular expression
      inputValue = inputValue.replace(/\D/g, "");
      // Limit the input to a maximum of 15 characters
      if (inputValue.length > 15) {
        inputValue = inputValue.slice(0, 15);
      }

      // Set the formatted value back in the input
      phoneNumberInput.value = inputValue;
    });
    const existingInput = phoneWrapper.querySelector(".MuiInputBase-input");
    existingInput && existingInput.replaceWith(phoneNumberInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  phone poper top and width customization
  const handleIconButtonClick = (e) => {
    if (window.innerWidth > 600) {
      // menuWrapper.style.visibility = "hidden";
      setTimeout(() => {
        const menuPoperWrapper = document.querySelector("#select-country");
        if (menuPoperWrapper) {
          const menuWrapper = menuPoperWrapper.querySelector(
            ".MuiPaper-elevation",
          );
          const rect = phoneInputRef.current.getBoundingClientRect();
          const top = rect.top + 80 + "px";
          const width = rect.width + "px";
          menuWrapper.style.top = top;
          menuWrapper.style.bottom = "20px";
          menuWrapper.style.width = width;
          menuWrapper.style.height = "auto";
        }
      }, 0);
    }
  };
  useEffect(() => {
    const iconButton = document.querySelector(".MuiTelInput-IconButton");
    if (iconButton) {
      iconButton.addEventListener("click", handleIconButtonClick);
    }
    window.addEventListener("resize", handleIconButtonClick);
    return () => {
      if (iconButton) {
        iconButton.removeEventListener("click", handleIconButtonClick);
      }
      window.removeEventListener("resize", handleIconButtonClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
