import React, { useEffect } from "react";
import Grid from "@mui/material/Grid/index.js";
import { Box, Typography } from "@mui/material";
import userIcon from "../../assets/icons/userIcon.svg";
import playIcon from "../../assets/icons/playIcon.svg";
import eyeIcon from "../../assets/icons/eyeIcon.svg";
import checkIcon from "../../assets/icons/checkIcon.svg";
import lockIcon from "../../assets/icons/lockIcon.svg";
import eyeIconSm from "../../assets/icons/eyeIconSm.svg";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles/index.js";
import CaluclateStartTime from "./CaluclateStartTime.js";
import { mediaPath } from "../../configs/main.config.js";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

let timeoutId;
function GamesCard({
  data,
  selectedGameInfo,
  setSlectedGameInfo,
  gameMode,
  gameId,
  setGameId,
  isDisplayInfo,
  selectedItem,
  value,
  teachComponentsRef,
}) {
  const { createRoomList } = useSelector((state) => state.gameLobby);
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const { t } = useTranslation();
  const filterDataRef = useRef([]);
  function handleGameSelect(game) {
    let roomData = {
      ...game,
      gameMode,
      idx: data.findIndex((room) => room._id === game._id),
    };
    setGameId(roomData._id);
    setSlectedGameInfo(roomData);
  }
  let isCreateMode = gameMode.gameMode === "create" && value === 0;

  useEffect(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(
      () =>
        filterDataRef.current.length > 0 &&
        handleGameSelect(filterDataRef.current[0]),
      0,
    );
  }, [selectedItem]);

  useEffect(() => {
    filterDataRef.current = data;
    if (data.length === 0) setSlectedGameInfo(null);
    else if (!isDisplayInfo) {
      handleGameSelect(data[0]);
    }
  }, [data, isDisplayInfo]);
  useEffect(() => {
    if (data.length > 0 && isDisplayInfo) {
      const idx = selectedGameInfo.idx;
      if (idx !== -1) {
        if (data.length > idx) {
          handleGameSelect(data[idx]);
        } else {
          handleGameSelect(data[data.length - 1]);
        }
      }
    }
  }, [data]);

  // state:"ENDED"

  return (
    <>
      {data.map((item, i) => {
        const itemScenario = item.scenario.replace(/\s/g, "_");
        let imgSrc;

        const isRejoin = item?.playerIds?.some((userObj) => {
          const isEndedWithShot =
            item.state === "ENDED" && userObj.status === "SHOT";
          const isActive = !["KICKED", "SHOT"].includes(userObj.status);
          return userObj.userId === user.id && (isEndedWithShot || isActive);
        });

        if (item.imagePath) {
          imgSrc = mediaPath + item.imagePath;
        } else {
          imgSrc =
            mediaPath +
            createRoomList.find((room) => {
              return item.scenario === room.scenario;
            })?.imagePath;
        }
        let middleIcon;
        let isLocked;
        let isActive;
        if (isCreateMode) {
          if (item.status === "active") {
            middleIcon = checkIcon;
            isActive = true;
          } else if (item.status === "inActive") {
            middleIcon = lockIcon;
            isLocked = true;
          }
        }
        if (!isCreateMode) {
          middleIcon =
            (item.gameInProgress && !isRejoin) ||
            item.playersCount === item.maxPlayer
              ? eyeIcon
              : item.privacy === "PUBLIC"
                ? playIcon
                : lockIcon;
        }
        return (
          <Grid
            key={"grid-" + i}
            className="lobby-cards-grid-item"
            sx={{
              pointerEvents: isLocked ? "none" : "",
            }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            onClick={(e) => handleGameSelect(item)}
          >
            <Box
              className={`grid-card-wrapper ${
                gameId === item._id ? "selected-card-border" : ""
              }`}
            >
              <Box
                className={`card-background-image ${
                  (item.gameInProgress && !isRejoin) ||
                  isLocked ||
                  (!isCreateMode && item.playersCount === item.maxPlayer)
                    ? "black-in-white-back-img"
                    : ""
                }`}
                style={{
                  backgroundImage: `url(${imgSrc})`,
                }}
              ></Box>

              {isLocked ? (
                <Box></Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1em",
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.8em",
                      },
                    }}
                    color={"var(--white)"}
                  >
                    <img
                      className="card-head-icon"
                      src={
                        (item.gameInProgress && !isRejoin) ||
                        (!isCreateMode && item.playersCount === item.maxPlayer)
                          ? eyeIconSm
                          : userIcon
                      }
                      alt="users"
                    />
                    &nbsp;
                    {isCreateMode
                      ? item.minPlayer + "-" + item.maxPlayer
                      : (item.gameInProgress && !isRejoin) ||
                          item.playersCount === item.maxPlayer
                        ? item.viewersCount
                        : `${item.playersCount}/${item.maxPlayer}`}
                  </Typography>

                  {gameMode.gameMode === "join" && (
                    <Box
                      sx={{
                        maxWidth: "80px",
                        width: "31.37%",
                        minWidth: "60px",
                        height: "21px",
                        borderRadius: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        ...(item.privacy === "PUBLIC"
                          ? {
                              backgroundColor: "var(--teal)",
                            }
                          : { backgroundColor: "var(--indianRed)" }),
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "var(--white)",
                        }}
                      >
                        {item.privacy === "PUBLIC"
                          ? t("APP.LOBBY.GAME_CARD.PUBLIC")
                          : t("APP.LOBBY.GAME_CARD.PRIVATE")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              <img
                ref={(elem) => {
                  if (item.scenario !== "CUSTOM" || value !== 0) return;
                  teachComponentsRef.current = {
                    ...teachComponentsRef.current,
                    playIcon: elem,
                  };
                }}
                className={`middle-icon ${
                  gameId === item._id ? "selected-card-middle-icon" : ""
                }`}
                src={middleIcon}
                alt="play"
              />

              <Box className="scenarios-title-desc">
                <Typography
                  color={"var(--white)"}
                  sx={{
                    fontSize: "1.1em",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "0.9em",
                    },
                  }}
                >
                  {t(
                    `APP.LOBBY.BODY.SCENARIO_CARDS.SCENARIOS.${itemScenario}.TITLE`,
                  )}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    color={"var(--white)"}
                    fontSize={"10px"}
                  >
                    <span
                      style={{
                        color: isCreateMode
                          ? isActive
                            ? "var(--springGreen)"
                            : "var(--Crimson)"
                          : "var(--springGreen)",
                      }}
                    >
                      {isLocked ? (
                        <Box>
                          <span style={{ color: "var(--primary)" }}>
                            {t(
                              `APP.LOBBY.BODY.SCENARIO_CARDS.SCENARIOS.COMING_SOON.TITLE`,
                            )}
                          </span>
                        </Box>
                      ) : isCreateMode ? (
                        isActive ? (
                          t("APP.LOBBY.GAME_CARD.ACTIVE")
                        ) : (
                          t("APP.LOBBY.GAME_CARD.INACTIVE")
                        )
                      ) : (
                        <Box>
                          {item.roomNumber
                            ? t("APP.LOBBY.GAME_CARD.OPEN")
                            : t("APP.LOBBY.GAME_CARD.WAITING")}
                          {item.roomNumber && (
                            <span style={{ color: "var(--white)" }}>
                              {" "}
                              - {t("APP.LOBBY.GAME_CARD.ROOM")}{" "}
                              {item.roomNumber}
                            </span>
                          )}
                        </Box>
                      )}
                    </span>
                  </Typography>
                  {isCreateMode || !item.startTime ? (
                    ""
                  ) : (
                    <Typography
                      color={"var(--white)"}
                      fontWeight={"400"}
                      fontSize={"10px"}
                    >
                      <CaluclateStartTime startTime={item.startTime} />
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </>
  );
}
GamesCard.propTypes = {
  data: PropTypes.array.isRequired,
  setSlectedGameInfo: PropTypes.func.isRequired,
  selectedGameInfo: PropTypes.any,
  gameMode: PropTypes.object.isRequired,
  gameId: PropTypes.string.isRequired,
  setGameId: PropTypes.func.isRequired,
  isDisplayInfo: PropTypes.bool,
  selectedItem: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  teachComponentsRef: PropTypes.object.isRequired,
};
export default GamesCard;
