import { params } from "../../../hooks/game-room/handlegameRoom.js";
let timeOutId;
export const handleSendLike = () => {
  let { roomId, socket, setUserPersonalAction } = params;

  setUserPersonalAction((pre) => {
    clearTimeout(timeOutId);
    if (pre === "likeUsers") {
      return "";
    } else {
      timeOutId = setTimeout(() => {
        setUserPersonalAction((pre) => {
          return pre === "likeUsers" ? "" : pre;
        });
      }, 1000 * 20);
      return "likeUsers";
    }
  });

  socket.emit("sendLike", roomId);
};
