import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import bikeImage from "../../assets/image/global/motorbike.webp";
import { useSelector } from "react-redux";
let timeOutId;
function GlobalLoader() {
  const { globalLoaderParams } = useSelector((state) => state.global);
  const [visibility, setVisiblity] = useState("");
  const { open = true, bgOpacity = 0.9 } = globalLoaderParams;

  useEffect(() => {
    if (open) {
      clearTimeout(timeOutId);
      setVisiblity("visible");
    } else {
      clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        setVisiblity("hide");
      }, 1000);
    }
  }, [open]);
  return (
    <Box
      sx={{ backgroundColor: `rgba(0, 0, 0, ${bgOpacity})` }}
      className={`global-loader-parent ${visibility}`}
    >
      <Box className={`loader-transition ${open ? "scale1" : ""}`}>
        <Box className={`center loop`}></Box>
        <Box className={`center bike-wrapper`}>
          <Box
            className={`center-bike bike`}
            sx={{ backgroundImage: `url(${bikeImage})` }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GlobalLoader;
