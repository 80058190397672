import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ProfileDrawer } from "./ProfileDrawer.js";
import PropTypes from "prop-types";
import { Typography, Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles/index.js";
import { setNavigateTo } from "../../features/global/globalSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { Logo } from "./Logo.js";
import coinIcon from "../../assets/icons/header/coinIcon.webp";
import coinPlusIcon from "../../assets/icons/header/coinPlusIcon.svg";
import { emitSocketEvent, useHandleGlobalSocket } from "../../socket/socket.js";
import { addUserInfo } from "../../features/auth/authSlice.js";
import { LanguageBtn, SupportBtn } from "../../components/global/index.js";
const isRankedOff = process.env.REACT_APP_RANKED_FEATURE_FLAG === "true";
export const Header = ({
  gameMode,
  setGameMode,
  screen,
  setValue,
  teachComponentsRef,
}) => {
  const { user } = useSelector((state) => state.auth);
  useHandleGlobalSocket();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    emitSocketEvent("getUserData", user.id, (coins) => {
      dispatch(addUserInfo({ coins }));
    });
  }, []);

  return (
    <>
      <Box className="header-parent-wrapper">
        <Box className="header-left">
          <Box>
            <Logo screen="header" />
          </Box>
          <Box className="vertical-line"></Box>
          <Tooltip
            disableHoverListener={!isRankedOff}
            title={t("APP.LOBBY.HEADER.TOOLTIP_COMING_SOON")}
            arrow
            placement="bottom"
          >
            <Typography
              className="header-tabs"
              color={gameMode === "join" ? "var(--primary)" : "var(--white)"}
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                cursor: isRankedOff ? "not-allowed !important" : "pointer",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "15px",
                },
              }}
              onClick={() => {
                if (!isRankedOff) {
                  if (screen === "gameLobby") {
                    setGameMode("join");
                    setValue(0);
                  }
                  dispatch(setNavigateTo(`/game-lobby?tab=join`));
                }
              }}
            >
              {t("APP.LOBBY.HEADER.RANKED")}
            </Typography>
          </Tooltip>
          <Typography
            className="header-tabs"
            color={gameMode === "create" ? "var(--primary)" : "var(--white)"}
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
              },
            }}
            onClick={() => {
              if (screen === "gameLobby") {
                setGameMode("create");
                setValue(0);
              }
              dispatch(setNavigateTo(`/game-lobby?tab=create`));
            }}
          >
            {t("APP.LOBBY.HEADER.FRIENDLY")}
          </Typography>
          <Tooltip
            title={t("APP.LOBBY.HEADER.TOOLTIP_COMING_SOON")}
            arrow
            placement="bottom"
          >
            <Box sx={{ cursor: "not-allowed" }}>
              <Typography
                color={
                  gameMode === "assist" ? "var(--primary)" : "var(--white)"
                }
                sx={{
                  pointerEvents: "none",
                  fontSize: "20px",
                  fontWeight: "500",
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                }}
                onClick={() => {
                  if (screen === "gameLobby") {
                    setGameMode("assist");
                    setValue(0);
                  }
                  dispatch(setNavigateTo(`/game-lobby?tab=assist`));
                }}
              >
                {t("APP.LOBBY.HEADER.ASSIST")}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        <Box className="header-right">
          <Box className="user-support-box">
            <SupportBtn />
            <LanguageBtn />

            <Box
              ref={(elem) => {
                if (!teachComponentsRef) return;
                teachComponentsRef.current = {
                  ...teachComponentsRef.current,
                  coinsBalance: elem,
                };
              }}
              className="user-coins-box"
            >
              <img src={coinIcon} alt="coin" />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "var(--white)",
                }}
              >
                {user.coins}
              </Typography>
              <Box
                className="coin-plus-icon"
                onClick={() => {
                  screen !== "subscription" &&
                    dispatch(setNavigateTo("/subscription"));
                }}
              >
                <img src={coinPlusIcon} alt="coin-plus-icon" />
              </Box>
            </Box>
          </Box>

          <ProfileDrawer setGameMode={setGameMode} screen={screen} />
        </Box>
      </Box>
    </>
  );
};

Header.propTypes = {
  gameMode: PropTypes.string,
  setGameMode: PropTypes.func,
  setValue: PropTypes.func,
  screen: PropTypes.string,
  teachComponentsRef: PropTypes.object,
};
