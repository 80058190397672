import { useParams } from "react-router";
import { decodeParams } from "../../../handlers/global/encodeDecodeUrl.js";
import { useRef, useState, useEffect } from "react";
import { browserName } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  showGlobalLoader,
  hideGlobalLoader,
} from "../../../features/global/globalSlice.js";
let timeoutId;
const usePemissionMediaPopup = ({
  setMicStatus,
  setVideoStatus,
  setShowMediaPermisionPopup,
}) => {
  const { encodedParams } = useParams();
  const dispatch = useDispatch();
  const decodedParams = decodeParams(encodedParams);
  let { v: viewer, t: type, m: mode } = decodedParams;
  type === "a" && (type = "audio");
  viewer === "t" && (viewer = true);
  mode === "a" && (mode = "AUTO");
  //
  const [mediaPermissionDenied, setmediaPermissionDenied] = useState(false);
  const [mediaPermission, setmediaPermission] = useState(false);
  const localStreamRef = useRef(null);
  const trackLocalStream = useRef();

  const setValuesForDenied = () => {
    setmediaPermissionDenied(true);
    setmediaPermission(false);
    setMicStatus(false);
    setVideoStatus(false);
  };
  const setValuesForGranted = () => {
    setmediaPermission(true);
    setmediaPermissionDenied(false);
  };

  const handleChange = async (event) => {
    if (event.target.state === "granted") {
      // Permission granted, proceed with getUserMedia
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(async (res) => {
          setValuesForGranted();
          handleMedia();
        })
        .catch((err) => {
          console.log(err, "err");
          setValuesForDenied();
        });
    } else {
      // Permission denied
      setValuesForDenied();
    }
  };

  const handleMedia = async () => {
    const enableVideo = type !== "audio";
    clearTimeout(timeoutId);
    try {
      const media = await navigator?.mediaDevices?.getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
        },
        video: enableVideo,
      });
      setmediaPermission(true);
      setVideoStatus(true);
      setMicStatus(true);
      trackLocalStream.current = media;
      localStreamRef.current.srcObject = media;

      if (browserName === "Firefox") {
        // if user first allow and then block the permssion
        media.getTracks().forEach((track) => {
          track.addEventListener("ended", () => {
            setValuesForDenied();
            handleMedia();
          });
        });
      }
    } catch (error) {
      console.log("error: ", error);
      if (browserName === "Firefox") {
        setValuesForDenied();
        timeoutId = setTimeout(() => {
          handleMedia();
        }, 1000);
      }
      setmediaPermissionDenied(true);
    }
  };

  useEffect(() => {
    if (mode === "AUTO" && mediaPermission) {
      setShowMediaPermisionPopup(false);
    }
  }, [mediaPermission]);
  useEffect(() => {
    if (mode === "AUTO") {
      dispatch(showGlobalLoader({ bgOpacity: 1 }));
      setTimeout(() => {
        dispatch(hideGlobalLoader());
      }, 1000);
    }
    let status;
    // premission listner
    const permissionListner = async () => {
      const name = type === "audio" ? "microphone" : "camera";
      status = await navigator.permissions.query({ name });
      status.addEventListener("change", handleChange);
    };
    if (browserName !== "Firefox") {
      permissionListner();
    }
    handleMedia();
    return () => {
      // remove event listner
      if (status) {
        status.removeEventListener("change", handleChange);
      }
      // Stop tracks and close the media stream
      if (trackLocalStream.current) {
        const tracks = trackLocalStream.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  return {
    mediaPermissionDenied,
    mediaPermission,
    type,
    localStreamRef,
    trackLocalStream,
    viewer,
    mode,
  };
};

export default usePemissionMediaPopup;
