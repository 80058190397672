import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { handleVideo } from "../helper-functions/handleVideo.js";
let tooltipTimeout = {};
export const muteVideo = () => {
  const {
    socket,
    currentVideoState,
    setAdminVideoMuted,
    adminVideoMutedRef,
    setMediaAllowedTooltip,
  } = params;
  socket.on("muteVideo", (data) => {
    const { state } = data;
    if (state === "ADMIN MUTE") {
      setMediaAllowedTooltip((prev) => ({ ...prev, videoMuted: true }));
      clearTimeout(tooltipTimeout["videoMuted"]);
      tooltipTimeout["videoMuted"] = setTimeout(() => {
        setMediaAllowedTooltip((prev) => {
          setTimeout(() => {
            setMediaAllowedTooltip((prev) => ({ ...prev, wait: false }));
          }, 500);
          return {
            ...prev,
            wait: true,
            videoMuted: false,
          };
        });
      }, 5000);
    } else {
      setAdminVideoMuted(true);
    }

    if (!currentVideoState?.current) {
      if ("socketId" in data) {
        socket.emit("videoMuteStatus", { ...data, status: false });
      } else {
        socket.emit("videoMuteStatus", {
          ...data,
          status: false,
          socketId: socket.id,
        });
      }
    } else {
      if ("socketId" in data) {
        socket.emit("videoMuteStatus", { ...data, status: true });
      } else {
        socket.emit("videoMuteStatus", {
          ...data,
          status: true,
          socketId: socket.id,
        });
      }

      adminVideoMutedRef.current = true;
      handleVideo(true);
    }
  });
};
