import React from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const SubscriptionQuestions = () => {
  const { t } = useTranslation();

  const accordionData = [
    {
      summary: t("APP.SUBSCRIPTION.QUESTIONS.SUMMARY.FREE_MOON"),
      details: [t("APP.SUBSCRIPTION.QUESTIONS.DETAILS.FREE_MOON.FIRST_LINE")],
    },
    {
      summary: t("APP.SUBSCRIPTION.QUESTIONS.SUMMARY.DID_NOT_RECEIVED_MOON"),
      details: [
        t(
          "APP.SUBSCRIPTION.QUESTIONS.DETAILS.DID_NOT_RECEIVED_MOON.FIRST_LINE",
        ),
        <>
          <a
            href="https://t.me/gorgine_support"
            style={{ color: "var(--fireRed)" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(
              "APP.SUBSCRIPTION.QUESTIONS.DETAILS.DID_NOT_RECEIVED_MOON.SECOND_LINE",
            )}
          </a>
        </>,
      ],
    },
  ];

  return (
    <>
      <Box className="subscription-questions-wrapper">
        {accordionData.map((data, index) => {
          return (
            <Accordion key={index} className="accordion-wrapper">
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: "var(--blueGray)" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordion-summary"
              >
                <Typography>{data.summary}</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                {data.details.map((para, index) => {
                  return (
                    <Typography key={index} sx={{ marginBottom: "16px" }}>
                      {para}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};
