import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
export const handleStartGame = () => {
  const { socket, startStatus, setIsGameStart, roomId, roomData, dispatch, t } =
    params;
  const { minPlayer, players } = roomData;
  if (players.length < minPlayer) {
    setTimeout(() => {
      dispatch(
        alertDialog({
          icon: "error",
          title: `${minPlayer - players.length} ${t(
            "APP.ALERT_DIALOGS.TITLE.GAME_ROOM.PLAYERS_REQUIRED",
          )}`,
          text: t(
            "APP.ALERT_DIALOGS.DESC_TEXT.MIN_PLAYER_TO_START_GARME",
          ).replace("❔", minPlayer),
        }),
      );
    }, 0);

    return;
  }
  if (!startStatus.current) {
    socket.emit("startGame", roomId);
    startStatus.current = true;
    setIsGameStart(true);
  } else {
    startStatus.current = false;
    setIsGameStart(false);
  }
};
