import { useRef } from "react";

const useGameRoomUi = () => {
  const teachComponentsRef = useRef({});

  return {
    teachComponentsRef,
  };
};

export default useGameRoomUi;
