import { jwtDecode } from "jwt-decode";

export const decodeToken = () => {
  const accessToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("accessToken="))
    ?.split("=")[1];
  let info;
  if (accessToken) {
    info = jwtDecode(accessToken);
    info.accessToken = accessToken;
  }
  return info;
};
function isTokenExpired(expirationTimestamp) {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp > expirationTimestamp;
}
export const handleTokenRefresh = () => {
  let tokenInfo = { isTokenExpire: false, isTokenAvailble: true };
  let accessToken = decodeToken();
  if (accessToken) {
    if (isTokenExpired(accessToken.exp)) {
      tokenInfo.isTokenExpire = true;
    }
  } else {
    tokenInfo.isTokenAvailble = false;
  }
  return tokenInfo;
};
