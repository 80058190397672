import { combineReducers } from "redux";
import {
  authReducer,
  globalReducer,
  gameLobbyReducer,
  socketReducer,
  gameRoomReducer,
} from "../features/index.js";

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  gameLobby: gameLobbyReducer,
  socket: socketReducer,
  gameRoom: gameRoomReducer,
});

export default rootReducer;
