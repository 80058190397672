import { params } from "../../../hooks/game-room/handlegameRoom.js";
import sanitizeMessage from "../../global/sanitizeMessage.js";
export const sendMessage = () => {
  const { socket, roomId, messageRef, setMessages, user, viewer } = params;
  let event = viewer ? "viewerChat" : "chat";
  let stateObj = viewer ? "viewers" : "players";
  socket.emit(event, {
    roomId: roomId,
    message: {
      senderName: user.username,
      text: messageRef?.current?.value,
    },
  });
  setMessages((prev) => {
    const newState = { ...prev };
    const newMessage = sanitizeMessage(messageRef?.current?.value);
    newState[stateObj].push({
      senderName: user.username,
      text: newMessage,
    });
    return newState;
  });
  messageRef.current.value = "";
};
