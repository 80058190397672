import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
export const handleEndGame = () => {
  const { socket, endStatus, setIsGameEnded, roomId, dispatch, t } = params;

  const confirmEndGame = () => {
    if (!endStatus.current) {
      socket.emit("endGame", roomId);
      endStatus.current = true;
      setIsGameEnded(true);
    } else {
      endStatus.current = false;
      setIsGameEnded(false);
    }
  };

  dispatch(
    alertDialog({
      title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.END_GAME"),
      confirmButtonText: t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.YES"),
      cancelButtonText: t("APP.ALERT_DIALOGS.CANCEL_BTN.NO"),
      onConfirm: confirmEndGame,
    }),
  );
};
