import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import coinIcon from "../../../../assets/icons/coinIconSm.webp";
import {
  privateJoinVIdeoRoomCoins,
  privateJoinAudioRoomCoins,
  publicJoinRoomCoins,
} from "../../../../constants/gameLobbyConstant.js";
const JoinButton = ({
  handleActions,
  selectedGameInfo,
  slectedTab,
  infoBtn2Ref,
}) => {
  const { t } = useTranslation();
  const coins =
    slectedTab.gameMode === "create"
      ? selectedGameInfo.type === "VIDEO"
        ? privateJoinVIdeoRoomCoins
        : privateJoinAudioRoomCoins
      : publicJoinRoomCoins;
  return (
    <Button
      ref={infoBtn2Ref}
      name="join-btn"
      onClick={() =>
        handleActions({
          actionType: "JOIN",
          coins,
        })
      }
      type="submit"
      color="primary"
      variant="contained"
      size="large"
      className="info-btn2"
    >
      <Box
        sx={{
          display: "flex",
          gap: "3px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {t("APP.LOBBY.INFO_TAB.JOIN_BTN")}
        <Box className="coins-required">
          <img src={coinIcon} alt="coin" />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "var(--lemonYellow)",
            }}
          >
            {coins}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};
JoinButton.propTypes = {
  handleActions: PropTypes.func.isRequired,
  selectedGameInfo: PropTypes.any.isRequired,
  slectedTab: PropTypes.any.isRequired,
  infoBtn2Ref: PropTypes.object.isRequired,
};
export default JoinButton;
