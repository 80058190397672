import React from "react";
import { RoomTable } from "./RoomTable.js";
import { Box } from "@mui/material";
import bgImg from "../../../assets/image/room/auto-mode/autoModeBg.webp";
import { useHandleJoinRoom } from "../../../hooks/game-room/handlegameRoom.js";
import Footer from "../footer/Footer.js";
import likeGreenIcon from "../../../assets/icons/room/auto-mode/likeIcon.svg";
import dislikeRedIcon from "../../../assets/icons/room/auto-mode/dislikeIcon.svg";
import challengePurpleIcon from "../../../assets/icons/room/auto-mode/challengeIcon.svg";

export const autoUserPropMap = {
  likeUsers: { icon: likeGreenIcon },
  dislikeUsers: { icon: dislikeRedIcon },
  challengeUsers: {
    icon: challengePurpleIcon,
  },
};

export const AutoMode = () => {
  const stream = useHandleJoinRoom({
    openVideo: false,
    openMic: false,
    shiftToWatchMode: () => {
      alert("...need to take from props");
    },
  });
  return (
    <Box
      sx={{
        background: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Box className="auto-mode-parent-wrapper">
        <Box className="room-table-box">
          <RoomTable stream={stream} />
        </Box>
        <Box sx={{ position: "absolute", bottom: "13px", width: "100%" }}>
          <Footer stream={stream} />
        </Box>
      </Box>
    </Box>
  );
};
