import { domain } from "../configs/main.config.js";
import { io } from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import {
  setSocketConnection,
  connectSocket,
  disconnectSocket,
} from "../features/socket/socketSlice.js";
import { useEffect } from "react";

let socket;
export const useHandleGlobalSocket = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!socket) {
      if (user.username && user.accessToken) {
        socket = io(domain, {
          path: "/v1/ws/global/",
          query: { token: user?.accessToken },
        });
      }
      socket?.on("connect", () => {
        dispatch(setSocketConnection(socket));
        dispatch(connectSocket());
        console.log("connect");
      });
      socket?.on("disconnect", () => {
        dispatch(disconnectSocket());
      });
    }
    return () => {
      const pagesUseSocket = ["game-lobby", "subscription"];
      const route = window.location.pathname.split("/")[2];
      if (!pagesUseSocket.includes(route)) {
        socket = null;
        socket?.disconnect();
        console.log("disconnect");
      }
    };
  }, [dispatch]);
};

export const emitSocketEvent = async (eventName, eventData, callback) => {
  let eventDataParms;
  if (Array.isArray(eventData)) {
    eventDataParms = eventData;
  } else {
    eventDataParms = [eventData];
  }
  if (socket) {
    if (!callback) {
      socket.emit(eventName, ...eventDataParms);
    } else {
      socket.emit(eventName, ...eventDataParms, callback);
    }
  } else {
    console.log("Socket is not connected.");
  }
};

export const socketEventListener = (eventName, callback) => {
  if (socket) {
    socket.on(eventName, callback);
    // Return a function that removes the event listener
    return () => {
      socket?.off(eventName, callback);
    };
  }
};
