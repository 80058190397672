let timeZoneCityToCountry = [
  {
    city: "Andorra",
    code: "AD",
    name: "Andorra",
  },
  {
    city: "Dubai",
    code: "AE",
    name: "United Arab Emirates",
  },
  {
    city: "Kabul",
    code: "AF",
    name: "Afghanistan",
  },
  {
    city: "Tirane",
    code: "AL",
    name: "Albania",
  },
  {
    city: "Yerevan",
    code: "AM",
    name: "Armenia",
  },
  {
    city: "Casey",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Davis",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Mawson",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Palmer",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Rothera",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Troll",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Vostok",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Buenos_Aires",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Cordoba",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Salta",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Jujuy",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Tucuman",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Catamarca",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "La_Rioja",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "San_Juan",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Mendoza",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "San_Luis",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Rio_Gallegos",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Ushuaia",
    code: "AR",
    name: "Argentina",
  },
  {
    city: "Vienna",
    code: "AT",
    name: "Austria",
  },
  {
    city: "Lord_Howe",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Macquarie",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Hobart",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Melbourne",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Sydney",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Broken_Hill",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Brisbane",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Lindeman",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Adelaide",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Darwin",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Perth",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Eucla",
    code: "AU",
    name: "Australia",
  },
  {
    city: "Baku",
    code: "AZ",
    name: "Azerbaijan",
  },
  {
    city: "Barbados",
    code: "BB",
    name: "Barbados",
  },
  {
    city: "Dhaka",
    code: "BD",
    name: "Bangladesh",
  },
  {
    city: "Brussels",
    code: "BE",
    name: "Belgium",
  },
  {
    city: "Sofia",
    code: "BG",
    name: "Bulgaria",
  },
  {
    city: "Bermuda",
    code: "BM",
    name: "Bermuda",
  },
  {
    city: "La_Paz",
    code: "BO",
    name: "Bolivia",
  },
  {
    city: "Noronha",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Belem",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Fortaleza",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Recife",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Araguaina",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Maceio",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Bahia",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Sao_Paulo",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Campo_Grande",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Cuiaba",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Santarem",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Porto_Velho",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Boa_Vista",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Manaus",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Eirunepe",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Rio_Branco",
    code: "BR",
    name: "Brazil",
  },
  {
    city: "Thimphu",
    code: "BT",
    name: "Bhutan",
  },
  {
    city: "Minsk",
    code: "BY",
    name: "Belarus",
  },
  {
    city: "Belize",
    code: "BZ",
    name: "Belize",
  },
  {
    city: "St_Johns",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Halifax",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Glace_Bay",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Moncton",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Goose_Bay",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Toronto",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Nipigon",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Thunder_Bay",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Iqaluit",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Pangnirtung",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Winnipeg",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Rainy_River",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Resolute",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Rankin_Inlet",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Regina",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Swift_Current",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Edmonton",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Cambridge_Bay",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Yellowknife",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Inuvik",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Dawson_Creek",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Fort_Nelson",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Whitehorse",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Dawson",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Vancouver",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Cocos",
    code: "CC",
    name: "Cocos (Keeling) Islands",
  },
  {
    city: "Zurich",
    code: "CH",
    name: "Switzerland",
  },
  {
    city: "Rarotonga",
    code: "CK",
    name: "Cook Islands",
  },
  {
    city: "Santiago",
    code: "CL",
    name: "Chile",
  },
  {
    city: "Punta_Arenas",
    code: "CL",
    name: "Chile",
  },
  {
    city: "Easter",
    code: "CL",
    name: "Chile",
  },
  {
    city: "Shanghai",
    code: "CN",
    name: "China",
  },
  {
    city: "Urumqi",
    code: "CN",
    name: "China",
  },
  {
    city: "Bogota",
    code: "CO",
    name: "Colombia",
  },
  {
    city: "Costa_Rica",
    code: "CR",
    name: "Costa Rica",
  },
  {
    city: "Havana",
    code: "CU",
    name: "Cuba",
  },
  {
    city: "Cape_Verde",
    code: "CV",
    name: "Cape Verde",
  },
  {
    city: "Christmas",
    code: "CX",
    name: "Christmas Island",
  },
  {
    city: "Nicosia",
    code: "CY",
    name: "Cyprus",
  },
  {
    city: "Famagusta",
    code: "CY",
    name: "Cyprus",
  },
  {
    city: "Prague",
    code: "CZ",
    name: "Czech Republic",
  },
  {
    city: "Berlin",
    code: "DE",
    name: "Germany",
  },
  {
    city: "Copenhagen",
    code: "DK",
    name: "Denmark",
  },
  {
    city: "Santo_Domingo",
    code: "DO",
    name: "Dominican Republic",
  },
  {
    city: "Algiers",
    code: "DZ",
    name: "Algeria",
  },
  {
    city: "Guayaquil",
    code: "EC",
    name: "Ecuador",
  },
  {
    city: "Galapagos",
    code: "EC",
    name: "Ecuador",
  },
  {
    city: "Tallinn",
    code: "EE",
    name: "Estonia",
  },
  {
    city: "Cairo",
    code: "EG",
    name: "Egypt",
  },
  {
    city: "El_Aaiun",
    code: "EH",
    name: "Western Sahara",
  },
  {
    city: "Madrid",
    code: "ES",
    name: "Spain",
  },
  {
    city: "Ceuta",
    code: "ES",
    name: "Spain",
  },
  {
    city: "Canary",
    code: "ES",
    name: "Spain",
  },
  {
    city: "Helsinki",
    code: "FI",
    name: "Finland",
  },
  {
    city: "Fiji",
    code: "FJ",
    name: "Fiji",
  },
  {
    city: "Faroe",
    code: "FO",
    name: "Faroe Islands",
  },
  {
    city: "Paris",
    code: "FR",
    name: "France",
  },
  {
    city: "Tbilisi",
    code: "GE",
    name: "Georgia",
  },
  {
    city: "Cayenne",
    code: "GF",
    name: "French Guiana",
  },
  {
    city: "Gibraltar",
    code: "GI",
    name: "Gibraltar",
  },
  {
    city: "Nuuk",
    code: "GL",
    name: "Greenland",
  },
  {
    city: "Danmarkshavn",
    code: "GL",
    name: "Greenland",
  },
  {
    city: "Scoresbysund",
    code: "GL",
    name: "Greenland",
  },
  {
    city: "Thule",
    code: "GL",
    name: "Greenland",
  },
  {
    city: "Athens",
    code: "GR",
    name: "Greece",
  },
  {
    city: "Guatemala",
    code: "GT",
    name: "Guatemala",
  },
  {
    city: "Guam",
    code: "GU",
    name: "Guam",
  },
  {
    city: "Bissau",
    code: "GW",
    name: "Guinea-Bissau",
  },
  {
    city: "Guyana",
    code: "GY",
    name: "Guyana",
  },
  {
    city: "Hong_Kong",
    code: "HK",
    name: "Hong Kong",
  },
  {
    city: "Tegucigalpa",
    code: "HN",
    name: "Honduras",
  },
  {
    city: "Port-au-Prince",
    code: "HT",
    name: "Haiti",
  },
  {
    city: "Budapest",
    code: "HU",
    name: "Hungary",
  },
  {
    city: "Jakarta",
    code: "ID",
    name: "Indonesia",
  },
  {
    city: "Pontianak",
    code: "ID",
    name: "Indonesia",
  },
  {
    city: "Makassar",
    code: "ID",
    name: "Indonesia",
  },
  {
    city: "Jayapura",
    code: "ID",
    name: "Indonesia",
  },
  {
    city: "Dublin",
    code: "IE",
    name: "Ireland",
  },
  {
    city: "Jerusalem",
    code: "IL",
    name: "Israel",
  },
  {
    city: "Kolkata",
    code: "IN",
    name: "India",
  },
  {
    city: "Calcutta",
    code: "IN",
    name: "India",
  },
  {
    city: "Chagos",
    code: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    city: "Baghdad",
    code: "IQ",
    name: "Iraq",
  },
  {
    city: "Reykjavik",
    code: "IS",
    name: "Iceland",
  },
  {
    city: "Rome",
    code: "IT",
    name: "Italy",
  },
  {
    city: "Jamaica",
    code: "JM",
    name: "Jamaica",
  },
  {
    city: "Amman",
    code: "JO",
    name: "Jordan",
  },
  {
    city: "Tokyo",
    code: "JP",
    name: "Japan",
  },
  {
    city: "Nairobi",
    code: "KE",
    name: "Kenya",
  },
  {
    city: "Bishkek",
    code: "KG",
    name: "Kyrgyzstan",
  },
  {
    city: "Tarawa",
    code: "KI",
    name: "Kiribati",
  },
  {
    city: "Kanton",
    code: "KI",
    name: "Kiribati",
  },
  {
    city: "Kiritimati",
    code: "KI",
    name: "Kiribati",
  },
  {
    city: "Almaty",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    city: "Qyzylorda",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    city: "Qostanay",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    city: "Aqtobe",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    city: "Aqtau",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    city: "Atyrau",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    city: "Oral",
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    city: "Beirut",
    code: "LB",
    name: "Lebanon",
  },
  {
    city: "Colombo",
    code: "LK",
    name: "Sri Lanka",
  },
  {
    city: "Monrovia",
    code: "LR",
    name: "Liberia",
  },
  {
    city: "Vilnius",
    code: "LT",
    name: "Lithuania",
  },
  {
    city: "Luxembourg",
    code: "LU",
    name: "Luxembourg",
  },
  {
    city: "Riga",
    code: "LV",
    name: "Latvia",
  },
  {
    city: "Casablanca",
    code: "MA",
    name: "Morocco",
  },
  {
    city: "Monaco",
    code: "MC",
    name: "Monaco",
  },
  {
    city: "Majuro",
    code: "MH",
    name: "Marshall Islands",
  },
  {
    city: "Kwajalein",
    code: "MH",
    name: "Marshall Islands",
  },
  {
    city: "Ulaanbaatar",
    code: "MN",
    name: "Mongolia",
  },
  {
    city: "Hovd",
    code: "MN",
    name: "Mongolia",
  },
  {
    city: "Choibalsan",
    code: "MN",
    name: "Mongolia",
  },
  {
    city: "Martinique",
    code: "MQ",
    name: "Martinique",
  },
  {
    city: "Malta",
    code: "MT",
    name: "Malta",
  },
  {
    city: "Mauritius",
    code: "MU",
    name: "Mauritius",
  },
  {
    city: "Maldives",
    code: "MV",
    name: "Maldives",
  },
  {
    city: "Mexico_City",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Cancun",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Merida",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Monterrey",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Matamoros",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Mazatlan",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Chihuahua",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Ojinaga",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Hermosillo",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Tijuana",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Bahia_Banderas",
    code: "MX",
    name: "Mexico",
  },
  {
    city: "Kuala_Lumpur",
    code: "MY",
    name: "Malaysia",
  },
  {
    city: "Kuching",
    code: "MY",
    name: "Malaysia",
  },
  {
    city: "Maputo",
    code: "MZ",
    name: "Mozambique",
  },
  {
    city: "Windhoek",
    code: "NA",
    name: "Namibia",
  },
  {
    city: "Noumea",
    code: "NC",
    name: "New Caledonia",
  },
  {
    city: "Norfolk",
    code: "NF",
    name: "Norfolk Island",
  },
  {
    city: "Lagos",
    code: "NG",
    name: "Nigeria",
  },
  {
    city: "Managua",
    code: "NI",
    name: "Nicaragua",
  },
  {
    city: "Amsterdam",
    code: "NL",
    name: "Netherlands",
  },
  {
    city: "Oslo",
    code: "NO",
    name: "Norway",
  },
  {
    city: "Kathmandu",
    code: "NP",
    name: "Nepal",
  },
  {
    city: "Nauru",
    code: "NR",
    name: "Nauru",
  },
  {
    city: "Niue",
    code: "NU",
    name: "Niue",
  },
  {
    city: "Auckland",
    code: "NZ",
    name: "New Zealand",
  },
  {
    city: "Chatham",
    code: "NZ",
    name: "New Zealand",
  },
  {
    city: "Panama",
    code: "PA",
    name: "Panama",
  },
  {
    city: "Lima",
    code: "PE",
    name: "Peru",
  },
  {
    city: "Tahiti",
    code: "PF",
    name: "French Polynesia",
  },
  {
    city: "Marquesas",
    code: "PF",
    name: "French Polynesia",
  },
  {
    city: "Gambier",
    code: "PF",
    name: "French Polynesia",
  },
  {
    city: "Port_Moresby",
    code: "PG",
    name: "Papua New Guinea",
  },
  {
    city: "Bougainville",
    code: "PG",
    name: "Papua New Guinea",
  },
  {
    city: "Manila",
    code: "PH",
    name: "Philippines",
  },
  {
    city: "Karachi",
    code: "PK",
    name: "Pakistan",
  },
  {
    city: "Warsaw",
    code: "PL",
    name: "Poland",
  },
  {
    city: "Pitcairn",
    code: "PN",
    name: "Pitcairn",
  },
  {
    city: "Puerto_Rico",
    code: "PR",
    name: "Puerto Rico",
  },
  {
    city: "Lisbon",
    code: "PT",
    name: "Portugal",
  },
  {
    city: "Madeira",
    code: "PT",
    name: "Portugal",
  },
  {
    city: "Azores",
    code: "PT",
    name: "Portugal",
  },
  {
    city: "Palau",
    code: "PW",
    name: "Palau",
  },
  {
    city: "Asuncion",
    code: "PY",
    name: "Paraguay",
  },
  {
    city: "Qatar",
    code: "QA",
    name: "Qatar",
  },
  {
    city: "Bucharest",
    code: "RO",
    name: "Romania",
  },
  {
    city: "Riyadh",
    code: "SA",
    name: "Saudi Arabia",
  },
  {
    city: "Guadalcanal",
    code: "SB",
    name: "Solomon Islands",
  },
  {
    city: "Mahe",
    code: "SC",
    name: "Seychelles",
  },
  {
    city: "Khartoum",
    code: "SD",
    name: "Sudan",
  },
  {
    city: "Stockholm",
    code: "SE",
    name: "Sweden",
  },
  {
    city: "Singapore",
    code: "SG",
    name: "Singapore",
  },
  {
    city: "Paramaribo",
    code: "SR",
    name: "Suriname",
  },
  {
    city: "El_Salvador",
    code: "SV",
    name: "El Salvador",
  },
  {
    city: "Ndjamena",
    code: "TD",
    name: "Chad",
  },
  {
    city: "Bangkok",
    code: "TH",
    name: "Thailand",
  },
  {
    city: "Dushanbe",
    code: "TJ",
    name: "Tajikistan",
  },
  {
    city: "Fakaofo",
    code: "TK",
    name: "Tokelau",
  },
  {
    city: "Ashgabat",
    code: "TM",
    name: "Turkmenistan",
  },
  {
    city: "Tunis",
    code: "TN",
    name: "Tunisia",
  },
  {
    city: "Tongatapu",
    code: "TO",
    name: "Tonga",
  },
  {
    city: "Istanbul",
    code: "TR",
    name: "Turkey",
  },
  {
    city: "Funafuti",
    code: "TV",
    name: "Tuvalu",
  },
  {
    city: "Kiev",
    code: "UA",
    name: "Ukraine",
  },
  {
    city: "Uzhgorod",
    code: "UA",
    name: "Ukraine",
  },
  {
    city: "Zaporozhye",
    code: "UA",
    name: "Ukraine",
  },
  {
    city: "New_York",
    code: "US",
    name: "United States",
  },
  {
    city: "Detroit",
    code: "US",
    name: "United States",
  },
  {
    city: "Louisville",
    code: "US",
    name: "United States",
  },
  {
    city: "Monticello",
    code: "US",
    name: "United States",
  },
  {
    city: "Indianapolis",
    code: "US",
    name: "United States",
  },
  {
    city: "Vincennes",
    code: "US",
    name: "United States",
  },
  {
    city: "Winamac",
    code: "US",
    name: "United States",
  },
  {
    city: "Marengo",
    code: "US",
    name: "United States",
  },
  {
    city: "Petersburg",
    code: "US",
    name: "United States",
  },
  {
    city: "Vevay",
    code: "US",
    name: "United States",
  },
  {
    city: "Chicago",
    code: "US",
    name: "United States",
  },
  {
    city: "Tell_City",
    code: "US",
    name: "United States",
  },
  {
    city: "Knox",
    code: "US",
    name: "United States",
  },
  {
    city: "Menominee",
    code: "US",
    name: "United States",
  },
  {
    city: "Center",
    code: "US",
    name: "United States",
  },
  {
    city: "New_Salem",
    code: "US",
    name: "United States",
  },
  {
    city: "Beulah",
    code: "US",
    name: "United States",
  },
  {
    city: "Denver",
    code: "US",
    name: "United States",
  },
  {
    city: "Boise",
    code: "US",
    name: "United States",
  },
  {
    city: "Phoenix",
    code: "US",
    name: "United States",
  },
  {
    city: "Los_Angeles",
    code: "US",
    name: "United States",
  },
  {
    city: "Anchorage",
    code: "US",
    name: "United States",
  },
  {
    city: "Juneau",
    code: "US",
    name: "United States",
  },
  {
    city: "Sitka",
    code: "US",
    name: "United States",
  },
  {
    city: "Metlakatla",
    code: "US",
    name: "United States",
  },
  {
    city: "Yakutat",
    code: "US",
    name: "United States",
  },
  {
    city: "Nome",
    code: "US",
    name: "United States",
  },
  {
    city: "Adak",
    code: "US",
    name: "United States",
  },
  {
    city: "Honolulu",
    code: "US",
    name: "United States",
  },
  {
    city: "Montevideo",
    code: "UY",
    name: "Uruguay",
  },
  {
    city: "Samarkand",
    code: "UZ",
    name: "Uzbekistan",
  },
  {
    city: "Tashkent",
    code: "UZ",
    name: "Uzbekistan",
  },
  {
    city: "Caracas",
    code: "VE",
    name: "Venezuela",
  },
  {
    city: "Efate",
    code: "VU",
    name: "Vanuatu",
  },
  {
    city: "Johannesburg",
    code: "ZA",
    name: "South Africa",
  },
  {
    city: "Anguilla",
    code: "AI",
    name: "Anguilla",
  },
  {
    city: "Luanda",
    code: "AO",
    name: "Angola",
  },
  {
    city: "McMurdo",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "DumontDUrville",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Syowa",
    code: "AQ",
    name: "Antarctica",
  },
  {
    city: "Aruba",
    code: "AW",
    name: "Aruba",
  },
  {
    city: "Mariehamn",
    code: "AX",
    name: "Åland Islands",
  },
  {
    city: "Ouagadougou",
    code: "BF",
    name: "Burkina Faso",
  },
  {
    city: "Bahrain",
    code: "BH",
    name: "Bahrain",
  },
  {
    city: "Bujumbura",
    code: "BI",
    name: "Burundi",
  },
  {
    city: "Porto-Novo",
    code: "BJ",
    name: "Benin",
  },
  {
    city: "Nassau",
    code: "BS",
    name: "Bahamas",
  },
  {
    city: "Gaborone",
    code: "BW",
    name: "Botswana",
  },
  {
    city: "Blanc-Sablon",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Atikokan",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Creston",
    code: "CA",
    name: "Canada",
  },
  {
    city: "Douala",
    code: "CM",
    name: "Cameroon",
  },
  {
    city: "Busingen",
    code: "DE",
    name: "Germany",
  },
  {
    city: "Djibouti",
    code: "DJ",
    name: "Djibouti",
  },
  {
    city: "Dominica",
    code: "DM",
    name: "Dominica",
  },
  {
    city: "Asmara",
    code: "ER",
    name: "Eritrea",
  },
  {
    city: "Addis_Ababa",
    code: "ET",
    name: "Ethiopia",
  },
  {
    city: "Libreville",
    code: "GA",
    name: "Gabon",
  },
  {
    city: "Grenada",
    code: "GD",
    name: "Grenada",
  },
  {
    city: "Guernsey",
    code: "GG",
    name: "Guernsey",
  },
  {
    city: "Accra",
    code: "GH",
    name: "Ghana",
  },
  {
    city: "Banjul",
    code: "GM",
    name: "Gambia",
  },
  {
    city: "Conakry",
    code: "GN",
    name: "Guinea",
  },
  {
    city: "Guadeloupe",
    code: "GP",
    name: "Guadeloupe",
  },
  {
    city: "Malabo",
    code: "GQ",
    name: "Equatorial Guinea",
  },
  {
    city: "Zagreb",
    code: "HR",
    name: "Croatia",
  },
  {
    city: "Isle_of_Man",
    code: "IM",
    name: "Isle of Man",
  },
  {
    city: "Jersey",
    code: "JE",
    name: "Jersey",
  },
  {
    city: "Phnom_Penh",
    code: "KH",
    name: "Cambodia",
  },
  {
    city: "Comoro",
    code: "KM",
    name: "Comoros",
  },
  {
    city: "Kuwait",
    code: "KW",
    name: "Kuwait",
  },
  {
    city: "Cayman",
    code: "KY",
    name: "Cayman Islands",
  },
  {
    city: "Vaduz",
    code: "LI",
    name: "Liechtenstein",
  },
  {
    city: "Maseru",
    code: "LS",
    name: "Lesotho",
  },
  {
    city: "Antananarivo",
    code: "MG",
    name: "Madagascar",
  },
  {
    city: "Bamako",
    code: "ML",
    name: "Mali",
  },
  {
    city: "Saipan",
    code: "MP",
    name: "Northern Mariana Islands",
  },
  {
    city: "Nouakchott",
    code: "MR",
    name: "Mauritania",
  },
  {
    city: "Montserrat",
    code: "MS",
    name: "Montserrat",
  },
  {
    city: "Blantyre",
    code: "MW",
    name: "Malawi",
  },
  {
    city: "Niamey",
    code: "NE",
    name: "Niger",
  },
  {
    city: "Muscat",
    code: "OM",
    name: "Oman",
  },
  {
    city: "Kigali",
    code: "RW",
    name: "Rwanda",
  },
  {
    city: "Ljubljana",
    code: "SI",
    name: "Slovenia",
  },
  {
    city: "Bratislava",
    code: "SK",
    name: "Slovakia",
  },
  {
    city: "Freetown",
    code: "SL",
    name: "Sierra Leone",
  },
  {
    city: "San_Marino",
    code: "SM",
    name: "San Marino",
  },
  {
    city: "Dakar",
    code: "SN",
    name: "Senegal",
  },
  {
    city: "Mogadishu",
    code: "SO",
    name: "Somalia",
  },
  {
    city: "Lome",
    code: "TG",
    name: "Togo",
  },
  {
    city: "Kampala",
    code: "UG",
    name: "Uganda",
  },
  {
    city: "Aden",
    code: "YE",
    name: "Yemen",
  },
  {
    city: "Mayotte",
    code: "YT",
    name: "Mayotte",
  },
  {
    city: "Lusaka",
    code: "ZM",
    name: "Zambia",
  },
  {
    city: "St_Thomas",
    code: "VI",
    name: "Virgin Islands (US)",
  },
  {
    city: "Tortola",
    code: "VG",
    name: "Virgin Islands (UK)",
  },
  {
    city: "Vatican",
    code: "VA",
    name: "Vatican City",
  },
  {
    city: "Pago_Pago",
    code: "AS",
    name: "Samoa (American)",
  },
  {
    city: "Brunei",
    code: "BN",
    name: "Brunei",
  },
  {
    city: "Stanley",
    code: "FK",
    name: "Falkland Islands",
  },
  {
    city: "South_Georgia",
    code: "GS",
    name: "South Georgia & the South Sandwich Islands",
  },
  {
    city: "Yangon",
    code: "MM",
    name: "Myanmar (Burma)",
  },
  {
    city: "Belgrade",
    code: "CS",
    name: "Serbia",
  },
  {
    city: "Juba",
    code: "SS",
    name: "South Sudan",
  },
  {
    city: "Sao_Tome",
    code: "ST",
    name: "Sao Tome & Principe",
  },
  {
    city: "Grand_Turk",
    code: "TC",
    name: "Turks & Caicos Is",
  },
  {
    city: "Kerguelen",
    code: "TF",
    name: "French Southern & Antarctic Lands",
  },
  {
    city: "Wallis",
    code: "WF",
    name: "Wallis & Futuna",
  },
  {
    city: "Apia",
    code: "WS",
    name: "Samoa (western)",
  },
  {
    city: "Antigua",
    code: "AG",
    name: "Antigua & Barbuda",
  },
  {
    city: "Sarajevo",
    code: "BA",
    name: "Bosnia & Herzegovina",
  },
  {
    city: "Kinshasa",
    code: "CD",
    name: "Congo (Dem. Rep.)",
  },
  {
    city: "Lubumbashi",
    code: "CD",
    name: "Congo (Dem. Rep.)",
  },
  {
    city: "Bangui",
    code: "CF",
    name: "Central African Rep.",
  },
  {
    city: "Brazzaville",
    code: "CG",
    name: "Congo (Rep.)",
  },
  {
    city: "Longyearbyen",
    code: "SJ",
    name: "Svalbard & Jan Mayen",
  },
  {
    city: "Port_of_Spain",
    code: "TT",
    name: "Trinidad & Tobago",
  },
  {
    city: "Abidjan",
    code: "CI",
    name: "Côte d'Ivoire",
  },
  {
    city: "Chuuk",
    code: "FM",
    name: "Micronesia",
  },
  {
    city: "Pohnpei",
    code: "FM",
    name: "Micronesia",
  },
  {
    city: "Kosrae",
    code: "FM",
    name: "Micronesia",
  },
  {
    city: "London",
    code: "GB",
    name: "Britain (UK)",
  },
  {
    city: "Tehran",
    code: "IR",
    name: "Iran",
  },
  {
    city: "Pyongyang",
    code: "KP",
    name: "Korea (North)",
  },
  {
    city: "Seoul",
    code: "KR",
    name: "Korea (South)",
  },
  {
    city: "Tripoli",
    code: "LY",
    name: "Libya",
  },
  {
    city: "Chisinau",
    code: "MD",
    name: "Moldova",
  },
  {
    city: "Macau",
    code: "MO",
    name: "Macau",
  },
  {
    city: "Miquelon",
    code: "PM",
    name: "St Pierre & Miquelon",
  },
  {
    city: "Gaza",
    code: "PS",
    name: "Palestine",
  },
  {
    city: "Hebron",
    code: "PS",
    name: "Palestine",
  },
  {
    city: "Reunion",
    code: "RE",
    name: "Réunion",
  },
  {
    city: "Kaliningrad",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Moscow",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Simferopol",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Kirov",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Volgograd",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Astrakhan",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Saratov",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Ulyanovsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Samara",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Yekaterinburg",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Omsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Novosibirsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Barnaul",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Tomsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Novokuznetsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Krasnoyarsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Irkutsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Chita",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Yakutsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Khandyga",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Vladivostok",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Ust-Nera",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Magadan",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Sakhalin",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Srednekolymsk",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Kamchatka",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Anadyr",
    code: "RU",
    name: "Russia",
  },
  {
    city: "Damascus",
    code: "SY",
    name: "Syria",
  },
  {
    city: "Dili",
    code: "TL",
    name: "East Timor",
  },
  {
    city: "Taipei",
    code: "TW",
    name: "Taiwan",
  },
  {
    city: "Wake",
    code: "UM",
    name: "US minor outlying islands",
  },
  {
    city: "Ho_Chi_Minh",
    code: "VN",
    name: "Vietnam",
  },
  {
    city: "St_Barthelemy",
    code: "BL",
    name: "St Barthelemy",
  },
  {
    city: "Kralendijk",
    code: "BQ",
    name: "Caribbean NL",
  },
  {
    city: "Curacao",
    code: "CW",
    name: "Curaçao",
  },
  {
    city: "St_Kitts",
    code: "KN",
    name: "St Kitts & Nevis",
  },
  {
    city: "Vientiane",
    code: "LA",
    name: "Laos",
  },
  {
    city: "St_Lucia",
    code: "LC",
    name: "St Lucia",
  },
  {
    city: "Podgorica",
    code: "ME",
    name: "Montenegro",
  },
  {
    city: "Marigot",
    code: "MF",
    name: "St Martin (French)",
  },
  {
    city: "Skopje",
    code: "MK",
    name: "North Macedonia",
  },
  {
    city: "St_Helena",
    code: "SH",
    name: "St Helena",
  },
  {
    city: "Lower_Princes",
    code: "SX",
    name: "St Maarten (Dutch)",
  },
  {
    city: "Mbabane",
    code: "SZ",
    name: "Eswatini (Swaziland)",
  },
  {
    city: "Dar_es_Salaam",
    code: "TZ",
    name: "Tanzania",
  },
  {
    city: "Midway",
    code: "UM",
    name: "US minor outlying islands",
  },
  {
    city: "St_Vincent",
    code: "VC",
    name: "St Vincent",
  },
  {
    city: "Harare",
    code: "ZW",
    name: "Zimbabwe",
  },
];
export const getUserCountry = () => {
  if (Intl) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split("/");
    const userCity = tzArr[tzArr.length - 1];
    const userCountry = timeZoneCityToCountry.find(
      (countryData) => countryData.city === userCity,
    );
    return userCountry;
  } else {
    console.warn("Intl is not supported in this environment.");
    return undefined;
  }
};
