import { useEffect } from "react";
import { useHref } from "react-router-dom";
import { useTranslation } from "react-i18next";

const supportedLanguages = ["en", "fa"];
const useHandleLang = () => {
  const { i18n } = useTranslation();
  const lang = useHref("/").replace("/", "");

  useEffect(() => {
    if (supportedLanguages.includes(lang)) {
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;
    }
  }, [lang, i18n]);
};

export default useHandleLang;
