import React, { useState } from "react";
import appStore from "../../assets/image/global/app-store.svg";
import googlePlay from "../../assets/image/global/google-play.svg";
import { Typography } from "@mui/material";

const NotAvaibleMobileVersion = () => {
  const [isPressed, setIsPressed] = useState({ googlePlay: false, appStore: false, iApps: false });

  const handleTouchStart = (logo) => {
    setIsPressed({ ...isPressed, [logo]: true });
  };

  const handleTouchEnd = (logo) => {
    setIsPressed({ ...isPressed, [logo]: false });
  };

  return (
    <div style={styles.container}>
      <Typography style={styles.text} variant="h6" component="h4">
      برای کسب تجربه بهتر از بازی مافیا در پلتفرم گرگینه بهتر است از نسخه دسکتاپ استفاده کنید. همچنین می‌توانید اپلیکیشن موبایل برای اندروید را از Google Play و برای آیفون از App Store یا iApps دانلود کنید.
      </Typography>
      <div style={styles.logoContainer}>
        <a href="https://play.google.com/store/apps/details?id=com.app.gorgine" 
          onTouchStart={() => handleTouchStart('googlePlay')} 
          onTouchEnd={() => handleTouchEnd('googlePlay')}
          style={isPressed.googlePlay ? {...styles.link, ...styles.activeLogo} : styles.link}
          target="_blank"
          rel="noopener noreferrer">
          <img src={googlePlay} alt="Google Play" style={styles.logo} />
        </a>
        <a href="https://apps.apple.com/ca/app/gorgine/id6483678893" 
          onTouchStart={() => handleTouchStart('appStore')} 
          onTouchEnd={() => handleTouchEnd('appStore')}
          style={isPressed.appStore ? {...styles.link, ...styles.activeLogo} : styles.link}
          target="_blank"
          rel="noopener noreferrer">
          <img src={appStore} alt="App Store" style={styles.logo} />
        </a>
      </div>
      <div style={{ paddingTop: '20px' }}>
        <a
          href="https://iapps.ir/app/گرگینه-مافیا-انلاین/702770346"
          onTouchStart={() => handleTouchStart("iApps")}
          onTouchEnd={() => handleTouchEnd("iApps")}
          style={
            isPressed.iApps
              ? { ...styles.link, ...styles.activeLogo }
              : styles.link
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://developer.iapps.ir/images/Iapps-Badge-Black-en.svg" alt="iApps" style={styles.logo} />
        </a>
      </div>
    </div>
  );
};


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: "#161B22",
    padding: 0,
    margin: 0,
    width: '100vw',
    direction: 'rtl',
    overflow: 'hidden',
  },
  text: {
    fontSize: '14px',
    color: "#3F94B2",
    margin: '0 30px 20px 30px',
    textAlign: 'center',
    // textAlign: 'justify',
    hyphens: 'auto'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '200px',
  },
  logoLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
    margin: '0 10px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  logo: {
    width: '150px',
    height: 'auto',
    marginRight: '10px',
    justifyContent: "space-between"
  },
  activeLogo: {
    transform: 'scale(0.95)',
    transition: 'transform 0.2s ease'
  }
};

export default NotAvaibleMobileVersion;
