import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { alertDialog } from "../../../features/global/globalSlice.js";

export const promptPermission = () => {
  const {
    socket,
    currentMicState,
    currentVideoState,
    dispatch,
    setAdminAudioMuted,
    setAdminVideoMuted,
    roomId,
    t,
    user,
    type: roomType,
  } = params;
  const queue = [];

  socket.on("promptPermission", (type, adminSocketId, mode, wokePlayers) => {
    if (roomType === "audio" && mode === "DAY") {
      dispatch(
        alertDialog({
          title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.OPEN_MIC_ON_TURN"),
          icon: "info",
        }),
      );
      return;
    }

    if (type === "microphone") {
      setAdminAudioMuted(false);
    } else if (type === "video") {
      setAdminVideoMuted(false);
    }
    if (currentMicState?.current && type === "microphone") {
      return;
    } else if (currentVideoState.current && type === "video") {
      return;
    } else if (
      currentMicState?.current &&
      currentVideoState.current &&
      type === "both"
    ) {
      return;
    }

    if (!queue.includes(type)) {
      queue.push(type);
    }
    // data is used to add in wokePlayers in WAKE UP mode
    const data = {
      userId: user.id,
      roomId,
      wokePlayers,
    };
    const acceptPermission = (type) => {
      socket.emit("acceptPermission", {
        socketId: socket.id,
        type,
        adminSocketId,
        mode,
        data,
      });
    };

    const denyPermission = (type) => {
      socket.emit("denyPermission", {
        socketId: socket.id,
        type,
        adminSocketId,
        mode,
      });
    };

    const handlePermissionDialog = async (type, mode) => {
      return new Promise((resolve) => {
        const title =
          mode === "DAY"
            ? t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.DAY_MODE")
            : mode === "WAKE UP"
              ? t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.TRYING_TO_AWAKE_PLAYER")
              : t(
                  `APP.ALERT_DIALOGS.TITLE.GAME_ROOM.TRYING_TO_OPEN_${type.toUpperCase()}`,
                );
        const onConfirm = (type) => {
          acceptPermission(type);
          resolve();
        };
        // const onConfirm2 = () => {
        //   acceptPermission("video");
        //   resolve();
        // };
        const onConfirm3 = () => {
          acceptPermission("both");
          resolve();
        };
        const confirmButtonText =
          mode === "WAKE UP"
            ? t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.OPEN_MIC")
            : t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.YES");
        // this for wake up player in night mode
        // const confirmButtonText2 = t(
        //   "APP.ALERT_DIALOGS.CONFIRMATION_BTN.OPEN_VIDEO",
        // );
        const confirmButtonText3 = t(
          "APP.ALERT_DIALOGS.CONFIRMATION_BTN.OPEN_BOTH",
        );
        const onCancel = () => {
          denyPermission(type);
          resolve();
        };

        dispatch(
          alertDialog({
            title: title,
            confirmButtonText,

            onCancel,
            ...(mode === "DAY"
              ? { autoCloseTimeout: 20 * 1000 } //20seconds
              : mode === "WAKE UP" && { autoCloseTimeout: 10 * 1000 }),
            // this for wake up player in night mode
            ...(mode === "WAKE UP"
              ? {
                  // confirmButtonText2,

                  onConfirm: () => {
                    onConfirm("microphone");
                  },
                  // onConfirm2,
                  ...(roomType !== "audio"
                    ? { confirmButtonText3, onConfirm3 }
                    : {}),
                }
              : {
                  cancelButtonText: t("APP.ALERT_DIALOGS.CANCEL_BTN.NO"),
                  onConfirm: () => {
                    onConfirm(type);
                  },
                }),
          }),
        );
      });
    };

    const processQueue = async (mode) => {
      for (const type of queue) {
        await handlePermissionDialog(type, mode);
      }
      queue.length = 0;
    };
    processQueue(mode);
  });
};
