const createVideoRoomCoins = 550;
const createAudioRoomCoins = 250;
const privateJoinVIdeoRoomCoins = 550;
const privateJoinAudioRoomCoins = 250;
const privateReJoinRoomCoins = 0;
const privateWatchRoomCoins = 0;
const publicJoinRoomCoins = 150;

export {
  createVideoRoomCoins,
  createAudioRoomCoins,
  privateJoinVIdeoRoomCoins,
  privateJoinAudioRoomCoins,
  privateReJoinRoomCoins,
  privateWatchRoomCoins,
  publicJoinRoomCoins,
};
