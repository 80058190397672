import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { ReactComponent as CancelIcon } from "../../../assets/icons/lobby/cancelIcon.svg";
import PropTypes from "prop-types";
import { mediaPath } from "../../../configs/main.config.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

let timeOutId;
const ReadyToPlayPopup = ({
  showPlayPopup,
  setShowPlayPopup,
  abilityCardInfo,
  abilityIconDistance,
  playerAbility,
}) => {
  const { t } = useTranslation();
  const { side, ability, imagePath, youtubePath, articlePath } =
    abilityCardInfo;

  useEffect(() => {
    if (showPlayPopup && !play) {
      clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        handleCloseAbilityCard();
      }, 10000);
    }
  }, [showPlayPopup]);

  const handleReadyToPlay = () => {
    setShowPlayPopup(false);
  };
  const handleCloseAbilityCard = () => {
    setShowPlayPopup(false);
  };
  let backgroundImage = mediaPath + imagePath;

  const [play, setPlay] = useState(false);

  const handleVideoClose = () => {
    setPlay(false);
  };

  const abilityCardRef = useRef(null);
  const [abilityCardWidth, setAbilityCardWidth] = useState(0);

  useEffect(() => {
    const calculateBoxWidth = () => {
      if (abilityCardRef.current) {
        const boundingRect = abilityCardRef.current.getBoundingClientRect();
        setAbilityCardWidth(boundingRect.width);
      }
    };

    // Initial calculation
    calculateBoxWidth();

    window.addEventListener("resize", calculateBoxWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateBoxWidth);
    };
  }, [playerAbility, showPlayPopup]);

  const popupDistance = abilityIconDistance - abilityCardWidth / 2;

  return (
    <Box
      ref={abilityCardRef}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        right: `${popupDistance}px`,
      }}
      className={`ability-card-parent-wrapper ${
        showPlayPopup ? "animate" : ""
      }`}
    >
      {play && (
        <>
          <Dialog
            onClose={handleVideoClose}
            aria-labelledby="customized-dialog-title"
            open={play}
            className="ability-video-popup-box"
          >
            <iframe
              width="100%"
              height="100%"
              src={youtubePath}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Dialog>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box className="cancel-icon-wrap">
          <CancelIcon
            onClick={handleCloseAbilityCard}
            className="cancel-icon"
          />
        </Box>

        <Box sx={{ flex: "1" }}>
          <Button
            onClick={handleReadyToPlay}
            sx={{
              width: "100%",
              textAlign: "center",
              height: "43px",
              fontSize: "20px",
              fontWeight: 800,
            }}
            variant="contained"
            color="translucentBlack"
          >
            {t("APP.GAME_ROOM.READY_PLAY_POPUP.READY_PLAY_BTN")}
          </Button>
        </Box>
        <Box className="content-box">
          <Typography
            className="ability-card-side"
            sx={{
              fontSize: "30px",
              color:
                side === "MAFIA"
                  ? "var(--darkRed)"
                  : side === "INDEPENDENT"
                    ? "var(--goldenRod)"
                    : "var(--darkGreen)",
              fontWeight: "700",
            }}
          >
            {t(`APP.ABILITIES.${side}.TITLE`)}
          </Typography>
          <Typography
            sx={{
              fontSize: "30px",
              color: "var(--lightGray)",
              fontWeight: "700",
              mt: "-10px",
              mb: "-5px",
            }}
          >
            {t(`APP.ABILITIES.${side}.${ability}.ABILITY`)}
          </Typography>
          <Typography
            color="var(--gray)"
            sx={{
              fontSize: "12px",
              fontWeight: "700",
            }}
          >
            {t(`APP.ABILITIES.${side}.${ability}.DESCRIPTION`)}
          </Typography>
          {(articlePath || youtubePath) && (
            <Box className="action-btn-wrap">
              {articlePath && (
                <Link to={articlePath} target="_blank">
                  <Button
                    onClick={handleReadyToPlay}
                    sx={{
                      height: "48px",
                      width: "140px",
                      fontSize: "17px",
                      fontWeight: 600,
                      borderRadius: "10px",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {t("APP.GAME_ROOM.READY_PLAY_POPUP.LEARN_BTN")}
                  </Button>
                </Link>
              )}
              {youtubePath && (
                <Button
                  onClick={() => setPlay(true)}
                  sx={{
                    height: "48px",
                    width: "140px",
                    fontSize: "17px",
                    fontWeight: 600,
                    borderRadius: "10px",
                    color: "var(--white)",
                  }}
                  variant="contained"
                  color="lightGray"
                >
                  {t("APP.GAME_ROOM.READY_PLAY_POPUP.VIDEO_BTN")}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
ReadyToPlayPopup.propTypes = {
  showPlayPopup: PropTypes.bool.isRequired,
  setShowPlayPopup: PropTypes.func.isRequired,
  abilityCardInfo: PropTypes.any.isRequired,
  abilityIconDistance: PropTypes.number,
  playerAbility: PropTypes.any,
};

export default ReadyToPlayPopup;
