import { useState, useEffect } from "react";
import PropTypes from "prop-types";

function CaluclateStartTime({ startTime, format }) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Update the clock every second
    const intervalID = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalID);
  }, []);

  const timeDifference = currentTime - new Date(startTime);
  const hours = Math.floor(timeDifference / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((timeDifference % 3600000) / 60000); // 1 minute = 60000 milliseconds
  const seconds = Math.floor((timeDifference % 60000) / 1000); // 1 second = 1000 milliseconds

  const formattedTime = `${hours}:${minutes}:${seconds}`;
  if (format === "minutes") {
    return timeDifference / (60 * 1000);
  }
  return formattedTime;
}
CaluclateStartTime.propTypes = {
  startTime: PropTypes.any,
};
export default CaluclateStartTime;
