import { handleApi } from "../../api/handleApi.js";
export const getCreateRoomApi = handleApi("scenarios", "GET", {
  showGlobalLoader: true,
});
export const checkRoomPasswordApi = handleApi("room/check-password", "GET", {
  showGlobalLoader: true,
});

export const getAbilitiesApi = handleApi("abilities", "GET", {
  showGlobalLoader: true,
});

export const createCustomScenarioApi = handleApi(
  "custom-scenario/create",
  "POST",
  {
    showGlobalLoader: true,
  },
);

export const createFeedbackApi = handleApi("feedback/create", "POST", {
  showGlobalLoader: true,
});
