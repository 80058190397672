export async function handleTransport({
  data,
  produceTransport,
  mediasoupDevice,
  socket,
  roomId,
  videoProducerId,
  audioProducerId,
  localStreamRef,
  audioProducer,
  videoProducer,
  openVideo,
  openMic,
  setCaptureMediaStreams,
  user,
}) {
  produceTransport.current =
    await mediasoupDevice.current?.createSendTransport(data);

  produceTransport.current?.on(
    "connect",
    ({ dtlsParameters }, callback, errback) => {
      socket.emit("connectTransport", { dtlsParameters, id: socket.id });
      socket.on("transportConnected", () => {
        callback();
      });
    },
  );

  produceTransport.current?.on(
    "produce",
    ({ kind, rtpParameters, appData }, callback, errback) => {
      console.log(
        "kind",
        kind,
        "rtpParameters",
        rtpParameters,
        "appData",
        appData,
      );
      socket.emit("produce", {
        kind,
        rtpParameters,
        id: socket.id,
        room: roomId,
        appData,
      });
      socket.on("producing", ({ producerId, kind }) => {
        if (kind === "video") {
          videoProducerId.current = producerId;
        } else {
          audioProducerId.current = producerId;
        }
        callback(producerId);
      });
    },
  );

  produceTransport.current?.on("connectionstatechange", async (state) => {
    switch (state) {
      case "connecting":
        console.log("connecting");
        break;
      case "connected":
        console.log("connected");
        break;
      case "failed":
        console.log("Connection failed at handle Transport");
        socket.emit("producerRestartIce", async (params) => {
          if (!params) {
            console.error("No ICE parameters received for producer restart");
            return;
          }
          try {
            await produceTransport.current?.restartIce({
              iceParameters: params,
            });
          } catch (error) {
            console.error("Error restarting ICE on producer", error);
          }
        });
        break;
      default:
        break;
    }
  });

  try {
    const Audiotracks = localStreamRef?.current?.getAudioTracks()[0];
    const Videotracks = localStreamRef?.current?.getVideoTracks()[0];
    // if user allow or not audio intially
    if (Audiotracks && openMic) {
      audioProducer.current = await produceTransport.current?.produce({
        track: Audiotracks,
      });
      setCaptureMediaStreams((prev) => ({
        ...prev,
        [user.username]: localStreamRef.current,
      }));
    }
    // if user allow or not video intially
    if (Videotracks && openVideo) {
      videoProducer.current = await produceTransport.current?.produce({
        track: Videotracks,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
