import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetNavigator } from "../../features/global/globalSlice.js";
function Navigator() {
  const dispatch = useDispatch();
  const { navigateTo, navigateState,navigateReplaceState } = useSelector((state) => state.global);
  const navigate = useNavigate();
  useEffect(() => {
    navigateTo && navigate(navigateTo, { state: navigateState ,replace:navigateReplaceState });
    dispatch(resetNavigator());
  }, [navigateTo, navigate, navigateState, navigateReplaceState,dispatch]);

  return null;
}

export default Navigator;
