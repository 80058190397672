import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const constantData = [
  { userName: "Bilits", vote: "10" },
  { userName: "Bilits", vote: "2" },
  { userName: "Bilits", vote: "1" },
  { userName: "Bilits", vote: "5" },
  { userName: "Bilits", vote: "10" },
  { userName: "Bilits", vote: "1" },
  { userName: "Bilits", vote: "1" },
  { userName: "Bilits", vote: "2" },
  { userName: "Bilits", vote: "4" },
  { userName: "Bilits", vote: "2" },
  { userName: "Bilits", vote: "6" },
  { userName: "Bilits", vote: "2" },
  { userName: "Bilits", vote: "1" },
  { userName: "Bilits", vote: "3" },
];

const columns = [
  { id: "rank", label: "Rank", width: "30px" },
  { id: "name", label: "Username" },
  {
    id: "total",
    label: "Total vote",
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "vote",
    label: "Vote",
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(rank, name, total, vote) {
  return { rank, name, total, vote };
}

const VoteButton = ({ isVoted, handleVoting }) => (
  <Button
    color={isVoted ? "darkStealBlue" : "primary"}
    variant="contained"
    size="small"
    onClick={handleVoting}
    className="vote-btn"
  >
    {isVoted ? "Voted" : "Click to vote"}
  </Button>
);

export default function VotingComponent() {
  const sortedData = constantData.sort(
    (a, b) => parseInt(b.vote) - parseInt(a.vote),
  );

  const [votesState, setVotesState] = React.useState(
    Array(sortedData.length).fill(false),
  );

  const handleVoting = (index) => {
    setVotesState((prevVotes) => {
      const newVotes = [...prevVotes];
      newVotes[index] = !newVotes[index];
      return newVotes;
    });
  };

  const rows = sortedData.map((data, index) => {
    const rank = index + 1;
    const userName = data.userName;
    const votes = parseInt(data.vote);

    return createData(
      rank,
      userName,
      votes,
      <VoteButton
        isVoted={votesState[index]}
        handleVoting={() => handleVoting(index)}
      />,
    );
  });

  return (
    <Paper
      className="voting-component-parent-wrapper"
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" className="my-table">
          <TableHead className="table-head">
            <TableRow className="table-head-row">
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className="head-items"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  className="table-body-row"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.rank}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className="body-items"
                        key={column.id}
                        align={column.align}
                        style={{ width: column.width, marginRight: "20px" }}
                      >
                        <div className="cell-content">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

VoteButton.propTypes = {
  isVoted: PropTypes.bool.isRequired,
  handleVoting: PropTypes.func.isRequired,
};
