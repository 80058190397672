import React from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles/index.js";

const ProfileAvatar = ({ useAs }) => {
  const { user } = useSelector((state) => state.auth);
  const level = user.level;
  let progress = level - Math.floor(level / 100) * 100;
  let avatarName = user?.username?.substring(0, 2);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const circularProgressSize = isSmallScreen ? "45px" : "50px";

  return (
    <Box className="profile-avatar-parent" sx={{ position: "relative" }}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className="profile-avatar-badge"
        badgeContent={String(Math.floor(level / 100))}
        max={1000}
      >
        <CircularProgress
          size={`${circularProgressSize}`}
          thickness={2.5}
          variant="determinate"
          value={progress}
          sx={{
            position: "absolute",
            zIndex: "10",
            transform: "scaleX(-1) rotate(100deg) !important",
          }}
        />
        {user?.avatar ? (
          <Avatar
            className={useAs === "avatar-btn" ? "avatar-img-btn" : "avatar-img"}
            alt="Remy Sharp"
            src={user.avatar}
            sx={{ bgcolor: "var(--gray)" }}
          />
        ) : (
          <Avatar
            className={useAs === "avatar-btn" ? "avatar-img-btn" : "avatar-img"}
            sx={{ bgcolor: "var(--gray)", textTransform: "uppercase" }}
          >
            {avatarName}
          </Avatar>
        )}
      </Badge>
    </Box>
  );
};

export default ProfileAvatar;

ProfileAvatar.propTypes = {
  useAs: PropTypes.string,
};
