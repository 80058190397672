import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mediaPath } from "../../configs/main.config.js";
import { ReactComponent as UserIcon } from "../../assets/icons/lobby/userIcon.svg";
import { ReactComponent as CopyLinkIcon } from "../../assets/icons/lobby/copyLinkIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/lobby/cancelIcon.svg";
import CopyTooltip from "../room/CoppyTooltip.js";
import { useTranslation } from "react-i18next";

let timeOutId;
export default function WaitingPopup({
  openWaitingPopup,
  setOpenWaitingPopup,
  selectedGameInfo,
  removeToWaitList,
  roomLists,
}) {
  const { createRoomList } = useSelector((state) => state.gameLobby);
  const { t } = useTranslation();
  const [toolTipOpen, setToolTipOpen] = useState(-1);
  const handleCopy = (text, toolTipIndex) => {
    setToolTipOpen(toolTipIndex);
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setToolTipOpen(-1);
    }, 1500);
    navigator.clipboard.writeText(text);
  };

  const imgSrc =
    mediaPath +
    createRoomList.find((room) => {
      return selectedGameInfo.scenario === room.scenario;
    })?.wideImagePath;

  const minPlayers = roomLists.public.join[0].playersCount;
  const maxPlayers = roomLists.public.join[0].maxPlayer;

  const userFilledIcons = Array(minPlayers)
    .fill(null)
    .map((_, index) => <UserIcon key={index} className="user-colored-icons" />);

  const userIcons = Array(maxPlayers - minPlayers)
    .fill(null)
    .map((_, index) => <UserIcon key={index} />);

  const handleClose = () => {
    setOpenWaitingPopup(false);
    removeToWaitList(selectedGameInfo);
    window.history.replaceState("", "", window.location.pathname + "?tab=join");
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openWaitingPopup}
        className="waiting-popup-parent-wrapper"
      >
        <Box
          className="background-image"
          style={{
            backgroundImage: `url(${imgSrc})`,
          }}
        ></Box>

        <Box className="popup-wrap">
          <Box className="popup-head">
            <DialogTitle className="popup-title" id="customized-dialog-title">
              {t("APP.LOBBY.WAITING_POPUP.WAITING")}
            </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon className="cancel-icon" />
            </IconButton>
          </Box>
          <DialogContent className="popup-middle">
            <Box>
              <Box className="user-icons-wrapper">
                {userFilledIcons}
                {userIcons}
              </Box>
              <Box className="user-numbers">
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "800",
                    color: "var(--primary)",
                  }}
                >
                  {`${minPlayers}/${maxPlayers}`}
                </Typography>
                <CircularProgress size={16} />
              </Box>
            </Box>
          </DialogContent>

          <Box className="popup-bottom">
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "700",
                color: "var(--white)",
              }}
            >
              {t("APP.LOBBY.WAITING_POPUP.COPY_INVITE_LINK")}
            </Typography>
            <Box>
              <CopyTooltip
                open={toolTipOpen === 1}
                CopyIcon={
                  <CopyLinkIcon
                    onClick={() => {
                      handleCopy(window.location.href, 1);
                    }}
                  />
                }
              />
            </Box>
          </Box>

          <DialogActions className="bottom-buttons">
            <Button
              className="action-btn"
              color="darkRed"
              variant="contained"
              size="large"
              onClick={handleClose}
              sx={{ maxWidth: "140px", width: "18%", minWidth: "90px" }}
            >
              {t("APP.LOBBY.WAITING_POPUP.EXIT_BTN")}
            </Button>
            <Link
              to="https://gorgine.com/"
              target="_blank"
              className="action-link"
            >
              <Button
                className="action-btn"
                color="primary"
                variant="contained"
                size="large"
                sx={{ width: "100%" }}
              >
                {t("APP.LOBBY.WAITING_POPUP.LEAVE_BTN")}
              </Button>
            </Link>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

WaitingPopup.propTypes = {
  openWaitingPopup: PropTypes.any.isRequired,
  setOpenWaitingPopup: PropTypes.func.isRequired,
  selectedGameInfo: PropTypes.any.isRequired,
  removeToWaitList: PropTypes.any.isRequired,
  roomLists: PropTypes.any.isRequired,
};
