import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { privateReJoinRoomCoins } from "../../../../constants/gameLobbyConstant.js";
import propTypes from "prop-types";
const RejoinButton = ({ handleActions, infoBtn2Ref }) => {
  const { t } = useTranslation();
  return (
    <Button
      ref={infoBtn2Ref}
      name="reJoin-btn"
      onClick={() =>
        handleActions({ actionType: "REJOIN", coins: privateReJoinRoomCoins })
      }
      type="submit"
      color="primary"
      variant="contained"
      size="large"
      className="info-btn2"
    >
      {t("APP.LOBBY.INFO_TAB.REJOIN_BTN")}
    </Button>
  );
};
RejoinButton.propTypes = {
  handleActions: propTypes.func.isRequired,
  infoBtn2Ref: propTypes.object.isRequired,
};
export default RejoinButton;
