import { Box, Typography, Button, Tooltip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import questionMarkIcon from "../../assets/icons/subscription/questionMark.svg";
import cancelLine from "../../assets/icons/subscription/cancelLine.svg";
import freeMoonImg from "../../assets/image/subscription/free-coin.webp";
import basicMoonImg from "../../assets/image/subscription/1000-coin.webp";
import standardMoonImg from "../../assets/image/subscription/2000-coin.webp";
import proMoonImg from "../../assets/image/subscription/4000-coin.webp";
import extremeMoonImg from "../../assets/image/subscription/10000-coin.webp";
import megaMoonImg from "../../assets/image/subscription/20000-coin.webp";
import referralMoonImg from "../../assets/image/subscription/referral-coin.webp";

export const SubscriptionCard = ({
  plan,
  handleSubscription,
  currency,
  handleCopyReferral,
}) => {
  const { t } = useTranslation();

  const cardImages = {
    free: freeMoonImg,
    referral: referralMoonImg,
    basic: basicMoonImg,
    standard: standardMoonImg,
    pro: proMoonImg,
    extreme: extremeMoonImg,
    mega: megaMoonImg,
  };

  const handleWatchAds = () => {};

  return (
    <>
      <Box className="subscription-card-parent-wrapper">
        <Box className="card-head">
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "30px",
              color:
                plan === "free" || "referral"
                  ? "var(--vividOrange)"
                  : "var(--blueGray)",
            }}
          >
            {plan === "free" && t("APP.SUBSCRIPTION.CARD.FREE_MOON")}
            {plan === "referral" && (
              <Box className="referral-box">
                {t("APP.SUBSCRIPTION.CARD.REFERRAL_MOON")}{" "}
                <Tooltip
                  title={t("APP.SUBSCRIPTION.CARD.INVITE_TOOLTIP_DESC")}
                  arrow
                  placement="bottom"
                >
                  <img src={questionMarkIcon} alt="question-icon" />
                </Tooltip>
              </Box>
            )}
            {plan === "basic" && t("APP.SUBSCRIPTION.CARD.BASIC_MOON")}
            {plan === "standard" && t("APP.SUBSCRIPTION.CARD.STANDARD_MOON")}
            {plan === "pro" && t("APP.SUBSCRIPTION.CARD.PRO_MOON")}
            {plan === "extreme" && t("APP.SUBSCRIPTION.CARD.EXTREME_MOON")}
            {plan === "mega" && t("APP.SUBSCRIPTION.CARD.MEGA_MOON")}
          </Typography>
          <Box className="buy-moons">
            <Typography
              sx={{
                fontSize: "36px",
                fontWeight: "400",
                lineHeight: "54px",
                color: "var(--blueGray)",
                position: "relative",
              }}
            >
              {plan === "free" ? `1 ${t("APP.SUBSCRIPTION.CARD.ADS")}` : ""}
              {plan === "referral"
                ? `1 ${t("APP.SUBSCRIPTION.CARD.USER")}`
                : ""}
              {plan === "basic" ? (
                <>
                  {" "}
                  <span className="discounted-price">
                    {currency === "IRT"
                      ? `${t("APP.SUBSCRIPTION.CARD.CURRENCY")}30`
                      : "$0.99"}
                  </span>
                  <span className="cancel-wrapper">
                    <img
                      className="cancel-line"
                      src={cancelLine}
                      alt="cancel-line"
                    />
                    {currency === "IRT"
                      ? `${t("APP.SUBSCRIPTION.CARD.CURRENCY")}50`
                      : "$1.99"}
                  </span>
                </>
              ) : (
                ""
              )}
              {plan === "standard"
                ? currency === "IRT"
                  ? `${t("APP.SUBSCRIPTION.CARD.CURRENCY")}90`
                  : "$3.49"
                : ""}
              {plan === "pro"
                ? currency === "IRT"
                  ? `${t("APP.SUBSCRIPTION.CARD.CURRENCY")}150`
                  : "$5.99"
                : ""}
              {plan === "extreme"
                ? currency === "IRT"
                  ? `${t("APP.SUBSCRIPTION.CARD.CURRENCY")}375`
                  : "$13.49"
                : ""}
              {plan === "mega"
                ? currency === "IRT"
                  ? `${t("APP.SUBSCRIPTION.CARD.CURRENCY")}750`
                  : "$28.99"
                : ""}
            </Typography>

            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "30px",
                color: "var(--mediumGray)",
                position: "relative",
              }}
            >
              / {plan === "free" && "25 "}
              {plan === "referral" && "300 "}
              {plan === "basic" && 1000}
              {plan === "standard" && "2000 "}
              {plan === "pro" && "4000 "}
              {plan === "extreme" && "10000 "}
              {plan === "mega" && "20000 "} MOONS
            </Typography>
          </Box>
          <Button
            fullWidth
            color="blueGray"
            className="subscribe-btn"
            variant="contained"
            onClick={() => {
              localStorage.setItem("plan", plan.toUpperCase());
              plan === "free"
                ? handleWatchAds()
                : plan === "referral"
                  ? handleCopyReferral()
                  : handleSubscription(plan.toUpperCase());
            }}
          >
            {plan === "free"
              ? t("APP.SUBSCRIPTION.CARD.WATCH_BUTTON")
              : plan === "referral"
                ? t("APP.SUBSCRIPTION.CARD.COPY_LINK_BUTTON")
                : t("APP.SUBSCRIPTION.CARD.BUY_BUTTON")}
          </Button>
        </Box>

        <Box className="card-bottom">
          <img
            src={cardImages[plan]}
            className="card-images"
            alt={`${plan}-img`}
          />
        </Box>
      </Box>
    </>
  );
};

SubscriptionCard.propTypes = {
  plan: PropTypes.string.isRequired,
  handleSubscription: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  handleCopyReferral: PropTypes.func,
};
