import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "../src/styling/index/main.css";
import "../src/styling/index/styles.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalLoader, Navigator } from "./components/global/index.js";
import { useSelector } from "react-redux";
import { useInitialHandles } from "./hooks/app/useInitialHandles.hook.js";
import "./configs/i18.config.js";
import AlertDialogue from "./components/global/dialogues/AlertDialogue.js";
import AllRoutes from "./routes/routes.js";
import { TeachStepperPopper } from "./components/global/popper/TeachStepperPopper.js";

function App() {
  const { getLanguage } = useInitialHandles();
  const { reRender, language } = useSelector((state) => state.global);
  const [base, setBase] = useState(getLanguage({ deafault: true }));
  useEffect(() => {
    if (reRender) {
      let lang;
      if (language) {
        lang = language;
      } else {
        lang = getLanguage({ deafault: false });
      }
      setBase(lang);
    }
  }, [reRender]);

  return (
    <div className="App">
      <GlobalLoader />
      <AlertDialogue />
      <TeachStepperPopper />
      <ToastContainer />
      {reRender ? (
        <></>
      ) : (
        <BrowserRouter basename={`/${base}`}>
          <Navigator />
          <Suspense fallback={<div>Loading translations...</div>}>
            <AllRoutes />
          </Suspense>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
