import React, { useRef } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { ReactComponent as CancelCircularIcon } from "../../assets/icons/cancelCircularIcon.svg";
import { ReactComponent as EmojiIcon } from "../../assets/icons/emojiIcon.svg";
import { ReactComponent as QuestionSquareIcon } from "../../assets/icons/questionSquareIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/sendIcon.svg";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import { sendMessage } from "../../handlers/game-room/index.js";
import { useTranslation } from "react-i18next";

const Chat = ({ setIsOpenChat, isOpenChat, stream }) => {
  const { messageRef, messages, user, viewer } = stream;
  let scrollableChat = useRef(null);
  const [chatText, setChatText] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpenChat) {
      setShowEmojiPicker(false);
    }
  }, [isOpenChat]);

  const handleEmojiDisplay = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiPicker = (e) => {
    setChatText((pre) => pre + e.emoji);
    messageRef.current.focus();
  };

  const handleCloseChat = () => {
    setIsOpenChat(false);
  };
  const scrollToStart = () => {
    setTimeout(() => {
      scrollableChat.current.scrollTop = scrollableChat.current.scrollHeight;
    }, 0);
  };
  const handleSendIcon = () => {
    sendMessage();
    setChatText("");
    scrollToStart();
    if (showEmojiPicker) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (isOpenChat) {
      scrollToStart();
    }
  }, [isOpenChat]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (chatText) {
        sendMessage();
        setChatText("");
        scrollToStart();
      }
    }
  };
  const role = viewer ? "viewers" : "players";
  return (
    <Box className={`chat-parent-wrapper ${isOpenChat ? "animate-chat" : ""}`}>
      <Box className="chat-header">
        <CancelCircularIcon onClick={handleCloseChat} className="cancel-icon" />
        <Typography sx={{ fontSize: "16px" }} color="var(--white)">
          {t("APP.GAME_ROOM.CHATS.CHAT")}
        </Typography>
        <Box></Box>
      </Box>

      <Box ref={scrollableChat} sx={{ flex: 1, overflow: "auto" }}>
        <Box className="messages-wrapper">
          {showEmojiPicker && (
            <Box className="emoji-wrapper">
              <EmojiPicker
                height="100%"
                width="100%"
                theme="dark"
                onEmojiClick={handleEmojiPicker}
              />
            </Box>
          )}

          {messages[role].map((message, i) => {
            return message.senderName !== user.username ? (
              <Box key={i}>
                <Typography
                  sx={{ color: "var(--white)", fontSize: "13px", ml: "7px" }}
                >
                  {message.senderName}
                </Typography>
                <Box>
                  <TextField
                    multiline
                    defaultValue={message.text}
                    type="text"
                    className="left-messages-box"
                    InputProps={{
                      readOnly: true,
                      sx: {
                        padding: 0,
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        border: "none",
                      },
                      "& textarea": {
                        padding: "6px",
                        fontSize: "14px",
                        color: "var(--white)",
                      },
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Box key={i}>
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "13px",
                    mr: "7px",
                    textAlign: "right",
                  }}
                >
                  {t("APP.GAME_ROOM.CHATS.ME")}
                </Typography>
                <TextField
                  multiline
                  type="text"
                  defaultValue={message.text}
                  className="right-messages-box"
                  InputProps={{
                    readOnly: true,
                    sx: {
                      padding: 0,
                    },
                  }}
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& textarea": {
                      padding: "6px",
                      fontSize: "14px",
                      color: "var(--white)",
                    },
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: "var(--white)",
            fontSize: "13px",
            textAlign: "center",
            padding: "5px",
            marginTop: "14px",
          }}
        >
          {t("APP.GAME_ROOM.CHATS.MESSAGE_VISIBILITY")}
        </Box>

        <TextField
          className="chat-input-field"
          inputRef={messageRef}
          value={chatText}
          id="chat"
          placeholder={t("APP.GAME_ROOM.CHATS.MESSAGE_TYPE_PLACEHOLDER")}
          multiline
          fullWidth
          maxRows={5}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setChatText(e.target.value);
          }}
          sx={{
            "& fieldset": {
              border: "none",
            },
            "& .MuiInputBase-multiline": {
              paddingLeft: "10px",
              paddingRight: "10px",
            },
          }}
          inputProps={{
            maxLength: 200,
            sx: {
              color: "var(--white)",
              "&::placeholder": {
                opacity: "1",
              },
              fontSize: "15px",
              fontWeight: "300",
            },
          }}
        />

        <Box className="chat-footer">
          <Box>
            <QuestionSquareIcon />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {showEmojiPicker ? (
              <Box
                sx={{
                  "& svg": {
                    height: "16.67px",
                    width: "16.67px",
                  },
                }}
              >
                <CancelCircularIcon
                  onClick={handleEmojiDisplay}
                  className="cancel-icon"
                />
              </Box>
            ) : (
              <EmojiIcon
                onClick={handleEmojiDisplay}
                className="emoji-icon-btn"
              />
            )}
            <Button
              sx={{ cursor: "auto" }}
              disabled={chatText ? false : true}
              variant="text"
            >
              <SendIcon onClick={handleSendIcon} className="msg-send-icon" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
Chat.propTypes = {
  setIsOpenChat: PropTypes.func.isRequired,
  isOpenChat: PropTypes.bool.isRequired,
  stream: PropTypes.any.isRequired,
};
export default Chat;
