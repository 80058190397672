import { createSlice } from "@reduxjs/toolkit";
import { loginApi, authenticateOtpApi, refreshTokenApi } from "./authThunks.js";
import { decodeToken } from "../../handlers/global/handleToken.js";
import { getUserCountry } from "../../handlers/global/getUserCountry.js";

const initialState = {
  user: { ...decodeToken(), country: getUserCountry(), coins: "" },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.user = { country: getUserCountry() }
    },
    addUserInfo: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginApi.fulfilled, (state) => {
      state.user = { ...state.user, ...decodeToken() };
    });
    builder.addCase(refreshTokenApi.fulfilled, (state) => {
      state.user = { ...state.user, ...decodeToken() };
    });
    builder.addCase(authenticateOtpApi.fulfilled, (state) => {
      state.user = { ...state.user, ...decodeToken() };
    });
  },
});
export const { logoutUser, addUserInfo } = authSlice.actions;
export default authSlice.reducer;
