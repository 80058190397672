import { registerApi } from "../../features/auth/authThunks.js";
import { useDispatch } from "react-redux";
import { getPhoneNumber } from "../../handlers/auth/getPhoneNumberFromInput.js";
import { setNavigateTo } from "../../features/global/globalSlice.js";
import { getUserCountry } from "../../handlers/global/getUserCountry.js";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

let timeOutId;
let errorTimeOutId;
export const useHandleRegister = ({
  username,
  password,
  isTermsChecked,
  isTermsOtpChecked,
  setValidation,
  isUserAvailble,
  setErrorCode,
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const handleRegister = () => {
    const referralCode = searchParams.get("referralCode");
    let emptyFields = [];
    let phoneNumber = getPhoneNumber(emptyFields);
    if (!password) {
      emptyFields.push("password");
    } else if (password.length < 8) {
      emptyFields.push("shortPassword");
    }
    if (!username) {
      emptyFields.push("username");
    }
    if (username && !isUserAvailble) {
      emptyFields.push("usernameNotAvailble");
    }
    if (!isTermsChecked) {
      emptyFields.push("terms");
    }
    if (!isTermsOtpChecked && i18n.language === 'en') {
      emptyFields.push("termsOtp");
    }

    setValidation(emptyFields);
    // hide errors after 3sec
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setValidation([]);
    }, 3000);

    const country = getUserCountry().name.toUpperCase();
    const city = getUserCountry().city.toUpperCase();

    const data = {
      phoneNumber,
      password,
      username,
      country,
      city,
      ...(referralCode ? { referralCode } : {}),
    };
    if (emptyFields.length < 1) {
      (async () => {
        try {
          await dispatch(registerApi({ payload: data })).unwrap();
          dispatch(
            setNavigateTo(`/otp?type=registration&phone-number=${phoneNumber}`),
          );
        } catch (error) {
          clearTimeout(errorTimeOutId);
          setErrorCode(error.code);
          if (error.code !== 4009) {
            errorTimeOutId = setTimeout(() => {
              setErrorCode(null);
            }, 6000);
          }
        }
      })();
    }
  };
  return { handleRegister };
};
