import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const PasswordInput = ({
  setPassword,
  validation,
  inputLabel,
  screen,
  type,
  errorCode,
  maxLength,
  minLength,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handlePassword = (e) => {
    let password = e.target.value.replace(/\s/g, ""); // Remove all white spaces
    // remove white spaces from input also
    e.target.value = password;
    // Check if maxLength is defined and if the password length exceeds it
    if (maxLength && password.length > maxLength) {
      // Truncate the password to the maxLength
      e.target.value = password.slice(0, maxLength);
      return;
    }
    setPassword(password);
  };
  const errorMessage = "APP.AUTH.PASSWORD_INPUT.SHORT_PASSWORD_ERROR";
  return (
    <>
      <Box
        className="password-input-parent-wrap"
        sx={{ height: "103px", width: "100%" }}
      >
        <Typography
          color={"var(--white)"}
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "18.75px",
            mb: "7px",
          }}
        >
          {screen === "changePassword"
            ? inputLabel === "newPassword"
              ? `${t("APP.AUTH.PASSWORD_INPUT.NEW_PASSWORD_LABEL")}`
              : `${t("APP.AUTH.PASSWORD_INPUT.RE_ENTER_PASSWORD_LABEL")}`
            : `${t("APP.AUTH.PASSWORD_INPUT.PASSWORD_LABEL")}`}
        </Typography>

        <FormControl
          error={
            validation.includes(
              inputLabel === "reEnterPassword" ? "reEnterPassword" : "password",
            ) ||
            validation.includes(
              inputLabel === "reEnterPassword"
                ? "reEntershortPassword"
                : "shortPassword",
            ) ||
            (errorCode ? true : false)
          }
          className={`${
            validation.includes(
              inputLabel === "reEnterPassword" ? "reEnterPassword" : "password",
            ) ||
            validation.includes(
              inputLabel === "reEnterPassword"
                ? "reEntershortPassword"
                : "shortPassword",
            ) ||
            (errorCode ? true : false)
              ? ""
              : "password-input-field"
          }`}
          variant="outlined"
          sx={{
            ...(screen === "gameLobby"
              ? { width: "330px" }
              : { width: "100%" }),
            borderRadius: "5px",
            height: "50px",
            "& label": {
              color: "var(--mutedGray) ",
              fontSize: "12px",
              fontWeight: "500",
            },
          }}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            {screen === "changePassword"
              ? inputLabel === "newPassword"
                ? `${t("APP.AUTH.PASSWORD_INPUT.NEW_PASSWORD_INPUT_LABEL")}`
                : `${t(
                    "APP.AUTH.PASSWORD_INPUT.RE_ENTER_PASSWORD_INPUT_LABEL",
                  )}`
              : `${t("APP.AUTH.PASSWORD_INPUT.PASSWORD_INPUT_LABEL")}`}
          </InputLabel>
          <OutlinedInput
            name="password"
            autoComplete="off"
            sx={{
              height: "50px",
              color: "var(--white)",
              fontSize: "12px",
              fontWeight: "500",
              "& fieldset": {
                color: "var(--primary)",
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            id="outlined-adornment-password"
            type={type ? type : showPassword ? "text" : "password"}
            onChange={handlePassword}
            label={
              screen === "changePassword"
                ? inputLabel === "newPassword"
                  ? `${t("APP.AUTH.PASSWORD_INPUT.NEW_PASSWORD_INPUT_LABEL")}`
                  : `${t(
                      "APP.AUTH.PASSWORD_INPUT.RE_ENTER_PASSWORD_INPUT_LABEL",
                    )}`
                : `${t("APP.AUTH.PASSWORD_INPUT.PASSWORD_INPUT_LABEL")}`
            }
            {...(screen !== "gameLobby" && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="white"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            })}
          />
          <FormHelperText
            sx={{
              visibility:
                validation.includes(
                  inputLabel === "reEnterPassword"
                    ? "reEnterPassword"
                    : "password",
                ) ||
                validation.includes(
                  inputLabel === "reEnterPassword"
                    ? "reEntershortPassword"
                    : "shortPassword",
                ) ||
                (errorCode ? true : false)
                  ? ""
                  : "hidden",
              mt: "5px",
              ml: "14px",
              mr: "14px",
              fontSize: "10px",
              fontWeight: "400",
            }}
            error
            id="accountId-error"
          >
            {errorCode
              ? t(`APP.ERROR_CODES.${errorCode}`)
              : validation.includes(
                    inputLabel === "reEnterPassword"
                      ? "reEntershortPassword"
                      : "shortPassword",
                  )
                ? minLength
                  ? t(errorMessage).replace(8, minLength)
                  : t(errorMessage)
                : t("APP.AUTH.FIELD_REQUIRED")}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};
PasswordInput.propTypes = {
  setPassword: PropTypes.func.isRequired,
  validation: PropTypes.array.isRequired,
  inputLabel: PropTypes.string,
  screen: PropTypes.string,
  type: PropTypes.string,
  errorCode: PropTypes.any,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

export default PasswordInput;
