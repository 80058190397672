import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
export const cameraMicPermissionAlert = (data) => {
  const { dispatch, t } = params;
  dispatch(
    alertDialog({
      icon: "error",
      title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.CAMERA_MIC_PERMISSION"),
    }),
  );
};

export async function startStream(night, state, isAdmin) {
  const {
    localStreamRef,
    type,
    setVideoStatus,
    setMicStatus,
    setCameraMicPermission,
    openMic,
    openVideo,
    currentMicState,
    currentVideoState,
    setLocalStream,
  } = params;
  try {
    const enableVideo = type !== "audio" && night && openVideo;
    localStreamRef.current = await navigator?.mediaDevices?.getUserMedia({
      audio: {
        noiseSuppression: true,
        echoCancellation: true,
      },
      video: enableVideo,
    });
    setCameraMicPermission(true);
    // when user refresh or rejoin if night mode we have to close its camera and mic
    if (state !== "NIGHT" || isAdmin) {
      if (openVideo && type !== "audio") {
        setVideoStatus(true);
        currentVideoState.current = true;
      }
      setLocalStream(localStreamRef.current);

      if (openMic) {
        setMicStatus(true);
        currentMicState.current = true;
      }
    }
  } catch (err) {
    cameraMicPermissionAlert("handlestartstream");
    setCameraMicPermission(false);
    console.log(err);
  }
}
