import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as CancelIcon } from "../../../assets/icons/room/cancelIcon.svg";
import micImg from "../../../assets/icons/room/footer/micIcon.svg";
import videoIcon from "../../../assets/icons/room/footer/videoIcon.svg";
import closeVideoIcon from "../../../assets/icons/room/footer/closeVideoIcon.svg";
import { useTranslation } from "react-i18next";
import cameraMicPermissionChromeImg from "../../../assets/image/room/join-popup/cameraMicPermissionChromeImg.webp";
import cameraMicBlockedChromeImg from "../../../assets/image/room/join-popup/cameraMicBlockedChromeImg.webp";
import micPermissionChromeImg from "../../../assets/image/room/join-popup/micPermissionChromeImg.webp";
import micBlockedChromeImg from "../../../assets/image/room/join-popup/micBlockedChromeImg.webp";
import cameraMicPermissionFirefoxImg from "../../../assets/image/room/join-popup/cameraMicPermissionFirefoxImg.webp";
import cameraMicBlockedFirefoxImg from "../../../assets/image/room/join-popup/cameraMicBlockedFirefoxImg.webp";
import micPermissionFirefoxImg from "../../../assets/image/room/join-popup/micPermissionFirefoxImg.webp";
import micBlockedFirefoxImg from "../../../assets/image/room/join-popup/micBlockedFirefoxImg.webp";
import cameraMicPermissionEdgeImg from "../../../assets/image/room/join-popup/cameraMicPermissionEdgeImg.webp";
import cameraMicBlockedEdgeImg from "../../../assets/image/room/join-popup/cameraMicBlockedEdgeImg.webp";
import micPermissionEdgeImg from "../../../assets/image/room/join-popup/micPermissionEdgeImg.webp";
import micBlockedEdgeImg from "../../../assets/image/room/join-popup/micBlockedEdgeImg.webp";
import micBlockedSafariImg from "../../../assets/image/room/join-popup/micBlockedSafariImg.webp";
import cameraMicBlockedSafariImg from "../../../assets/image/room/join-popup/cameraMicBlockedSafariImg.webp";
import usePemissionMediaPopup from "../../../hooks/game-room/popup/mediaPemissionPopup.hook.js";
import { useDispatch } from "react-redux";
import { setNavigateTo } from "../../../features/global/globalSlice.js";
import { browserName } from "react-device-detect";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function MediaPermisionPopup({
  showMediaPermisionPopup,
  setShowMediaPermisionPopup,
  micStatus,
  videoStatus,
  setMicStatus,
  setVideoStatus,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { noTermsRequired } = location.state || {};
  const {
    mediaPermissionDenied,
    mediaPermission,
    type,
    localStreamRef,
    viewer,
    trackLocalStream,
    mode,
  } = usePemissionMediaPopup({
    setVideoStatus,
    setMicStatus,
    setShowMediaPermisionPopup,
  });
  const handleMic = () => {
    setMicStatus(!micStatus);
  };
  const handleVideo = async () => {
    if (videoStatus) {
      const tracks = trackLocalStream.current.getTracks();
      tracks.forEach((track) => track.stop());
    } else {
      await (async () => {
        const media = await navigator?.mediaDevices?.getUserMedia({
          video: true,
        });
        trackLocalStream.current = media;
        localStreamRef.current.srcObject = media;
      })();
    }

    setVideoStatus(!videoStatus);
  };
  const handleClose = () => {
    let url = `/game-lobby?tab=create&value=`;
    if (viewer) {
      url += `2`;
    } else {
      url += `1`;
    }

    dispatch(setNavigateTo(url));
  };
  const handleJoin = () => {
    setShowMediaPermisionPopup(false);
  };

  const termsConditionsText = t("APP.LOBBY.INFO_TAB.READ_TERMS").split("❔");

  const permissionImages = {
    Chrome: {
      allowMic: micPermissionChromeImg,
      blockedMic: micBlockedChromeImg,
      allowCameraMic: cameraMicPermissionChromeImg,
      blockedCameraMic: cameraMicBlockedChromeImg,
    },
    Safari: {
      allowMic: micBlockedSafariImg,
      blockedMic: micBlockedSafariImg,
      allowCameraMic: cameraMicBlockedSafariImg,
      blockedCameraMic: cameraMicBlockedSafariImg,
    },
    Edge: {
      allowMic: micPermissionEdgeImg,
      blockedMic: micBlockedEdgeImg,
      allowCameraMic: cameraMicPermissionEdgeImg,
      blockedCameraMic: cameraMicBlockedEdgeImg,
    },
    Firefox: {
      allowMic: micPermissionFirefoxImg,
      blockedMic: micBlockedFirefoxImg,
      allowCameraMic: cameraMicPermissionFirefoxImg,
      blockedCameraMic: cameraMicBlockedFirefoxImg,
    },
  };
  const imgSrc =
    type === "audio"
      ? mediaPermissionDenied
        ? permissionImages[browserName]?.blockedMic
        : !mediaPermission && permissionImages[browserName]?.allowMic
      : mediaPermissionDenied
        ? permissionImages[browserName]?.blockedCameraMic
        : !mediaPermission && permissionImages[browserName]?.allowCameraMic;

  return (
    <>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showMediaPermisionPopup}
        className="media-permission-popup-wrapper"
      >
        <Box
          className="background-video"
          sx={{
            visibility:
              videoStatus && localStreamRef.current ? "visible" : "hidden",
          }}
        >
          <video muted autoPlay playsInline ref={localStreamRef}></video>
        </Box>

        <Box className="popupWrap">
          <Box className="popupHead">
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon className="cancelIcon" />
            </IconButton>
          </Box>
          <DialogContent className="popupMiddle">
            <Box sx={{ color: "var(--mutedGray)" }} className="middleContent">
              {imgSrc ? (
                <img
                  src={imgSrc}
                  style={{ width: "92.4%" }}
                  alt="camera-mic-permission"
                />
              ) : (
                !mediaPermission && browserName
              )}

              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "700",
                  color: "var(--mutedGray)",
                  textAlign: "center",
                }}
              >
                {mediaPermission
                  ? type === "audio"
                    ? !micStatus
                      ? t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.MIC_OFF")
                      : t(
                          "APP.GAME_ROOM.MEDIA_PERMISION_POPUP.MIC_PERMISION_ALLOWED",
                        )
                    : !videoStatus && !micStatus
                      ? t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.CAMERA_MIC_OFF")
                      : (!videoStatus &&
                          t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.VIDEO_OFF")) ||
                        (!micStatus &&
                          t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.MIC_OFF"))
                  : type === "audio"
                    ? t(
                        "APP.GAME_ROOM.MEDIA_PERMISION_POPUP.MIC_PERMISION_REQUIRED",
                      )
                    : t(
                        "APP.GAME_ROOM.MEDIA_PERMISION_POPUP.CAMERA_MIC_PERMISION_REQUIRED",
                      )}
              </Typography>
            </Box>
          </DialogContent>

          <Box
            sx={{
              padding: mode !== "AUTO" ? "25px" : 0,
              paddingBottom:
                mode !== "AUTO" ? (!noTermsRequired ? "10px" : "25px") : 0,
              backgroundColor: "var(--semiTransparentBlack)",
              backdropFilter: "blur(2px)",
              textAlign: "center",
            }}
          >
            {mode !== "AUTO" && (
              <DialogActions className="popupbottom">
                <Box
                  sx={{
                    cursor: mediaPermission ? "" : "not-allowed",
                    flex: "1",
                  }}
                >
                  <Box
                    onClick={() => handleMic()}
                    className="iconWrapper"
                    sx={{
                      pointerEvents: mediaPermission ? "" : "none",
                    }}
                  >
                    <Box>
                      <Box
                        className={`iconBox ${micStatus ? "" : `stop`}
            }`}
                      >
                        <img src={micImg} alt="mic" />
                      </Box>
                    </Box>
                    <Typography
                      color={"var(--white)"}
                      fontWeight={400}
                      sx={{
                        fontSize: "8px",
                      }}
                    >
                      {micStatus
                        ? t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.MUTE")
                        : t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.UNMUTE")}
                    </Typography>
                  </Box>
                </Box>
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        display: mediaPermission ? "none" : "",
                      },
                    },
                  }}
                  title={
                    type === "audio"
                      ? t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.AUDIO_TOOLTIP")
                      : t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.VIDEO_TOOLTIP")
                  }
                  arrow
                  placement="top"
                >
                  <Box
                    sx={{
                      cursor: mediaPermission ? "pointer" : "not-allowed",
                    }}
                  >
                    <Button
                      sx={{ pointerEvents: mediaPermission ? "" : "none" }}
                      className="actionBtn"
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={handleJoin}
                    >
                      {t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.JOIN_BTN")}
                    </Button>
                  </Box>
                </Tooltip>

                <Box
                  sx={{
                    cursor: mediaPermission ? "" : "not-allowed",
                    flex: "1",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {!(type === "audio") && (
                    <Box
                      sx={{
                        pointerEvents: mediaPermission ? "" : "none",
                      }}
                      onClick={() => handleVideo()}
                      className="iconWrapper"
                    >
                      <Box>
                        <Box className="iconBox">
                          <img
                            src={videoStatus ? videoIcon : closeVideoIcon}
                            alt="video"
                          />
                        </Box>
                      </Box>
                      <Typography
                        color={"var(--white)"}
                        fontWeight={400}
                        sx={{
                          fontSize: "8px",
                        }}
                      >
                        {videoStatus
                          ? t("APP.GAME_ROOM.MEDIA_PERMISION_POPUP.STOP_VIDEO")
                          : t(
                              "APP.GAME_ROOM.MEDIA_PERMISION_POPUP.START_VIDEO",
                            )}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </DialogActions>
            )}

            {!noTermsRequired && (
              <Typography
                color={"var(--white)"}
                fontWeight={400}
                sx={{
                  fontSize: "10px",
                  paddingTop: "5px",
                }}
              >
                {termsConditionsText[0]}
                {
                  <Link
                    to="https://gorgine.com/terms-and-conditions-en/"
                    target="_blank"
                    style={{ color: "var(--primary)" }}
                  >
                    {t("APP.LOBBY.INFO_TAB.TERMS_LINK")}
                  </Link>
                }
                {termsConditionsText[1]}
              </Typography>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

MediaPermisionPopup.propTypes = {
  showMediaPermisionPopup: PropTypes.any.isRequired,
  setShowMediaPermisionPopup: PropTypes.func.isRequired,
  setMicStatus: PropTypes.func.isRequired,
  setVideoStatus: PropTypes.func.isRequired,
  videoStatus: PropTypes.bool.isRequired,
  micStatus: PropTypes.bool.isRequired,
};
