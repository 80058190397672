import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as PinIcon } from "../../../assets/icons/room/actions/pinIcon.svg";
import { ReactComponent as VideoIcon } from "../../../assets/icons/room/actions/videoIconMd.svg";
import { ReactComponent as VideoCloseIcon } from "../../../assets/icons/room/actions/videoMdCloseIcon.svg";
import { ReactComponent as MicIcon } from "../../../assets/icons/room/actions/micIconMd.svg";
import { ReactComponent as MicCloseIcon } from "../../../assets/icons/room/actions/micMdClose.svg";
import { ReactComponent as MuteMic } from "../../../assets/icons/room/muteIcon.svg";
import { ReactComponent as Mic } from "../../../assets/icons/room/actions/micIcon.svg";
import { handleAdminMute } from "../../../handlers/game-room/index.js";
import PropTypes from "prop-types";
import MoreActions from "./more-actions/MoreActions.js";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  handleMute,
  handleMuteVideo,
} from "../../../handlers/game-room/index.js";
const ActionsMenu = ({ actionBoxRef, iteration, stream }) => {
  const {
    isAdmin,
    currentSelectedPeer,
    videoMutedUsers,
    audioMutedUsers,
    viewer,
    adminAudioMutedUsers,
    isNightMode,
    pendingMediaReqSockIds,
  } = stream;
  const isAudioMuted = adminAudioMutedUsers.includes(currentSelectedPeer);
  const showPendReq = pendingMediaReqSockIds.mic.includes(currentSelectedPeer);

  const { t } = useTranslation();
  return (
    <Box
      ref={(element) => (actionBoxRef.current[iteration - 1] = element)}
      className="action-box-parent-wrapper"
      sx={{ color: "var(--white)" }}
    >
      <Box className="action-bar-wrap">
        <Box className="action-box">
          <IconButton>
            <PinIcon />
          </IconButton>

          {isAdmin ? (
            <>
              {!isNightMode && (
                <IconButton
                  onClick={() => {
                    handleAdminMute(currentSelectedPeer);
                  }}
                >
                  {showPendReq ? (
                    <>
                      <CircularProgress size={12} color="white" />
                    </>
                  ) : isAudioMuted ? (
                    <MuteMic style={{ marginRight: "6px" }} />
                  ) : (
                    <Mic style={{ marginRight: "6px" }} />
                  )}
                </IconButton>
              )}
              <MoreActions
                iteration={iteration}
                actionBoxRef={actionBoxRef}
                stream={stream}
              />
            </>
          ) : (
            !viewer && (
              <>
                <IconButton
                  onClick={() => handleMuteVideo(currentSelectedPeer)}
                >
                  {videoMutedUsers.includes(currentSelectedPeer) ? (
                    <VideoCloseIcon />
                  ) : (
                    <VideoIcon />
                  )}
                </IconButton>
                <IconButton onClick={() => handleMute(currentSelectedPeer)}>
                  {audioMutedUsers.includes(currentSelectedPeer) ? (
                    <MicCloseIcon />
                  ) : (
                    <MicIcon />
                  )}
                </IconButton>
              </>
            )
          )}
        </Box>
        {showPendReq && (
          <Typography sx={{ color: "var(--white)", fontSize: 12 }}>
            {t("APP.GAME_ROOM.GRID_SCREEN_CARD.ASKING_USER_PERMISSION")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
ActionsMenu.propTypes = {
  actionBoxRef: PropTypes.any.isRequired,
  stream: PropTypes.any.isRequired,
  iteration: PropTypes.number.isRequired,
};
export default ActionsMenu;
