import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { cameraMicPermissionAlert } from "./handleStartStream.js";
export const handleVideo = async (state, logs) => {
  const {
    currentVideoState,
    socket,
    videoProducerId,
    videoProducer,
    localStreamRef,
    produceTransport,
    setVideoStatus,
    camerMicPermission,
    setLocalStream,
  } = params;

  if (!camerMicPermission) {
    cameraMicPermissionAlert("handlevideo");
    return;
  }
  const stateVar =
    state === true || state === false ? state : currentVideoState.current;
  if (stateVar) {
    if (logs) {
      console.log(logs + "closeProducer for video emited...");
    }
    socket.emit("closeProducer", videoProducerId.current, async (response) => {
      await videoProducer.current?.close();
      const isVideoClosed = videoProducer.current?.closed;
      if (isVideoClosed) {
        if (logs) {
          console.log(
            logs +
              "in response of closeProducer for video , video producer is close on frontend e.g => videoProducer.current?.closed;",
          );
        }

        videoProducer.current = null;
        videoProducerId.current = null;
        localStreamRef.current?.srcObject
          ?.getVideoTracks()
          ?.forEach((track) => {
            track?.stop();
            localStreamRef.current?.srcObject?.removeTrack(track);
          });
        setLocalStream(null);
        currentVideoState.current = false;
        setVideoStatus(false);
      }
    });
  } else {
    localStreamRef.current = await navigator?.mediaDevices?.getUserMedia({
      audio: {
        noiseSuppression: true,
        echoCancellation: true,
      },
      video: true,
    });
    videoProducer.current = await produceTransport.current?.produce({
      track: localStreamRef.current.getVideoTracks()[0],
    });
    const isVideoRsumed = !videoProducer.current?.closed;
    if (isVideoRsumed) {
      setLocalStream(localStreamRef.current);
      currentVideoState.current = true;
      setVideoStatus(true);
    }
  }
};

export const handleMuteVideoForAll = (isMute) => {
  const { socket, videoMutedAllStatus, roomId, setVideoAllStatus } = params;

  if (!videoMutedAllStatus.current || isMute) {
    socket.emit("videomuteAll", roomId);
    videoMutedAllStatus.current = true;
    setVideoAllStatus(false);
  } else {
    socket.emit("videounmuteAll", roomId);
    videoMutedAllStatus.current = false;
    setVideoAllStatus(true);
  }
};
