import {
  Box,
  Button,
  Fade,
  Paper,
  Popper,
  Typography,
  Backdrop,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { hideStepperPopper } from "../../../features/global/globalSlice.js";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CancelIcon } from "../../../assets/icons/lobby/cancelIcon.svg";
import { useTranslation } from "react-i18next";
export const TeachStepperPopper = () => {
  const { teachStapperPoperParams } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const {
    setStepToLocal,
    open = true,
    refernceArray = [],
  } = teachStapperPoperParams;
  const [openPopper, setOpenPoppers] = useState(open);
  useEffect(() => {
    setOpenPoppers(open);
  }, [open]);
  const { t } = useTranslation();
  const [teachIndex, setTeachIndex] = useState(0);
  const {
    text,
    parent,
    placement = "top",
    key,
  } = refernceArray[teachIndex] || {};
  const isDone = teachIndex + 1 === refernceArray.length;
  const handleClose = (action) => {
    setTeachIndex(0);
    dispatch(hideStepperPopper());
    if (action !== "done") {
      setStepToLocal({ key: "done", value: true });
    } else {
      setStepToLocal({ key, value: true });
    }
  };
  const handleNext = () => {
    setOpenPoppers(false);
    setTimeout(() => {
      setTeachIndex((prev) => prev + 1);
      setStepToLocal({ key, value: true });
      setOpenPoppers(true);
    }, 500);
  };

  const handleBack = () => {
    setOpenPoppers(false);
    setTimeout(() => {
      setTeachIndex((prev) => prev - 1);
      setOpenPoppers(true);
    }, 500);
  };
  return (
    refernceArray.length > 0 && (
      <>
        <Backdrop sx={{ color: "#fff", zIndex: 1449 }} open={open}></Backdrop>

        <Popper
          open={openPopper}
          anchorEl={parent}
          placement={placement}
          transition
          disablePortal={false}
          className={`custom-popper ${placement}`}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className="dot-line-info-box" {...TransitionProps}>
                <div className="dot"></div>
                <div className="line"></div>
                <div className="info-box">
                  <CancelIcon onClick={handleClose} className="cancel-icon" />
                  <Typography className="popper-text">{text}</Typography>
                  <Box className="popper-btns-box">
                    {teachIndex > 0 && (
                      <Button size="small" color="white" onClick={handleBack}>
                        {t("APP.TEACH_USER_DATA.ACTION_BTNS.BACK")}
                      </Button>
                    )}
                    <Button
                      size="small"
                      sx={{
                        color: "var(--darkBlue) !important",
                        background: "var(--white) !important",
                        height: "25px",
                        width: "44px",
                      }}
                      onClick={() =>
                        isDone ? handleClose("done") : handleNext()
                      }
                    >
                      {isDone
                        ? t("APP.TEACH_USER_DATA.ACTION_BTNS.DONE")
                        : t("APP.TEACH_USER_DATA.ACTION_BTNS.NEXT")}
                    </Button>
                  </Box>
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      </>
    )
  );
};
