import React, { useEffect } from "react";
import astronaut from "../../assets/image/404/astronaut.svg";
import planet from "../../assets/image/404/planet.svg";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();

  const particles = {
    fpsLimit: 60,
    particles: {
      number: {
        value: 160,
        density: {
          enable: true,
          area: 800,
        },
      },
      color: {
        value: "#ffffff",
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 1,
        random: {
          enable: true,
          minimumValue: 0.1,
        },
        animation: {
          enable: true,
          speed: 1,
          minimumValue: 0,
          sync: false,
        },
      },
      size: {
        value: 3,
        random: {
          enable: true,
          minimumValue: 1,
        },
      },
      move: {
        enable: true,
        speed: 0.17,
        direction: "none",
        random: true,
        straight: false,
        outModes: {
          default: "out",
        },
      },
    },
    interactivity: {
      detectsOn: "canvas",
      events: {
        resize: false,
      },
    },
    detectRetina: true,
  };

  useEffect(() => {
    window.tsParticles.load("tsparticles", particles);
  }, []);

  return (
    <>
      <Box className="not-found-parent-wrapper">
        <Box className="permission-denied">
          <Box id="tsparticles" className="tsparticles"></Box>
          <Box className="denied-wrapper">
            <Typography className="denied-heading">404</Typography>
            <Typography className="denied-sub-heading">
              {t("APP.NOT_FOUND.LOST")} <span>{t("APP.NOT_FOUND.SPACE")}</span>{" "}
              {t("APP.NOT_FOUND.PAGE_NOT_EXIST")}
            </Typography>
            <img
              id="astronaut"
              className="astronaut"
              src={astronaut}
              alt="Astronaut"
            />
            <img id="planet" src={planet} alt="Planet" />
            <Link to="/game-lobby" className="denied-link-wrap">
              <button className="denied-link">
                {t("APP.NOT_FOUND.HOME_LINK")}
              </button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// &apos;
