import React from "react";
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const RecommendBrowserModal = (props) => {
  const { openRecomendModal, setOpenRecomendModal, preserveActionFunc } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenRecomendModal(false);
    preserveActionFunc();
  };

  const redirectToDownload = () => {
    window.open(
      "https://chromeenterprise.google/download/?modal-id=download-chrome",
      "_blank",
    );
  };

  const baseT = "APP.ALERT_DIALOGS.";

  return (
    <Dialog
      open={openRecomendModal}
      sx={{
        ".MuiPaper-root": {
          minWidth: "305px",
          background: "var(--shadowNavy)",
          borderRadius: "15px",
        },
      }}
    >
      <Box sx={{ px: "8px", pt: "8px", textAlign: "right" }}>
        <Button
          variant="outlined"
          sx={{ fontSize: 8, borderRadius: "5px", color: "var(--silverGray)", borderColor: "var(--silverGray)", borderWidth: "0.5px" }}
          onClick={handleClose}
        >
          {t(baseT + "CANCEL_BTN.SKIP")}
        </Button>
      </Box>

      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: "370px"
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: "var(--silverGray)", fontSize: 15, textAlign: 'center', justifyContent: "center" }}
        >
          {t(baseT + "TITLE.RECOMEND_BROWSER")}
        </Typography>
      </DialogTitle>
      <DialogActions
        sx={{ justifyContent: "center", mt: "16px", pb: "14px" }}
        className="action-btns-wrapper"
      >
        <Button
          variant="contained"
          color={"primary"}
          className="dialog-btn"
          sx={{ fontSize: 12, borderRadius: "5px" }}
          onClick={redirectToDownload}
        >
          {t(baseT + "CONFIRMATION_BTN.DOWNLOAD")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RecommendBrowserModal.propTypes = {
  openRecomendModal: PropTypes.bool.isRequired,
  setOpenRecomendModal: PropTypes.func.isRequired,
  preserveActionFunc: PropTypes.func.isRequired,
};

export default RecommendBrowserModal;
