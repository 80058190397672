import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { handleMic } from "../index.js";

let tooltipTimeout = {};
export const muteMic = () => {
  const {
    socket,
    setAdminAudioMuted,
    adminAudioMutedRef,
    viewer,
    setMediaAllowedTooltip,
  } = params;

  if (!viewer) {
    socket.on("muteMic", ({ state }) => {
      // state will be DAY when mode shifts from night to day , so everyon's mic will be close but ,they can open it
      if (state === "DAY") {
        // to make sure user can open his mic
        setAdminAudioMuted(false);
        adminAudioMutedRef.current = false;
      } else if (state === "ADMIN MUTE") {
        setMediaAllowedTooltip((prev) => ({ ...prev, audioMuted: true }));
        clearTimeout(tooltipTimeout["audioMuted"]);
        tooltipTimeout["audioMuted"] = setTimeout(() => {
          setMediaAllowedTooltip((prev) => {
            setTimeout(() => {
              setMediaAllowedTooltip((prev) => ({ ...prev, wait: false }));
            }, 500);

            return {
              ...prev,
              wait: true,
              audioMuted: false,
            };
          });
        }, 5000);
      } else {
        // to put mic closed status
        setAdminAudioMuted(true);
        adminAudioMutedRef.current = true;
      }
      handleMic(true);
    });
  }
};
