import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { encodeParams } from "../../global/encodeDecodeUrl.js";
import { setNavigateTo } from "../../../features/global/globalSlice.js";
const handleRejoin = () => {
  const {
    dispatch,
    decodedParams,
    shiftToWatchMode: shiftToJoinAsPlayerMode,
  } = params;
  const queryParams = { ...decodedParams };
  delete queryParams.v;
  const encodedParams = encodeParams(queryParams);
  const pathName = `../room/${encodedParams}`;
  dispatch(setNavigateTo({path:pathName,replace:true}));
  setTimeout(() => {
    shiftToJoinAsPlayerMode();
  }, 0);
};

export default handleRejoin;
