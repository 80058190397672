import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles/index.js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
export const Terms = ({ setIsTermsChecked, validation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    setIsTermsChecked(isChecked);
  };
  return (
    <Box>
      <Checkbox
        className="terms-checkbox"
        onChange={handleCheckbox}
        name="terms"
        sx={{
          color: "var(--white)",
          marginLeft: "-11px",
          "& .MuiSvgIcon-root": { fontSize: "18.3px" },
        }}
        {...{ inputProps: { "aria-label": "Checkbox" } }}
        color="primary"
      />
      <Typography
        color={"var(--white)"}
        fontWeight={300}
        sx={{
          fontSize: "11px",
          marginLeft: "-4px",
          fontWeight: "500",
        }}
        display="inline"
      >
        {t("APP.AUTH.TERMS.AGREE_TERMS_INTRO")}{" "}
        <Link
          to="https://gorgine.com/terms-and-conditions-en/"
          target="_blank"
          style={{ color: "var(--primary)" }}
        >
          {t("APP.AUTH.TERMS.TERMS_AND_CONDITIONS_LINK")}{" "}
        </Link>
        {t("APP.AUTH.TERMS.AGREE_TERMS_END")}
      </Typography>
      <Typography
        sx={{
          visibility: validation.includes("terms") ? "visible" : "hidden",
          mt: "-3px",
          ml: "27px",
          whiteSpace: "nowrap",
          [theme.breakpoints.down("sm")]: {
            overflow: "hidden",
          },
        }}
        color={"var(--darkRed)"}
        fontSize={"10px"}
        fontWeight={400}
      >
        {t("APP.AUTH.TERMS.TERMS_AND_CONDITIONS_ERROR")}
      </Typography>
    </Box>
  );
};

Terms.propTypes = {
  setIsTermsChecked: PropTypes.func.isRequired,
  validation: PropTypes.array.isRequired,
};
