import {
  Fade,
  Paper,
  Popper,
  Box,
  styled,
  List,
  ListItemButton,
  ListItemIcon,
  ThemeProvider,
  createTheme,
  ListItemText,
  ClickAwayListener,
  Typography
} from "@mui/material";

import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useChangeAudioSource from "../../../hooks/game-room/audio/useChangeAudioSource.js";

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const AudioSourceList = (props) => {
  const { open, setOpen, micIconRef, stream } = props;
  const { devices, localStream } = stream;
  const { changeAudioSource, changeSpeakerSource } = useChangeAudioSource();
  const { t, i18n } = useTranslation();

  // Get the audio track
  const audioTrack = localStream?.getAudioTracks()?.[0];
  // Get the device ID from the audio track settings
  const { microphones, speakers } = devices;
  const currentlyUsedDeviceId = audioTrack?.getSettings()?.deviceId;
  const enableClickAwayRef = useRef(false);
  useEffect(() => {
    if (open) {
      enableClickAwayRef.current = false;
      setTimeout(() => {
        enableClickAwayRef.current = true;
      }, 100);
    }
  }, [open]);
  const handlePopperClose = () => {
    enableClickAwayRef.current && setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handlePopperClose}>
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={micIconRef.current}
        placement={"top"}
        transition
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 25],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ display: "flex" }}>
              <ThemeProvider
                theme={createTheme({
                  components: {
                    MuiListItemButton: {
                      defaultProps: {
                        disableTouchRipple: true,
                      },
                    },
                  },
                  palette: {
                    background: { paper: `rgb(32, 33, 36)` },
                  },
                })}
              >
                <Paper
                  elevation={0}
                  sx={{
                    minWidth: "16.25rem",
                    borderRadius: "15px",
                    overflow: "hidden",
                  }}
                >
                  <FireNav component="nav" disablePadding>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        px: 2,
                        py: 1,
                        backgroundColor: "rgba(50,50,50,0.8)",
                        direction: i18n.language === 'fa' ? 'rtl' : 'ltr',
                      }}
                      color="white"
                    >
                      {t("APP.GAME_ROOM.FOOTER.MIDDLE.MIC_SOURCE_TITLE")}
                    </Typography>
                    {microphones.map((item, idx) => {
                      const { label, deviceId, groupId } = item;
                      const isCurrentlyInUse = deviceId === currentlyUsedDeviceId;
                      return (
                        <ListItemButton
                          key={"mic-" + label + idx}
                          onClick={() => {
                            changeAudioSource(deviceId, groupId, speakers);
                            setOpen(false);
                          }}
                          sx={{
                            py: 0,
                            px: 0,
                            minHeight: 32,
                            color: isCurrentlyInUse
                              ? "var(--primary)"
                              : "rgba(255,255,255,.8)",
                            "&.MuiListItemButton-root": {
                              p: -1,
                            },
                            "&:hover": {
                              backgroundColor: "rgba(255,255,255,.3)", // Darker hover effect
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: "inherit",
                              visibility: isCurrentlyInUse ? "visible" : "hidden",
                            }}
                          >
                            <Check />
                          </ListItemIcon>
                          <ListItemText
                            primary={label}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          />
                        </ListItemButton>
                      );
                    })}
                    {document.querySelectorAll("audio").length > 0 && (
                      <>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            px: 2,
                            py: 1,
                            backgroundColor: "rgba(50,50,50,0.8)",
                            direction: i18n.language === 'fa' ? 'rtl' : 'ltr',
                          }}
                          color="white"
                        >
                          {t("APP.GAME_ROOM.FOOTER.MIDDLE.SPEAKER_SOURCE_TITLE")}
                        </Typography>
                        {speakers.map((item, idx) => {
                          const { label, deviceId } = item;
                          const selectedSpeakerId =
                            localStorage.getItem("selectedSpeakerDeviceId") ||
                            "default";
                          const isCurrentlyInUse = deviceId === selectedSpeakerId;
                          return (
                            <ListItemButton
                              key={"speaker-" + label + idx}
                              onClick={() => {
                                changeSpeakerSource(deviceId);
                                setOpen(false);
                              }}
                              sx={{
                                py: 0,
                                px: 0,
                                minHeight: 32,
                                color: isCurrentlyInUse
                                  ? "var(--primary)"
                                  : "rgba(255,255,255,.8)",
                                "&.MuiListItemButton-root": {
                                  p: -1,
                                },
                                "&:hover": {
                                  backgroundColor: "rgba(255,255,255,.3)", // Darker hover effect
                                },
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  color: "inherit",
                                  visibility: isCurrentlyInUse ? "visible" : "hidden",
                                }}
                              >
                                <Check />
                              </ListItemIcon>
                              <ListItemText
                                primary={label}
                                primaryTypographyProps={{
                                  fontSize: 14,
                                  fontWeight: "medium",
                                }}
                              />
                            </ListItemButton>
                          );
                        })}
                      </>
                    )}
                  </FireNav>
                </Paper>
              </ThemeProvider>
            </Box>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );  
};
AudioSourceList.propTypes = {
  open: PropTypes.bool.isRequired,
  micIconRef: PropTypes.object.isRequired,
  stream: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
};
export default AudioSourceList;
