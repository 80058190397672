import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { handleRejoin } from "../../../handlers/game-room/index.js";
import { useTranslation } from "react-i18next";
const Rejoin = (props) => {
  const { rejoinEnabled } = props;
  const { t } = useTranslation();

  return rejoinEnabled ? (
    <Box onClick={handleRejoin} className="rejoin-wrap">
      <Typography variant="h3">{t("APP.GAME_ROOM.VIEWER.REJOIN")}</Typography>
      <Typography variant="body1">
        {t("APP.GAME_ROOM.VIEWER.REJOIN_DESCRIPTION")}
      </Typography>
    </Box>
  ) : null;
};
Rejoin.propTypes = {
  rejoinEnabled: PropTypes.bool.isRequired,
};
export default Rejoin;
