import { params } from "../../../hooks/game-room/handlegameRoom.js";
import {
  handleTransport,
  startConsumeProducer,
  consume,
  handleConsumer,
  handleLeave,
  handleMic,
  handleVideo,
  handleUserAction,
} from "../index.js";
import {
  alertDialog,
  hideGlobalLoader,
  setNavigateTo,
} from "../../../features/global/globalSlice.js";
import { closeConsumer } from "./handleCloseConsumer.js";
import { userLeft } from "./handleUserLeft.js";
import { producerResumedPaused } from "./producerResumedPaused.js";
import { muteVideo } from "./handleMuteVideo.js";
import { muteMic } from "./handleMuteMic.js";
import { promptPermission } from "./handlePromptPermission.js";
import { unMuteVideoAudioRequest } from "./handleUnmuteVideoAudioRequest.js";
import { handleChat } from "./handleChat.js";
import { nightModeInitiated } from "./handleInitiatingNight.js";
import { nightModeStarted } from "./handleNightMode.js";
import { disconnectSocket } from "./handleSocketDisconnect.js";
import { handlePauseConsumers } from "./day&night-mode/handlePauseConsumers.js";
import { encodeParams } from "../../global/encodeDecodeUrl.js";
let tooltipTimeout = {};
export const eventListners = () => {
  const {
    socket,
    firsTimeLoading,
    dispatch,
    t,
    setAdminAudioMuted,
    adminAudioMutedRef,
    setAdminVideoMuted,
    adminVideoMutedRef,
    setAdminVideoMutedUsers,
    viewer,
    getState,
    consumers,
    getWokeStatus,
    adminRef,
    setAwakedPlayers,
    setWaitingForResponse,
    setReconnectingUsers,
    decodedParams,
    shiftToWatchMode,
    setMediaAllowedTooltip,
    setRejoinEnabled,
  } = params;

  socket.on("transportCreated", (data) => {
    handleTransport({ ...params, data: data.data });
  });
  socket.on("roomError", (data) => {
    if (firsTimeLoading.current) {
      dispatch(hideGlobalLoader());
    }
  });
  socket.on("currentProducers", async (producers) => {
    if (viewer) {
      producers?.forEach((producer) => {
        startConsumeProducer(producer, "currentProducers-if(viewer)");
      });
    } else {
      const state = await getState();
      if (state === "NIGHT") {
        producers?.forEach((producer) => {
          // if (producer?.kind === "video") return;
          startConsumeProducer(producer, "currentProducersstate === NIGHT");
        });
      } else {
        producers?.forEach((producer) => {
          startConsumeProducer(producer, "currentProducersstate-else");
        });
      }
    }
  });
  socket.on("newProducer", async (producer) => {
    // const isWoke = await getWokeStatus();
    if (producer.kind === "video") {
      const state = await getState();
      if (state === "NIGHT") {
        if (!viewer) {
          const shouldConusmeProducer = adminRef.current
            ? true
            : await getWokeStatus();
          if (shouldConusmeProducer) {
            startConsumeProducer(producer);
          }
        }
        // if (adminRef.current) {
        //   const cons = consumers.current.filter(
        //     (consumer) => consumer?.socketId === producer.socketId,
        //   );
        //   cons?.forEach((consumer) => {
        //     if (consumer?.kind === "video") return;
        //     socket.emit("resumeConsumer", consumer?.storageId, async () => {
        //       await consumer?.consumer?.resume();
        //     });
        //   });
        // } else if (viewer) {
        //   return;
        //   // below is commented to prevent from grid refresh for viewer  ,when player wakes ,  !important
        //   // startConsumeProducer(producer,"newProducer-viewer");
        // } else if (isWoke) {
        //   startConsumeProducer(producer, "newProducer-isWoke");
        //   // const cons = consumers.current.filter(
        //   //   (consumer) => consumer?.socketId === producer.socketId,
        //   // );
        //   // cons?.forEach((consumer) => {
        //   //   if (consumer?.kind === "video") return;
        //   //   socket.emit("resumeConsumer", consumer?.storageId, async () => {
        //   //     await consumer?.consumer?.resume();
        //   //   });
        //   // });
        // }
      } else {
        startConsumeProducer(producer, "newProducer not night -else");
        const cons = consumers.current.filter(
          (consumer) => consumer?.socketId === producer.socketId,
        );
        cons?.forEach((consumer) => {
          if (consumer?.kind === "video") return;
          socket.emit("resumeConsumer", consumer?.storageId, async () => {
            await consumer?.consumer?.resume();
          });
        });
      }
    } else {
      startConsumeProducer(producer, "newProducer-kind audio");
    }
  });
  socket.on("ConsumeTransportCreated", async (data) => {
    await consume(data);
  });
  socket.on("consumerCreated", (data) => {
    handleConsumer({ ...params, data });
  });
  socket.on("removeUser", (reason) => {
    dispatch(
      alertDialog({
        title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.KICK_SHOT_PLAYER").replace(
          "❔",
          t(`APP.GAME_ROOM.GRID_SCREEN_CARD.PLAYER_STATUS.${reason}`),
        ),
        icon: "error",
      }),
    );
    if (reason === "SHOT") {
      decodedParams.v = "t";
      const encodedParams = encodeParams(decodedParams);
      const pathName = `/room/${encodedParams}`;
      // window.history.replaceState(null, "", pathName + encodedParams);
      dispatch(setNavigateTo(pathName));
      shiftToWatchMode();
    } else {
      handleLeave();
    }
  });
  socket.on("unmuteMic", () => {
    setAdminAudioMuted(false);
    adminAudioMutedRef.current = false;
    handleMic(false);
  });
  socket.on("unmuteVideo", () => {
    setAdminVideoMuted(false);
    adminVideoMutedRef.current = false;
    handleVideo(false);
  });

  socket.on("videoMuteStatus", (data) => {
    if (data?.status) {
      setAdminVideoMutedUsers((prev) => [...prev, data?.socketId]);
    }
  });
  socket.on("receiveLike", (socketId) => {
    handleUserAction("likeUsers", socketId);
  });

  socket.on("receiveDislike", (socketId) => {
    handleUserAction("dislikeUsers", socketId);
  });

  socket.on("receiveChallenge", (socketId) => {
    handleUserAction("challengeUsers", socketId);
  });

  socket.on("onWakeUp", async (socketId) => {
    // this is to consume audio , for video newProducer socket is working
    if (!viewer) {
      const shouldConsume = adminRef.current ? true : await getWokeStatus();
      if (shouldConsume) {
        // this only for other woke players and admin
        setAwakedPlayers((prev) => {
          if (prev.includes(socketId)) {
            return prev;
          } else {
            return [...prev, socketId];
          }
        });

        setWaitingForResponse((waitingPlayer) => {
          return waitingPlayer.filter((id) => id !== socketId);
        });

        const cons = consumers.current.filter(
          (consumer) => consumer?.socketId === socketId,
        );
        cons?.forEach((consumer) => {
          if (consumer?.kind === "video") return;
          socket.emit("resumeConsumer", consumer?.storageId, async () => {
            await consumer?.consumer?.resume();
          });
        });
      }
    }
  });

  socket.on("onSleep", (socketId) => {
    if (!adminRef.current && !viewer) {
      // this only for other woke players , for admin woke player is removing in handleDay&Night.js
      setAwakedPlayers((prev) =>
        prev.includes(socketId) ? prev.filter((id) => id !== socketId) : prev,
      );
    }
  });

  socket.on("player-unstable-connection", (socketId) => {
    setReconnectingUsers((prev) =>
      prev.includes(socketId) ? prev : [...prev, socketId],
    );
  });
  socket.on("player-stabilized-connection", (socketId) => {
    setReconnectingUsers((prev) => prev.filter((id) => id !== socketId));
  });
  socket.on("unmuteAll", (type) => {
    if (type === "microphone") {
      setAdminAudioMuted(false);
      setMediaAllowedTooltip((prev) => ({ ...prev, audio: true }));
      clearTimeout(tooltipTimeout["audio"]);
      tooltipTimeout["audio"] = setTimeout(() => {
        setMediaAllowedTooltip((prev) => ({ ...prev, audio: false }));
      }, 5000);
    } else if (type === "video") {
      setAdminVideoMuted(false);
      setMediaAllowedTooltip((prev) => ({ ...prev, video: true }));
      clearTimeout(tooltipTimeout["video"]);
      tooltipTimeout["video"] = setTimeout(() => {
        setMediaAllowedTooltip((prev) => ({ ...prev, video: false }));
      }, 5000);
    }
  });
  //Once room ended, allow shot player to rejoin
  socket.on("rejoinEnabled", () => {
    console.log("Rejoin enabled event received");
    setRejoinEnabled(true);
  });
  // socket.on("muteStatus", (data) => {
  //   if (data?.status) {
  //     setAdminAudioMutedUsers((prev) => [...prev, data?.socketId]);
  //   }
  // });
  closeConsumer();
  userLeft();
  producerResumedPaused();
  muteVideo();
  muteMic();
  if (!viewer) {
    promptPermission();
  }
  unMuteVideoAudioRequest();
  handleChat();
  nightModeInitiated();
  nightModeStarted();
  const { pingInterval, setTimeout } = disconnectSocket();
  // day&night mode
  handlePauseConsumers();
  return () => {
    socket.off("transportCreated");
    socket.off("currentProducers");
    socket.off("ConsumeTransportCreated");
    socket.off("consumerCreated");
    socket.off("newProducer");
    socket.off("closeConsumer");
    socket.off("userLeft");
    socket.off("producerPaused");
    socket.off("producerResumed");
    socket.off("removeUser");
    socket.off("muteVideo");
    socket.off("muteStatus");
    socket.off("muteMic");
    socket.off("promptPermission");
    socket.off("unmuteVideo");
    socket.off("unMuteVideoRequest");
    socket.off("unmuteMic");
    socket.off("unMuteMicRequest");
    socket.off("videoMuteStatus");
    socket.off("receiveLike");
    socket.off("receiveDislike");
    socket.off("receiveChallenge");
    let event = viewer ? "viewerChat" : "chat";
    socket.off(event);
    socket.off("nightModeInitiated");
    socket.off("nightModeStarted");
    socket.off("addUserToRoom");
    socket.off("disconnect");
    socket.off("ping");
    socket.off("onWakeUp");
    socket.off("player-stabilized-connection");
    socket.off("player-unstable-connection");
    clearInterval(pingInterval);
    clearTimeout(setTimeout);
  };
};
