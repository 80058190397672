import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
export const kickOutUser = (reason) => {
  const { socket, currentSelectedPeer, dispatch, t } = params;
  const capitalizedReason =
    reason === "KICKED"
      ? t("APP.GAME_ROOM.ADMIN_ACTIONS.KICK_PLAYER")
      : reason === "SHOT" && t("APP.GAME_ROOM.ADMIN_ACTIONS.SHOT_PLAYER");

  const title = t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.KICK_SHOT_ADMIN").replace(
    "❔",
    capitalizedReason,
  );

  const onConfirm = () =>
    socket.emit("removeUser", currentSelectedPeer, reason);
  dispatch(
    alertDialog({
      title: title,
      confirmButtonText: t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.YES"),
      cancelButtonText: t("APP.ALERT_DIALOGS.CANCEL_BTN.NO"),
      onConfirm,
    }),
  );
};

export const handleAdminMute = (socketId) => {
  const { socket, adminAudioMutedUsers, setPendingMediaReqSockIds } = params;
  const state = adminAudioMutedUsers.includes(socketId) ? false : true;
  if (state) {
    socket.emit("muteMic", {
      socketId: socketId,
      mySocketId: socket.id,
      state: "ADMIN MUTE",
    });
  } else {
    setPendingMediaReqSockIds((prev) => ({
      ...prev,
      mic: [...prev.mic, socketId],
    }));
    socket.emit("unmuteMic", socketId);
  }
};

export const handleAdminMuteVideo = (socketId) => {
  const { socket, adminVideoMutedUsers } = params;
  const state = adminVideoMutedUsers.includes(socketId) ? false : true;
  if (state) {
    socket.emit("muteVideo", {
      socketId: socketId,
      mySocketId: socket.id,
      state: "ADMIN MUTE",
    });
  } else {
    socket.emit("unmuteVideo", socketId);
  }
};

export const handleAwakeSleep = (socketId) => {
  const {
    awakedPlayers,
    setAwakedPlayers,
    socket,
    roomId,
    setWaitingForResponse,
  } = params;
  if (awakedPlayers.includes(socketId)) {
    // handle sleep
    socket.emit("sleep", {
      socketId: socketId,
      roomId: roomId,
    });
    setAwakedPlayers(awakedPlayers.filter((id) => id !== socketId));
  } else {
    // handle awake
    socket.emit("wakeUp", {
      socketId: socketId,
      userId: "",
      roomId: roomId,
      wokePlayers: [...awakedPlayers, socketId],
    });

    setWaitingForResponse((prev) => {
      if (prev.includes(socketId)) {
        return prev;
      } else {
        return [...prev, socketId];
      }
    });
  }
};
