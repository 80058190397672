import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  globalLoaderParams: {
    open: false,
  },
  navigateTo: "",
  navigateState: "",
  navigateReplaceState:false,
  language: "",
  alertDialogParams: { open: false },
  teachStapperPoperParams: { open: false },
  reRender: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    showGlobalLoader: (state, { payload }) => {
      state.globalLoaderParams = payload ?? {};
    },
    hideGlobalLoader: (state) => {
      state.globalLoaderParams = { open: false };
    },
    alertDialog: (state, { payload }) => {
      state.alertDialogParams = payload;
    },
    hideAlertDialogue: (state) => {
      state.alertDialogParams = { open: false };
    },
    teachStepperPopper: (state, { payload }) => {
      state.teachStapperPoperParams = payload;
    },
    hideStepperPopper: (state) => {
      state.teachStapperPoperParams = { open: false };
    },
    setNavigateTo: (state, { payload }) => {
      if (payload?.path &&( payload?.state ||payload?.replace )) {
        state.navigateTo = payload.path;
        if(payload.state){
          state.navigateState = payload.state;
        }
        if(payload.replace){
          state.navigateReplaceState = payload.replace;
        }
      } else {
        state.navigateTo = payload;
      }
    },
    resetNavigator: (state) => {
      state.navigateTo = "";
      state.navigateState = "";
      state.navigateReplaceState=false;
    },
    setReRender: (state, { payload }) => {
      state.reRender = payload;
    },
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
  },
  extraReducers: (builder) => {},
});
export const {
  showGlobalLoader,
  hideGlobalLoader,
  setNavigateTo,
  resetNavigator,
  alertDialog,
  hideAlertDialogue,
  setReRender,
  setLanguage,
  teachStepperPopper,
  hideStepperPopper,
} = globalSlice.actions;
export default globalSlice.reducer;
