import { createSlice } from "@reduxjs/toolkit";
import { getCreateRoomApi } from "./gameLobbyThunk.js";
const initialState = {
  createRoomList: [],
};

const gameLobbySlice = createSlice({
  name: "gameLobby",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCreateRoomApi.fulfilled, (state, { payload }) => {
      state.createRoomList = payload;
    });
  },
});

export default gameLobbySlice.reducer;
