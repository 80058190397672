import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import * as React from "react";
import InfoIcon from "../../../assets/icons/dialogue/InfoIcon.svg";
import WarningIcon from "../../../assets/icons/dialogue/warningIcon.svg";
import ErrorIcon from "../../../assets/icons/dialogue/errorIcon.svg";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { hideAlertDialogue } from "../../../features/global/globalSlice.js";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function AlertDialogue() {
  const dispatch = useDispatch();
  const { alertDialogParams } = useSelector((state) => state.global);
  const { t } = useTranslation();
  const timeOutIdRef = useRef(null);
  const {
    open = true,
    icon = "info",
    title = t("APP.ALERT_DIALOGS.TITLE.DEFAULT_TITLE"),
    text,
    confirmButtonText = t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.DEFAULT_BTN"),
    confirmButtonText2,
    confirmButtonText3,
    cancelButtonText,
    onConfirm = () => {},
    onConfirm2 = () => {},
    onConfirm3 = () => {},
    onCancel = () => {},
    onAction = () => {},
    autoCloseTimeout,
  } = alertDialogParams;
  const alertIcons = {
    info: InfoIcon,
    warning: WarningIcon,
    error: ErrorIcon,
  };
  const buttonBgColors = {
    info: "stealBlue",
    warning: "goldenYellow",
    error: "burntOrange",
  };

  const handleClose = () => {
    onCancel();
    onAction();
    dispatch(hideAlertDialogue());
  };
  const handleConfirm = () => {
    clearTimeout(timeOutIdRef.current);
    onConfirm();
    onAction();
    dispatch(hideAlertDialogue());
  };
  const handleConfirm2 = () => {
    clearTimeout(timeOutIdRef.current);
    onConfirm2();
    onAction();
    dispatch(hideAlertDialogue());
  };
  const handleConfirm3 = () => {
    clearTimeout(timeOutIdRef.current);
    onConfirm3();
    onAction();
    dispatch(hideAlertDialogue());
  };
  if (autoCloseTimeout) {
    clearTimeout(timeOutIdRef.current);
    timeOutIdRef.current = setTimeout(() => {
      handleClose();
    }, autoCloseTimeout);
  }
  const btnFontSize = confirmButtonText3 ? "16px" : "20px";
  return !open ? null : (
    <>
      <Dialog
        className="alert-dialog-parent-wrapper"
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
        sx={{
          ".MuiPaper-root": {
            width: confirmButtonText3 ? "450px" : "400px",
          },
        }}
      >
        <Box>
          <img
            className="dialog-icon"
            src={alertIcons[icon]}
            alt="alert-icon"
          />
        </Box>
        <DialogTitle className="dialog-title" id="dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            height: "25px",
            width: "25px",
            position: "absolute",
            right: 13,
            top: 13,
            backgroundColor: "var(--lightGray) !important",
            "&:hover": {
              backgroundColor: "var(--slateGray) !important",
            },
          }}
        >
          <Close sx={{ height: "16px", width: "16px" }} color="white" />
        </IconButton>
        {text ? (
          <DialogContent className="dialog-content">
            <Typography>{text}</Typography>
          </DialogContent>
        ) : (
          <Box sx={{ height: "20px", width: "100%" }}></Box>
        )}
        <DialogActions className="action-btns-wrapper">
          <Button
            variant="contained"
            color={buttonBgColors[icon]}
            className="dialog-btn"
            sx={{ fontSize: btnFontSize }}
            onClick={handleConfirm}
          >
            {confirmButtonText}
          </Button>
          {confirmButtonText2 && (
            <Button
              variant="contained"
              color={buttonBgColors[icon]}
              className="dialog-btn"
              sx={{ fontSize: btnFontSize }}
              onClick={handleConfirm2}
            >
              {confirmButtonText2}
            </Button>
          )}
          {confirmButtonText3 && (
            <Button
              variant="contained"
              color={buttonBgColors[icon]}
              className="dialog-btn"
              sx={{ fontSize: btnFontSize }}
              onClick={handleConfirm3}
            >
              {confirmButtonText3}
            </Button>
          )}
          {cancelButtonText && (
            <Button
              variant="outlined"
              color={buttonBgColors[icon]}
              className="dialog-btn"
              onClick={handleClose}
              sx={{
                color: "var(--black)",
                fontSize: btnFontSize,
              }}
            >
              {cancelButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

AlertDialogue.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
