import { useEffect, useRef, useState } from "react";
import useChangeAudioSource from "./audio/useChangeAudioSource.js";

const detectDeviceChnages = ({ prevDevices, newDevices }) => {
  console.log("10");
  let response = {};
  if (newDevices.length > prevDevices.length) {
    const addedDevice = newDevices.filter(
      (device) =>
        !prevDevices.some(
          (prevDevice) => prevDevice.deviceId === device.deviceId,
        ),
    );
    response = { status: "added", devicedetails: addedDevice };
  } else if (newDevices.length < prevDevices.length) {
    const removedDevice = prevDevices.filter(
      (device) =>
        !newDevices.some((newDevice) => newDevice.deviceId === device.deviceId),
    );
    response = { status: "removed", devicedetails: removedDevice };
  } else {
    // No devices added or removed
    response = { status: "no_changes" };
  }
  return response;
};

const checkDefaultDeviceAvailable = (devices) => {
  return devices.some((device) => device.deviceId === "default");
};

const useDetectDevices = (props) => {
  const { localStreamRef } = props;
  const { changeAudioSource } = useChangeAudioSource();
  const [devices, setDevices] = useState({
    microphones: [],
    speakers: [],
  });
  const devicesRef = useRef({
    microphones: [],
    speakers: [],
  });
  const automaticallySwitchedAudioInput = useRef(true);
  const updateDeviceList = async (notIntial) => {
    const audioInputAuto = automaticallySwitchedAudioInput.current;
    try {
      const localStream = localStreamRef.current;
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphones = devices.filter(
        (device) => device.kind === "audioinput",
      );
      const speakers = devices.filter(
        (device) => device.kind === "audiooutput",
      );

      if (localStream) {
        const isDeafultAvailable = checkDefaultDeviceAvailable(
          devicesRef.current.microphones,
        );
        if (notIntial) {
          const inputAudioDeviceChanges = detectDeviceChnages({
            prevDevices: devicesRef.current.microphones,
            newDevices: microphones,
          });
          
          devicesRef.current = { microphones, speakers };
          const { status, devicedetails } = inputAudioDeviceChanges;
          if (status === "added" && audioInputAuto) {
            // if selection is dafault we will automatically switch user to new added device user
            const { deviceId, groupId } = devicedetails[0];
            changeAudioSource(deviceId, groupId);
          } else if (status === "removed") {
            if (audioInputAuto && isDeafultAvailable) {
              changeAudioSource("default", null);
            } else {
              // otherwise chose any of available source
              const availableDevice = devicesRef.current.microphones;
              if (Boolean(availableDevice.length)) {
                const { deviceId, groupId } = availableDevice;
                changeAudioSource(deviceId, groupId);
              }
            }
          }
        }
      }

      setDevices({ microphones, speakers });
      devicesRef.current = { microphones, speakers };
    } catch (error) {
      console.error("Error updating device list:", error);
    }
  };

  useEffect(() => {
    updateDeviceList(false);
    navigator.mediaDevices.ondevicechange = updateDeviceList;
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);
  return { devices };
};

export default useDetectDevices;
