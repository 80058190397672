import React, { useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles/index.js";
import { KeyboardArrowDown } from "@mui/icons-material";
import listCheckIcon from "../../assets/icons/lobby/listCheckIcon.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const DropDown = ({ selectedItem, setSelectedItem }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const dropDownItem = {
    0: t("APP.LOBBY.DROPDOWN.ALL_ACTIVE"),
    1: t("APP.LOBBY.DROPDOWN.IN_PROGRESS"),
    2: t("APP.LOBBY.DROPDOWN.OPEN"),
    3: t("APP.LOBBY.DROPDOWN.PRIVATE"),
    4: t("APP.LOBBY.DROPDOWN.PUBLIC"),
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (val) => {
    setSelectedItem(val);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        sx={{
          width: "137px",
          paddingBottom: "0",
          fontSize: "18px",
          fontWeight: "600",
          color: "var(--white)",
          textTransform: "none",
          whiteSpace: "nowrap",
          [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            width: "120px",
          },
        }}
        endIcon={<KeyboardArrowDown />}
      >
        {}
        {dropDownItem[selectedItem]}
      </Button>
      <Menu
        className="ranked-rooms-drop-down"
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          className="drop-down-menu-item"
          onClick={() => handleClose(0)}
          disableRipple
          sx={{
            ...(selectedItem === "All active" && {
              backgroundColor: "var(--charcoalSlate)",
            }),
          }}
        >
          <img
            style={{
              ...(selectedItem === 0
                ? { visibility: "visible" }
                : { visibility: "hidden" }),
            }}
            src={listCheckIcon}
            alt="check-icon"
          />
          <Typography className="menu-item-text">
            {dropDownItem[0]}
            {/* All active */}
          </Typography>
        </MenuItem>
        <MenuItem
          className="drop-down-menu-item"
          onClick={() => handleClose(1)}
          disableRipple
          sx={{
            ...(selectedItem === "In progress" && {
              backgroundColor: "var(--charcoalSlate)",
            }),
          }}
        >
          <img
            style={{
              ...(selectedItem === 1
                ? { visibility: "visible" }
                : { visibility: "hidden" }),
            }}
            src={listCheckIcon}
            alt="check-icon"
          />
          <Typography className="menu-item-text">
            {dropDownItem[1]}
            {/* In progress */}
          </Typography>
        </MenuItem>
        <MenuItem
          className="drop-down-menu-item"
          onClick={() => handleClose(2)}
          disableRipple
          sx={{
            ...(selectedItem === "Open" && {
              backgroundColor: "var(--charcoalSlate)",
            }),
          }}
        >
          <img
            style={{
              ...(selectedItem === 2
                ? { visibility: "visible" }
                : { visibility: "hidden" }),
            }}
            src={listCheckIcon}
            alt="check-icon"
          />
          <Typography className="menu-item-text">{dropDownItem[2]}</Typography>
        </MenuItem>
        <MenuItem
          className="drop-down-menu-item"
          onClick={() => handleClose(3)}
          disableRipple
          sx={{
            ...(selectedItem === "Private" && {
              backgroundColor: "var(--charcoalSlate)",
            }),
          }}
        >
          <img
            style={{
              ...(selectedItem === 3
                ? { visibility: "visible" }
                : { visibility: "hidden" }),
            }}
            src={listCheckIcon}
            alt="check-icon"
          />
          <Typography className="menu-item-text">{dropDownItem[3]}</Typography>
        </MenuItem>
        <MenuItem
          className="drop-down-menu-item"
          onClick={() => handleClose(4)}
          disableRipple
          sx={{
            ...(selectedItem === "Public" && {
              backgroundColor: "var(--charcoalSlate)",
            }),
          }}
        >
          <img
            style={{
              ...(selectedItem === 4
                ? { visibility: "visible" }
                : { visibility: "hidden" }),
            }}
            src={listCheckIcon}
            alt="check-icon"
          />
          <Typography className="menu-item-text">{dropDownItem[4]}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

DropDown.propTypes = {
  selectedItem: PropTypes.number.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
};
