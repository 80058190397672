import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useTheme } from "@mui/material";
import dualUserIcon from "../../../../assets/icons/dualUsersIconSm.svg";
import videoIcon from "../../../../assets/icons/room/footer/videoIcon.svg";
import micImg from "../../../../assets/icons/room/footer/micIcon.svg";
import {
  handleMuteForAll,
  handleMuteVideoForAll,
} from "../../../../handlers/game-room/index.js";
import { useTranslation } from "react-i18next";
const VideoAudioForAll = ({ stream, isMiddle }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isAdmin, micAllStatus, videoAllStatus, type, isNightMode, roomData } =
    stream;
  return (
    isAdmin &&
    !isNightMode &&
    roomData.players.length > 1 && (
      <>
        <Box
          onClick={() => {
            handleMuteForAll();
          }}
          className="footer-icon-wrapper video-audio-all-wrapper"
          sx={{
            [theme.breakpoints.up("sm")]: {
              display: isMiddle ? "none !important" : "",
            },
          }}
        >
          <Box>
            <Box
              className={`footer-icon-box ${
                micAllStatus ? "" : `stop stop-mic-all`
              }
          }`}
            >
              <img className="dula-users-sup" src={dualUserIcon} alt="all" />
              <img src={micImg} alt="mic" />
            </Box>
          </Box>
          <Typography
            color={"var(--white)"}
            fontWeight={300}
            sx={{
              fontSize: "11px",
              whiteSpace: "nowrap",
            }}
          >
            {micAllStatus
              ? t(
                  "APP.GAME_ROOM.FOOTER.LEFT_SIDE.VIDEO_AUDIO_ALL.DISABLE_AUDIO_ALL",
                )
              : t(
                  "APP.GAME_ROOM.FOOTER.LEFT_SIDE.VIDEO_AUDIO_ALL.ENABLE_AUDIO_ALL",
                )}
          </Typography>
        </Box>
        {type !== "audio" && (
          <Box
            onClick={() => {
              handleMuteVideoForAll();
            }}
            className="footer-icon-wrapper video-audio-all-wrapper"
            sx={{
              [theme.breakpoints.up("sm")]: {
                display: isMiddle ? "none !important" : "",
              },
            }}
          >
            <Box>
              <Box
                className={`footer-icon-box ${
                  videoAllStatus ? "" : `stop stop-video-all`
                }`}
              >
                <img className="dula-users-sup" src={dualUserIcon} alt="all" />
                <img src={videoIcon} alt="mic" />
              </Box>
            </Box>
            <Typography
              color={"var(--white)"}
              fontWeight={300}
              sx={{
                fontSize: "11px",
                whiteSpace: "nowrap",
              }}
            >
              {videoAllStatus
                ? t(
                    "APP.GAME_ROOM.FOOTER.LEFT_SIDE.VIDEO_AUDIO_ALL.DISABLE_VIDEO_ALL",
                  )
                : t(
                    "APP.GAME_ROOM.FOOTER.LEFT_SIDE.VIDEO_AUDIO_ALL.ENABLE_VIDEO_ALL",
                  )}
            </Typography>
          </Box>
        )}
      </>
    )
  );
};
VideoAudioForAll.propTypes = {
  stream: PropTypes.object.isRequired,
  isMiddle: PropTypes.bool,
};
export default VideoAudioForAll;
