import { params } from "../../../hooks/game-room/handlegameRoom.js";
export async function consume(data) {
  const {
    socket,
    receiveTransport,
    mediasoupDevice,
    getState,
    getRemoteWokeStatus,
    getWokeStatus,
    viewer,
  } = params;
  receiveTransport.current[data?.storageId] =
    await mediasoupDevice.current?.createRecvTransport(data.data);
  receiveTransport?.current[data?.storageId].on(
    "connect",
    ({ dtlsParameters }, callback, errback) => {
      socket.emit("transportConnect", {
        dtlsParameters,
        storageId: data?.storageId,
      });
      socket.on("consumerTransportConnected", (storageId) => {
        if (storageId === data?.storageId) {
          callback();
        }
      });
    },
  );

  receiveTransport?.current[data?.storageId].on(
    "connectionstatechange",
    (state) => {
      switch (state) {
        case "connecting":
          console.log("Connecting To Stream!");
          break;
        case "connected":
          console.log("subscribed!");
          break;
        case "failed":
          console.log("Connection failed at consume");
          socket.emit("consumerRestartIce", data?.storageId, async (params) => {
            if (!params) {
              console.error("No ICE parameters received for consumer restart");
              return;
            }
            try {
              await receiveTransport.current[data?.storageId].restartIce({
                iceParameters: params,
              });
            } catch (error) {
              console.error("Error restarting ICE on consumer", error);
            }
          });
          break;
        default:
          break;
      }
    },
  );

  const remotePlayerWoke = await getRemoteWokeStatus(data?.socketId);
  const isWoke = await getWokeStatus();
  const state = await getState();

  socket.emit("startConsuming", {
    rtpCapabilities: mediasoupDevice?.current?.rtpCapabilities,
    storageId: data?.storageId,
    producerId: data?.producerId,
    socketId: data?.socketId,
    paused:
      state === "NIGHT"
        ? viewer
          ? true
          : remotePlayerWoke && isWoke
            ? false
            : true
        : false,
  });
}
