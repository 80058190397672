import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { updatePermission } from "../index.js";
export const unMuteVideoAudioRequest = () => {
  const {
    socket,
    setAdminAudioMutedUsers,
    adminVideoMutedUsers,
    setAdminVideoMutedUsers,
    setWaitingForResponse,
    setPendingMediaReqSockIds,
  } = params;
  socket.on("unMuteMicRequest", (socketId, status) => {
    // stop loader for pending mic request
    setPendingMediaReqSockIds((prev) => ({
      ...prev,
      mic: prev.mic.filter((sockId) => sockId !== socketId),
    }));
    if (status === "accepted") {
      setAdminAudioMutedUsers((prev) => {
        const filteredUsers = prev.filter((sockId) => sockId !== socketId);
        return filteredUsers;
      });
    } else if (status === "declined") {
      updatePermission(socketId, "mic");
    }
  });
  socket.on("wakeUpRequest", (socketId, status) => {
    if (status === "accepted") {
    } else if (status === "declined") {
      updatePermission(socketId, "wakeUp");
      setWaitingForResponse((waitingPlayer) => {
        return waitingPlayer.filter((id) => id !== socketId);
      });
      // setAwakedPlayers((awakedPlayer) => {
      //   return awakedPlayer.filter((id) => id !== socketId);
      // });
    }
  });

  socket.on("unMuteVideoRequest", (socketId, status) => {
    if (status === "accepted") {
      const filteredUsers = adminVideoMutedUsers.filter(
        (sockId) => sockId !== socketId,
      );
      setAdminVideoMutedUsers(filteredUsers);
    } else if (status === "declined") {
      updatePermission(socketId, "video");
    }
  });
};
