import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles/index.js";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
export const TermsOtp = ({ setIsTermsOtpChecked, validation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    setIsTermsOtpChecked(isChecked);
  };
  return (
    <Box>
      <Checkbox
        className="terms-checkbox"
        onChange={handleCheckbox}
        name="termsOtp"
        sx={{
          color: "var(--white)",
          marginLeft: "-11px",
          "& .MuiSvgIcon-root": { fontSize: "18.3px" },
        }}
        {...{ inputProps: { "aria-label": "Checkbox" } }}
        color="primary"
      />
      <Typography
        color={"var(--white)"}
        fontWeight={200}
        sx={{
          fontSize: "11px",
          marginLeft: "-4px",
          fontWeight: "300",
        }}
        display="inline"
      >
        By entering your phone number, you agree to receive SMS from Gorgine. You can opt out at any time.
      </Typography>
      <Typography
        sx={{
          visibility: validation.includes("termsOtp") ? "visible" : "hidden",
          mt: "-3px",
          ml: "27px",
          whiteSpace: "nowrap",
          [theme.breakpoints.down("sm")]: {
            overflow: "hidden",
          },
        }}
        color={"var(--darkRed)"}
        fontSize={"10px"}
        fontWeight={400}
      >
        {t("APP.AUTH.TERMS.TERMS_AND_CONDITIONS_ERROR")}
      </Typography>
    </Box>
  );
};

TermsOtp.propTypes = {
  setIsTermsOtpChecked: PropTypes.func.isRequired,
  validation: PropTypes.array.isRequired,
};
