import { params } from "../handlegameRoom.js";
const useChangeAudioSource = () => {
  const changeAudioSource = async (deviceId, groupId) => {
    const {
      audioProducer,
      localStreamRef,
      setLocalStream,
      setCaptureMediaStreams,
      user,
    } = params;
    try {
      // Get the new audio stream with the desired device
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: deviceId } },
      });

      // Extract the audio track from the new stream
      const newAudioTrack = newStream.getAudioTracks()[0];
      // Replace the existing audio track in the mediasoup transport
      await audioProducer.current.replaceTrack({ track: newAudioTrack });
      // Preserve the existing audio settings
      const localStream = localStreamRef.current;
      const existingAudioTrack = localStream.getAudioTracks()[0];
      // Stop and remove the old audio track
      existingAudioTrack.stop();
      localStream.removeTrack(existingAudioTrack);
      // Add the new audio track to the localStreamRef
      localStream.addTrack(newAudioTrack);
      // Update the localStreamRef
      localStreamRef.current = localStream;
      setLocalStream(localStream);
      setCaptureMediaStreams((prev) => {
        const streams = {
          ...prev,
          [user.username]: localStreamRef.current,
        };
        return streams;
      });

      const devices = await navigator.mediaDevices.enumerateDevices();
      const speakers = devices.filter(
        (device) => device.kind === "audiooutput",
      );

      const correspondingSpeaker = speakers.find(speaker => speaker.groupId === groupId);
      if (correspondingSpeaker) {
        changeSpeakerSource(correspondingSpeaker.deviceId);
      } else {
        changeSpeakerSource("default"); // Fallback to default speaker if no matching speaker found
      }
    } catch (error) {
      console.error("Error changing audio source:", error);
    }
  };

  const changeSpeakerSource = async (speakerDeviceId) => {
    const audioElements = document.querySelectorAll('audio');
    audioElements.forEach(audioElement => {
      if (typeof audioElement.setSinkId === 'function') {
        audioElement.setSinkId(speakerDeviceId).then(() => {
        }).catch(error => {
          console.error('Error changing speaker device:', error);
        });
      }
    });
  };

  return { changeAudioSource, changeSpeakerSource };
};

export default useChangeAudioSource;
