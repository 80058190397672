import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as CancelIcon } from "../../assets/icons/lobby/cancelIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/lobby/searchIcon.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/lobby/warningIcon.svg";
import { useTranslation } from "react-i18next";
import AddAbilityPopup from "./AddAbilityPopup.js";
import { useGetAbilities } from "../../hooks/game-lobby/gameLobby.hooks.js";
import { mediaPath } from "../../configs/main.config.js";
import { Link } from "react-router-dom";

export default function CustomScenarioPopup({
  openCustomScenarioPopup,
  setOpenCustomScenarioPopup,
  submitForm,
  selectedGameInfo,
}) {
  const { t } = useTranslation();
  const [openAddAbilityPopup, setOpenAddAbilityPopup] = useState(false);
  const [value, setValue] = useState(0);
  const [play, setPlay] = useState(false);
  const [counts, setCounts] = useState({});
  const [youtubeVideoPath, setYoutubeVideoPath] = useState("");
  const [abilitiesSum, setAbilitiesSum] = useState(0);
  const [searchAbility, setSearchAbility] = useState("");
  const { minPlayer, maxPlayer } = selectedGameInfo;
  const minAbilities = minPlayer - 1;
  const maxAbilities = maxPlayer - 1;

  const { abilitiesData, createScenario } = useGetAbilities();

  useEffect(() => {
    const abilities = Object.values(counts).reduce(
      (acc, currentVal) => acc + currentVal,
      0,
    );
    setAbilitiesSum(abilities);
  }, [counts]);

  useEffect(() => {
    if (abilitiesData) {
      if (Object.keys(counts).length !== 0) {
        setCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          return updatedCounts;
        });
        return;
      }

      setCounts(
        abilitiesData?.reduce((acc, abilityData) => {
          acc[abilityData._id] = 0;
          return acc;
        }, {}),
      );
    }
  }, [abilitiesData, value]);

  const handleDecrease = (abilityId) => {
    if (counts[abilityId] > 0) {
      const newCounts = { ...counts };
      newCounts[abilityId] -= 1;
      setCounts(newCounts);
    }
  };

  const handleIncrease = (abilityId) => {
    if (abilitiesSum < maxAbilities) {
      const newCounts = { ...counts };
      newCounts[abilityId] += 1;
      setCounts(newCounts);
    }
  };

  const abilityBtns = [
    { typo: t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.ABILITIES_BTNS.ALL"), value: 0 },
    {
      typo: t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.ABILITIES_BTNS.MAFIA"),
      value: 1,
    },
    {
      typo: t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.ABILITIES_BTNS.CIVILIAN"),
      value: 2,
    },
    {
      typo: t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.ABILITIES_BTNS.INDEPENDENT"),
      value: 3,
    },
  ];

  const handleCreateScenario = () => {
    abilitiesSum >= minAbilities &&
      abilitiesSum <= maxAbilities &&
      createScenario(counts, abilitiesSum, submitForm);
  };

  const handleSearch = (e) => {
    setSearchAbility(e.target.value);
  };

  function handleChange(newValue) {
    setValue(newValue);
  }

  const handlePlayVideo = (videoPath) => {
    setPlay(true);
    setYoutubeVideoPath(`${videoPath}`);
  };

  const handleVideoClose = () => {
    setPlay(false);
  };

  const handleClose = () => {
    setOpenCustomScenarioPopup(false);
  };

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openCustomScenarioPopup}
        className="custom-scenario-popup-parent-wrapper"
      >
        {play && (
          <>
            <Dialog
              onClose={handleVideoClose}
              aria-labelledby="customized-dialog-title"
              open={play}
              className="custom-scenario-video-popup-box"
            >
              <iframe
                width="100%"
                height="100%"
                src={youtubeVideoPath}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Dialog>
          </>
        )}

        {openAddAbilityPopup && (
          <AddAbilityPopup
            openAddAbilityPopup={openAddAbilityPopup}
            setOpenAddAbilityPopup={setOpenAddAbilityPopup}
          />
        )}
        <Box className="popup-wrap">
          <Box className="popup-head">
            <DialogTitle className="popup-title" id="customized-dialog-title">
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="search-input-field"
                    placeholder={t(
                      "APP.LOBBY.CUSTOM_SCENARIO_POPUP.SEARCH_ABILITY",
                    )}
                    onChange={handleSearch}
                    InputProps={{
                      ...params.InputProps,
                      // type: "search",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        fontSize: "16px",
                        fontWeight: "300",
                        color: "var(--silverGray)",
                      },
                    }}
                  />
                )}
              />
            </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon className="cancel-icon" />
            </IconButton>
          </Box>

          <Box className="ability-btns-wrap">
            <Box className="ability-btns-box">
              {abilityBtns.map((abilityBtn, index) => (
                <Button
                  key={index}
                  className="ability-btn"
                  color={
                    abilityBtn.value === value ? "primary" : "midNightNavy"
                  }
                  variant="contained"
                  size="large"
                  onClick={() => handleChange(abilityBtn.value)}
                >
                  {abilityBtn.typo}
                </Button>
              ))}
            </Box>

            <Box className="ability-total-box">
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "var(--silverGray)",
                }}
              >
                {t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.TOTAL")}
              </Typography>

              <Box className="ability-count">
                <Typography className="ability-text">{abilitiesSum}</Typography>
              </Box>
            </Box>
          </Box>
          <DialogContent className="popup-middle">
            <Box className="abilities-wrap">
              {abilitiesData
                ?.filter((abilityDta) => {
                  const { side, ability } = abilityDta;

                  const lowerCaseSearch = searchAbility
                    .toLowerCase()
                    .trim()
                    .replace(/\s+/g, " ");

                  const lowerCaseAbility = `${t(
                    `APP.ABILITIES.${side}.${ability}.ABILITY`,
                  )}`
                    .toLowerCase()
                    .trim()
                    .replace(/\s+/g, " ");

                  const abilitySide = `${t(
                    `APP.ABILITIES.${side}.TITLE`,
                  )}`.toLowerCase();

                  // Check if the abilityData.side matches the selected button value
                  const sideMatches =
                    value === 0 ||
                    abilitySide === abilityBtns[value].typo.toLowerCase();

                  // Check if the abilityData.ability starts with the search input
                  const startsWithSearch =
                    lowerCaseAbility.startsWith(lowerCaseSearch);

                  return sideMatches && startsWithSearch;
                })
                ?.map((abilityData, index) => {
                  const {
                    _id,
                    side,
                    ability,
                    imagePath,
                    articlePath,
                    youtubePath,
                  } = abilityData;
                  const imgSrc = mediaPath + imagePath;
                  return (
                    <Box className="ability-box" key={index}>
                      <Box className="ability-data">
                        <img src={imgSrc} alt="custom-scenario-img" />
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "var(--silverGray)",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                color:
                                  side === "MAFIA"
                                    ? "var(--darkRed)"
                                    : side === "INDEPENDENT"
                                      ? "var(--goldenRod)"
                                      : "var(--darkGreen)",
                              }}
                            >
                              {t(`APP.ABILITIES.${side}.TITLE`)}
                            </span>{" "}
                            - {t(`APP.ABILITIES.${side}.${ability}.ABILITY`)}
                          </Typography>
                          {(articlePath || youtubePath) && (
                            <Box
                              sx={{
                                paddingTop: "5px",
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              {articlePath && (
                                <Link to={articlePath} target="_blank">
                                  <Button
                                    className="ability-data-btn"
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                  >
                                    {t(
                                      "APP.LOBBY.CUSTOM_SCENARIO_POPUP.LEARN_BTN",
                                    )}
                                  </Button>
                                </Link>
                              )}
                              {youtubePath && (
                                <Button
                                  onClick={() => handlePlayVideo(youtubePath)}
                                  className="ability-data-btn"
                                  color="lightGray"
                                  variant="contained"
                                  size="small"
                                  sx={{ color: "var(--white)" }}
                                >
                                  {t(
                                    "APP.LOBBY.CUSTOM_SCENARIO_POPUP.VIDEO_BTN",
                                  )}
                                </Button>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Box className="ability-count">
                        <Button onClick={() => handleDecrease(_id)}>-</Button>

                        <Typography
                          className="ability-text"
                          sx={{
                            width: "12px",
                          }}
                        >
                          {counts[_id]}
                        </Typography>

                        <Button onClick={() => handleIncrease(_id)}>+</Button>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </DialogContent>

          <DialogActions className="popup-bottom">
            <Typography
              onClick={() => setOpenAddAbilityPopup(true)}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "var(--silverGray)",
                cursor: "pointer",
              }}
            >
              {t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.REQUEST_NEW_ABILITY")}
            </Typography>

            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    display:
                      abilitiesSum >= minAbilities &&
                      abilitiesSum <= maxAbilities
                        ? "none"
                        : "",
                  },
                },
              }}
              title={
                abilitiesSum > maxAbilities
                  ? `${t(
                      "APP.LOBBY.CUSTOM_SCENARIO_POPUP.TOOLTIP_TITLE_MAX",
                    )} ${maxAbilities} ${t(
                      "APP.LOBBY.CUSTOM_SCENARIO_POPUP.TOOLTIP_TITLE_ABILITIES",
                    )}`
                  : `${t(
                      "APP.LOBBY.CUSTOM_SCENARIO_POPUP.TOOLTIP_TITLE_MIN",
                    )} ${minAbilities} ${t(
                      "APP.LOBBY.CUSTOM_SCENARIO_POPUP.TOOLTIP_TITLE_ABILITIES",
                    )}`
              }
              arrow
              placement="top"
            >
              <Box
                className="action-btn-box"
                sx={{
                  cursor:
                    abilitiesSum >= minAbilities && abilitiesSum <= maxAbilities
                      ? "pointer"
                      : "not-allowed",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    pointerEvents:
                      abilitiesSum >= minAbilities &&
                      abilitiesSum <= maxAbilities
                        ? ""
                        : "none",
                  }}
                  className="action-btn"
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleCreateScenario}
                >
                  {t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.CREATE_BTN")}
                </Button>
              </Box>
            </Tooltip>

            <Box className="ability-criteria">
              <WarningIcon />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color:
                    abilitiesSum < maxAbilities
                      ? "var(--silverGray)"
                      : "var(--darkRed)",
                  whiteSpace: "nowrap",
                }}
              >
                <span>{t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.MINIMUM")}</span>{" "}
                {minAbilities} -{" "}
                <span>{t("APP.LOBBY.CUSTOM_SCENARIO_POPUP.MAXIMUM")}</span>{" "}
                {maxAbilities}
              </Typography>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

CustomScenarioPopup.propTypes = {
  openCustomScenarioPopup: PropTypes.bool.isRequired,
  setOpenCustomScenarioPopup: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  selectedGameInfo: PropTypes.object.isRequired,
};
