import React, { useRef } from "react";
import { GridItem, Users } from "../../../styling/styles/grid.style.js";
import PropTypes from "prop-types";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import ActionsMenu from "../popups/ActionsMenu.js";
import { userPropMap } from "../Grids.js";
import wifiIcon from "../../../assets/icons/room/wifiIcon.svg";
import slowConnectionIcon from "../../../assets/icons/room/slowConnection.svg";
import disconnectIcon from "../../../assets/icons/room/disconnectIcon.svg";
import muteIcon from "../../../assets/icons/room/muteIcon.svg";
import nightModebg from "../../../assets/image/room/nightModeBg.webp";
import sleepIcon from "../../../assets/icons/room/actions/sleepIcon.svg";
import { useTranslation } from "react-i18next";
import godScreenCardIcon from "../../../assets/icons/room/godScreenCardIcon.svg";
import UserLocalGridCard from "./UserLocalGridCard.js";
import AudioDetector from "../audio-detector/AudioDetector.js";
import { handleAwakeSleep } from "../../../handlers/game-room/index.js";
import SmallAudioDetector from "../audio-detector/SmallAudioDetector.js";

const GridScreenCard = ({ stream, isAniamtion, isFooter, gridClass }) => {
  const { t } = useTranslation();
  const {
    currentSelectedPeer,
    setCurrentSelectedPeer,
    viewer,
    likeDislikeChallange,
    declinedPermssionUsers,
    isAdmin,
    roomData,
    isNightMode,
    micHiddenElems,
    abilityCardInfo,
    screenArray,
    peersRef,
    awakedPlayers,
    waitingForResponse,
    reconnectingUsers,
    socket,
    captureMediaStreams,
    setAudioLevel,
  } = stream;
  const actionBoxRef = useRef([]);
  const smallAnimatorRef = useRef({});
  const sleepBtnRef = useRef([]);
  const handleMouseEnter = (index) => {
    actionBoxRef.current[index - 1].style.display = "block";
    sleepBtnRef.current[index - 1].style.backgroundColor = "var(--primary)";
  };
  const handleMouseLeave = (index) => {
    actionBoxRef.current[index - 1].style.display = "none";
    sleepBtnRef.current[index - 1].style.backgroundColor = "var(--skyBlue)";
  };

  const handlePlayersNight = (func) => {
    if (func) {
      func(currentSelectedPeer);
    }
  };

  function getUserActionProp(socketId) {
    for (const userType in userPropMap) {
      if (likeDislikeChallange[userType].includes(socketId)) {
        return userPropMap[userType];
      }
    }
    return null;
  }
  let isWakedUser = awakedPlayers.includes(socket?.id);
  return screenArray.map((peer, idx) => {
    let userIndex = idx + 1;
    // close video will only avaible when user close/open video other video for himself
    let { socketId, username, userId, localUser, storageId, closeVideo, role } =
      peer;
    const mediaStream = captureMediaStreams[username];
    if (localUser) {
      return (
        <UserLocalGridCard
          key={username}
          stream={stream}
          gridClass={gridClass}
          isAniamtion={isAniamtion}
          userIndex={userIndex}
          isWakedUser={isWakedUser}
          mediaStream={mediaStream}
          smallAnimatorRef={smallAnimatorRef}
          username={username}
          role={role}
        />
      );
    } else {
      let userActionProp = getUserActionProp(socketId);
      const playerInfo = roomData.players?.find(
        (user) => user.username === username,
      );
      const isGod = playerInfo?.role === "GOD";
      const avatar = playerInfo?.avatar;
      const status = playerInfo?.status;
      const isWakedPlayer = awakedPlayers.includes(socketId);
      const isWaiting = waitingForResponse.includes(socketId);
      const isReconnecting = reconnectingUsers.includes(socketId);
      const name = isGod
        ? t("APP.GAME_ROOM.GRID_SCREEN_CARD.DEFAULT_NAME")
        : username;

      // to show abilites of mafia players
      let abilityInfo = abilityCardInfo.find(
        (ability) => ability.userId === userId,
      )?.ability;
      let isMafiaAbility = abilityInfo?.side === "MAFIA";

      let source;
      if (storageId && !closeVideo) {
        const currentPeer = peersRef.current.find(
          (obj) => obj?.storageId === storageId,
        )?.mediaStream;

        if (currentPeer?.active) {
          source = currentPeer;
        }
      }

      const isConnected = status === "CONNECTED";
      const isMute = micHiddenElems.includes(socketId);

      const audioStatusClass =
        !isConnected || isNightMode
          ? null
          : !isMute
            ? "audio-detector"
            : "mute-mic";
      const showMuteMic = audioStatusClass === "mute-mic";
      const showAudioDetector = audioStatusClass === "audio-detector";

      const videoClass = playerInfo?.isMobile ? "video-mobile" : "video";

      return (
        <GridItem
          shouldanimate={!isNightMode}
          key={username}
          onMouseOver={() => setCurrentSelectedPeer(socketId)}
          onMouseEnter={() => {
            handleMouseEnter(userIndex);
          }}
          onMouseLeave={() => {
            handleMouseLeave(userIndex);
          }}
          className={`${gridClass} item${userIndex} grid-item`}
        >
          <Users
            id={username + "-grid"}
            style={{
              height: isFooter && "100%",
              width: isFooter && "100%",
              border: `3px solid transparent`,
              borderRadius: "18px",
              transition: `border-color 0.2s ease`,
              borderColor: userActionProp
                ? userActionProp.borderColor
                : "transparent",
              ...(isNightMode &&
                isMafiaAbility && { border: "3px solid var(--darkRed)" }),
            }}
            className={`grid-alignment ${isAniamtion ? "gird-animation" : ""}`}
          >
            {/* {(isAdmin || !isNightMode) && !isReconnecting && (
              <Box className="user-connection-lost-wrap">
                <Box>
                  <Box
                    className="connection-lost-title"
                    id="customized-dialog-title"
                  >
                    <CircularProgress size={25} />
                  </Box>
                </Box>
              </Box>
            )} */}
            <video
              muted
              autoPlay
              playsInline
              className={videoClass}
              ref={(video) => {
                if (video) {
                  if (!video.srcObject && source) {
                    video.srcObject = source;
                  } else if (!source && video.srcObject) {
                    video.srcObject = null;
                  }
                }
              }}
            ></video>

            {isGod && (
              <Box className="god-screen-card-icon">
                <img src={godScreenCardIcon} alt="god-screen-card-icon" />
              </Box>
            )}
            <Box className="avatar-box">
              {!isNightMode || (isGod && isWakedUser) || isWakedPlayer ? (
                avatar && (
                  <img
                    className={`avatar grid-screen-avatar`}
                    src={avatar}
                    alt="avatar"
                  />
                )
              ) : (
                <img
                  className="night-mode-bg"
                  src={nightModebg}
                  alt="night"
                ></img>
              )}
            </Box>

            <Box
              sx={{
                visibility:
                  (isConnected && !isNightMode && isAdmin) || viewer
                    ? "visible"
                    : "hidden",
              }}
            >
              <ActionsMenu
                stream={stream}
                iteration={userIndex}
                actionBoxRef={actionBoxRef}
              />
            </Box>

            <Box
              className="sleep-wakeup-btn-wrap"
              sx={{
                visibility:
                  isConnected && isAdmin && isNightMode && isWakedPlayer
                    ? "visible"
                    : "hidden",
              }}
            >
              <Button
                ref={(element) =>
                  (sleepBtnRef.current[userIndex - 1] = element)
                }
                color="primary"
                variant="contained"
                size="large"
                className="sleep-wakeup-btn"
                onClick={() => handlePlayersNight(handleAwakeSleep)}
              >
                {t("APP.GAME_ROOM.GRID_SCREEN_CARD.SLEEP")}
              </Button>
            </Box>

            <Box
              className="sleep-wakeup-btn-wrap"
              sx={{
                visibility:
                  isConnected && isAdmin && isNightMode && !isWakedPlayer
                    ? "visible"
                    : "hidden",
              }}
            >
              <img src={sleepIcon} className="sleep-icon" alt="sleep-icon" />

              <Button
                className={`${isWaiting && "waiting-btn"} sleep-wakeup-btn `}
                color="primary"
                variant="contained"
                size="large"
                onClick={() => handlePlayersNight(handleAwakeSleep)}
              >
                {isWaiting ? (
                  <Box>
                    {t("APP.GAME_ROOM.GRID_SCREEN_CARD.WAITING")}{" "}
                    <CircularProgress size={8} />
                  </Box>
                ) : (
                  t("APP.GAME_ROOM.GRID_SCREEN_CARD.WAKE_UP")
                )}
              </Button>
            </Box>

            {userActionProp && !isNightMode && (
              <Box key={userActionProp.icon} className="user-actions-icons">
                <img src={userActionProp.icon} alt="like" />
              </Box>
            )}
            {isAdmin && (
              <Typography className="declined-text">
                {declinedPermssionUsers.mic.includes(socketId) &&
                declinedPermssionUsers.video.includes(socketId)
                  ? t("APP.GAME_ROOM.GRID_SCREEN_CARD.DECLINED_MIC_VIDEO") // "Declined to open mic & video"
                  : declinedPermssionUsers.mic.includes(socketId)
                    ? t("APP.GAME_ROOM.GRID_SCREEN_CARD.DECLINED_MIC") // "Declined to open mic"
                    : declinedPermssionUsers.video.includes(socketId)
                      ? t("APP.GAME_ROOM.GRID_SCREEN_CARD.DECLINED_VIDEO") // "Declined to open video"
                      : declinedPermssionUsers.wakeUp.includes(socketId)
                        ? t("APP.GAME_ROOM.GRID_SCREEN_CARD.DECLINED_WAKE_UP")
                        : ""}
              </Typography>
            )}
            <Box className="user-status-wrapper">
              <Box className="user-name-box">
                <img
                  src={
                    (isAdmin || !isNightMode) && isReconnecting
                      ? slowConnectionIcon
                      : isConnected ||
                          (isNightMode && !isAdmin && status === "DISCONNECTED")
                        ? wifiIcon
                        : disconnectIcon
                  }
                  alt="online"
                  style={{ width: "17px", height: "17px" }}
                ></img>
                <Typography color="var(--white)" fontSize={"14px"}>
                  {name}
                  {abilityInfo &&
                    isAdmin &&
                    " - " +
                      t(
                        `APP.ABILITIES.${abilityInfo?.side}.${abilityInfo?.ability}.ABILITY`,
                      )}
                </Typography>

                {showMuteMic ? (
                  <img src={muteIcon} alt="mute" width="13px" height="13px" />
                ) : (
                  showAudioDetector && (
                    <SmallAudioDetector
                      smallAnimatorRef={smallAnimatorRef}
                      username={username}
                    />
                  )
                )}
              </Box>

              {isConnected ||
              (isNightMode && !isAdmin && status === "DISCONNECTED") ? (
                ""
              ) : (
                <Typography sx={{ color: "var(--darkRed)", fontSize: "14px" }}>
                  {status
                    ? t(
                        `APP.GAME_ROOM.GRID_SCREEN_CARD.PLAYER_STATUS.${status}`,
                      )
                    : t(
                        `APP.GAME_ROOM.GRID_SCREEN_CARD.PLAYER_STATUS.DISCONNECTED`,
                      )}
                </Typography>
              )}
            </Box>
            {showAudioDetector && (
              <AudioDetector
                smallAnimatorRef={smallAnimatorRef}
                username={username}
                mediaStream={mediaStream}
                setAudioLevel={setAudioLevel}
                captureMediaStreams={captureMediaStreams}
              />
            )}
            {role !== "GOD" && (
              <Box className="grid-number-box">{userIndex}</Box>
            )}
          </Users>
        </GridItem>
      );
    }
  });
};
GridScreenCard.propTypes = {
  stream: PropTypes.any,
  isAniamtion: PropTypes.bool,
  isFooter: PropTypes.bool,
  gridClass: PropTypes.any.isRequired,
};

export default GridScreenCard;
