import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { emitEvents } from "../events/index.js";
import { eventListners } from "./evnetListnersEncapsulator.js";
import { hideGlobalLoader } from "../../../features/global/globalSlice.js";
import { setNavigateTo } from "../../../features/global/globalSlice.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
import { encodeParams } from "../../global/encodeDecodeUrl.js";
import getUserMetadata from "../../global/getUserMetadata.js";

let cleanups = () => {};
export const listenEvents = () => {
  const {
    socket,
    roomId,
    roomIdRef,
    firsTimeLoading,
    setRoomData,
    i18n,
    t,
    dispatch,
    location,
    viewer,
    user,
    password,
    type,
    startStatus,
    setIsGameStart,
    peersRef,
    setPeers,
    setIsNightMode,
    setMicHiddenElems,
    setAdminAudioMutedUsers,
    setAdminVideoMutedUsers,
    setLocalUser,
    setAwakedPlayers,
    setinvitePopup,
  } = params;
  const {
    privacy,
    mode,
    scenario,
    operationType,
    roomType,
    minPlayer,
    maxPlayer,
  } = location.state || {};
  const role = viewer ? "VIEWER" : "PLAYER";
  const metadata = getUserMetadata();
  let payload = {
    operationType: operationType ?? "joinRoom",
    roomId: roomIdRef.current,
    role,
    password,
    scenario,
    privacy,
    mode,
    minPlayer,
    maxPlayer,
    userId: user.id,
    username: user.username,
    type: roomType,
    browser: metadata.browser,
    browserVersion: metadata.browserVersion,
    os: metadata.os,
    device: metadata.device,
    isMobile: false,
  };
  socket.emit("roomOperation", payload);
  // listen for errors
  socket.on("roomError", (error) => {
    dispatch(hideGlobalLoader());
    const redirectToGameLobby = () => {
      let url = `/game-lobby?tab=create&value=`;
      if (viewer) {
        url += `2`;
      } else {
        url += `1`;
      }
      dispatch(setNavigateTo(url));
    };
    dispatch(
      alertDialog({
        icon: "error",
        title: t(`APP.ERROR_CODES.${error.code}`),
        onAction: redirectToGameLobby,
      }),
    );
  });
  socket.on("roomData", (data) => {
    const { players, senderSocketId } = data;
    setRoomData(data);
    const { micHiddenElems, adminAudioMutedUsers, adminVideoMutedUsers } =
      params;
    // "obj.username !== user.username || viewer " only allow viwer ,he might be kickout ,so he can se him as a viewer
    // item.socketId === socketId ,use to check if it is new connection
    const users = players.filter((obj) => {
      const { username, socketId } = obj;
      const condition =
        (obj.username !== user.username || viewer) &&
        !peersRef.current.some(
          (item) => item.username === username && item.socketId === socketId,
        );

      if (condition) {
        // to prevent icon change on refresh
        if (socketId) {
          if (!micHiddenElems.includes(socketId)) {
            setMicHiddenElems((prev) => [...prev, socketId]);
          }
          if (!adminAudioMutedUsers.includes(socketId)) {
            setAdminAudioMutedUsers((prev) => [...prev, socketId]);
          }
          if (!adminVideoMutedUsers.includes(socketId)) {
            setAdminVideoMutedUsers((prev) => [...prev, socketId]);
          }
        }
      }

      return condition;
    });
    // remove  user old object
    if (peersRef.current.length > 0) {
      peersRef.current = peersRef.current.filter(
        (obj) => !users.some((user) => user.username === obj.username),
      );
    }
    peersRef.current = [...peersRef.current, ...users];
    setPeers(peersRef.current);
    if (firsTimeLoading.current && senderSocketId === socket.id) {
      const { gameInProgress, players, state, wokePlayers } = data;
      if (!viewer) {
        const localUser = players.find(
          (item) => item.username === user.username,
        );
        if (localUser) {
          const { _id, role, username } = localUser;
          setLocalUser([
            {
              role,
              localUser: true,
              _id,
              username,
              avatar: user.avatar,
            },
          ]);
          if (role === "GOD") {
            // setting wokeplayers for admin to  prevent icons change on refresh
            const wokePlayersSocketIds = wokePlayers.map(
              (item) => item.socketId,
            );
            setAwakedPlayers(wokePlayersSocketIds);
          }
        }
      }
      // setting room status if user rejoin
      startStatus.current = gameInProgress;
      setIsGameStart(gameInProgress);
      if (!viewer && gameInProgress) {
        setinvitePopup(false);
      } else {
        setinvitePopup(true);
      }
      setIsNightMode(state === "NIGHT");

      if (!roomId) {
        roomIdRef.current = data.roomNumber;
        const pathName = `/${i18n.language}/room/`;
        const paramsObj = { i: data.roomNumber };
        type === "audio" && (paramsObj.t = "a");
        if (data.privacy === "PRIVATE") {
          paramsObj.p = data.password;
        }
        const encodedParams = encodeParams(paramsObj);
        window.history.replaceState(null, "", pathName + encodedParams);
      }
      dispatch(hideGlobalLoader());
      firsTimeLoading.current = false;
      emitEvents();
      cleanups = eventListners();
    }
  });
  // socket event listner cleanups
  return () => {
    cleanups();
    socket.off("roomData");
    socket.off("roomError");
  };
};
