import { useDispatch } from "react-redux";
import { setNavigateTo } from "../../features/global/globalSlice.js";
import { encodeParams } from "../../handlers/global/encodeDecodeUrl.js";
import { checkRoomPasswordApi } from "../../features/gameLobby/gameLobbyThunk.js";
import { useState } from "react";

let passwordTimeoutId;
export const useJoinRoom = () => {
  const dispatch = useDispatch();
  const [errorCode, setErrorCode] = useState("");
  const handleRoomOpperation = async ({
    role,
    roomId,
    operationType,
    privacy,
    mode,
    scenario,
    password,
    type,
    roomType,
    minPlayer,
    maxPlayer,
  }) => {
    const pathName = "/room/";
    if (operationType === "joinRoom") {
      const paramsObj = { i: roomId };

      if (role === "VIEWER") {
        paramsObj.v = "t";
      }
      if (password) {
        paramsObj.p = password;
      }
      if (type === "AUDIO") {
        paramsObj.t = "a";
      }
      if (mode === "AUTO") {
        paramsObj.m = "a";
      }
      const encodedParams = encodeParams(paramsObj);
      dispatch(
        setNavigateTo({
          path: pathName + encodedParams,
          state: {
            noTermsRequired: true,
          },
        }),
      );
    } else {
      const paramsObj = { t: "v" };
      if (roomType === "AUDIO") {
        paramsObj.t = "a";
      }
      const encodedParams = encodeParams(paramsObj);
      dispatch(
        setNavigateTo({
          path: pathName + encodedParams,
          state: {
            operationType,
            scenario,
            mode,
            privacy,
            roomType,
            minPlayer,
            maxPlayer,
            noTermsRequired: true,
          },
        }),
      );
    }
  };

  const handleCheckPassword = async ({
    roomId,
    password,
    passwordRequired,
    callback,
  }) => {
    try {
      passwordRequired &&
        (await dispatch(
          checkRoomPasswordApi({
            params: { roomId, password },
          }),
        ).unwrap());
      callback();
    } catch (error) {
      setErrorCode(error.code);
      clearTimeout(passwordTimeoutId);
      passwordTimeoutId = setTimeout(() => {
        setErrorCode("");
      }, 3000);
    }
  };

  return { handleRoomOpperation, handleCheckPassword, errorCode };
};
