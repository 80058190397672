import { useState } from "react";
import { Box, Typography } from "@mui/material";
import bg from "../../assets/image/login-background.webp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import warningIcon from "../../assets/icons/auth/warningIcon.svg";
import { PhoneInput, SubmitBtn } from "../../components/auth/common/index.js";
import { useHandleForgetPassword } from "../../hooks/auth/HandleForgetPassword.hook.js";
import { Logo } from "../../components/global/Logo.js";
import { LanguageBtn, SupportBtn } from "../../components/global/index.js";
const ForgotPassword = () => {
  const { t } = useTranslation();
  const [validation, setValidation] = useState([]);
  const [errorCode, setErrorCode] = useState(false);
  const { handleForgetPassword } = useHandleForgetPassword({
    setValidation,
    setErrorCode,
  });

  const submitForm = (e) => {
    e.preventDefault();
  };

  return (
    <Box
      noValidate
      component="form"
      onSubmit={submitForm}
      sx={{
        backgroundImage: `url(${bg})`,
      }}
      className="forgot-password-parent-wrap auth-pages-parent-wrapper"
    >
      <Box className="auth-header">
        <Logo />
        <Box sx={{ display: "flex", gap: "22px" }}>
          <SupportBtn />
          <LanguageBtn />
        </Box>
      </Box>

      <Box className="form-wrap">
        <Box className="form-box">
          <Typography
            color={"var(--white)"}
            sx={{
              fontSize: "25px",
              fontWeight: "700",
            }}
          >
            {t("APP.AUTH.FORGET_PASSWORD.FORGET_PASSWORD_TITLE")}
          </Typography>
          <Typography
            color={"var(--mutedGray)"}
            sx={{
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {t("APP.AUTH.FORGET_PASSWORD.FORGET_PASSWORD_INSTRUCTIONS")}
          </Typography>
          <Box sx={{ height: "30px", width: "100%" }}></Box>

          <Box className="forgot-form">
            <PhoneInput validation={validation} />

            {errorCode ? (
              <>
                <Box className="warning-alert">
                <img src={warningIcon} alt="warning" />
                {errorCode === 4009 ? (
                  <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                 {t("APP.AUTH.REGISTER.OTP_FAILED")}
                    <a
                      href="https://t.me/gorgine_support"
                      style={{ color: "var(--primary)" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("APP.SUBSCRIPTION.BODY.TELEGRAM")}
                    </a>
                    {t("APP.AUTH.REGISTER.OTP_FAILED_2")}
                  </Typography>

                ) : (
                  t(`APP.ERROR_CODES.${errorCode}`)
                )}
              </Box>
                <Box sx={{ height: "44px", width: "100%" }}></Box>
              </>
            ) : (
              <Box sx={{ height: "10px", width: "100%" }}></Box>
            )}

            <SubmitBtn
              handleSubmit={handleForgetPassword}
              screen="forgetPassword"
            />
            <Box sx={{ height: "23px", width: "100%" }}></Box>
            <Typography
              color={"var(--white)"}
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "18.75px",
              }}
            >
              {t("APP.AUTH.NOT_HAVE_ACCOUNT")}{" "}
              <Link className="link-hover" to="/register">
                {t("APP.AUTH.REGISTER_LINK_TEXT")}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
