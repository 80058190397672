import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
import { handleLeave } from "./handleLeave.js";
export const handleLeavePlayer = () => {
  const { dispatch, t } = params;

  dispatch(
    alertDialog({
      title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.PLAYER_LEAVE"),
      confirmButtonText: t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.YES"),
      cancelButtonText: t("APP.ALERT_DIALOGS.CANCEL_BTN.NO_STAY"),
      onConfirm: handleLeave,
    }),
  );
};
