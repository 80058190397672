export const rankedJoinRoomList = [
  {
    scenario: "COMMANDO",
    maxPlayer: 10,
    privacy: "PUBLIC",
    mode: "MANUAL",
    gameInProgress: false,
    isActive: true,
    type: "VIDEO",
    playersCount: 0,
    _id: "aJ0t",
  },
];
