import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const supportedLanguages = ["en", "fa"];
export const useInitialHandles = () => {
  const { i18n } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const getLanguage = ({ deafault }) => {
    const isIranianNumber = user.phoneNumber?.startsWith("+98");
    let lang = window.location.pathname.split("/")[1];
    if (!supportedLanguages.includes(lang) || !deafault) {
      lang = isIranianNumber ? "fa" : "en";
      const currentURL = new URL(window.location.href);
      if (deafault) {
        currentURL.pathname = lang + currentURL.pathname;
      } else {
        currentURL.pathname = currentURL.pathname.replace(
          /\/(en|fa)\//,
          `/${lang}/`,
        );
      }
      window.history.replaceState(null, "", currentURL.toString());
    } else {
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang);
      }
    }
    return lang;
  };

  return { getLanguage };
};
