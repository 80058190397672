import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import check from "../../../assets/icons/auth/check.svg";
import checkNotAvailable from "../../../assets/icons/auth/checkNotAvailable.svg";
import { useDispatch } from "react-redux";
import { checkUsernameApi } from "../../../features/auth/authThunks.js";
let timeOutId;
const UserNameInput = ({
  username,
  setUsername,
  isUserAvailble,
  setIsUserAvailble,
  validation,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUserName = (e) => {
    const userName = e.target.value.toLowerCase();
    setUsername(userName);
    clearTimeout(timeOutId);
    if (userName) {
      timeOutId = setTimeout(async () => {
        try {
          await dispatch(
            checkUsernameApi({ params: { username: userName } }),
          ).unwrap();
          setIsUserAvailble(true);
        } catch (error) {
          setIsUserAvailble(false);
        }
      }, 1000);
    }
  };

  return (
    <>
      <form autoComplete="off">
        <Box
          className="username-input-parent-wrap"
          sx={{ height: "103px", width: "100%" }}
        >
          <Typography
            color={"var(--white)"}
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "18.75px",
              mb: "7px",
            }}
          >
            {t("APP.AUTH.USER_NAME_INPUT.USER_NAME_LABEL")}
          </Typography>

          <FormControl
            error={validation.includes("username")}
            className={`${
              validation.includes("username")
                ? ""
                : `username-input-field username-form`
            }`}
            fullWidth
            variant="outlined"
            sx={{
              borderRadius: "5px",
              height: "50px",
              "& label": {
                color: "var(--mutedGray) ",
                fontSize: "12px",
                fontWeight: "500",
              },
            }}
          >
            <InputLabel
              className="username-form-label"
              htmlFor="outlined-adornment-password"
            >
              {t("APP.AUTH.USER_NAME_INPUT.ENTER_USER_NAME_PLACEHOLDER")}
            </InputLabel>
            <OutlinedInput
              onChange={handleUserName}
              sx={{
                color: "var(--white)",
                height: "50px",
                fontSize: "12px",
                fontWeight: "500",
                "& fieldset": {
                  color: "var(--primary)",
                },
              }}
              id="outlined-adornment-password"
              label={t("APP.AUTH.USER_NAME_INPUT.ENTER_USER_NAME_INPUT_LABEL")}
              inputProps={{
                maxLength: 12,
                autoComplete: "off",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <img
                    src={isUserAvailble && username ? check : checkNotAvailable}
                    alt="check"
                  />
                  :
                </InputAdornment>
              }
            />
            <FormHelperText
              sx={{
                visibility:
                  validation.includes("username") ||
                  validation.includes("usernameNotAvailble")
                    ? ""
                    : "hidden",
                ml: "14px",
                mr: "14px",
                mt: "5px",
                fontSize: "10px",
                fontWeight: "400",
              }}
              error
              id="accountId-error"
            >
              {validation.includes("usernameNotAvailble")
                ? t("APP.ERROR_CODES.4013")
                : validation.includes("username") &&
                  t("APP.AUTH.FIELD_REQUIRED")}
            </FormHelperText>
          </FormControl>
        </Box>
      </form>
    </>
  );
};

UserNameInput.propTypes = {
  username: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
  isUserAvailble: PropTypes.bool.isRequired,
  setIsUserAvailble: PropTypes.func.isRequired,
  validation: PropTypes.array.isRequired,
};

export default UserNameInput;
