import { useEffect } from "react";

export const useResizeAnimatorHook = ({
  setIsAnimation,
  animator,
  setisSmScreen,
  isOpenChat,
  screenArray,
}) => {
  useEffect(() => {
    let resizeTimer;
    setIsAnimation(true);
    animator();
    const handleResize = () => {
      setIsAnimation(false);
      setisSmScreen(window.innerWidth < 600);
      animator();
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setIsAnimation(true);
      }, 100);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenArray, isOpenChat, animator, setIsAnimation, setisSmScreen]);
};
