import React, { useRef, useState } from "react";
import chatIconNotification from "../../../assets/icons/room/footer/chatIcon.svg";
import chatIcon from "../../../assets/icons/room/footer/chatIconWithoutNotifiaction.svg";
import videoIcon from "../../../assets/icons/room/footer/videoIcon.svg";
import closeVideoIcon from "../../../assets/icons/room/footer/closeVideoIcon.svg";
import nightIcon from "../../../assets/icons/room/footer/nightIcon.svg";
import dayIcon from "../../../assets/icons/room/footer/dayIcon.svg";
import startIcon from "../../../assets/icons/room/footer/startIcon.svg";
import micImg from "../../../assets/icons/room/footer/micIcon.svg";
import inviteIcon from "../../../assets/icons/room/footer/dualUserIcon.svg";
import { ReactComponent as Challangeicon } from "../../../assets/icons/room/footer/challengeIcon.svg";
import { ReactComponent as Abilityicon } from "../../../assets/icons/room/footer/abilityIcon.svg";
import { ReactComponent as LikeIcon } from "../../../assets/icons/room/footer/likeIcon.svg";
import { ReactComponent as DislikeIcon } from "../../../assets/icons/room/footer/dislikeIcon.svg";
import leaveIcon from "../../../assets/icons/room/footer/leaveIconForSm.svg";
import readyToPlay from "../../../assets/image/room/footer/openReadyToplaySm.webp";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import InvitePopup from "../popups/InvitePopup.js";
import VideoAudioForAll from "./admin-actions/Video&AudioForAll.js";
import { alertDialog } from "../../../features/global/globalSlice.js";
import endGameIcon from "../../../assets/icons/room/footer/endGameIcon.svg";
import { handleEndGame } from "../../../handlers/game-room/helper-functions/handleGameEnd.js";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  handleMic,
  handleVideo,
  handleSendLike,
  handleSendDislike,
  handleSendChallenge,
  handleStartGame,
  handleDayNight,
} from "../../../handlers/game-room/index.js";
import { useTranslation } from "react-i18next";
import { KeyboardArrowUp } from "@mui/icons-material";
import AudioSourceList from "../elements/AudioSourceList.js";
let timeOut;
let lastNotifiedMessages = 0;
const Middle = ({
  showPlayPopup,
  stream,
  setIsOpenChat,
  isOpenChat,
  setShowPlayPopup,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    isAdmin,
    micStatus,
    videoStatus,
    isGameStart,
    isNightMode,
    messages,
    viewer,
    handleLeavePlayer,
    adminAudioMuted,
    adminVideoMuted,
    type,
    userPersonalAction,
    invitePopup,
    setinvitePopup,
    mediaAllowedTooltip,
    teachComponentsRef,
    mode,
    isGameEnded,
    playerAbility,
    setAbilityIconDistance,
    audioProducer,
  } = stream;

  const [openTooltip, setOpenTooltip] = useState({
    audio: false,
    video: false,
  });

  // to close popup after 30 seconds
  useEffect(() => {
    if (invitePopup) {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        setinvitePopup(false);
      }, 15 * 1000);
    }
  }, [invitePopup]);

  useEffect(() => {
    isGameStart && setinvitePopup(false);
  }, []);

  function handleStartBtn() {
    if (isGameStart) {
      const modeChange = isNightMode
        ? t("APP.GAME_ROOM.FOOTER.MIDDLE.DAY")
        : t("APP.GAME_ROOM.FOOTER.MIDDLE.NIGHT");
      dispatch(
        alertDialog({
          title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.DAY_NIGHT_MODE").replace(
            "❔",
            modeChange.toLowerCase(),
          ),
          confirmButtonText: t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.YES"),
          cancelButtonText: t("APP.ALERT_DIALOGS.CANCEL_BTN.CANCEL"),
          onConfirm: handleDayNight,
        }),
      );
    } else {
      dispatch(
        alertDialog({
          title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.START_GAME"),
          confirmButtonText: t("APP.ALERT_DIALOGS.CONFIRMATION_BTN.YES"),
          cancelButtonText: t("APP.ALERT_DIALOGS.CANCEL_BTN.CANCEL"),
          onConfirm: handleStartGame,
        }),
      );
    }
  }
  let startBtnIconSrc;
  if (isGameStart) {
    if (isNightMode) {
      startBtnIconSrc = dayIcon;
    } else {
      startBtnIconSrc = nightIcon;
    }
  } else {
    startBtnIconSrc = startIcon;
  }

  const handleChat = () => {
    setIsOpenChat((pre) => !pre);
  };
  const role = viewer ? "viewers" : "players";
  const [isChatNotification, setIsChatNotification] = useState(false);
  useEffect(() => {
    if (!isOpenChat) {
      if (messages[role].length > lastNotifiedMessages) {
        setIsChatNotification(true);
        lastNotifiedMessages = messages[role].length;
      }
    } else {
      setIsChatNotification(false);
      lastNotifiedMessages = messages[role].length;
    }
  }, [messages, isOpenChat, role]);

  let chaticonSrc = isChatNotification ? chatIconNotification : chatIcon;

  const isDisableActionIcons = !isAdmin && !viewer && isNightMode;

  const abilityIconRef = useRef(null);

  useEffect(() => {
    const calculateDistanceFromRight = () => {
      if (abilityIconRef.current) {
        const boundingRect = abilityIconRef.current.getBoundingClientRect();
        const distanceFromRight =
          window.innerWidth - (boundingRect.right - boundingRect.width / 2);
        setAbilityIconDistance(distanceFromRight);
      }
    };

    // Initial calculation
    calculateDistanceFromRight();

    window.addEventListener("resize", calculateDistanceFromRight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateDistanceFromRight);
    };
  }, [playerAbility, showPlayPopup]);
  const micIconRef = useRef(null);
  const [audioSourceSelect, setAudioSourceSelect] = useState(false);
  const handleAudioSource = () => {
    setAudioSourceSelect((prev) => !prev);
  };

  return (
    <>
      <InvitePopup
        invitePopup={invitePopup}
        setinvitePopup={setinvitePopup}
        stream={stream}
      />
      <Box className="middle-main-box">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              [theme.breakpoints.down("sm")]: {
                justifyContent: !viewer || isAdmin ? "start" : "center",
              },
            }}
            className="middle-icons"
          >
            {/* className="footer-icon-wrapper" */}

            {!viewer && (
              <>
                {mode !== "AUTO" && (
                  <Box ref={micIconRef} className="mic-footer-icon-wrap">

                    <Tooltip
                      onMouseEnter={() => {
                        (adminAudioMuted || isDisableActionIcons) &&
                          setOpenTooltip((prev) => ({
                            ...prev,
                            audio: true,
                          }));
                      }}
                      onMouseLeave={() =>
                        setOpenTooltip((prev) => ({
                          ...prev,
                          audio: false,
                        }))
                      }
                      open={
                        mediaAllowedTooltip["audio"] ||
                        mediaAllowedTooltip["audioMuted"] ||
                        openTooltip["audio"]
                      }
                      title={t(
                        `APP.GAME_ROOM.FOOTER.MIDDLE.ADMIN_${mediaAllowedTooltip["audioMuted"] || mediaAllowedTooltip["wait"] ? "MUTED" : mediaAllowedTooltip["audio"] ? "ENABLED" : "DIABLED"}_AUDIO_TOOLTIP`,
                      )}
                      arrow
                      placement="top"
                    >

                      <Box
                        ref={(elem) =>
                          (teachComponentsRef.current = {
                            ...teachComponentsRef.current,
                            mic: elem,
                          })
                        }
                        sx={{
                          cursor:
                            adminAudioMuted || isDisableActionIcons
                              ? "not-allowed"
                              : "",
                          paddingRight: '10px',
                          display: 'flex',
                          alignItems: 'center', 
                        }}
                      >
                    <Box
                        sx={{
                          display: audioProducer.current ? 'block' : 'none',
                          pointerEvents:
                            adminAudioMuted || isDisableActionIcons
                              ? "none"
                              : "",
                        }}
                    >
                      <IconButton
                        color="white"
                        onClick={handleAudioSource}
                        edge="end"
                        size="small"
                        sx={{
                          position: 'relative',
                          top: '-20px',
                          left: "10px",
                        }}
                      >
                        <KeyboardArrowUp
                          className={`rotate-icon ${audioSourceSelect ? "rotated" : ""}`}
                          color="white"
                        />
                      </IconButton>
                    </Box>
                        <Box
                          onClick={() => handleMic()}
                          sx={{
                            pointerEvents:
                              adminAudioMuted || isDisableActionIcons
                                ? "none"
                                : "",
                          }}
                          className="footer-icon-wrapper"
                        >
                          <Box>
                            <Box
                              className={`footer-icon-box ${
                                micStatus ? "" : `stop stop-mic-all`
                              }
                }`}
                            >
                              <img src={micImg} alt="mic" />
                            </Box>
                          </Box>
                          <Typography
                            color={"var(--white)"}
                            fontWeight={300}
                            sx={{
                              fontSize: "11px",
                            }}
                          >
                            {micStatus
                              ? t("APP.GAME_ROOM.FOOTER.MIDDLE.MUTE")
                              : t("APP.GAME_ROOM.FOOTER.MIDDLE.UNMUTE")}
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  </Box>
                )}
                {type !== "audio" && (
                  <Tooltip
                    onMouseEnter={() => {
                      (adminVideoMuted || isDisableActionIcons) &&
                        setOpenTooltip((prev) => ({
                          ...prev,
                          video: true,
                        }));
                    }}
                    onMouseLeave={() =>
                      setOpenTooltip((prev) => ({
                        ...prev,
                        video: false,
                      }))
                    }
                    open={
                      mediaAllowedTooltip["video"] ||
                      mediaAllowedTooltip["videoMuted"] ||
                      openTooltip["video"]
                    }
                    title={t(
                      `APP.GAME_ROOM.FOOTER.MIDDLE.ADMIN_${mediaAllowedTooltip["videoMuted"] || mediaAllowedTooltip["wait"] ? "MUTED" : mediaAllowedTooltip["video"] ? "ENABLED" : "DIABLED"}_VIDEO_TOOLTIP`,
                    )}
                    arrow
                    placement="bottom"
                  >
                    <Box
                      ref={(elem) =>
                        (teachComponentsRef.current = {
                          ...teachComponentsRef.current,
                          video: elem,
                        })
                      }
                      sx={{
                        cursor:
                          adminVideoMuted || isDisableActionIcons
                            ? "not-allowed"
                            : "",
                      }}
                    >
                      <Box
                        sx={{
                          pointerEvents:
                            adminVideoMuted || isDisableActionIcons
                              ? "none"
                              : "",
                        }}
                        onClick={() => handleVideo()}
                        className="footer-icon-wrapper"
                      >
                        <Box>
                          <Box className="footer-icon-box">
                            <img
                              src={videoStatus ? videoIcon : closeVideoIcon}
                              alt="video"
                            />
                          </Box>
                        </Box>
                        <Typography
                          color={"var(--white)"}
                          fontWeight={300}
                          sx={{
                            fontSize: "11px",
                          }}
                        >
                          {videoStatus
                            ? t("APP.GAME_ROOM.FOOTER.MIDDLE.STOP_VIDEO")
                            : t("APP.GAME_ROOM.FOOTER.MIDDLE.START_VIDEO")}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>
                )}

                <Box className="vertical-line-for-sm"></Box>
                {!isAdmin && (
                  <Box
                    onClick={() => {
                      setShowPlayPopup(true);
                    }}
                    sx={{
                      [theme.breakpoints.up("sm")]: {
                        display: "none",
                      },
                    }}
                  >
                    <img src={readyToPlay} alt="readyToplay" />
                  </Box>
                )}
              </>
            )}
            {isAdmin && isGameStart && !isGameEnded && !isNightMode && (
              <>
                <Box onClick={handleEndGame} className="footer-icon-wrapper">
                  <Box>
                    <Box className="footer-icon-box">
                      <img src={endGameIcon} alt="endGameIcon" />
                    </Box>
                  </Box>
                  <Typography
                    color={"var(--darkRed)"}
                    fontWeight={300}
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    {t("APP.GAME_ROOM.FOOTER.END_GAME")}
                  </Typography>
                </Box>
              </>
            )}
            {isAdmin && !isGameEnded && (
              <>
                <Box onClick={handleStartBtn} className="footer-icon-wrapper">
                  <Box>
                    <Box className="footer-icon-box">
                      <img src={startBtnIconSrc} alt="start" />
                    </Box>
                  </Box>
                  <Typography
                    color={"var(--darkGreen)"}
                    fontWeight={300}
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    {isGameStart
                      ? isNightMode
                        ? t("APP.GAME_ROOM.FOOTER.MIDDLE.DAY")
                        : t("APP.GAME_ROOM.FOOTER.MIDDLE.NIGHT")
                      : t("APP.GAME_ROOM.FOOTER.MIDDLE.START_GAME")}
                  </Typography>
                </Box>
              </>
            )}
            {mode !== "AUTO" && (
              <Box sx={{ cursor: isDisableActionIcons ? "not-allowed" : "" }}>
                <Box
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      display: isAdmin ? "" : "none !important",
                    },
                    pointerEvents: isDisableActionIcons ? "none" : "",
                  }}
                  onClick={handleChat}
                  className="footer-icon-wrapper"
                >
                  <Box>
                    <Box className="footer-icon-box">
                      <img src={chaticonSrc} alt="conmment" />
                    </Box>
                  </Box>
                  <Typography
                    color={"var(--white)"}
                    fontWeight={300}
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    {t("APP.GAME_ROOM.FOOTER.MIDDLE.CHAT")}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box>
              <Box
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    display: isAdmin ? "" : "none !important",
                  },
                }}
                onClick={() => {
                  setinvitePopup(true);
                }}
                className="footer-icon-wrapper"
              >
                <Box>
                  <Box className="footer-icon-box">
                    <img src={inviteIcon} alt="invite" />
                  </Box>
                </Box>
                <Typography
                  color={"var(--white)"}
                  fontWeight={300}
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  {t("APP.GAME_ROOM.FOOTER.MIDDLE.INVITE")}
                </Typography>
              </Box>
            </Box>
            <VideoAudioForAll stream={stream} isMiddle={true} />
            {!isAdmin && !viewer && (
              <>
                <Box
                  sx={{
                    cursor: isDisableActionIcons ? "not-allowed" : "",
                  }}
                >
                  <Box
                    onClick={handleSendChallenge}
                    className="footer-icon-wrapper"
                    sx={{
                      pointerEvents: isDisableActionIcons ? "none" : "",
                    }}
                  >
                    <Box>
                      <Box className="footer-icon-box">
                        {/* <img src={challangeicon} alt="challangeicon" /> */}
                        <Challangeicon
                          className={`${
                            userPersonalAction === "challengeUsers" &&
                            "challenge-icon-svg"
                          }`}
                        />
                      </Box>
                    </Box>
                    <Typography
                      color={"var(--white)"}
                      fontWeight={300}
                      sx={{
                        fontSize: "11px",
                      }}
                    >
                      {t("APP.GAME_ROOM.FOOTER.MIDDLE.CHALLENGE")}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    cursor: isDisableActionIcons ? "not-allowed" : "",
                  }}
                >
                  <Box
                    sx={{
                      pointerEvents: isDisableActionIcons ? "none" : "",
                    }}
                    onClick={handleSendLike}
                    className="footer-icon-wrapper"
                  >
                    <Box>
                      <Box className="footer-icon-box">
                        {/* <img src={likeIcon} alt="likeIcon" /> */}
                        <LikeIcon
                          className={`${
                            userPersonalAction === "likeUsers" &&
                            "like-icon-svg"
                          }`}
                        />
                      </Box>
                    </Box>
                    <Typography
                      color={"var(--white)"}
                      fontWeight={300}
                      sx={{
                        fontSize: "11px",
                      }}
                    >
                      {t("APP.GAME_ROOM.FOOTER.MIDDLE.LIKE")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    cursor: isDisableActionIcons ? "not-allowed" : "",
                  }}
                >
                  <Box
                    sx={{
                      pointerEvents: isDisableActionIcons ? "none" : "",
                    }}
                    onClick={handleSendDislike}
                    className="footer-icon-wrapper"
                  >
                    <Box>
                      <Box className="footer-icon-box">
                        {/* <img src={dislikeIcon} alt="dislikeIcon" /> */}
                        <DislikeIcon
                          className={`${
                            userPersonalAction === "dislikeUsers" &&
                            "dislike-icon-svg"
                          }`}
                        />
                      </Box>
                    </Box>
                    <Typography
                      color={"var(--white)"}
                      fontWeight={300}
                      sx={{
                        fontSize: "11px",
                      }}
                    >
                      {t("APP.GAME_ROOM.FOOTER.MIDDLE.DISLIKE")}
                    </Typography>
                  </Box>
                </Box>
                {mode !== "AUTO" && (
                  <Box
                    sx={{
                      cursor: isDisableActionIcons ? "not-allowed" : "",
                    }}
                  >
                    <Box
                      onClick={handleChat}
                      className={`footer-icon-wrapper chat-icon-for-sm`}
                      sx={{
                        pointerEvents: isDisableActionIcons ? "none" : "",
                      }}
                    >
                      <Box>
                        <Box className="footer-icon-box">
                          <img src={chaticonSrc} alt="conmment" />
                        </Box>
                      </Box>
                      <Typography
                        color={"var(--white)"}
                        fontWeight={300}
                        sx={{
                          fontSize: "11px",
                        }}
                      >
                        {t("APP.GAME_ROOM.FOOTER.MIDDLE.CHAT")}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box>
                  <Box>
                    <Box
                      onClick={() => {
                        setinvitePopup(true);
                      }}
                      className={`footer-icon-wrapper invite-icon-for-sm`}
                    >
                      <Box>
                        <Box className="footer-icon-box">
                          <img src={inviteIcon} alt="invite" />
                        </Box>
                      </Box>
                      <Typography
                        color={"var(--white)"}
                        fontWeight={300}
                        sx={{
                          fontSize: "11px",
                        }}
                      >
                        {t("APP.GAME_ROOM.FOOTER.MIDDLE.INVITE")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {!viewer && !isAdmin && playerAbility && (
              <Box>
                <Box
                  onClick={() => {
                    setShowPlayPopup(true);
                  }}
                  className="footer-icon-wrapper"
                  sx={{
                    pointerEvents: isDisableActionIcons ? "none" : "",
                  }}
                >
                  <Box>
                    <Box
                      ref={abilityIconRef}
                      className="footer-icon-box ability-icon-svg"
                    >
                      <Abilityicon />
                    </Box>
                  </Box>
                  <Typography
                    color={"var(--white)"}
                    fontWeight={300}
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    {t("APP.GAME_ROOM.FOOTER.MIDDLE.ABILITY")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box className="leave-btn-wrap-for-sm">
            <Box className="leave-btn-for-sm" onClick={handleLeavePlayer}>
              <Box className="footer-icon-box">
                <img src={leaveIcon} alt="leave" />
              </Box>
            </Box>
          </Box>
        </Box>
        <AudioSourceList {...{ open: audioSourceSelect,setOpen:setAudioSourceSelect,micIconRef ,stream}} />
      </Box>
    </>
  );
};
Middle.propTypes = {
  stream: PropTypes.object.isRequired,
  setIsOpenChat: PropTypes.func.isRequired,
  isOpenChat: PropTypes.bool.isRequired,
  setShowPlayPopup: PropTypes.func.isRequired,
  showPlayPopup: PropTypes.bool.isRequired,
};

export default Middle;
