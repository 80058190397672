import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Button,
  Drawer,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Tooltip,
} from "@mui/material";
import { ReactComponent as CancelIcon } from "../../assets/icons/lobby/cancelIcon.svg";
import playIcon from "../../assets/icons/lobby/playIcon.svg";
import addIcon from "../../assets/icons/lobby/addIcon.svg";
import assistIcon from "../../assets/icons/lobby/assistIcon.svg";
import subscribeIcon from "../../assets/icons/lobby/subscribeIcon.svg";
import termsAndConditionIcon from "../../assets/icons/lobby/termsAndCondition.svg";
import privacyPolicy from "../../assets/icons/lobby/privacyPolicy.svg";
import logoutIcon from "../../assets/icons/lobby/logoutIcon.svg";
import { useTranslation } from "react-i18next";
import { useHandleLogout } from "../../hooks/auth/HandleLogout.hook.js";
import { useSelector } from "react-redux";
import ProfileAvatar from "./profile/ProfileAvatar.js";
import { useDispatch } from "react-redux";
import { setNavigateTo } from "../../features/global/globalSlice.js";
const isRankedOff = process.env.REACT_APP_RANKED_FEATURE_FLAG === "true";
export const ProfileDrawer = ({ setGameMode, screen }) => {
  const { user } = useSelector((state) => state.auth);
  const { handleLogout } = useHandleLogout();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [toggleMenu, settoggleMenu] = useState({ right: false });
  const listItems = [
    {
      icon: playIcon,
      iconAlt: "play-icon",
      text: t("APP.LOBBY.PROFILE_DRAWER.PLAY_MAFIA"),
      tab: "join",
      func: () => {
        screen === "gameLobby" && setGameMode("join");
        dispatch(setNavigateTo(`/game-lobby?tab=join`));
      },
    },
    {
      icon: addIcon,
      iconAlt: "add-icon",
      text: t("APP.LOBBY.PROFILE_DRAWER.CREATE_ROOM"),
      tab: "create",
      func: () => {
        screen === "gameLobby" && setGameMode("create");
        dispatch(setNavigateTo(`/game-lobby?tab=create`));
      },
    },
    {
      icon: assistIcon,
      iconAlt: "assist-icon",
      text: t("APP.LOBBY.PROFILE_DRAWER.GOD_ASSIST"),
      tab: "assist",
      func: () => {
        screen === "gameLobby" && setGameMode("assist");
        dispatch(setNavigateTo(`/game-lobby?tab=assist`));
      },
    },
  ];
  const toggleDrawer = (anchor, open, func) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    settoggleMenu({ ...toggleMenu, [anchor]: open });
    if (func) {
      func();
    }
  };

  const list = (anchor) => (
    <Box
      className="profile-drawer-list"
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box className="drawer-profile-id">
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <ProfileAvatar />

          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                color: "var(--white)",
              }}
            >
              {user?.username}
            </Typography>

            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "300",
                color: "var(--mutedGray)",
                marginTop: "-3px",
              }}
            >
              {t("APP.LOBBY.PROFILE_DRAWER.MODE")}
            </Typography>
          </Box>
        </Box>
        <CancelIcon
          className="cancel-icon"
          onClick={toggleDrawer(anchor, false)}
        />
      </Box>
      <List sx={{ marginTop: "23px", padding: "0" }}>
        {listItems.map((listItem, index) => {
          const { tab } = listItem;
          const notAllowed =
            tab === "assist" || (tab === "join" && isRankedOff);
          return (
            <Tooltip
              disableHoverListener={!notAllowed}
              key={index}
              title={t("APP.LOBBY.PROFILE_DRAWER.TOOLTIP_TITLE")}
              arrow
              placement="left"
            >
              <ListItem
                key={index}
                sx={{
                  padding: "0",
                  cursor: notAllowed ? "not-allowed" : "pointer",
                }}
              >
                <ListItemButton
                  sx={{
                    pointerEvents: notAllowed ? "none" : "auto",
                  }}
                  onClick={toggleDrawer(anchor, false, listItem.func)}
                  className="menu-item-box"
                >
                  <ListItemIcon className="menu-item-icon">
                    <img src={listItem.icon} alt={listItem.iconAlt} />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item-text"
                    primary={listItem.text}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>

      <Divider className="items-divider-line" />
      <List sx={{ marginTop: "2px", padding: "0" }}>
        <ListItem sx={{ padding: "0" }}>
          <ListItemButton
            onClick={toggleDrawer(anchor, false, () => {
              screen !== "subscription" &&
                dispatch(setNavigateTo("/subscription"));
            })}
            className="menu-item-box"
          >
            <ListItemIcon className="menu-item-icon">
              <img src={subscribeIcon} alt="subscribe-icon" />
            </ListItemIcon>
            <ListItemText
              className="menu-item-text"
              primary={t("APP.LOBBY.PROFILE_DRAWER.SUBSCRIPTION")}
            />

            <span className="span-moons">
              {t("APP.LOBBY.PROFILE_DRAWER.MOONS")}
            </span>
          </ListItemButton>
        </ListItem>
      </List>

      <Divider className="items-divider-line" />
      <List sx={{ marginTop: "2px", padding: "0" }}>
        <ListItem sx={{ padding: "0" }}>
          <ListItemButton
            onClick={() => {
              window.open(
                "https://gorgine.com/privacy-policy-en/",
                "_blank",
                "noopener,noreferrer",
              );
            }}
            className="menu-item-box"
          >
            <ListItemIcon className="menu-item-icon">
              <img src={privacyPolicy} alt="privacy-icon" />
            </ListItemIcon>
            <ListItemText
              className="menu-item-text"
              primary={t("APP.LOBBY.PROFILE_DRAWER.PRIVACY_POLICY")}
            />
          </ListItemButton>
        </ListItem>
      </List>

      <List sx={{ marginTop: "2px", padding: "0" }}>
        <ListItem sx={{ padding: "0" }}>
          <ListItemButton
            onClick={() => {
              window.open(
                "https://gorgine.com/terms-and-conditions-en/",
                "_blank",
                "noopener,noreferrer",
              );
            }}
            className="menu-item-box"
          >
            <ListItemIcon className="menu-item-icon">
              <img src={termsAndConditionIcon} alt="terms-icon" />
            </ListItemIcon>
            <ListItemText
              className="menu-item-text"
              primary={t("APP.LOBBY.PROFILE_DRAWER.TERMS_AND_CONDITION")}
            />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider className="items-divider-line" />
      <List sx={{ marginTop: "2px", padding: "0" }}>
        <ListItem sx={{ padding: "0" }}>
          <ListItemButton
            onClick={toggleDrawer(anchor, false, handleLogout)}
            className="menu-item-box"
          >
            <ListItemIcon className="menu-item-icon">
              <img src={logoutIcon} alt="logout-icon" />
            </ListItemIcon>
            <ListItemText
              className="menu-item-text"
              primary={t("APP.LOBBY.PROFILE_DRAWER.LOGOUT")}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Box className="profile-drawer-parent-wrap">
        <Button
          className="drawer-avatar-btn"
          onClick={toggleDrawer("right", true)}
        >
          <ProfileAvatar useAs="avatar-btn" />
        </Button>
        <Drawer
          anchor={"right"}
          open={toggleMenu["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </Box>
    </>
  );
};

ProfileDrawer.propTypes = {
  setGameMode: PropTypes.func,
  screen: PropTypes.string,
};
