import React, { useState, useEffect } from "react";
import { Header } from "../../components/global/Header.js";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles/index.js";
import { SubscriptionCard } from "../../components/subscription/SubscriptionCard.js";
import { SubscriptionQuestions } from "../../components/subscription/SubscriptionQuestions.js";
// import { SubscribedPlan } from "../components/subscription/SubscribedPlan.js";
import CopiedPopup from "../../components/subscription/CopiedPopup.js";
import { useTranslation } from "react-i18next";
import { useHandleSubscription } from "../../hooks/subscription/HandleSubscription.hook.js";
import { useSelector } from "react-redux";

let timeOut;
export const Subscription = () => {
  const { user } = useSelector((state) => state.auth);
  const isIranianNumber = user.phoneNumber.startsWith("+98");
  const defaultCurrency = isIranianNumber ? "IRT" : "USD";
  const theme = useTheme();
  const { t } = useTranslation();
  const storedCurrency = localStorage.getItem("selectedCurrency");
  const initialCurrency = storedCurrency || defaultCurrency;
  const [currency, setCurrency] = useState(initialCurrency);
  const [copiedPopup, setCopiedPopup] = useState(false);

  const plans = [
    "free",
    "referral",
    "basic",
    "standard",
    "pro",
    "extreme",
    "mega",
  ];

  const { handleSubscription } = useHandleSubscription(currency);

  const handleCurrencyChange = (event, value) => {
    if (value !== null && value !== currency) {
      setCurrency(value);
      localStorage.setItem("selectedCurrency", value);
    }
  };

  const handleCopyReferral = () => {
    const inviteLink =
      window.location.origin + "/register?referralCode=" + user.referralCode;
    setCopiedPopup(true);
    navigator.clipboard.writeText(inviteLink);
  };

  useEffect(() => {
    if (copiedPopup) {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        setCopiedPopup(false);
      }, 15 * 1000);
    }
  }, [copiedPopup]);

  return (
    <>
      <Box className="subscription-parent-wrapper">
        <Box className="container">
          <Header screen="subscription" />
          <Box className="subs-body">
            <Box className="headings">
              <Typography
                sx={{
                  fontSize: "48px",
                  fontWeight: "800",
                  color: "var(--blueGray)",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "30px",
                  },
                }}
              >
                {t("APP.SUBSCRIPTION.BODY.PURCHASE_SUBSCRIPTION")}
              </Typography>

              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "30px",
                  color: "var(--stormyBlue)",
                  marginTop: "8px",
                }}
              >
                {t("APP.SUBSCRIPTION.BODY.CHOOSE_PLAN")}
              </Typography>
            </Box>

            {/* <Box sx={{ marginTop: "60px", marginBottom: "90px" }}>
              {" "}
              <SubscribedPlan />{" "}
            </Box> */}

            <ToggleButtonGroup
              className="toggle-button-wrapper"
              value={currency}
              exclusive
              onChange={handleCurrencyChange}
              aria-label="mode"
              size="large"
              sx={{ marginTop: "65px" }}
            >
              <ToggleButton className="toggle-button" value="USD">
                {t("APP.SUBSCRIPTION.BODY.TOGGLE_BUTTON_DOLLAR")}
              </ToggleButton>
              <ToggleButton className="toggle-button" value="IRT">
                {t("APP.SUBSCRIPTION.BODY.TOGGLE_BUTTON_RIAL")}
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ height: "48px", width: "100%" }}></Box>

            <Box className="cards">
              {plans.map((plan, index) => {
                return (
                  <SubscriptionCard
                    key={index}
                    plan={plan}
                    handleSubscription={handleSubscription}
                    currency={currency}
                    handleCopyReferral={handleCopyReferral}
                  />
                );
              })}
            </Box>
            <Box
              sx={{
                height: "160px",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  height: "110px",
                },
              }}
            ></Box>
            <Box
              sx={{
                padding: "0px 64px",
                [theme.breakpoints.down("sm")]: {
                  padding: "0px 14px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: "800",
                  lineHeight: "45px",
                  color: "var(--blueGray)",
                }}
              >
                {t("APP.SUBSCRIPTION.BODY.ASKED_QUESTIONS")}
              </Typography>
              <Typography
                sx={{
                  marginTop: "16px",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "27px",
                  color: "var(--skyBlueGray)",
                }}
              >
                {t("APP.SUBSCRIPTION.BODY.FIND_ANSWERS")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "27px",
                  color: "var(--skyBlueGray)",
                }}
              >
                {t("APP.SUBSCRIPTION.BODY.MESSAGE")}{" "}
                <a
                  href="https://t.me/gorgine_support"
                  style={{ color: "var(--fireRed)" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("APP.SUBSCRIPTION.BODY.TELEGRAM")}
                </a>{" "}
                {t("APP.SUBSCRIPTION.BODY.OR")}{" "}
                <a
                  href="https://instagram.com/gorgine.mafia"
                  style={{ color: "var(--fireRed)" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("APP.SUBSCRIPTION.BODY.INSTAGRAM")}
                </a>{" "}
                {t("APP.SUBSCRIPTION.BODY.OR_2")}{" "}
                <a
                  href="mailto:info@gorgine.com"
                  style={{ color: "var(--fireRed)" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("APP.SUBSCRIPTION.BODY.EMAIL")}
                </a>{" "}
                {t("APP.SUBSCRIPTION.BODY.ASK_FOR_HELP")}
              </Typography>

              <Box sx={{ marginTop: "48px" }}>
                <SubscriptionQuestions />
              </Box>
            </Box>
          </Box>
          <CopiedPopup
            copiedPopup={copiedPopup}
            setCopiedPopup={setCopiedPopup}
          />
        </Box>
      </Box>
    </>
  );
};
