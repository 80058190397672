import { params } from "../../../../hooks/game-room/handlegameRoom.js";
import { handleVideo } from "../../helper-functions/handleVideo.js";
import { handleMic } from "../../helper-functions/handleMic.js";
export const handlePauseConsumers = () => {
  const { socket, viewer, consumers } = params;
  socket.on("pauseConsumers", (mode) => {
    if (!viewer) {
      // pause audio producers for both conditions shifting day to night or night to day
      handleMic(true);
      handleVideo(true, "socket pauseConsumers logs if not viewer...");
      consumers.current?.forEach((consumer) => {
        socket.emit("pauseConsumer", consumer?.storageId, async () => {
          if (consumer.consumer?.kind === "audio") {
            await consumer.consumer?.pause();
          }
        });
      });
    } else {
      consumers.current?.forEach((consumer) => {
        socket.emit("pauseConsumer", consumer?.storageId, async () => {
          await consumer.consumer?.pause();
        });
      });
    }
  });
};
