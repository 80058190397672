import { useState } from "react";
import { Box, Typography } from "@mui/material";
import bg from "../../assets/image/login-background.webp";
import warningIcon from "../../assets/icons/auth/warningIcon.svg";
import { Link } from "react-router-dom";
import { useHandleRegister } from "../../hooks/auth/handleRegister.js";
import { useTranslation } from "react-i18next";
import {
  PhoneInput,
  PasswordInput,
  SubmitBtn,
} from "../../components/auth/common/index.js";
import UserNameInput from "../../components/auth/common/UserNameInput.js";
import { Terms } from "../../components/auth/common/Terms.js";
import { Logo } from "../../components/global/Logo.js";
import { LanguageBtn, SupportBtn } from "../../components/global/index.js";
import { TermsOtp } from "../../components/auth/common/TermsOtp.js";

const Register = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState([]);
  const [username, setUsername] = useState("");
  const [isUserAvailble, setIsUserAvailble] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isTermsOtpChecked, setIsTermsOtpChecked] = useState(lang === "en" ? false : true);
  const [errorCode, setErrorCode] = useState(null);

  const { handleRegister } = useHandleRegister({
    username,
    password,
    setValidation,
    isTermsChecked,
    isTermsOtpChecked,
    isUserAvailble,
    setErrorCode,
  });

  const submitForm = (e) => {
    e.preventDefault();
  };

  return (
    <Box
      noValidate
      component="form"
      onSubmit={submitForm}
      sx={{
        backgroundImage: `url(${bg})`,
      }}
      className="register-parent-wrap auth-pages-parent-wrapper"
    >
      <Box className="auth-header">
        <Logo />
        <Box sx={{ display: "flex", gap: "22px" }}>
          <SupportBtn />
          <LanguageBtn />
        </Box>
      </Box>

      <Box className="form-wrap">
        <Box className="form-box">
          <Typography
            color={"var(--white)"}
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              lineHeight: "35px",
              marginBottom: "5px",
            }}
          >
            {t("APP.AUTH.REGISTER.REGISTER_ACCOUNT_TITLE")}
          </Typography>
          <Typography
            color={"var(--mutedGray)"}
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "28px",
            }}
          >
            {t("APP.AUTH.REGISTER.REGISTER_PHONE_NUMBER_INSTRUCTION")}
          </Typography>
          <Box sx={{ height: "30px", width: "100%" }}></Box>
          <PhoneInput validation={validation} screen={"register"} />
          <Box sx={{ height: "7px", width: "100%" }}></Box>

          <UserNameInput
            isUserAvailble={isUserAvailble}
            setIsUserAvailble={setIsUserAvailble}
            username={username}
            setUsername={setUsername}
            validation={validation}
          />

          <Box sx={{ height: "7px", width: "100%" }}></Box>
          <PasswordInput validation={validation} setPassword={setPassword} />

          <Box sx={{ height: "9px", width: "100%" }}></Box>

          <Terms
            validation={validation}
            setIsTermsChecked={setIsTermsChecked}
          />

          { lang === "en" && (
            <TermsOtp
              validation={validation}
              setIsTermsOtpChecked={setIsTermsOtpChecked}
            />
          )}

          {errorCode ? (
            <>
              <Box sx={{ height: "10px", width: "100%" }}></Box>
              <Box className="warning-alert">
                <img src={warningIcon} alt="warning" />
                {errorCode === 4009 ? (
                  <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                 {t("APP.AUTH.REGISTER.OTP_FAILED")}
                    <a
                      href="https://t.me/gorgine_support"
                      style={{ color: "var(--primary)" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("APP.SUBSCRIPTION.BODY.TELEGRAM")}
                    </a>
                    {t("APP.AUTH.REGISTER.OTP_FAILED_2")}
                  </Typography>

                ) : (
                  t(`APP.ERROR_CODES.${errorCode}`)
                )}
              </Box>
              <Box sx={{ height: "18px", width: "100%" }}></Box>
            </>
          ) : (
            <Box sx={{ height: "7px", width: "100%" }}></Box>
          )}

          <SubmitBtn handleSubmit={handleRegister} screen={"register"} />
          <Box sx={{ height: "23px", width: "100%" }}></Box>
          <Typography
            color={"var(--white)"}
            sx={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "18.75px",
            }}
          >
            {t("APP.AUTH.REGISTER.ALREADY_HAVE_ACCOUNT_INTRO")}{" "}
            <Link className="link-hover" to="/login">
              {t("APP.AUTH.REGISTER.LOG_IN_LINK")}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
