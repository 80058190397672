import { useDispatch } from "react-redux";
import { getPhoneNumber } from "../../handlers/auth/getPhoneNumberFromInput.js";
import { forgetPasswordApi } from "../../features/auth/authThunks.js";
import { setNavigateTo } from "../../features/global/globalSlice.js";
let timeOutId;
let errorTimeOutId;
export const useHandleForgetPassword = ({ setValidation, setErrorCode }) => {
  const dispatch = useDispatch();
  const handleForgetPassword = () => {
    let emptyFields = [];
    let phoneNumber = getPhoneNumber(emptyFields);
    setValidation(emptyFields);
    // hide errors after 3sec
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setValidation([]);
    }, 3000);

    if (emptyFields.length < 1) {
      (async () => {
        try {
          await dispatch(
            forgetPasswordApi({ payload: { phoneNumber } }),
          ).unwrap();
          dispatch(
            setNavigateTo(
              `/otp?type=forget-password&phone-number=${phoneNumber}`,
            ),
          );
        } catch (error) {
          clearTimeout(errorTimeOutId);
          setErrorCode(error.code);
          errorTimeOutId = setTimeout(() => {
            setErrorCode(null);
          }, 6000);
        }
      })();
    }
  };
  return { handleForgetPassword };
};
