export const getPhoneNumber = (emptyFields = [], noCountryCode) => {
  let countryCode = document.querySelector(".MuiTelInput-Flag").innerText;
  let phoneNumber = document
    .querySelector(".phone-input-field")
    .value.replace(/^0+/, "");
  if (!phoneNumber) {
    emptyFields.push("phoneNumber");
    // return null;
  } else if (phoneNumber.length < 8) {
    emptyFields.push("invalidPhoneNumber");
    // return null;
  } else if (countryCode === "+98") {
    if (!phoneNumber.startsWith("9") || phoneNumber.length > 10) {
      emptyFields.push("invalidPhoneNumber");
    }
  } else if (countryCode === "+1") {
    if (phoneNumber.length > 10) {
      emptyFields.push("invalidPhoneNumber");
    }
  }
  return (phoneNumber = noCountryCode
    ? phoneNumber
    : countryCode + phoneNumber).replace(" ", "");
};
