export async function handleConsumer({
  data,
  receiveTransport,
  consumers,
  peersRef,
  setPeers,
  micHiddenElems,
  setMicHiddenElems,
  adminVideoMutedUsers,
  setAdminVideoMutedUsers,
  setAdminAudioMutedUsers,
  adminAudioMutedUsers,
  cleanupsRef,
  setCaptureMediaStreams,
}) {
  const {
    producerId,
    kind,
    id,
    rtpParameters,
    socketId,
    storageId,
    username,
    // userId,
    muted,
  } = data;
  let codecOptions = {};
  const consumer = await receiveTransport?.current[data?.storageId].consume({
    id,
    producerId,
    kind,
    rtpParameters,
    codecOptions,
  });
  consumers.current.push({
    consumer: consumer,
    storageId: storageId,
    socketId: socketId,
  });
  const mediaStream = new MediaStream();
  mediaStream.addTrack(consumer.track);
  if (kind === "video") {
    const idx = peersRef.current?.findIndex(
      (peer) => peer?.username === username,
    );
    peersRef.current[idx].mediaStream = mediaStream;
    peersRef.current[idx].storageId = storageId;
    const peerArr = [...peersRef.current];
    setPeers(peerArr);

    const filteredUsers = adminVideoMutedUsers.filter(
      (sockId) => sockId !== socketId,
    );
    setAdminVideoMutedUsers(filteredUsers);
  } else {
    if (document.getElementById(storageId)) {
      console.error("audio element already exist with storageID=", storageId);
      // document.body.removeChild(document.getElementById(storageId));
      console.log("already exist audio element removed and created new one");
    }
    const audioElem = document.createElement("audio");
    audioElem.autoplay = true;
    audioElem.srcObject = mediaStream;
    audioElem.id = data.storageId;
    audioElem.classList.add(data?.socketId);
    document.body.appendChild(audioElem);
    setCaptureMediaStreams((prev) => ({ ...prev, [username]: mediaStream }));
    // const { audioContext, analyser } = setupAudioContext(mediaStream);
    // cleanupsRef.current.push(() => {
    //   audioContext.close();
    // });
    // updateAudioMeter(analyser, username);
    if (muted) {
      if (!micHiddenElems.includes(socketId)) {
        setMicHiddenElems((prev) => [...prev, socketId]);
      }
      if (!adminAudioMutedUsers.includes(socketId)) {
        setAdminAudioMutedUsers((prev) => [...prev, socketId]);
      }
    } else {
      if (micHiddenElems.includes(socketId)) {
        setMicHiddenElems((prev) => prev.filter((id) => id !== socketId));
      }
      if (adminAudioMutedUsers.includes(socketId)) {
        setAdminAudioMutedUsers((prev) => prev.filter((id) => id !== socketId));
      }
    }
  }

  // return {cleanups:()=>{
  //   audioContext.close();
  // }}
}
