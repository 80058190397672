import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { handleMediaPermissionChange } from "../helper-functions/handleMediaPermission.js";
import { browserName } from "react-device-detect";
import { handleCapabilities, startStream } from "../index.js";
export const getRTPCapabilites = () => {
  const {
    socket,
    user,
    setIsAdmin,
    viewer,
    adminRef,
    getState,
    setCameraMicPermission,
  } = params;
  async function startProducing() {
    socket.emit("createTransport", socket.id);
  }
  socket.emit("getRTPCapabilites", async (response) => {
    await handleCapabilities(response?.capabilities);
    const { roomId } = params;
    const obj = {
      id: user.id,
      username: user.username,
      room: roomId,
      viewer,
    };
    socket.emit("addUserCall", obj, async (isAdmin) => {
      setIsAdmin(isAdmin);
      adminRef.current = isAdmin;
      if (!viewer) {
        const state = await getState();
        const night = state !== "NIGHT" || isAdmin ? true : false;
        await startStream(night, state, isAdmin);

        // Media permission change listner
        const grantedCallback = async () => {
          const state = await getState();
          const night = state !== "NIGHT" || isAdmin ? true : false;
          await startStream(night, state, isAdmin);
          socket.emit("createTransport", socket.id);
          setCameraMicPermission(true);
        };
        if (browserName !== "Firefox") {
          handleMediaPermissionChange({ grantedCallback });
        }

        startProducing();
      }
    });
  });
};
