import React, { useEffect, useState } from "react";
import bgImg from "../../assets/image/game-lobby/lobbyBackground.webp";
import MediaPermisionPopup from "../../components/room/popups/MediaPermisionPopup.js";
import Grids from "../../components/room/Grids.js";
import { decodeParams } from "../../handlers/global/encodeDecodeUrl.js";
import { useParams } from "react-router";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setNavigateTo } from "../../features/global/globalSlice.js";
import { AutoMode } from "../../components/room/auto-mode/AutoMode.js";
const GameScreen = () => {
  const dispatch = useDispatch();
  const { encodedParams } = useParams();
  const decodedParams = decodeParams(encodedParams);
  let { v: viewer, error, m: mode } = decodedParams;
  const [showMediaPermisionPopup, setShowMediaPermisionPopup] =
    useState(!viewer);
  mode === "a" && (mode = "AUTO");
  const [micStatus, setMicStatus] = useState(false);
  const [videoStatus, setVideoStatus] = useState(false);
  const [reRender, setReRender] = useState(false);
  useEffect(() => {
    if (error) {
      dispatch(setNavigateTo("/not-found"));
      return;
    }
  }, [error]);
  const shiftToWatchMode = () => {
    setReRender(true);
    setTimeout(() => {
      setReRender(false);
    }, 0);
  };

  return showMediaPermisionPopup ? (
    <MediaPermisionPopup
      videoStatus={videoStatus}
      micStatus={micStatus}
      setMicStatus={setMicStatus}
      setVideoStatus={setVideoStatus}
      showMediaPermisionPopup={showMediaPermisionPopup}
      setShowMediaPermisionPopup={setShowMediaPermisionPopup}
    />
  ) : (
    !reRender &&
      (mode === "AUTO" ? (
        <AutoMode />
      ) : (
        <Box
          className="game-screen-parent-wrapper"
          sx={{
            backgroundImage: `url(${bgImg})`,
          }}
        >
          <Box className="grid-main">
            <Grids
              shiftToWatchMode={shiftToWatchMode}
              openVideo={mode === "AUTO" ? false : videoStatus}
              openMic={mode === "AUTO" ? false : micStatus}
            />
          </Box>
        </Box>
      ))
  );
};
export default GameScreen;
