import React from "react";
import { Button } from "@mui/material";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { privateWatchRoomCoins } from "../../../../constants/gameLobbyConstant.js";
const WatchButton = ({ handleActions, infoBtn2Ref }) => {
  const { t } = useTranslation();

  return (
    <Button
      ref={infoBtn2Ref}
      name="watch-btn"
      onClick={() =>
        handleActions({ actionType: "WATCH", coins: privateWatchRoomCoins })
      }
      type="submit"
      color="primary"
      variant="contained"
      size="large"
      className="info-btn2"
    >
      {t("APP.LOBBY.INFO_TAB.WATCH_BTN")}
    </Button>
  );
};
WatchButton.propTypes = {
  handleActions: propTypes.func.isRequired,
  infoBtn2Ref: propTypes.object.isRequired,
};
export default WatchButton;
