import { params } from "../../../hooks/game-room/handlegameRoom.js";
const timeoutIds = {};
export const updatePermission = (socketId, property) => {
  const { setDeclinedPermissionUsers } = params;
  setDeclinedPermissionUsers((prev) => {
    const updatedSet = new Set([...prev[property], socketId]);
    if (timeoutIds[socketId]) {
      clearTimeout(timeoutIds[socketId]);
    }
    timeoutIds[socketId] = setTimeout(() => {
      setDeclinedPermissionUsers((prev) => {
        const updatedMic = prev.mic.filter((item) => item !== socketId);
        const updatedVideo = prev.video.filter((item) => item !== socketId);
        const updatedWakeUp = prev.wakeUp.filter((item) => item !== socketId);
        return { mic: updatedMic, video: updatedVideo, wakeUp: updatedWakeUp };
      });
    }, 5000);

    return { ...prev, [property]: [...updatedSet] };
  });
};
