import { useEffect, useRef, useState } from "react";
import { emitSocketEvent, socketEventListener } from "../../socket/socket.js";
import {
  createCustomScenarioApi,
  createFeedbackApi,
  getAbilitiesApi,
  getCreateRoomApi,
} from "../../features/gameLobby/gameLobbyThunk.js";
import { useDispatch, useSelector } from "react-redux";
import {
  alertDialog,
  teachStepperPopper,
} from "../../features/global/globalSlice.js";
import { useTranslation } from "react-i18next";
import { useTeachData, trackTeachSteps } from "./teachUserData.js";
import { rankedJoinRoomList } from "../../data/game-lobby/index.js";
// const filterFunction = {
//   0: (item) => item.isActive,
//   1: (item) => item.gameInProgress,
//   2: (item) => !item.gameInProgress,
//   3: (item) => item.privacy === "PRIVATE",
//   4: (item) => item.privacy === "PUBLIC",
// };

// let roomList = [];

const useGetRoomList = (selectedItem, setRoomLists) => {
  // const [publicRoomList, setPublicRoomList] = useState([]);

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    emitSocketEvent("getRoomList");

    const removeEventListener = socketEventListener("pushRoomList", (data) => {
      const sortedRoomLists = {
        public: { join: rankedJoinRoomList, watch: [] },
        private: { join: [], watch: [] },
      };

      data.forEach((room) => {
        const privacyType = room.privacy.toLowerCase();

        // if room is public we don't need to show rejoin/watch in join tab
        const isPlayerRejoin =
          privacyType === "public"
            ? false
            : room?.playerIds?.some((userObj) => {
                const isEndedWithShot =
                  room.state === "ENDED" && userObj.status === "SHOT";
                const isActive = !["KICKED", "SHOT"].includes(userObj.status);
                return (
                  userObj.userId === user.id && (isEndedWithShot || isActive)
                );
              });

        if (sortedRoomLists[privacyType]) {
          const roomListType = room.gameInProgress
            ? isPlayerRejoin
              ? "join"
              : "watch"
            : room.maxPlayer === room.playersCount
              ? "watch"
              : "join";
          // if (!(privacyType === "public" && roomListType === "join")) {
          sortedRoomLists[privacyType][roomListType].push(room);
          // }
        }
      });
      setRoomLists(sortedRoomLists);
      // roomList = data;
      // setPublicRoomList(data.filter(filterFunction[selectedItem]));
    });
    return () => {
      if (removeEventListener) {
        removeEventListener();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // setPublicRoomList(roomList.filter(filterFunction[selectedItem]));
  }, [selectedItem]);
};

const useGetCreateRoomList = () => {
  const dispatch = useDispatch();
  const { createRoomList } = useSelector((state) => state.gameLobby);

  useEffect(() => {
    if (createRoomList.length < 1) {
      dispatch(getCreateRoomApi({}));
    }
  }, [dispatch, createRoomList]);

  return { createRoomList };
};

let timeOutId;
const useGetAbilities = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [abilitiesData, setAbilitiesData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAbilitiesApi({}));
        const data = response.payload;
        setAbilitiesData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const createScenario = (data, abilitiesSum, submitForm) => {
    const nonZeroValues = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== 0),
    );

    const abilityData = Object.entries(nonZeroValues).map(
      ([abilityId, count]) => ({
        abilityId,
        count,
      }),
    );

    const maxCustomPlayer = abilitiesSum + 1;

    (async () => {
      try {
        const response = await dispatch(
          createCustomScenarioApi({ payload: abilityData }),
        ).unwrap();
        submitForm(null, response.scenario, maxCustomPlayer);
      } catch (error) {
        console.log(error);
        dispatch(
          alertDialog({
            title: t(`APP.ERROR_CODES.${error.code}`),
            icon: "error",
          }),
        );
      }
    })();
  };

  const createFeedback = (desc, setEmptyDesc) => {
    if (!desc) {
      setEmptyDesc("emptyInputField");
    }
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setEmptyDesc("");
    }, 3000);

    if (desc) {
      const feedbackData = {
        type: "RECOMMENDED_ABILITY",
        description: desc,
      };

      (async () => {
        try {
          await dispatch(createFeedbackApi({ payload: feedbackData })).unwrap();
          dispatch(
            alertDialog({
              title: t(`APP.RESPONSE_CODES.2019`),
              icon: "info",
            }),
          );
        } catch (error) {
          dispatch(
            alertDialog({
              title: t(`APP.ERROR_CODES.${error.code}`),
              icon: "error",
            }),
          );
        }
      })();
    }
  };

  return { abilitiesData, createScenario, createFeedback };
};
let timeoutId;
const useGameLobby = ({ tabName, value }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const tab = value === 1 ? "joinField" : value === 2 && "watchField";

  const teachData = useTeachData();
  const setStepsToLocal = (steps) => {
    window.localStorage.setItem("trackTeachSteps", JSON.stringify(steps));
  };
  const getStepsFromLocal = () => {
    let steps = window.localStorage.getItem("trackTeachSteps");
    return JSON.parse(steps);
  };
  const setStepToLocal = ({ key, value }) => {
    const steps = getStepsFromLocal();

    if (key === "roomNumber" || key === "password") {
      steps[tab][key] = value;
    } else {
      steps[key] = value;
    }

    setStepsToLocal(steps);
  };
  let getSteps;
  if (user.loginCount < 2) {
    getSteps = getStepsFromLocal();
    if (!getSteps) {
      setStepsToLocal(trackTeachSteps);
      getSteps = trackTeachSteps;
    }
  }

  const teachComponentsRef = useRef({});

  const getActionName = {
    "create-btn": "createRoom",
    "join-btn": "joinRoom",
    "watch-btn": "watchRoom",
  };
  useEffect(() => {
    const getRefrences = () => {
      let teachComponent = teachComponentsRef.current;
      const {
        coinsBalance,
        roomType,
        infoBtnsRef,
        password,
        playIcon,
        roomNumber,
        joinTab,
        watchTab,
      } = teachComponent;
      const createTab =
        coinsBalance &&
        roomType &&
        playIcon &&
        joinTab &&
        watchTab &&
        value === 0;
      const joinWatchTab = password && roomNumber && value !== 0;
      if (infoBtnsRef && (createTab || joinWatchTab)) {
        if (createTab) {
          teachComponent = {
            coinsBalance,
            playIcon,
            roomType,
            infoBtnsRef,
            joinTab,
            watchTab,
          };
          console.log("createTab: ", createTab);
        } else if (joinWatchTab) {
          teachComponent = { roomNumber, password, infoBtnsRef };
          console.log("joinWatchTab: ", joinWatchTab);
        }

        const refernceArray = [];
        for (const item in teachComponent) {
          const ref = teachComponent[item];
          const key = teachData[item]
            ? item
            : getActionName[infoBtnsRef.querySelector("button").name];
          if (key === "playIcon" && value !== 0) {
            return;
          }
          let data = { ...teachData[key] };

          let step;
          if (key === "roomNumber" || key === "password") {
            step = getSteps[tab][key];
          } else {
            step = getSteps[key];
          }

          if (data && !step) {
            data.parent = ref;
            refernceArray.push(data);
          }
        }
        dispatch(teachStepperPopper({ setStepToLocal, refernceArray }));
        clearTimeout(timeoutId);
      } else {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          getRefrences();
        }, 500);
      }
    };
    if (getSteps && !getSteps.done) {
      getRefrences();
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [tabName, value]);

  return { teachComponentsRef };
};

export { useGetRoomList, useGetCreateRoomList, useGetAbilities, useGameLobby };
