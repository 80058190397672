import { useState } from "react";
import { Box, Typography } from "@mui/material";
import bg from "../../assets/image/login-background.webp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHandleResetPassword } from "../../hooks/auth/HandleResetPassword.hook.js";
import warningIcon from "../../assets/icons/auth/warningIcon.svg";
import { useLocation } from "react-router-dom";
import {
  PasswordInput,
  SubmitBtn,
} from "../../components/auth/common/index.js";
import { Logo } from "../../components/global/Logo.js";
import { LanguageBtn, SupportBtn } from "../../components/global/index.js";

const NewPassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [validation, setValidation] = useState([]);
  const [errorCode, setErrorCode] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumber = "+" + searchParams.get("phone-number")?.trim();
  const { handleResetPassword } = useHandleResetPassword({
    password,
    reEnterPassword,
    setValidation,
    setErrorCode,
    phoneNumber,
  });

  const submitForm = (e) => {
    e.preventDefault();
  };

  return (
    <Box
      noValidate
      component="form"
      onSubmit={submitForm}
      sx={{
        backgroundImage: `url(${bg})`,
      }}
      className="new-password-parent-wrap auth-pages-parent-wrapper"
    >
      <Box className="auth-header">
        <Logo />
        <Box sx={{ display: "flex", gap: "22px" }}>
          <SupportBtn />
          <LanguageBtn />
        </Box>
      </Box>

      <Box className="form-wrap">
        <Box className="form-box">
          <Typography
            color={"var(--white)"}
            sx={{
              fontSize: "30px",
              fontWeight: "700",
            }}
          >
            {t("APP.AUTH.NEW_PASSWORD.NEW_PASSWORD_TITLE")}
          </Typography>
          <Typography
            color={"var(--mutedGray)"}
            sx={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {t("APP.AUTH.NEW_PASSWORD.NEW_PASSWORD_INSTRUCTIONS")}
          </Typography>
          <Box sx={{ height: "30px", width: "100%" }}></Box>
          <PasswordInput
            screen="changePassword"
            inputLabel="newPassword"
            validation={validation}
            setPassword={setPassword}
          />
          <Box sx={{ height: "2px", width: "100%" }}></Box>
          <PasswordInput
            screen="changePassword"
            inputLabel="reEnterPassword"
            validation={validation}
            setPassword={setReEnterPassword}
          />

          {errorCode ? (
            <>
              <Box sx={{ height: "10px", width: "100%" }}></Box>
              <Box className="warning-alert">
                <img src={warningIcon} alt="warning" />
                {errorCode === "notMatch"
                  ? t(`APP.AUTH.NEW_PASSWORD.PASSWORD_NOT_MATCH`)
                  : t(`APP.ERROR_CODES.${errorCode}`)}
              </Box>
              <Box sx={{ height: "18px", width: "100%" }}></Box>
            </>
          ) : (
            <Box sx={{ height: "18px", width: "100%" }}></Box>
          )}
          <SubmitBtn handleSubmit={handleResetPassword} screen="newPassword" />
          <Box sx={{ height: "23px", width: "100%" }}></Box>
          <Typography
            color={"var(--white)"}
            sx={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "18.75px",
            }}
          >
            {t("APP.AUTH.NOT_HAVE_ACCOUNT")}{" "}
            <Link className="link-hover" to="/register">
              {t("APP.AUTH.REGISTER_LINK_TEXT")}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewPassword;
