import React from "react";
import logo from "../../assets/image/logo.svg";
import { Box, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setNavigateTo } from "../../features/global/globalSlice.js";

export const Logo = ({ screen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleLogoClick = () => {
    dispatch(setNavigateTo(`/game-lobby?tab=join`));
  };

  return (
    <Box
      sx={{
        width: screen === "header" ? "50px" : "69px",
        height: screen === "header" ? "50px" : "69px",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
          width: "45px",
          height: "45px",
        },
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
        onClick={handleLogoClick}
        src={logo}
        alt="Logo"
      />
    </Box>
  );
};

Logo.propTypes = {
  screen: PropTypes.string,
};
