import { params } from "../../../hooks/game-room/handlegameRoom.js";
export const closeConsumer = () => {
  const {
    socket,
    receiveTransport,
    consumers,
    peersRef,
    setAdminVideoMutedUsers,
  } = params;
  socket.on("closeConsumer", async ({ storageId, socketId }) => {
    console.log("close consumer", storageId);
    await receiveTransport.current[storageId]?.close();
    receiveTransport.current[storageId] = null;
    consumers.current = consumers.current?.filter(
      (consumer) => consumer?.storageId !== storageId,
    );
    if (peersRef.current?.some((item) => item?.storageId === storageId)) {
      setAdminVideoMutedUsers((prev) => [...prev, socketId]);
    }
    if (document.getElementById(storageId)) {
      document.body.removeChild(document.getElementById(storageId));
    }
  });
};
