import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import LeftSide from "./LeftSide.js";
import Middle from "./Middle.js";

const Footer = ({
  stream,
  setShowPlayPopup,
  showPlayPopup,
  setIsOpenChat,
  isOpenChat,
  totalUsers,
}) => {
  return (
    <Box className="room-footer-parent-wrapper">
      <Box className="left-parent-wrapper">
        <LeftSide stream={stream} />
      </Box>

      <Box className="middle-parent-wrapper">
        <Middle
          isOpenChat={isOpenChat}
          setShowPlayPopup={setShowPlayPopup}
          setIsOpenChat={setIsOpenChat}
          stream={stream}
          showPlayPopup={showPlayPopup}
        />
      </Box>
      <Box className="right-parent-wrapper">
        
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  stream: PropTypes.any,
  setShowPlayPopup: PropTypes.func.isRequired,
  setIsOpenChat: PropTypes.func.isRequired,
  showPlayPopup: PropTypes.bool.isRequired,
  isOpenChat: PropTypes.bool.isRequired,
  totalUsers: PropTypes.number.isRequired,
};

export default Footer;
