import React, { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import propTypes from "prop-types";

import { Box } from "@mui/system";
import hark from "hark";
import { useState } from "react";
const AudioDetector = ({
  mediaStream,
  smallAnimatorRef,
  username,
  captureMediaStreams,
}) => {
  const [scale, setScale] = useState(0);
  const { scale: springScale } = useSpring({
    scale: 1 + scale,
    config: { duration: 200 },
  });
  useEffect(() => {
    const targetSmallAnimator = smallAnimatorRef.current[username];
    targetSmallAnimator && targetSmallAnimator.classList.add("stop-animating");
    if (mediaStream) {
      var options = { threshold: -55 };
      var speechEvents = hark(mediaStream, options);
      const userGrid = document.getElementById(username + "-grid");
      speechEvents.on("speaking", function () {
        targetSmallAnimator &&
          targetSmallAnimator.classList.remove("stop-animating");
        if (userGrid.style.borderColor !== "rgb(11, 168, 237)") {
          userGrid.style.borderColor = "rgba(11, 168, 237, 1)";
        }
      });
      speechEvents.on("stopped_speaking", function () {
        targetSmallAnimator &&
          targetSmallAnimator.classList.add("stop-animating");
        if (userGrid.style.borderColor === "rgb(11, 168, 237)") {
          userGrid.style.borderColor = "transparent";
        }
      });

      const slope = 10;
      speechEvents.on("volume_change", function (volume, threshold) {
        if (volume > threshold) {
          const sigmoid = (x) => 0.8 / (1 + Math.exp(-x));
          const scaleFactor = sigmoid((volume - threshold) / slope);
          setScale(scaleFactor);
        } else {
          setScale(0);
        }
      });
    }
    return () => {
      if (speechEvents) {
        speechEvents.stop();
      }
    };
  }, [mediaStream, captureMediaStreams]);

  return (
    <>
      <Box
        sx={{
          transform: `translate(-50%, -50%)`,
          position: "absolute",
          zIndex: "0",
          top: "50%",
          left: "50%",
        }}
      >
        <animated.div
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "120px",
            // backgroundColor: "rgba(125, 244, 102, 0.3)",
            backgroundColor: "rgba(134, 146, 166, 0.4)",
            transform: springScale.interpolate((s) => `scale(${s})`),
          }}
        />
      </Box>
    </>
  );
};
AudioDetector.propTypes = {
  mediaStream: propTypes.any,
  smallAnimatorRef: propTypes.object.isRequired,
  username: propTypes.string.isRequired,
  setAudioLevel: propTypes.func,
  captureMediaStreams: propTypes.object.isRequired,
};
export default AudioDetector;
