import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/auth/Login.js";
import Register from "../pages/auth/Register.js";
import Otp from "../pages/auth/Otp.js";
import ForgotPassword from "../pages/auth/ForgotPassword.js";
import NewPassword from "../pages/auth/NewPassword.js";
import GameScreen from "../pages/game-room/GameScreen.js";
import GameLobby from "../pages/game-lobby/GameLobby.js";
import { Subscription } from "../pages/subscription/Subscription.js";
import NotAvaibleMobileVersion from "../pages/404/NotAvaibleMobileVersion.js";
import { NotFound } from "../pages/404/NotFound.js";
import { useLocation } from "react-router-dom";
import useDetectMobile from "../handlers/global/mobileDetector.js";
import setupAuthInterceptor from "../api/authInterceptor.js";
import { AuthProvider, useAuth } from "../contexts/AuthContext.js";
import useHandleLang from "../hooks/auth/handleLang.hook.js";
import Cookies from "js-cookie";

const AllRoutes = () => {
  const isMobileDevice = useDetectMobile();
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigateAddress = Cookies.get("navigationState");

  setupAuthInterceptor();
  useHandleLang();

  // if user already logged in , don't allow user to open auth pages
  const pages = (path) => {
    return isAuthenticated ? (
      navigateAddress ? (
        <Navigate to={`${navigateAddress}`} replace />
      ) : (
        <Navigate to="/game-lobby" replace />
      )
    ) : (
      {
        login: <Login />,
        register: <Register />,
        "forgot-password": <ForgotPassword />,
        "new-password": <NewPassword />,
        otp: <Otp />,
      }[path]
    );
  };
  const desktopPages = (path) => {
    return isMobileDevice ? (
      <Navigate to="/comming-soon-mobile-version" replace />
    ) : (
      {
        room: <GameScreen />,
        "game-lobby": <GameLobby />,
        subscription: <Subscription />,
        "mobile-version": <NotAvaibleMobileVersion />,
      }[path]
    );
  };

  return (
    <Routes>
      <Route path="/login" element={pages("login")} />
      <Route path="/register" element={pages("register")} />
      <Route path="/otp" element={pages("otp")} />
      <Route path="/forgot-password" element={pages("forgot-password")} />
      <Route path="/new-password" element={pages("new-password")} />
      {isAuthenticated ? (
        <>
          <Route path="/" element={<Navigate to="/game-lobby" replace />} />
          <Route path="/room/:encodedParams" element={desktopPages("room")} />
          <Route path="/game-lobby" element={desktopPages("game-lobby")} />
          <Route path="/subscription" element={desktopPages("subscription")} />
          <Route
            path="/comming-soon-mobile-version"
            element={<NotAvaibleMobileVersion />}
          />
        </>
      ) : (
        <Route
          path="*"
          element={<Navigate to="/login" replace state={{ from: location.pathname }} />}
        />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function AppRoutes() {
  return (
    <AuthProvider>
      <AllRoutes />
    </AuthProvider>
  );
}

export default AppRoutes;
