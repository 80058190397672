import { params } from "../../../hooks/game-room/handlegameRoom.js";
let timeOutId;
export const handleSendDislike = () => {
  const { socket, roomId, setUserPersonalAction } = params;

  setUserPersonalAction((pre) => {
    clearTimeout(timeOutId);
    if (pre === "dislikeUsers") {
      return "";
    } else {
      timeOutId = setTimeout(() => {
        setUserPersonalAction((pre) => {
          return pre === "dislikeUsers" ? "" : pre;
        });
      }, 1000 * 20);
      return "dislikeUsers";
    }
  });

  socket.emit("sendDislike", roomId);
};
