import { params } from "../../../hooks/game-room/handlegameRoom.js";
let timeOutId = {};

export const handleUserAction = (actionType, socketId) => {
  const { setLikeDislikeChallange } = params;
  setLikeDislikeChallange((pre) => {
    const preData = { ...pre };
    // Remove other actions of the same socketId first

    for (const otherActionType in preData) {
      if (otherActionType !== actionType) {
        preData[otherActionType] = preData[otherActionType].filter(
          (id) => id !== socketId,
        );
      }
    }
    clearTimeout(timeOutId[socketId]);
    if (preData[actionType].includes(socketId)) {
      preData[actionType] = preData[actionType].filter((id) => id !== socketId);
      return preData;
    } else {
      timeOutId[socketId] = setTimeout(() => {
        setLikeDislikeChallange((pre) => {
          const preData = { ...pre };
          preData[actionType] = preData[actionType].filter(
            (id) => id !== socketId,
          );
          return preData;
        });
      }, 1000 * 20);

      preData[actionType].push(socketId);
      return preData;
    }
  });
};
