import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import store from "./store/store.js";
import "./configs/i18.config.js";
import "./api/axiosInterceptor.js";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles/index.js";
import * as Sentry from "@sentry/react";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    primary: createColor("#3F94B2"),
    stealBlue: createColor("#4894b2"),
    darkStealBlue: createColor("#1469B8"),
    white: createColor("#FFF"),
    lightGray: createColor("#A1A0A0"),
    slateGray: createColor("#898989"),
    darkGray: createColor("#4F4F4F"),
    darkRed: createColor("#d23333"),
    burntOrange: createColor("#e7472c"),
    blueGray: createColor("#434651"),
    coalBlack: createColor("#121317"),
    midNightGray: createColor("#212127"),
    midNightNavy: createColor("#161B22"),
    goldenYellow: createColor("#f4cc2f"),
    neutralGray: createColor("#dadada"),
    translucentBlack: createColor("#0000008F"),
  },
});

const isProduction = process.env.REACT_APP_ENV === 'prod';
const loggingLevel = process.env.REACT_APP_LOG_LEVEL || 'error';

const logLevels = {
  debug: ['debug', 'info', 'log', 'warn', 'error'],
  info: ['info', 'log', 'warn', 'error'],
  log: ['log', 'warn', 'error'],
  warn: ['warn', 'error'],
  error: ['error'],
  none: []
};

function disableConsoleMethods(level) {
  const levelsToDisable = {
    debug: !logLevels[level].includes('debug'),
    info: !logLevels[level].includes('info'),
    log: !logLevels[level].includes('log'),
    warn: !logLevels[level].includes('warn'),
    error: !logLevels[level].includes('error')
  };

  if (levelsToDisable.debug) {
    console.debug = function () {};
  }
  if (levelsToDisable.info) {
    console.info = function () {};
  }
  if (levelsToDisable.log) {
    console.log = function () {};
  }
  if (levelsToDisable.warn) {
    console.warn = function () {};
  }
  if (levelsToDisable.error) {
    console.error = function () {};
  }
}

if (isProduction) {
  disableConsoleMethods(loggingLevel);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1, 
    replaysOnErrorSampleRate: 1.0,
  });

  const originalConsoleError = console.error;

  console.error = function (...args) {
    Sentry.captureException(new Error(args.join(' ')));
    originalConsoleError.apply(console, args);
  };

  window.onerror = function (message, source, lineno, colno, error) {
    Sentry.captureException(error || new Error(message));
    return false;
  };

  window.onunhandledrejection = function (event) {
    Sentry.captureException(event.reason);
  };
} else {
  console.log('Sentry is disabled in non-production environments');
}

const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  </HelmetProvider>,
);
