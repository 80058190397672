import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const SubmitBtn = ({ screen, handleSubmit }) => {
  const { t } = useTranslation();
  return (
    <Button
      fullWidth
      type="submit"
      onClick={handleSubmit}
      className="auth-confirmation-btn"
      variant="contained"
    >
      {screen === "login" && t("APP.AUTH.LOGIN.LOGIN_BUTTON_TEXT")}
      {screen === "register" && t("APP.AUTH.REGISTER.REGISTER_ACCOUNT_BUTTON")}
      {screen === "forgetPassword" &&
        t("APP.AUTH.FORGET_PASSWORD.VERIFY_BUTTON_TEXT")}
      {screen === "newPassword" && t("APP.AUTH.NEW_PASSWORD.RESET_BUTTON_TEXT")}
    </Button>
  );
};
SubmitBtn.propTypes = {
  screen: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
