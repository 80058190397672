import React, { useState } from "react";
// import { Helmet } from "react-helmet-async";
import { Grid, Box } from "@mui/material";
import { animator } from "./Animator.js";
import { useHandleJoinRoom } from "../../hooks/game-room/handlegameRoom.js";
import { useResizeAnimatorHook } from "../../hooks/game-room/resize&AnimatorHook.js";
import InfoPopup from "./popups/InfoPopup.js";
import ReadyToPlayPopup from "./popups/ReadyToPlayPopup.js";
import Footer from "./footer/Footer.js";

import Chat from "./Chat.js";
import likeGreenIcon from "../../assets/icons/room/likeGreenIcon.svg";
import challengePurpleIcon from "../..//assets/icons/room/challengePurpleIcon.svg";
import dislikeRedIcon from "../../assets/icons/room/dislikeRedIcon.svg";
import { useEffect } from "react";
import GridScreenCard from "./grid/GridScreenCard.js";
import godFatherAudio from "../../assets/media/audios/room/The_Godfather_Theme_Song.mp3";
import { useTheme } from "@mui/material/styles/index.js";
import PropTypes from "prop-types";
import bgImg from "../../assets/image/game-lobby/lobbyBackground.webp";
import NoConnection from "./popups/NoConnection.js";
// import { useTranslation } from "react-i18next";
import useGameRoomUi from "../../hooks/game-room/gameRoomUi.hook.js";
import Rejoin from "./elements/Rejoin.js";
let mouseTimeOutId;
export const userPropMap = {
  likeUsers: { icon: likeGreenIcon, borderColor: "var(--darkGreen)" },
  dislikeUsers: { icon: dislikeRedIcon, borderColor: "var(--darkRed)" },
  challengeUsers: {
    icon: challengePurpleIcon,
    borderColor: "var(--magentaPurple)",
  },
};
const AllGrids = ({ openVideo, openMic, shiftToWatchMode }) => {
  // const { t } = useTranslation();
  const [isAniamtion, setIsAnimation] = useState(true);
  const [isSmScreen, setisSmScreen] = useState(window.innerWidth < 600);
  const [isShowInfoPopup, setIsShowInfoPopup] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const props1 = useHandleJoinRoom({ openVideo, openMic, shiftToWatchMode });
  const props2 = useGameRoomUi();
  const stream = { ...props1, ...props2 };
  const {
    viewer,
    roomData,
    isNightMode,
    isAdmin,
    playerWakeStatus,
    internetStatus,
    screenArray,
    showPlayPopup,
    setShowPlayPopup,
    playerAbility,
    rejoinEnabled,
    abilityIconDistance
  } = stream;
  useResizeAnimatorHook({
    setIsAnimation,
    animator,
    setisSmScreen,
    isOpenChat,
    screenArray,
  });
  const handleMouseMove = () => {
    if (!isShowInfoPopup) {
      setIsShowInfoPopup(true);
    }
    clearTimeout(mouseTimeOutId);
    mouseTimeOutId = setTimeout(() => {
      setIsShowInfoPopup(false);
    }, 1000 * 10);
  };
  let totalUsers = screenArray.length;
  let maxUsers = isSmScreen ? 8 : 15;
  let users = Math.min(totalUsers, maxUsers);
  const getGameGridManage = users > 0 ? users : 1;
  const getGridClass = `grid${getGameGridManage}`;

  const theme = useTheme();

  useEffect(() => {
    !isAdmin && !viewer && isNightMode && setIsOpenChat(false);
  }, [isNightMode]);

  const shouldMute = viewer ? true : isAdmin || playerWakeStatus ? false : true;

  // const scenario = t(
  //   `APP.LOBBY.BODY.SCENARIO_CARDS.SCENARIOS.${roomData.scenario}.TITLE`,
  // );

  // const metaDescription = t("APP.GAME_ROOM.GRIDS.META_DESC")
  //   .replace("❔❔", scenario)
  //   .replace("❔", user.username);
  return (
    <>
      {/* <Helmet>
        <meta property="og:title" content="Your Dynamic Title" />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="Dynamic_image_URL" />
        <meta property="og:url" content="Dynamic_URL_of_your_website" />
      </Helmet> */}
      {isNightMode && (
        <audio muted={!shouldMute} hidden controls autoPlay loop>
          <source src={godFatherAudio} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      )}
      {!roomData && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            background: "var(--black)",
            zIndex: "1440",
            left: "0",
            top: "0",
          }}
        ></Box>
      )}
      <InfoPopup
        stream={stream}
        isShow={isShowInfoPopup}
        onMouseMove={handleMouseMove}
      />

      <Box sx={{ display: "flex", gap: "10px" }}>
        <Box className="game-gird-parent-wrapper">
          {playerAbility && !isAdmin && (
            <ReadyToPlayPopup
              showPlayPopup={showPlayPopup}
              setShowPlayPopup={setShowPlayPopup}
              abilityCardInfo={playerAbility}
              abilityIconDistance={abilityIconDistance}
              playerAbility={playerAbility}
            />
          )}
          <Grid
            onMouseMove={handleMouseMove}
            id="gridWrapper"
            className={`${getGridClass} grid-container`}
          >
            <GridScreenCard
              stream={stream}
              isAniamtion={isAniamtion}
              gridClass={getGridClass}
            />
          </Grid>

          <Footer
            isOpenChat={isOpenChat}
            setIsOpenChat={setIsOpenChat}
            showPlayPopup={showPlayPopup}
            setShowPlayPopup={setShowPlayPopup}
            stream={stream}
            isSmScreen={isSmScreen}
            totalUsers={totalUsers}
            gridClass={getGridClass}
          />
        </Box>
        <Box
          className={`grid-chat-wrapper ${isOpenChat ? "open-chat" : ""}`}
          sx={{
            [theme.breakpoints.down("sm")]: {
              backgroundImage: `url(${bgImg})`,
            },
          }}
        >
          <Chat
            stream={stream}
            isOpenChat={isOpenChat}
            setIsOpenChat={setIsOpenChat}
          />
          <NoConnection isConnectionGone={!internetStatus} />
        </Box>
      </Box>
      <Rejoin {...{rejoinEnabled}}/>
    </>
  );
};

export default AllGrids;
AllGrids.propTypes = {
  openVideo: PropTypes.bool.isRequired,
  openMic: PropTypes.bool.isRequired,
  shiftToWatchMode: PropTypes.func.isRequired,
};
