import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  showGlobalLoader,
  hideGlobalLoader,
  alertDialog,
  hideAlertDialogue,
} from "../../features/global/globalSlice.js";
import { listenEvents } from "../../handlers/game-room/event-listners/index.js";
import { io } from "socket.io-client";
import { domain } from "../../configs/main.config.js";
import { useTranslation } from "react-i18next";
import { decodeParams } from "../../handlers/global/encodeDecodeUrl.js";
import { useParams } from "react-router-dom";
import { startConsumeProducer } from "./../../handlers/game-room/helper-functions/handleStartConsumeProducer.js";
import { handleVideo } from "./../../handlers/game-room/helper-functions/handleVideo.js";
import {
  handleLeave,
  handleMic,
  handleLeavePlayer,
} from "../../handlers/game-room/index.js";
import useDetectDevices from "./useDetectDevices.js";

let params;
export const useHandleJoinRoom = ({ openVideo, openMic, shiftToWatchMode }) => {
  const { i18n, t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [socket, setSocket] = useState();
  const location = useLocation();
  const { encodedParams } = useParams();
  const decodedParams = decodeParams(encodedParams);
  let { i: id, v: viewer, p: password, t: type, m: mode } = decodedParams;
  type === "a" && (type = "audio");
  mode === "a" && (mode = "AUTO");
  viewer === "t" && (viewer = true);
  const [peers, setPeers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [messages, setMessages] = useState({ viewers: [], players: [] });
  const [audioMutedUsers, setAudioMutedUsers] = useState([]);
  const [videoMutedUsers, setVideoMutedUsers] = useState([]);
  const [adminAudioMutedUsers, setAdminAudioMutedUsers] = useState([]);
  const [adminVideoMutedUsers, setAdminVideoMutedUsers] = useState([]);
  const [adminAudioMuted, setAdminAudioMuted] = useState(false);
  const [adminVideoMuted, setAdminVideoMuted] = useState(false);
  const [currentSelectedPeer, setCurrentSelectedPeer] = useState({});
  const [micStatus, setMicStatus] = useState(false);
  const [micHiddenElems, setMicHiddenElems] = useState([]);
  const [micAllStatus, setMicAllStatus] = useState(true);
  const [videoStatus, setVideoStatus] = useState(false);
  const [videoAllStatus, setVideoAllStatus] = useState(true);
  const [isGameStart, setIsGameStart] = useState(false);
  const [isGameEnded, setIsGameEnded] = useState(false);
  const [isNightMode, setIsNightMode] = useState(false);
  const [invitePopup, setinvitePopup] = useState(false);
  const [showPlayPopup, setShowPlayPopup] = useState(false);
  const [likeDislikeChallange, setLikeDislikeChallange] = useState({
    likeUsers: [],
    dislikeUsers: [],
    challengeUsers: [],
  });
  const [userPersonalAction, setUserPersonalAction] = useState("");
  const [declinedPermssionUsers, setDeclinedPermissionUsers] = useState({
    mic: [],
    video: [],
    wakeUp: [],
  });
  const [inActiveUserTime, setInActiveUserTime] = useState(0);
  const [internetStatus, setInternetStatus] = useState(true);
  const [abilityCardInfo, setAbilityCardInfo] = useState([]);
  const [roomData, setRoomData] = useState("");
  const [awakedPlayers, setAwakedPlayers] = useState([]);
  const [waitingForResponse, setWaitingForResponse] = useState([]);
  const [camerMicPermission, setCameraMicPermission] = useState(true);
  const [playerWakeStatus, setPlayerWakeStatus] = useState(false);
  const [localUser, setLocalUser] = useState([]);
  const [screenArray, setScreenArray] = useState([]);
  const [localStream, setLocalStream] = useState(null);
  const [reconnectingUsers, setReconnectingUsers] = useState([]);
  const [playerAbility, setPlayerAbility] = useState("");
  const [mediaAllowedTooltip, setMediaAllowedTooltip] = useState({
    audio: false,
    video: false,
  });
  const [captureMediaStreams, setCaptureMediaStreams] = useState({});
  const [pendingMediaReqSockIds, setPendingMediaReqSockIds] = useState({
    mic: [],
    camera: [],
  });
  const [rejoinEnabled, setRejoinEnabled] = useState(false);
  const [abilityIconDistance, setAbilityIconDistance] = useState(0);

  const socketRef = useRef();
  const mediasoupDevice = useRef();
  const receiveTransport = useRef([]);
  const produceTransport = useRef();
  const localStreamRef = useRef();
  const audioProducer = useRef();
  const videoProducer = useRef();
  const audioProducerId = useRef();
  const videoProducerId = useRef();
  const consumers = useRef([]);
  const localVideoCont = useRef();
  const peersRef = useRef([]);
  const currentMicState = useRef(false);
  const currentVideoState = useRef(false);
  const messageRef = useRef();
  const mutedAllStatus = useRef(false);
  const videoMutedAllStatus = useRef(false);
  const adminAudioMutedRef = useRef(false);
  const adminVideoMutedRef = useRef(false);
  const startStatus = useRef(false);
  const endStatus = useRef(false);
  const firsTimeLoading = useRef(true);
  const roomIdRef = useRef(id);
  const adminRef = useRef(false);
  const timerAlertShownRef = useRef(false);
  const leaveClickedRef = useRef(false);
  const cleanupsRef = useRef([]);
  // custom hooks
  const { devices } = useDetectDevices({localStreamRef});
  //

  let connectionErrCount = 0;
  useEffect(() => {
    dispatch(showGlobalLoader());
    const socket = io(domain, {
      path: "/v1/ws/room",
      query: { token: user?.accessToken },
    });

    socket.on("connect", () => {
      connectionErrCount = 0;
      console.log(`connect`);
    });

    socket.on("connect_error", (err) => {
      connectionErrCount++;

      if (connectionErrCount >= 3) {
        handleLeave();
        dispatch(
          alertDialog({
            title: t("APP.ALERT_DIALOGS.TITLE.GAME_ROOM.ERROR_500"),
            icon: "error",
          }),
        );
      }
      console.log(`connect_error due to ${err.message}`); //Outputs connect_error due to timeout
    });
    socketRef.current = socket;
    setSocket(socket);
    return () => {
      socketRef.current?.disconnect();
      dispatch(hideGlobalLoader());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // New function: getState
  async function getState() {
    return new Promise((resolve, reject) => {
      socket.emit("getState", roomIdRef.current, (response) => {
        resolve(response);
      });
    });
  }
  // New function: getRemoteWokeStatus
  async function getRemoteWokeStatus(socketId) {
    return new Promise((resolve, reject) => {
      socket.emit(
        "getWokeStatus",
        { roomId: roomIdRef.current, socketId: socketId },
        (response) => {
          resolve(response);
        },
      );
    });
  }

  // New function: getWokeStatus
  async function getWokeStatus() {
    return new Promise((resolve, reject) => {
      socket.emit(
        "getWokeStatus",
        { roomId: roomIdRef.current, socketId: socket.id },
        (response) => {
          resolve(response);
        },
      );
    });
  }

  params = {
    produceTransport,
    mediasoupDevice,
    socket,
    roomId: roomIdRef.current,
    roomIdRef,
    videoProducerId,
    audioProducerId,
    localStreamRef,
    audioProducer,
    videoProducer,
    currentMicState,
    receiveTransport,
    peersRef,
    setPeers,
    peers,
    micHiddenElems,
    setMicStatus,
    setMicAllStatus,
    viewer,
    consumers,
    currentVideoState,
    setVideoStatus,
    mutedAllStatus,
    videoMutedAllStatus,
    setVideoAllStatus,
    startStatus,
    endStatus,
    setIsGameStart,
    setIsGameEnded,
    user,
    isNightMode,
    setIsNightMode,
    messageRef,
    setMessages,
    currentSelectedPeer,
    adminAudioMutedUsers,
    setAdminAudioMutedUsers,
    audioMutedUsers,
    setAudioMutedUsers,
    adminVideoMutedUsers,
    setAdminVideoMutedUsers,
    videoMutedUsers,
    setVideoMutedUsers,
    setUserPersonalAction,
    setDeclinedPermissionUsers,
    setAwakedPlayers,
    setIsAdmin,
    isAdmin,
    firsTimeLoading,
    dispatch,
    setAdminVideoMuted,
    setAdminAudioMuted,
    adminAudioMutedRef,
    adminVideoMutedRef,
    setLikeDislikeChallange,
    setAbilityCardInfo,
    setRoomData,
    i18n,
    t,
    location,
    type,
    password,
    adminRef,
    isGameStart,
    roomData,
    timerAlertShownRef,
    leaveClickedRef,
    setMicHiddenElems,
    getState,
    getRemoteWokeStatus,
    getWokeStatus,
    setCameraMicPermission,
    camerMicPermission,
    openVideo,
    openMic,
    setInternetStatus,
    setLocalUser,
    cleanupsRef,
    setLocalStream,
    setScreenArray,
    awakedPlayers,
    waitingForResponse,
    setWaitingForResponse,
    setinvitePopup,
    setShowPlayPopup,
    setReconnectingUsers,
    decodedParams,
    shiftToWatchMode,
    setPlayerAbility,
    setMediaAllowedTooltip,
    setCaptureMediaStreams,
    setPendingMediaReqSockIds,
    setRejoinEnabled,
  };
  useEffect(() => {
    if (!socket) return;
    const cleanups = listenEvents();
    return () => {
      cleanups();
      console.log("cleanupsRef.current", cleanupsRef.current);
      cleanupsRef.current.forEach((cleanup) => cleanup());
      // eslint-disable-next-line react-hooks/exhaustive-deps
      localStreamRef.current?.getTracks()?.forEach((track) => {
        track?.stop();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      produceTransport.current?.close();
      socket.off("connect");
    };
  }, [socket]);

  useEffect(() => {
    const gridsArray = [...peersRef.current, ...localUser];
    // Sort the array: admin last, then sort by _id for the rest
    gridsArray.sort((a, b) => {
      if (a.role === "GOD" && b.role !== "GOD") {
        return 1; // Move admin to the end
      } else if (a.role !== "GOD" && b.role === "GOD") {
        return -1;
      }
      return a._id.localeCompare(b._id);
    });
    setScreenArray(gridsArray);
  }, [peers, localUser]);

  useEffect(() => {
    if (!socket) return;
    socket.on("resumeConsumers", () => {
      if (!viewer) {
        const { isAdmin } = params;
        if (isAdmin && type !== "audio") {
          handleVideo(false);
        }

        consumers.current?.forEach((consumer) => {
          socket.emit("resumeConsumer", consumer?.storageId, async () => {
            if (consumer.consumer?.kind === "audio") {
              await consumer.consumer?.resume();
            }
          });
        });
      } else {
        consumers.current?.forEach((consumer) => {
          socket.emit("resumeConsumer", consumer?.storageId, async () => {
            await consumer.consumer?.resume();
          });
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    socket.on("resumeConsumer", async (data) => {
      const { wokePlayers } = data;
      // this will only work for new wake up user
      setAwakedPlayers(wokePlayers);
      setPlayerWakeStatus(true);

      // await audioProducer.current?.resume();
      // const isAudioResumed = !audioProducer.current?.paused;
      // if (isAudioResumed) {
      //   currentMicState.current = true;
      //   setMicStatus(true);
      // }
      // setMicStatus(true);
      resumeConsumer(data);
    });
  }, [socket]);

  async function resumeConsumer(data) {
    if ("producers" in data) {
      data?.producers?.forEach((producer) => {
        if (producer.kind === "video") {
          startConsumeProducer(producer);
        }
        // const cons = consumers.current.filter(
        //   (consumer) => consumer?.socketId === producer.socketId,
        // );
        // cons?.forEach((consumer) => {
        //   if (consumer?.kind === "video") return;
        //   console.log("audio consumer", consumer);
        //   socket.emit("resumeConsumer", consumer?.storageId, async () => {
        //     await consumer?.consumer?.resume();
        //   });
        // });
        const audioConsumers = consumers.current.filter(
          (consumer) =>
            consumer?.socketId === producer.socketId &&
            consumer?.kind !== "video",
        );

        audioConsumers.forEach(async (consumer) => {
          console.log("Audio consumer", consumer);
          const { storageId, consumer: audioConsumer } = consumer || {};
          if (audioConsumer) {
            socket.emit("resumeConsumer", storageId, async () => {
              await audioConsumer.resume();
            });
          }
        });
      });
    }

    // handleVideo(false);
  }

  useEffect(() => {
    if (!socket) return;
    socket.on("pauseConsumer", async (socketId) => {
      setPlayerWakeStatus(false);
      //on sleep set to empty so , he can't see futher info related to waked players
      setAwakedPlayers([]);
      await audioProducer.current?.pause();
      const isAudioPaused = audioProducer.current?.paused;
      if (isAudioPaused) {
        currentMicState.current = false;
        setMicStatus(false);
      }
      pauseConsumer(socketId);
    });
  }, [socket]);

  async function pauseConsumer(socketId) {
    handleVideo(true);
    consumers.current?.forEach((consumer) => {
      socket.emit("pauseConsumer", consumer?.storageId, async () => {
        if (consumer.consumer?.kind === "audio") {
          await consumer.consumer?.pause();
        } else {
          socket.emit("closeConsumer", {
            storageId: consumer?.storageId,
            socketId: consumer?.socketId,
          });
        }
      });
    });
  }

  useEffect(() => {
    if (internetStatus) {
      console.log("internet connection available");
    } else {
      console.log("internet connection is not available");
    }
  }, [internetStatus]);

  useEffect(() => {
    if (!socket) return;
    socket.on("startAdminProduce", async () => {
      if (adminRef.current) {
        handleMic(false);
        if (type === "audio") {
          return;
        }
        handleVideo(false);
        handleMic(false);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    socket.on("userAbility", (data, roomState) => {
      const abilityData = data.find((obj) => obj.userId === user.id)?.ability;
      setPlayerAbility(abilityData);
      setAbilityCardInfo(data);
      setIsGameStart(true);
      startStatus.current = true;

      const isNight = roomState === "NIGHT";
      if (!adminRef.current && data && !isNight) {
        setTimeout(() => {
          setShowPlayPopup(true);
        }, 1000);
      }
    });
  }, [socket]);

  // Wake lock started **********************
  let wakeLock = null;

  const requestWakeLock = async () => {
    if ('wakeLock' in navigator && document.visibilityState === 'visible') {
      try {
        wakeLock = await navigator.wakeLock.request('screen');
      } catch (error) {
        console.warn('Wake Lock request failed: ', error);
      }
    } else if (document.visibilityState !== 'visible') {
      console.warn('Wake Lock request failed: Page is not visible');
    } else {
      console.warn('Wake Lock API is not supported.');
    }
  };
  
  const releaseWakeLock = async () => {
    if (wakeLock !== null) {
      try {
        await wakeLock.release();
        wakeLock = null;
      } catch (error) {
        console.warn('Failed to release Wake Lock: ', error);
      }
    }
  };
  
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      requestWakeLock();
    } else {
      releaseWakeLock();
    }
  };
  
  document.addEventListener('visibilitychange', handleVisibilityChange);
  
  const checkForInactivity = () => {
    if (inActiveUserTime < Date.now() && document.visibilityState === 'visible') {
      requestWakeLock();
    }
  };

  // Wake lock ended **********************

  const updateExpireTime = () => {
    const expireTime = Date.now() + 3000 * 10;
    setInActiveUserTime(expireTime);
  };

  useEffect(() => {
    const interval = setInterval(checkForInactivity, 3000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // Update InActiveUserTime when component mounts or when user interacts with the page
    updateExpireTime();

    // List of events to listen for user activity
    const events = ["click", "keypress", "scroll", "mouseover"];
    events.forEach((event) => {
      window.addEventListener(event, updateExpireTime);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, updateExpireTime);
      });
    };
  }, []);
  //

  useEffect(() => {
    if (isNightMode) {
      dispatch(hideAlertDialogue());
    }
  }, [isNightMode]);
  //
  return {
    peers,
    setPeers,
    setCurrentSelectedPeer,
    currentSelectedPeer,
    micHiddenElems,
    localVideoCont,
    localStreamRef,
    viewer,
    isAdmin,
    micStatus,
    adminVideoMuted,
    videoStatus,
    micAllStatus,
    videoAllStatus,
    isGameStart,
    isGameEnded,
    isNightMode,
    roomId: roomIdRef.current,
    messageRef,
    messages,
    user,
    adminAudioMutedUsers,
    audioMutedUsers,
    adminVideoMutedUsers,
    videoMutedUsers,
    likeDislikeChallange,
    userPersonalAction,
    adminAudioMuted,
    declinedPermssionUsers,
    abilityCardInfo,
    roomData,
    awakedPlayers,
    type,
    getState,
    getRemoteWokeStatus,
    getWokeStatus,
    camerMicPermission,
    playerWakeStatus,
    internetStatus,
    localUser,
    screenArray,
    localStream,
    setLocalStream,
    peersRef,
    waitingForResponse,
    invitePopup,
    setinvitePopup,
    showPlayPopup,
    setShowPlayPopup,
    reconnectingUsers,
    playerAbility,
    socket,
    mediaAllowedTooltip,
    captureMediaStreams,
    mode,
    handleLeavePlayer,
    pendingMediaReqSockIds,
    rejoinEnabled,
    abilityIconDistance,
    setAbilityIconDistance,
    devices,
    audioProducer,
    setCaptureMediaStreams
  };
};

export { params };
