import { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";

const useDetectMobile = () => {
  const [isLikelyMobile, setIsLikelyMobile] = useState(isMobile || isTablet);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileUA =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
        userAgent,
      );
    const hasOrientationApi =
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1;

    const checkIfMobile =
      isMobile || isTablet || isMobileUA || hasOrientationApi;

    setIsLikelyMobile(checkIfMobile);
  }, []);

  return isLikelyMobile;
};

export default useDetectMobile;
