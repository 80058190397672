import { params } from "../../../hooks/game-room/handlegameRoom.js";
let timeOutId;
export const handleSendChallenge = () => {
  const { socket, roomId, setUserPersonalAction } = params;

  setUserPersonalAction((pre) => {
    clearTimeout(timeOutId);
    if (pre === "challengeUsers") {
      return "";
    } else {
      timeOutId = setTimeout(() => {
        setUserPersonalAction((pre) => {
          return pre === "challengeUsers" ? "" : pre;
        });
      }, 1000 * 20);
      return "challengeUsers";
    }
  });

  socket.emit("sendChallenge", roomId);
};
