import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { ReactComponent as CancelIcon } from "../../assets/icons/lobby/cancelIcon.svg";
import { useTranslation } from "react-i18next";
import { useGetAbilities } from "../../hooks/game-lobby/gameLobby.hooks.js";

const AddAbilityPopup = ({ openAddAbilityPopup, setOpenAddAbilityPopup }) => {
  const { createFeedback } = useGetAbilities();
  const { t } = useTranslation();
  const [feedbackDesc, setFeedbackDesc] = useState("");
  const [emptyDesc, setEmptyDesc] = useState("");

  const handleChange = (e) => {
    setFeedbackDesc(e.target.value);
  };

  const handleFeedback = () => {
    createFeedback(feedbackDesc, setEmptyDesc);
  };

  const handleClose = () => {
    setOpenAddAbilityPopup(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openAddAbilityPopup}
      className="add-ability-popup-parent-wrapper"
    >
      <Box className="popup-wrap">
        <Box className="popup-head">
          <Box sx={{ flex: "1" }}></Box>
          <DialogTitle className="popup-title" id="customized-dialog-title">
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                color: "var(--silverGray)",
              }}
            >
              {t("APP.LOBBY.ADD_ABILITY_POPUP.REQUEST_ADD_ABILITY")}
            </Typography>
          </DialogTitle>
          <Box sx={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon className="cancel-icon" />
            </IconButton>
          </Box>
        </Box>
        <DialogContent className="popup-middle">
          <TextField
            error
            onChange={handleChange}
            className="enter-ability-textfield"
            placeholder={t(
              "APP.LOBBY.ADD_ABILITY_POPUP.ABILITY_INPUT_PLACEHOLDER",
            )}
            helperText={
              emptyDesc
                ? t("APP.LOBBY.ADD_ABILITY_POPUP.INPUT_EMPTY_ERROR")
                : ""
            }
            sx={{
              "& fieldset": {
                borderColor: emptyDesc ? "var(--darkRed) !important" : "",
              },
            }}
            inputProps={{
              sx: {
                color: "var(--mutedGray)",
                "&::placeholder": {
                  opacity: "1",
                  color: emptyDesc ? "var(--darkRed)" : "",
                },
                fontSize: "10px",
                fontWeight: "400",
              },
            }}
          />
        </DialogContent>

        <DialogActions className="popup-bottom">
          <Button
            onClick={handleFeedback}
            className="action-btn"
            color="primary"
            variant="contained"
            size="large"
          >
            {t("APP.LOBBY.ADD_ABILITY_POPUP.SUBMIT_BTN")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddAbilityPopup;

AddAbilityPopup.propTypes = {
  openAddAbilityPopup: PropTypes.any.isRequired,
  setOpenAddAbilityPopup: PropTypes.func.isRequired,
};
