import { params } from "../../../hooks/game-room/handlegameRoom.js";
export const handleDayNight = () => {
  const {
    setIsNightMode,
    socket,
    roomId,
    setAwakedPlayers,
    peers,
    setAdminAudioMutedUsers,
  } = params;
  setIsNightMode((isNight) => {
    // if already night mode , we have to emit day mode
    let actionType = isNight ? "dayMode" : "nightMode";
    // shift to day mode
    if (actionType === "dayMode") {
      // make it empty so , when shifts to night again , icon for awake player will be correct
      setAwakedPlayers([]);
      // to chnage icon for admin actions for mic
      const allplayersSocketIds = [];
      peers.forEach((peer) => {
        allplayersSocketIds.push(peer.socketId);
      });
      setAdminAudioMutedUsers(allplayersSocketIds);
    }
    socket.emit(actionType, roomId, !isNight);
    return !isNight;
  });
};
