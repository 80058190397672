import styled, { keyframes, css } from "styled-components";
import { Grid } from "@mui/material";
const delayedFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const GridItem = styled(({ shouldanimate, ...rest }) => <Grid {...rest} />)`
  ${(props) =>
    props.shouldanimate &&
    css`
      animation: ${delayedFadeIn} 0.75s cubic-bezier(0.4, 0, 0.2, 1);
    `}
  border-radius: 15px;

  @media (max-width: 600px) {
    border-radius: 10px;
  }
`;

export const Users = styled.div`
  position: absolute;
  border-radius: 15px;
  @media (max-width: 600px) {
    border-radius: 10px;
  }
`;
