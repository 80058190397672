import UAParser from 'ua-parser-js';

const getUserMetadata = () => {
    const parser = new UAParser();
    const result = parser.getResult();

    return {
        browser: result.browser.name,
        browserVersion: result.browser.version,
        os: result.os.name + ' ' + result.os.version,
        device: result.device.vendor + " " + result.device.model,
    };
};

export default getUserMetadata;
