import { params } from "../../../hooks/game-room/handlegameRoom.js";
export const handleChat = () => {
  const { socket, viewer, setMessages } = params;
  let event = viewer ? "viewerChat" : "chat";
  let stateObj = viewer ? "viewers" : "players";
  socket.on(event, (message) => {
    setMessages((prev) => {
      const newState = { ...prev };
      newState[stateObj].push(message);
      return newState;
    });
  });
};
