import { useDispatch } from "react-redux";
import {
  subscriptionApi,
  verifyApi,
} from "../../features/subscription/subscriptionThunks.js";
import { useSearchParams } from "react-router-dom";
import { alertDialog } from "../../features/global/globalSlice.js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useHandleSubscription = (currency) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const paymentStatus = searchParams.get("Status");
  const paymentAuthority = searchParams.get("Authority");
  const paymentPlan = localStorage.getItem("plan");

  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus === "NOK") {
        dispatch(
          alertDialog({
            icon: "error",
            title: t("APP.SUBSCRIPTION.BODY.ALERT_DIALOGUE_UNSUCCESS_TITLE"),
          }),
        );
        window.history.replaceState("", "", window.location.pathname);
      } else if (paymentStatus === "OK") {
        (async () => {
          try {
            const response = await dispatch(
              verifyApi({
                payload: {
                  plan: paymentPlan,
                  authority: paymentAuthority,
                  currency: currency,
                },
              }),
            ).unwrap();
            window.history.replaceState("", "", window.location.pathname);
            if (response.code === 100) {
              const message =
                currency === "IRT"
                  ? t("APP.SUBSCRIPTION.BODY.ALERT_DIALOGUE_REFERENCE_ID") +
                    " " +
                    response?.ref_id
                  : "";

              dispatch(
                alertDialog({
                  icon: "info",
                  title: t(
                    "APP.SUBSCRIPTION.BODY.ALERT_DIALOGUE_SUCCESS_TITLE",
                  ),
                  text: message,
                }),
              );
            } else if (response.code === 101) {
              dispatch(
                alertDialog({
                  icon: "info",
                  title: t(
                    "APP.SUBSCRIPTION.BODY.ALERT_DIALOGUE_PAYMENT_VERIFIED_TITLE",
                  ),
                }),
              );
            }
          } catch (error) {
            dispatch(
              alertDialog({
                icon: "error",
                title: t(
                  "APP.SUBSCRIPTION.BODY.ALERT_DIALOGUE_UNSUCCESS_TITLE",
                ),
              }),
            );
          }
        })();

        dispatch(
          verifyApi({
            plan: "BASIC",
            authority: paymentAuthority,
          }),
        );
      }
    }
  }, []);

  const handleSubscription = async (plan) => {
    try {
      const respone = await dispatch(
        subscriptionApi({ payload: { plan, currency } }),
      ).unwrap();
      if (currency === "IRT") {
        window.location.href = `https://www.zarinpal.com/pg/StartPay/${respone.authority}`;
      } else {
        window.location.href = respone.redirectUrl;
      }
    } catch (error) {
      dispatch(
        alertDialog({
          icon: "error",
          title: t(`APP.ERROR_CODES.${error.code}`),
        }),
      );
    }
  };

  return { handleSubscription };
};
