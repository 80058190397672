import axios from "axios";
import baseUrl from "../configs/main.config.js";
import store from "../store/store.js";
import {
  showGlobalLoader,
  hideGlobalLoader,
} from "../features/global/globalSlice.js";

axios.defaults.baseURL = baseUrl;
var loadingStatusArray = [];
axios.interceptors.request.use(
  async (config) => {
    if (config.showGlobalLoader) {
      if (!loadingStatusArray.includes(config.url)) {
        loadingStatusArray.push(config.url);
      }
      if (loadingStatusArray.length > 0) {
        store.dispatch(showGlobalLoader());
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    if (response.config?.showGlobalLoader) {
      loadingStatusArray.splice(
        loadingStatusArray.indexOf(response.config.url),
        1,
      );
      if (loadingStatusArray.length === 0) {
        store.dispatch(hideGlobalLoader());
      }
    }
    // alert(response.data.message);
    return response.data ? response.data : response;
  },
  function (error) {
    if (error.config?.showGlobalLoader) {
      loadingStatusArray.splice(
        loadingStatusArray.indexOf(error.config.url),
        1,
      );
      if (loadingStatusArray.length === 0) {
        store.dispatch(hideGlobalLoader());
      }
    }
    if (error.response.status === 401) {
      //Unauthorized user
      // store.dispatch(setNavigateTo("login"));
    } else {
      // alert(error.response.data.message);
    }
    return Promise.reject(error);
  },
);
