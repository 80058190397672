const sanitizeMessage = (message) => {
  let sanitizedMessage = message;

  // Limit the message length
  const MAX_LENGTH = 200; // Example max length
  if (sanitizedMessage.length > MAX_LENGTH) {
    sanitizedMessage = sanitizedMessage.substring(0, MAX_LENGTH);
  }

  return sanitizedMessage;
};
export default sanitizeMessage;
