import {
  CircularProgress,
  Dialog,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

export default function NoConnection({ isConnectionGone }) {
  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isConnectionGone}
        className="noconnection-dialog-parent-wrapper"
      >
        <DialogTitle className="popup-title" id="customized-dialog-title">
          <CircularProgress size={25} />{" "}
          {/* <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "600",
              color: "var(--white)",
              opacity: "0.9",
            }}
          >
            {t("APP.GAME_ROOM.NO_CONNECTION.TITLE")}
          </Typography> */}
        </DialogTitle>

        {/* <DialogContent className="popup-middle">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "var(--silverGray)",
            }}
          >
            {t("APP.GAME_ROOM.NO_CONNECTION.DESC")}
          </Typography>
        </DialogContent> */}

        {/* <DialogActions className="popup-bottom">
          <Button
            type="submit"
            onClick={handleLeavePlayer}
            sx={{
              borderRadius: "10px",
              width: "94px",
              height: "31px",
              fontSize: "15px",
              fontWeight: "400",
            }}
            color="darkRed"
            variant="contained"
            size="large"
          >
            {t("APP.GAME_ROOM.NO_CONNECTION.LEAVE_BTN")}
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}

NoConnection.propTypes = {
  isConnectionGone: PropTypes.bool.isRequired,
};
