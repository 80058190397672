import React, { useRef } from "react";
import { MuiTelInput } from "mui-tel-input";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { getCodeByCountyName } from "../../../handlers/auth/getCodeByCountyName.js";
import { useState } from "react";
import { useHandlePhoneInput } from "../../../hooks/auth/PhoneInput.hook.js";
import { Typography, FormHelperText } from "@mui/material";
import dropDownIcon from "../../../assets/icons/auth/dropDownIcon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
export const PhoneInput = ({ validation, screen }) => {
  const { user } = useSelector((state) => state.auth);
  const phoneInputRef = useRef(null);
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState("");
  const defaultCountryRef = useRef(
    localStorage.getItem("country-code") || user.country?.code || "IR",
  );
  useHandlePhoneInput(screen, phoneInputRef);
  useEffect(() => {
    let code = countryCode;
    const flag = document.querySelector(".MuiTelInput-Flag");
    const countryShortName = flag.getAttribute("data-testid");
    if (!countryCode) {
      code = getCodeByCountyName(countryShortName);
    }
    localStorage.setItem("country-code", countryShortName);
    flag.innerHTML = ` ${code} <img style="margin-left:8px" src=${dropDownIcon} alt="dropDown"/>`;
  }, [countryCode]);

  return (
    <Box
      ref={phoneInputRef}
      sx={{ height: "103px", width: "100%" }}
      className={`phone-input-custom-wrapper phone-input-parent-wrap`}
    >
      <Typography
        color={"var(--white)"}
        sx={{
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "18.75px",
          marginBottom: "7px",
        }}
      >
        {t("APP.AUTH.PHONE_NUMBER_INPUT.PHONE_NUMBER_LABEL")}
      </Typography>
      <MuiTelInput
        name="phoneNumber"
        fullWidth
        className="phone-number-wrapper"
        sx={{
          borderRadius: "5px",
          height: "50px",
          "& fieldset": {
            borderColor:
              validation.includes("phoneNumber") ||
              validation.includes("invalidPhoneNumber")
                ? "var(--darkRed) !important"
                : "var(--mutedGray) !important",
          },
          "& input": {
            "&::before": {
              content: '""',
              height: "10px",
              width: "10px",
              background: "red",
            },

            color: "var(--white)",
          },
        }}
        defaultCountry={defaultCountryRef.current}
        value={countryCode}
        onChange={(code) => {
          setCountryCode(code);
        }}
      />
      <FormHelperText
        sx={{
          visibility:
            validation.includes("phoneNumber") ||
            validation.includes("invalidPhoneNumber")
              ? ""
              : "hidden",
          ml: "14px",
          mr: "14px",
          mt: "5px",
          fontSize: "10px",
          fontWeight: "400",
        }}
        error
        id="phone-number-error"
      >
        {validation.includes("invalidPhoneNumber")
          ? t("APP.AUTH.PHONE_NUMBER_INPUT.INVALID_PHONE_NUMBER_ERROR")
          : t("APP.AUTH.FIELD_REQUIRED")}
      </FormHelperText>
    </Box>
  );
};

PhoneInput.propTypes = {
  validation: PropTypes.array.isRequired,
  screen: PropTypes.string,
};
