import { params } from "../../../hooks/game-room/handlegameRoom.js";
export const userLeft = () => {
  const { socket } = params;
  socket.on("userLeft", (userInfo) => {
    const {
      viewer,
      setAwakedPlayers,
      isNightMode,
      peersRef,
      setPeers,
      startStatus,
      setReconnectingUsers,
    } = params;
    const { socketId } = userInfo;
    if (startStatus.current) {
      // to remove wake player from wake array
      if (isNightMode && !viewer) {
        setAwakedPlayers((prev) =>
          prev.includes(socketId) ? prev.filter((id) => id !== socketId) : prev,
        );
      }
    } else {
      if (peersRef.current?.some((item) => item?.socketId === socketId)) {
        const filteredArr = peersRef.current.filter(
          (peer) => peer?.socketId !== socketId,
        );
        peersRef.current = filteredArr;
        setPeers(peersRef.current);
      }
    }
    // stop reconecting , now user is disconnected
    setReconnectingUsers((prev) => prev.filter((id) => id !== socketId));
  });
};
