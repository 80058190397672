import { useDispatch } from "react-redux";
import { logoutApi } from "../../features/auth/authThunks.js";
import { logoutUser } from "../../features/auth/authSlice.js";
import { setLanguage } from "../../features/global/globalSlice.js";

export const useHandleLogout= () => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      await dispatch(logoutApi({})).unwrap();
      dispatch(setLanguage(""));
      dispatch(logoutUser());
    } catch (error) {}
  };

  return { handleLogout };
};
