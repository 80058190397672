import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import VideoAudioForAll from "./admin-actions/Video&AudioForAll.js";
import { handleLeavePlayer } from "../../../handlers/game-room/index.js";
import { useTranslation } from "react-i18next";
// import TimeLeft from "./TimeLeft.js";
const LeftSide = ({ stream }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        type="submit"
        onClick={handleLeavePlayer}
        sx={{
          borderRadius: "10px",
          width: "94px",
          height: "31px",
          fontSize: "15px",
          fontWeight: "400",
        }}
        color="darkRed"
        variant="contained"
        size="large"
      >
        {t("APP.GAME_ROOM.FOOTER.LEFT_SIDE.LEAVE_BTN")}
      </Button>
      <VideoAudioForAll stream={stream} />
      {/* {!viewer && <TimeLeft stream={stream} />} */}
    </>
  );
};
LeftSide.propTypes = {
  stream: PropTypes.object.isRequired,
};

export default LeftSide;
