import { useDispatch } from "react-redux";
import { loginApi, sendOtpApi } from "../../features/auth/authThunks.js";
import {
  setNavigateTo,
  setReRender,
} from "../../features/global/globalSlice.js";
import { getPhoneNumber } from "../../handlers/auth/getPhoneNumberFromInput.js";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
let timeOutId;
let errorTimeOutId;
export const useHandleLoginHook = ({
  setValidation,
  password,
  setErrorCode,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const setCookie = (navigationState) => {
    // Calculate expiry time 5 seconds from now
    const expiryTime = new Date(new Date().getTime() + 1000);
    Cookies.set("navigationState", navigationState, { expires: expiryTime });
  };

  const handleLogin = () => {
    let emptyFields = [];
    const phoneNumber = getPhoneNumber(emptyFields);
    if (!password) {
      emptyFields.push("password");
    }
    setValidation(emptyFields);
    // hide errors after 3sec
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setValidation([]);
    }, 3000);
    if (emptyFields.length < 1) {
      (async () => {
        try {
          await dispatch(
            loginApi({ payload: { phoneNumber, password } }),
          ).unwrap();

          dispatch(setReRender(true));
          setTimeout(() => {
            dispatch(setReRender(false));
          }, 0);
          setCookie(!location.state?.from ? "" : location.state?.from);
        } catch (error) {
          const handleError = (errorCode) => {
            setErrorCode(errorCode);
            clearTimeout(errorTimeOutId);
            errorTimeOutId = setTimeout(() => {
              setErrorCode(null);
            }, 6000);
          };

          if (error.code === 4007) {
            try {
              await dispatch(sendOtpApi({ payload: { phoneNumber } })).unwrap();
              dispatch(
                setNavigateTo(
                  `/otp?type=registration&phone-number=${phoneNumber}`,
                ),
              );
            } catch (error) {
              handleError(error.code);
            }
          }

          handleError(error.code);
        }
      })();
    }
  };

  return { handleLogin };
};
