import { params } from "../../../hooks/game-room/handlegameRoom.js";
import { cameraMicPermissionAlert } from "./handleStartStream.js";
const deniedCallback = () => {
  const { setCameraMicPermission } = params;
  cameraMicPermissionAlert("handlestartstream");
  setCameraMicPermission(false);
};

export const handleMediaPermissionChange = async ({ grantedCallback }) => {
  const { type, cleanupsRef } = params;
  const name = type === "audio" ? "microphone" : "camera";
  const status = await navigator.permissions.query({ name: name });

  // Add the event listener for the change event
  const handleChange = async (event) => {
    if (event.target.state === "granted") {
      // Permission granted, proceed with getUserMedia
      await grantedCallback();
    } else {
      // Permission denied
      deniedCallback();
      const video = type !== "audio";
      setTimeout(async () => {
        try {
          console.log("video", video);
          await navigator.mediaDevices.getUserMedia({ video, audio: true });
        } catch (error) {
          console.log("error: ", error);
        }
      }, 1000);
    }
  };
  status.addEventListener("change", handleChange);
  // Push the cleanup function into the cleanupsRef
  cleanupsRef.current.push(() => {
    status.removeEventListener("change", handleChange);
  });
};
