import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isAdmin: false,
};

const gameRoomSlice = createSlice({
  name: "gameRoom",
  initialState,
  reducers: {
    setIsAdmin: (state, { payload }) => {
      state.isAdmin = payload;
    },
  },
});
export const { setIsAdmin } = gameRoomSlice.actions;
export default gameRoomSlice.reducer;
