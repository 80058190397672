import { useDispatch } from "react-redux";
import { resetPasswordApi } from "../../features/auth/authThunks.js";
import { setNavigateTo } from "../../features/global/globalSlice.js";
let timeOutId;
let errorTimeOutId;
export const useHandleResetPassword = ({
  password,
  reEnterPassword,
  setValidation,
  setErrorCode,
  phoneNumber,
}) => {
  const dispatch = useDispatch();
  const handleResetPassword = () => {
    let emptyFields = [];
    if (!password) {
      emptyFields.push("password");
    } else if (password.length < 8) {
      emptyFields.push("shortPassword");
    }
    if (!reEnterPassword) {
      emptyFields.push("reEnterPassword");
    } else if (reEnterPassword.length < 8) {
      emptyFields.push("reEntershortPassword");
    }

    setValidation(emptyFields);
    // hide errors after 3sec
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setValidation([]);
    }, 3000);

    if (emptyFields.length < 1) {
      if (password !== reEnterPassword) {
        setErrorCode("notMatch");
        clearTimeout(errorTimeOutId);
        errorTimeOutId = setTimeout(() => {
          setErrorCode(null);
        }, 6000);
        return;
      }
      (async () => {
        try {
          await dispatch(
            resetPasswordApi({ payload: { phoneNumber, password } }),
          ).unwrap();
          dispatch(setNavigateTo("/login"));
        } catch (error) {
          clearTimeout(errorTimeOutId);
          setErrorCode(error.code);
          errorTimeOutId = setTimeout(() => {
            setErrorCode(null);
          }, 6000);
        }
      })();
    }
  };
  return { handleResetPassword };
};
